import React from "react";
import { Helmet } from "react-helmet";
import BannerTwo from "../reuseable/Banner2";
import CompanySlider from "./WhyUs/CompaySlider";
import KeepingYouUp from "../reuseable/KeepingYouUp";
import Passion from "../reuseable/Passion";
import Testimonails from "../reuseable/Testimonails";
import WhyUsIcons from "../reuseable/WhyUsIcons";
import WhyChooseUsSecond from "./WhyUs/WhyChooseUsSecond";

const WhyUs = () => {
  const bannerData = {
    heading: "What Makes Us The Right Choice?",
    peragraph: "We Deliver Your Money’s Worth",
  };
  const PassionData = {
    img: "assests/img/Proactive-Technical-Support-Team.png",
    Text: [
      <>
        <h3>
          Reliable Dependable <span> Loyal </span>{" "}
        </h3>
        <p>
          At Websouls, we come together to earn the trust of our customers by
          serving them with a web hosting experience that’s focused on
          delivering speed, security, superb support, and excellent performance,
          with an uptime that exceeds 99.9%.
        </p>
        <p>
          We’re proud to be Pakistan’s no. 1 web hosting company and one of the
          very few official partners of cPanel in Pakistan. The reliability of
          our services is what sets us apart and we owe it all to the utter
          dedication and expert knowledge of our hardworking team members,
          who’re committed to helping our clients hit it big online.&nbsp;
        </p>
        <p>
          WebSouls is an organization that has successfully brought together
          individuals who’re interested in not just aiming to achieve goals, but
          actually getting the job done like impoverished leaders!
        </p>
        <p>
          Our Speciality: Focusing on what our clients need and working hard to
          make going online easier, faster, and safer for them!
        </p>
      </>,
    ],
  };
  const WhyItWorthData = {
    box: [
      {
        heading: "Trusted by 1000s of Corporate and Government Organizations",
        icon: "icon ws-reviews",
      },
      {
        heading: "Relaxed and Friendly Environment That Fosters True Teamwork",
        icon: "icon ws-easy",
      },
      {
        heading:
          "No. 1 Provider of Highest Quality Web Hosting Services in Pakistan",
        icon: "icon ws-award",
      },
    ],
  };
  const KeepingYouUpData = {
    heading: "Keeping You Up And Running",
    subHeading: "Secure, Reliable, and Blazing-Fast",
    box: [
      {
        heading: "Domain Registration",
        peragraph:
          "Providing you with unique domain names that can be your new brand identity.",
        link: "/domain-registration",
      },
      {
        heading: "Web Hosting",
        peragraph:
          "Hosting plans charged with ultimate power, security, reliability, and blazing fast speed!",
        link: "/shared-hosting",
      },
      {
        heading: "VPS Hosting",
        peragraph:
          "Hosting that comes with remarkable performance for those who need more power.",
        link: "/vps-hosting",
      },
      {
        heading: "Dedicated Servers",
        peragraph:
          "Offering dedicated servers for more control and the best possible performance.",
        link: "/dedicated-server",
      },
      {
        heading: "SSL Certificates",
        peragraph:
          "Focused on keeping your and your customers' data safe from malicious attacks and hackers.",
        link: "/ssl-certificate",
      },
      {
        heading: "Web Development",
        peragraph:
          "Developing innovative websites that are 100% secure, fully featured, and highly functional.",
        link: "/web-development",
      },
    ],
  };
  return (
    <>
      <Helmet>
        <title>
          No. 1 Hosting Company | Proactive Technical Support | WebSouls
        </title>
        <meta
          name="title"
          content="No. 1 Hosting Company | Proactive Technical Support | WebSouls"
        />
        <meta
          name="description"
          content="Come with us because we believe in “We Make Customer, Not Sale”. In addition, you will find us competitive, cooperative & really helpful. We’re WebSouls."
        />
        <meta name="keywords" content="" />
        <link rel="canonical" href="https://websouls.com/whyus" />
      </Helmet>
      <BannerTwo Data={bannerData} />
      <WhyUsIcons Data={WhyItWorthData} />
      <Passion Data={PassionData} />
      <WhyChooseUsSecond />
      <CompanySlider />
      <Testimonails />
      <KeepingYouUp Data={KeepingYouUpData} />
    </>
  );
};

export default WhyUs;
