import React from "react";

const BannerTwo = (props) => {
  const { Data } = props;
  return (
    <section
      className="banner-size d-flex align-items-center height-adjust"
      style={{ backgroundColor: "#ebf2f5", height: "400px" }}
    >
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12 ">
            <div className="w-100 text-center bannerSecond">
              <h2 className="first-heading color-b">{Data?.heading}</h2>
              <p className="second-heading">{Data?.peragraph}</p>
            
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default React.memo(BannerTwo);
