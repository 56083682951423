import React from "react";
import ReactReadMoreReadLess from "react-read-more-read-less";

const InPakistan = (props) => {
  const { Data } = props;
  return (
    <section className="pakistan_hosting">
      <div className="container">
        <h1 className="first-heading">{Data?.Heading}</h1>
        <h5 className="second-heading">{Data?.subHeading}</h5>
        <hr className="divider scnd_divider mx-auto" />
        <div className="websouls_experience">
        {/* <ReactReadMoreReadLess
                charLimit={1200}
                readMoreText={"Read more..."}
                readLessText={"Read less"}
                readMoreClassName="read-more-less--more"
                readLessClassName="read-more-less--less"
            >
               {Data?.peragraph}
            </ReactReadMoreReadLess> */}
          <p>{Data?.peragraph}</p>
        </div>
      </div>
    </section>
  );
};

export default React.memo(InPakistan);
