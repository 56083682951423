import React from "react";
import BannerTwo from "../reuseable/Banner2";
import Faq from "../reuseable/Faq";

const Policy = () => {
  const bannerData = {
    heading: "Welcome To WebSouls!",
    peragraph: "We’re Glad to Have You – Please Follow the Rules",
  };
  const faqData = {
    heading: "Acceptable Use Policy",
    subHeading: "",
    faqs: [
      {
        id: 1,
        title: "Illegal use",
        desc: "WebSouls services may not be used for illegal purposes, or in support of illegal activities. WebSouls reserves the right to cooperate with legal authorities and/or injured third parties in the investigation of any suspected crime or civil wrongdoing.",
        faqId: "FAQ1",
        dataBsTarget: "#faqOne",
        ariaControls: "faqOne",
        ariaLabelledby: "FAQ1",
        faqParaghId: "faqOne",
      },
      {
        id: 2,
        title: "Harm to minors",
        desc: "Use of the WebSouls service to harm, or attempt to harm, minors in any way, including, but not limited to child pornography is strictly prohibited.",
        faqId: "FAQ2",
        dataBsTarget: "#faqTwo",
        ariaControls: "faqTwo",
        ariaLabelledby: "FAQ2",
        faqParaghId: "faqTwo",
      },
      {
        id: 3,
        title: "Threats",
        desc: "Use of the WebSouls service to transmit any material (by e-mail, uploading, posting or otherwise) that threatens or encourages bodily harm or destruction of property is strictly prohibited.",
        faqId: "FAQ3",
        dataBsTarget: "#faqThree",
        ariaControls: "faqThree",
        ariaLabelledby: "FAQ3",
        faqParaghId: "faqThree",
      },
      {
        id: 4,
        title: "Harassment",
        desc: "Use of the WebSouls service to transmit any material (by e-mail, uploading, posting or otherwise) that harasses another is strictly prohibited.",
        faqId: "FAQ4",
        dataBsTarget: "#faqFour",
        ariaControls: "faqFour",
        ariaLabelledby: "FAQ4",
        faqParaghId: "faqFour",
      },
      {
        id: 36,
        title: "Limitation Of Liability",
        desc1:
          "b) In no event we, nor any of its officers, directors and employees, shall be held liable for anything arising out of or in any way connected with your use of our website whether such liability is under contract. We, including our officers, directors and employees shall not be held liable for any indirect, consequential or special liability arising out of or in any way related to your use of our website. Your use of any information or materials on this website is entirely at your own risk, for which we shall not be liable. It shall be your own responsibility to ensure that any products, services or information available through this website meet your specific requirements. Neither we nor any third parties provide any warranty or guarantee as to the accuracy, timeliness, performance, completeness or suitability of the information and materials found or offered on this website for any particular purpose. You acknowledge that such information and materials may contain inaccuracies or errors and we expressly exclude liability for any such inaccuracies or errors to the fullest extent permitted by law.",
        desc: `a) To the fullest extent permitted by applicable law, in no event will Websouls be liable to customer for any lost profits, lost savings, lost or corrupted content data, unauthorized access to or alteration of your transmissions of content data, lost revenue, business interruption, or loss of capital (in each case, whether direct or indirect) or for any special, consequential, indirect or incidental damages arising out of or relating to this agreement or any Websouls services furnished or to be furnished under this agreement or the use thereof, even if Websouls has been advised of the possibility of such loss or damage. Except for liability arising out of Websouls's indemnification obligations, the aggregate liability of Websouls in connection with any and all claims howsoever arising out of or relating to this agreement or any Websouls services furnished or to be furnished under this agreement will in any event be absolutely limited to: the amount paid to Websouls for the services under this agreement during the twelve (12) months immediately preceding the date the damaged party notifies in writing of the claim for damages.`,
        faqId: "FAQ36",
        dataBsTarget: "#faqThirtySix",
        ariaControls: "faqThirtySix",
        ariaLabelledby: "FAQ36",
        faqParaghId: "faqThirtySix",
      },
      {
        id: 5,
        title: "Spam And Phishing Policy",
        desc: `Manipulating identifiers, such as email headers and images,to disguise the origin of any content transmitted using the Service. Using or causing to be used Service computer systems to facilitate the transmission of unsolicited or unauthorized material. This includes any promotional materials, URLs, "junk mail," "chain letters," "pyramid schemes," or any other form of unauthorized solicitation that you may upload, post, email, transmit, or otherwise make available.Using "robots" or otherwise harvesting other's email addresses from the Service. Sending a message that directs a recipient to a web site that uses a third party's branding, trademarks and or other intellectual property to mislead the recipient into believing that he is using a site authorized by that third party. Test email accounts and Test keywords are blocked at shared hosting environment in order to prevent spamming and other malicious activities. eg. test@yourdomain.com, any.test@yourdomain.com, test.any@yourdomain.com`,
        faqId: "FAQ5",
        dataBsTarget: "#faqFive",
        ariaControls: "faqFive",
        ariaLabelledby: "FAQ5",
        faqParaghId: "faqFive",
      },
      {
        id: 6,
        title: "Fraudulent activity",
        desc: `Use of WebSouls service to make fraudulent offers to sell or buy products, items, or services, or to advance any type of financial scam such as "pyramid schemes," and "chain letters" is strictly prohibited. `,
        faqId: "FAQ6",
        dataBsTarget: "#faqSix",
        ariaControls: "faqSix",
        ariaLabelledby: "FAQ6",
        faqParaghId: "faqSix",
      },
      {
        id: 7,
        title: "Impersonation",
        desc: `Adding, removing or modifying identifying network header information in an effort to deceive or mislead is prohibited. Misuse the name of any Government entity is strictly prohibited and WebSouls reserves the rights to shutdown services at request of any Government entity. Company domains registered by employees or third parties shall be considered under the ownership of organizatoin and can be claimed back after providing all necessary information. Attempting to impersonate any person by using forged headers or other identifying information is prohibited. The use of anonymous remailers or nicknames does not constitute impersonation.`,
        faqId: "FAQ7",
        dataBsTarget: "#faq",
        ariaControls: "faq",
        ariaLabelledby: "FAQ7",
        faqParaghId: "faq",
      },
      {
        id: 8,
        title: "Unsolicited commercial e-mail / Unsolicited bulk e-mail (SPAM)",
        desc: `Use of the WebSouls service to transmit any unsolicited commercial or unsolicited bulk e-mail is expressly prohibited. Violations of this type will result in the immediate termination of the offending WebSouls account. `,
        faqId: "FAQ8",
        dataBsTarget: "#faqEight",
        ariaControls: "faqEight",
        ariaLabelledby: "FAQ8",
        faqParaghId: "faqEight",
      },
      {
        id: 9,
        title: "E-mail / News Bombing",
        desc: `Malicious intent to impede another person's use of electronic mail services or news will result in the immediate termination of the offending WebSouls account. `,
        faqId: "FAQ9",
        dataBsTarget: "#faqNine",
        ariaControls: "faqNine",
        ariaLabelledby: "FAQ9",
        faqParaghId: "faqNine",
      },
      {
        id: 10,
        title: "E-mail / Message Forging",
        desc: `Forging any message header, in part or whole, of any electronic transmission, originating or passing through the WebSouls service is in violation of this POLICY.`,
        faqId: "FAQ10",
        dataBsTarget: "#faqTen",
        ariaControls: "faqTen",
        ariaLabelledby: "FAQ10",
        faqParaghId: "faqTen",
      },
      {
        id: 11,
        title: "Hourly E-mail Limit",
        desc: `At shared hosting accounts, clients are limited to send not more than 60 emails per hour. Similarly VIP accounts hosted at shared hosting are restricted to send up to 200 email per hour max. Clients can contact to our Sales Team and can take appropriate email solution if they may want to send more emails within an hour.`,
        faqId: "FAQ11",
        dataBsTarget: "#faqEleven",
        ariaControls: "faqEleven",
        ariaLabelledby: "FAQ11",
        faqParaghId: "faqEleven",
      },
      {
        id: 12,
        title: "Usenet Spaming",
        desc: `WebSouls has a zero tolerance policy for the use of its network for the posting of messages or commercial advertisements, which violate the rules, regulations, FAQ or charter of any newsgroups or mailing list. Commercial messages that are appropriate under the rules of a newsgroup or mailing list or that are solicited by the recipients are permitted.`,
        faqId: "FAQ12",
        dataBsTarget: "#faqTwelve",
        ariaControls: "faqTwelve",
        ariaLabelledby: "FAQ12",
        faqParaghId: "faqTwelve",
      },
      {
        id: 13,
        title: "Unauthorized access",
        desc: `Use of the WebSouls service to access, or to attempt to access, the accounts of others, or to penetrate, or attempt to penetrate, security measures of HostRock.Net's or another entity's computer software or hardware, electronic communications system, or telecommunications system, whether or not the intrusion results in the corruption or loss of data, is expressly prohibited and the offending WebSouls account is subject to immediate termination.`,
        faqId: "FAQ13",
        dataBsTarget: "#faqThirteen",
        ariaControls: "faqThirteen",
        ariaLabelledby: "FAQ13",
        faqParaghId: "faqThirteen",
      },
      {
        id: 14,
        title: "Copyright or trademark infringement",
        desc: `Use of the WebSouls service to transmit any material (by e-mail, uploading, posting or otherwise) that infringes any copyright, trademark, patent, trade secret or other proprietary rights of any third party, including, but not limited to, the unauthorized copying of copyrighted material, the digitization and distribution of photographs from magazines, books, or other copyrighted sources, and the unauthorized transmittal of copyrighted software.`,
        faqId: "FAQ14",
        dataBsTarget: "#faqFourteen",
        ariaControls: "faqFourteen",
        ariaLabelledby: "FAQ14",
        faqParaghId: "faqFourteen",
      },
      {
        id: 15,
        title: "Collection of personal data",
        desc: `Use of the WebSouls service to collect, or attempt to collect, personal information about third parties without their knowledge or consent.`,
        faqId: "FAQ15",
        dataBsTarget: "#faqfifteen",
        ariaControls: "faqfifteen",
        ariaLabelledby: "FAQ15",
        faqParaghId: "faqfifteen",
      },
      {
        id: 16,
        title: "Network disruptions and unfriendly activity",
        desc: `Use of the WebSouls service for any activity that affects the ability of other people or systems to use WebSouls Services or the Internet. This includes "denial of service" (DOS) attacks against another network host or individual user. Interference with or disruption of other network users, services or equipment is prohibited. It is the Member's responsibility to ensure that their network is configured in a secure manner. A Subscriber may not, through action or inaction, allow others to use their network for illegal or inappropriate actions. A Subscriber may not permit their network, through action or inaction, to be configured in such a way that gives a third party the capability to use their network in an illegal or inappropriate manner. Unauthorized entry and/or use of another company and/or individual's computer system will result in immediate account termination. WebSouls will not tolerate any subscriber attempting to access the accounts of others, or penetrate security measures of other systems, whether or not the intrusion results in corruption or loss of data.`,
        faqId: "FAQ16",
        dataBsTarget: "#faqsixteen",
        ariaControls: "faqsixteen",
        ariaLabelledby: "FAQ16",
        faqParaghId: "faqsixteen",
      },
      {
        id: 17,
        title: "Fraud",
        desc: "Involves a knowing misrepresentation or misleading statement, writing or activity made with the intent that the person receiving it will act upon it. Infringement of Copyright, Patent, Trademark, Trade Secret, or Intellectual Property",
        faqId: "FAQ17",
        dataBsTarget: "#faqSeventeen",
        ariaControls: "faqSeventeen",
        ariaLabelledby: "FAQ17",
        faqParaghId: "faqSeventeen",
      },
      {
        id: 18,
        title: "Right",
        desc: "Distribution and/or posting of copyrighted or the aforementioned infringements will not be tolerated.",
        faqId: "FAQ18",
        dataBsTarget: "#faqEighteen",
        ariaControls: "faqEighteen",
        ariaLabelledby: "FAQ18",
        faqParaghId: "faqEighteen",
      },
      {
        id: 19,
        title: "Distribution of Viruses",
        desc: "Intentional distributions of software that attempts to and/or causes damage, harassment, or annoyance to persons, data, and/or computer systems are prohibited. Such an offense will result in the immediate termination of the offending account.",
        faqId: "FAQ19",
        dataBsTarget: "#faq",
        ariaControls: "faq",
        ariaLabelledby: "FAQ19",
        faqParaghId: "faq",
      },
      {
        id: 20,
        title: "Inappropriate Use of Software",
        desc: "Use of software or any device that would facilitate a continued connection, i.e. pinging, while using WebSouls services could result in suspension service.",
        faqId: "FAQ20",
        dataBsTarget: "#faqTwenty",
        ariaControls: "faqTwenty",
        ariaLabelledby: "FAQ20",
        faqParaghId: "faqTwenty",
      },
      {
        id: 21,
        title: "Third Party Accountability",
        desc: "WebSouls subscribers will be held responsible and accountable for any activity by third parties, using their account that violates guidelines created within the Acceptable Use Policy.",
        faqId: "FAQ21",
        dataBsTarget: "#faqTwentyOne",
        ariaControls: "faqTwentyOne",
        ariaLabelledby: "FAQ21",
        faqParaghId: "faqTwentyOne",
      },
      {
        id: 22,
        title: "Violation Of WebSouls Virtual Accounts",
        desc: `It is absolutely forbidden to host pornographic content or IRC servers on Virtual Server Accounts. Virtual Server Accounts found hosting this material would be subject to immediate cancellation without refund. In case of any abuse complaint WebSouls reserves the rights to change password of Virtual Machine and take necessary action.`,
        faqId: "FAQ22",
        dataBsTarget: "#faqTwentyTwo",
        ariaControls: "faqTwentyTwo",
        ariaLabelledby: "FAQ22",
        faqParaghId: "faqTwentyTwo",
      },
      {
        id: 23,
        title: "Payments",
        desc: `Customer will be responsible for charges as per rate schedule associated with registration of domain name in advance including but not limited to all costs for moving or transferring such domain name. Domain registration confirmation will be send to you after 24 hours of payment. All prices published at our website including domain registration, web hosting packages, VPS hosting packages, dedicated server packages, SSLs and of other services are exclusive of all taxes. WebSouls have the right to change the charges without any notice to the customer, which would be applicable for renewal of the registration.`,
        faqId: "FAQ23",
        dataBsTarget: "#faqTwentyThree",
        ariaControls: "faqTwentyThree",
        ariaLabelledby: "FAQ23",
        faqParaghId: "faqTwentyThree",
      },
      {
        id: 24,
        title: "Renewal",
        desc: `Customer shall be notified when renewal fees are due. If these charges go unpaid within stipulated period in the second notice/ reminder regarding renewal, the registration will be cancelled, and WebSouls will not be responsible if the domain is registered by any other person, after the expiry of registration term. Country base TLDs have different terms &amp; conditions on renewal and customer should need to act accordingly. Like all .com.au, .net.au, .org.au, .ch, .fr, .li, .es, .com.es, .nom.es, .org.es, .pe, .com.pe, .net.pe, .nom.pe, .org.pe, .sg, and .com.sg domains MUST be renewed a minimum of 12 days prior to the expiration date. Anything not renewed by this time will go into a redemption status and, if recoverable, will include an extra fee for recovery. Similarly all .COM.SA domains MUST be renewed 60 days prior to the expiration date.Terms of every CTLD can be found at registry's website.`,
        faqId: "FAQ24",
        dataBsTarget: "#faqTwentyFour",
        ariaControls: "faqTwentyFour",
        ariaLabelledby: "FAQ24",
        faqParaghId: "faqTwentyFour",
      },
      {
        id: 25,
        title: "Warranty Disclaimer",
        desc: `Customer shall be notified when renewal fees are due. If these charges go unpaid within stipulated period in the second notice/ reminder regarding renewal, the registration will be cancelled, and WebSouls will not be responsible if the domain is registered by any other person, after the expiry of registration term. Country base TLDs have different terms &amp; conditions on renewal and customer should need to act accordingly. Like all .com.au, .net.au, .org.au, .ch, .fr, .li, .es, .com.es, .nom.es, .org.es, .pe, .com.pe, .net.pe, .nom.pe, .org.pe, .sg, and .com.sg domains MUST be renewed a minimum of 12 days prior to the expiration date. Anything not renewed by this time will go into a redemption status and, if recoverable, will include an extra fee for recovery. Similarly all .COM.SA domains MUST be renewed 60 days prior to the expiration date.Terms of every CTLD can be found at registry's website.`,
        faqId: "FAQ25",
        dataBsTarget: "#faqTwentyFive",
        ariaControls: "faqTwentyFive",
        ariaLabelledby: "FAQ25",
        faqParaghId: "faqTwentyFive",
      },
      {
        id: 26,
        title: "Indemnification",
        desc: `Customer will indemnify, defend and hold harmless WebSouls and its employees, directors and agents, collectively indemnified parties form and against any and all claims, damages, losses, liabilities, suits, actions, demands, proceedings or filed by a third party against any of the indemnified parties arising out of or relating to the use of the services.`,
        faqId: "FAQ26",
        dataBsTarget: "#faqTwentySix",
        ariaControls: "faqTwentySix",
        ariaLabelledby: "FAQ26",
        faqParaghId: "faqTwentySix",
      },
      {
        id: 27,
        title: "Force Majeure",
        desc: `WebSouls will not be liable for failure or delay in performing its obligations here under if it is beyond its reasonable control, including, without limitation, acts of any governmental body, war, natural disaster insurrection, sabotage, embargo, fire, flood, strike or other disturbance, interruption of or delay in transportation, unavailability of interruption or delay in telecommunication or third party services including DNS propagation, failure of third party software or hardware or inability to obtain raw materials, our power used in or equipment needed for provision of the services.`,
        faqId: "FAQ27",
        dataBsTarget: "#faqTwentySeven",
        ariaControls: "faqTwentySeven",
        ariaLabelledby: "FAQ27",
        faqParaghId: "faqTwentySeven",
      },
      {
        id: 28,
        title: "Bandwidth",
        desc: `Customer agrees not to use excessive amounts of CPU processing on any of WebSouls server. Any violation of this policy may result in corrective action by WebSouls in its sole direction, including assessment of additional charges, suspension or disconnection of any and all services, or termination of this agreement. In the event that WebSouls elect to take any corrective action, Customer shall not be entitled to a refund of any amount paid in advance prior to such corrective action.$1.0 / GB is payable by customer for any extra Bandwidth usage.`,
        faqId: "FAQ28",
        dataBsTarget: "#faqTwentyEight",
        ariaControls: "faqTwentyEight",
        ariaLabelledby: "FAQ28",
        faqParaghId: "faqTwentyEight",
      },
      {
        id: 29,
        title: "Acceptance Of Services",
        desc: `Acceptance of this agreement by WebSouls may be subject, in WebSouls absolute discretion, to satisfactory completion of a credit check. Activation of service shall indicate WebSouls acceptance of this agreement. Customer represents and warrants that to have full authority and right into this agreement and that there are no conflicting claims relating to the rights granted by this agreement.`,
        faqId: "FAQ29",
        dataBsTarget: "#faqTwentyNine",
        ariaControls: "faqTwentyNine",
        ariaLabelledby: "FAQ29",
        faqParaghId: "faqTwentyNine",
      },
      {
        id: 30,
        title: "Change of Plans/ Increase in Hosting Quota",
        desc: `For further increase in hosting space the formal contract will be rendered inactive and new will be signed thereof as per space acquired and the charges recurring during that set period of time.`,
        faqId: "FAQ30",
        dataBsTarget: "#faqTherty",
        ariaControls: "faqTherty",
        ariaLabelledby: "FAQ30",
        faqParaghId: "faqTherty",
      },
      {
        id: 31,
        title: "Cancellation Policy",
        desc: `If customer cancels this agreement prior to the end of the term specified in order form, WebSouls, shall not refund fees paid in advance of such cancellation. Customer will be required to pay 100 % of WebSouls standard yearly charges of the term. For security, all cancellation request must be made by the primary contact person on the account who must provide sufficient customer identification information. Any such request shall be effective upon receipt. Any cancellation shall not relieve the customer of any obligations to pay fees accrued prior to such cancellation.`,
        faqId: "FAQ31",
        dataBsTarget: "#faqThertyOne",
        ariaControls: "faqThertyOne",
        ariaLabelledby: "FAQ31",
        faqParaghId: "faqThertyOne",
      },
      {
        id: 32,
        title: "Regulatory",
        desc: `Where applicable, either party may cancel the service agreement(s) without penalty in the event of any regulatory or legislative change or Govt. policy that renders the service agreement illegal or enforceable. Customer will remain obligated to pay any amount that has occurred at the time of such cancellation.`,
        faqId: "FAQ32",
        dataBsTarget: "#faqThertyTwo",
        ariaControls: "faqThertyTwo",
        ariaLabelledby: "FAQ32",
        faqParaghId: "faqThertyTwo",
      },
      {
        id: 33,
        title: "Liability and Other Services",
        desc: `Treat WebSouls scratch card number and all other access codes passwords where applicable as confidential and non transferable. WebSouls reserve the right to de-activate card codes without any intimation if in WebSouls sole direction, fraudulent use is suspected. If Customer changes email addresses, it is his responsibility to notify WebSouls immediately of such change(s).`,
        faqId: "FAQ33",
        dataBsTarget: "#faqThirtyThree",
        ariaControls: "faqThirtyThree",
        ariaLabelledby: "FAQ33",
        faqParaghId: "faqThirtyThree",
      },
      {
        id: 34,
        title: "Reseller Policies",
        desc: `WebSouls always respect the premisses of its prospective clients, especially the "Resellers",as no business can grow until or unless there is a group of clients that engulfs its verstality in positive manner.The way WebSouls always respect the domains and parameters of its resellers, on other hand we dont allow the customers to violates the principles of Hosting and Host(WebSouls).If any individual customer has any complaint or inconvenience with any of our Reseller than WebSouls (The Host) has complete authority to undertake the whole situation and can take necessary measures in that regard.As we believe in equality of liberty for both the person having 50 MB of Hosting and with the person having account in GBs.`,
        faqId: "FAQ34",
        dataBsTarget: "#faqThirtyFour",
        ariaControls: "faqThirtyFour",
        ariaLabelledby: "FAQ34",
        faqParaghId: "faqThirtyFour",
      },
      {
        id: 35,
        title: "Network & Resource Utilization",
        desc: `WebSouls accounts operate on shared resources. Excessive use or abuse of these shared network resources by one customer may have a negative impact on all other customers. Misuse of network resources in a manner that impairs network performance is prohibited by this policy and may result in suspension or termination of your account. User are prohibited from excessive consumption of resources, including but not limited to 10% CPU, 200MB Memory, 1024Kbps IOPs of Disk, Database size which is restricted to 100MB and successive session time. However these limitations may vary at different packages. You may not use resource-intensive programs that negatively impact other customers or the performances of WebSouls systems or networks. WebSouls reserves the right to terminate or limit such activities.`,
        faqId: "FAQ35",
        dataBsTarget: "#faqThirtyFive",
        ariaControls: "faqThirtyFive",
        ariaLabelledby: "FAQ35",
        faqParaghId: "faqThirtyFive",
      },
    ],
  };
  return (
    <>
      <BannerTwo Data={bannerData} />
      <Faq Data={faqData} />
    </>
  );
};

export default Policy;
