import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import BannerTwo from "../reuseable/Banner2";
import Faq from "../reuseable/Faq";
import Passion from "../reuseable/Passion";
import WhyUsIcons from "../reuseable/WhyUsIcons";

const Team = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    const url = window.location.href;
    if (!url.includes("hiring")) {
      window.scrollTo({
        top: 0,
        left: 0,
        behavior: "smooth",
      });
    }

    if (url.includes("hiring")) {
      jumpToReleventDiv("hiring");
    }
  }, []);

  const jumpToReleventDiv = (id) => {
    const releventDiv = document.getElementById(id);
    // behavior: "smooth" parameter for smooth movement
    releventDiv.scrollIntoView({ behavior: "smooth" });
  };

  const bannerData = {
    heading: "Our Soul Asset",
    peragraph: "We Join Hands to Bring Out the Best in Us!",
  };
  const PassionData = {
    img: "/assests/img/Web-Hosting-&-Development-Services.png",
    Text: [
      <>
        <h3>
          Professional <span> Staff,</span> Expert <span> Services </span>&nbsp;
        </h3>
        <p>
          Behind every great achievement, there’s a team, and ours is the kind
          that loves unleashing the potential of other teams!
        </p>
        <p>
          We’re a group of professionals who’ve come together to practice the
          art of thinking outside the box and finding unexpected solutions to
          convert businesses into brands. For our company, we make customers,
          and for our customers we make Success!
        </p>
        <p>
          What brings us together? Our passion to serve others, commitment to
          achieving goals, dedication to creating success, and, of course, our
          great sense of humour. We are a group of experts adept in exceeding
          expectations and outperforming everything, always.
        </p>
        <p>
          At Websouls, we start each day with the aim of turning visions into
          missions, ideas into reality, and Our Passion into Your Profit! Come
          meet us, and we’d find the best way to help your business grow.
        </p>
      </>,
    ],
  };
  const WhyItWorthData = {
    box: [
      {
        heading: "Trusted by 1000s of Corporate and Government Organizations",
        icon: "icon ws-reviews",
      },
      {
        heading: "Relaxed and Friendly Environment That Fosters True Teamwork",
        icon: "icon ws-easy",
      },
      {
        heading:
          "No. 1 Provider of Highest Quality Web Hosting Services in Pakistan",
        icon: "icon ws-award",
      },
    ],
  };
  const faqData = {
    heading: "Let’s Do Great Work, Together!",
    subHeading: "Become a Part of Pakistan’s No. 1 Web Hosting Company",
    faqs: [
      {
        id: 1,
        title: "Operations Executive - Sales | Lahore Office | 1 Opening",
        faqId: "FAQ1",
        dataBsTarget: "#faqOne",
        ariaControls: "faqOne",
        ariaLabelledby: "FAQ1",
        faqParaghId: "faqOne",
        desc: [
          <>
            <p>
            We are looking for an “Operations Executive - Sales” in Lahore. We have (1) openings for this position and the candidates should have a minimum of 1-Year experience.
            </p>
            <br />
            <p>
              <strong>Benefits:</strong>&nbsp;Salary + PF + Medical (Career Oriented Opportunity)
              <br />
              <strong>Environment:</strong>&nbsp;Very Friendly Working Environment
              Environment
              <br />
              <strong>Our Clients Portfolio:</strong>
              &nbsp;https://www.websouls.com/clients
            </p>
            <p>
              <strong>NOTE:</strong>&nbsp;Please don't forget to attach your resume while applying.
            </p>
            <p>&nbsp;</p>
            <a
              className="FaqButton"
              target="_blank"
              href="https://billing.websouls.com/submitticket.php?step=2&deptid=7"
            >
              Apply Now
              <i className="ms-3 fa fa-arrow-right" />
            </a>
          </>,
        ],
      },
      {
        id: 1,
        title: "Mern Stack Developer - Software Development | Lahore Office | 1 Opening",
        faqId: "FAQ1",
        dataBsTarget: "#faqOne",
        ariaControls: "faqOne",
        ariaLabelledby: "FAQ1",
        faqParaghId: "faqOne",
        desc: [
          <>
            <p>
            We are looking for a “Mern Stack Developer” in Lahore. We have (1) openings for this position and the candidates should have a minimum of 3-Year experience. 
            </p><br />
            <p>
              <strong>Benefits:</strong>&nbsp;Salary + PF + Medical (Career Oriented Opportunity)
              <br />
              <strong>Environment:</strong>&nbsp;Very Friendly Working Environment
              Environment
              <br />
              <strong>Our Clients Portfolio:</strong>
              &nbsp;https://www.websouls.com/clients
            </p>
            <p>
              <strong>NOTE:</strong>&nbsp;Please don't forget to attach your resume while applying.
            </p>
            <p>&nbsp;</p>
            <a
              className="FaqButton"
              target="_blank"
              href="https://billing.websouls.com/submitticket.php?step=2&deptid=7"
            >
              Apply Now
              <i className="ms-3 fa fa-arrow-right" />
            </a>
          </>,
        ],
      }



    ],
  };

  return (
    <>
      <Helmet>
        <title>Professional Staff, Expert Services | Team WebSouls</title>
        <meta
          name="title"
          content="Professional Staff, Expert Services | Team WebSouls"
        />
        <meta
          name="description"
          content="We believe in teamwork, professionalism, & are committed to serve our clients. We’re Team WebSouls!"
        />
        <meta name="keywords" content="" />
        <link rel="canonical" href="https://websouls.com/team" />
      </Helmet>
      <BannerTwo Data={bannerData} />
      <Passion Data={PassionData} />
      <WhyUsIcons Data={WhyItWorthData} />
      <section className="career_team pt-100">
        <div className="container">
          <h2 className="first-heading">We’re Not A Team, We’re THE Team</h2>
          <h5 className="second-heading">
            Knowledgeable Experts with a Great Sense of Humour
          </h5>
          <hr className="divider scnd_divider  light mx-auto" />
          <div className="carer_team_paragraph">
            <p>
              We believe in teamwork more than anything else, and our team of
              experts are our Soul asset. At WebSouls, we strive to lead with
              our guiding principles – commitment, optimism, and never
              saying&nbsp; “I”.
            </p>
            <p>
              Our customers are the reason why we do what we do, and we take
              “your customer is your business” seriously.
            </p>
          </div>
          <img
            src="assests/img/Leading-web-hosting-providers-since-2002.png"
            className="lazy"
            title="Leading-web-hosting-providers-since-2002"
            alt="Leading-web-hosting-providers-since-2002"
          />
        </div>
      </section>
      <Faq Data={faqData} />
    </>
  );
};

export default Team;
