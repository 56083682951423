import React, { useState, useEffect } from "react";
import { Link, useLocation, useParams, useNavigate } from "react-router-dom";
const Thankyou1 = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [paymentmethod, setpaymentmethod] = useState("");
  const [order_id, setorder_id] = useState("");
  const [invoice_id, setinvoice_id] = useState("");
  const [fullName, setifullName] = useState("");
  useEffect(() => {
    if (location.key === "default") {
      navigate("/");
    }
    setpaymentmethod(location.state.paymentmethod);
    setorder_id(location.state.order_id);
    setinvoice_id(location.state.invoice_id);
    setifullName(location.state.fullNmae);
    setTimeout(() => {
      navigate("/thankyou", {
        state: {
          order_id: location.state.order_id,
          invoice_id: location.state.invoice_id,
          order_total: location.state.order_total,
          paymentmethod: location.state.paymentmethod,
          fullNmae: location.state.fullNmae,
        },
      });
      if (location.state.isOnlineMethod !== true) {
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }
    }, 1000);
  }, []);

  const GoBack = () => {
    navigate("/");
    window.location.reload();
  };

  let data = "";

  return (
    <div className="container animate__animated animate__fadeIn">
      <div className="row">
        <div className="col-md-8 col-sm-12 col-xs-12 mt-5 mx-auto">
          <div className="billing_datail">{data}</div>
        </div>
      </div>
    </div>
  );
};

export default Thankyou1;
