import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useLocation, Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import {
  packagesFeatures,
  packagesFirstFeature,
  getPackageBaseCycle,
  delayTime,
  getdedicatedPrice,
} from "../../../services/Helper";
import WhyChooseUs from "./includes/DedicatedServers/WhyChooseUs";
import WillingForHelp from "../../reuseable/WillingForHelp";
import Faq from "../../reuseable/Faq";
import InPakistan from "./includes/DedicatedServers/InPakistan";
import Carousel from "react-elastic-carousel";
import WhyItWoth from "./includes/DedicatedServers/WhyItWoth";
import GoingOnline from "./includes/DedicatedServers/GoingOnline";
import DomainTips from "./includes/DedicatedServers/DomainTips";
import BundelBooster2 from "./includes/DedicatedServers/BundelBooster2";
import CustomizeServer from "./includes/DedicatedServers/CustomizeServer";
import { GetPackages, GetActivePromotions } from "../../../services/Network.js";
const DedicatedServers = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const [statusOne, setStatusOne] = useState(true);
  const [statusTwo, setStatusTwo] = useState(true);

  const WillingToHelpData = {
    heading: "Ready, Willing and Able to Help!",
    subHeading:
      "Whether it’s a question, query, or you want to discuss a project, the WebSouls team is at your service anytime, at all times!",
    buttonOne: "Call: +92 423 523 8871 - 73",
    buttonTwo: " Email Us",
    image: "assests/img/Muhammad-Shehryar-Team-Websouls.png",
  };

  const faqData = {
    heading: "Frequently Asked Questions",
    subHeading: "Dedicated to Helping You Succeed!",
    faqs: [
      {
        id: 0,
        title: "What is dedicated server hosting?",
        desc: "With a dedicated server hosting package, you can have exclusive access to the server and all its resources. In a sense, you’d have full control of your server and can tailor it to fit your business requirements. In addition to this, dedicated server hosting provides you with an increasingly high level of performance, reliability, security, and speed.",
        faqId: "FAQ1",
        dataBsTarget: "#faqOne",
        ariaControls: "faqOne",
        ariaLabelledby: "FAQ1",
        faqParaghId: "faqOne",
      },
      {
        id: 1,
        title:
          "Can I manage my dedicated server through a web-based control panel?",
        desc: "Yes, you can. We are the official partner of cPanel in Pakistan and offer FREE installation and a complete FREE initial server setup, so that you can enjoy complete GUI access of their control panel.",
        faqId: "FAQ2",
        dataBsTarget: "#faqTwo",
        ariaControls: "faqTwo",
        ariaLabelledby: "FAQ2",
        faqParaghId: "faqTwo",
      },
      {
        id: 2,
        title: "Can I upgrade my VPS hosting plan to dedicated server?",
        desc: "Yes. If you have a VPS server account at WebSouls, it’s quite easy to upgrade it to a dedicated server. Our team will help you through the process and we will migrate all your data to your new dedicated server.",
        faqId: "FAQ3",
        dataBsTarget: "#faqThree",
        ariaControls: "faqThree",
        ariaLabelledby: "FAQ3",
        faqParaghId: "faqThree",
      },
      {
        id: 3,
        title: "Would I be able to upgrade my package later?",
        desc: "Yes, you can always upgrade your package through a quick process and there’s no downtime. If you register more domains or need to use your account for hosting more websites, it’s quite easy to upgrade your package from Premium to Standard and to Professional hosting plan.",
        faqId: "FAQ4",
        dataBsTarget: "#faqFour",
        ariaControls: "faqFour",
        ariaLabelledby: "FAQ4",
        faqParaghId: "faqFour",
      },
    ],
  };
  const [linuxPids, setLiniuxPids] = useState([
    304, 286, 282, 293, 309, 299, 306,
  ]);
  const [winodwsPids, setWindowsPids] = useState([
    300, 301, 305, 302, 303, 307, 309,
  ]);
  const [dedicatedServersLinuxList, setDedicatedServersLinuxList] = useState(
    []
  );
  const [dedicatedServersWindowsList, setDedicatedServersWindowsList] =
    useState([]);
  const [getActivePromotions, setActivePromotions] = useState([]);
  const [loading, setLoading] = useState(false);

  //reducx states
  const { currencyName } = useSelector((state) => state.currencyName);
  const { billingCycleHosting } = useSelector(
    (state) => state.billingCycleHosting
  );

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2 },
    { width: 768, itemsToShow: 3 },
    { width: 1200, itemsToShow: 3 },
  ];

  useEffect(() => {
    if (linuxPids.length <= 3) {
      setStatusOne(false);
    }
    if (winodwsPids.length <= 3) {
      setStatusTwo(false);
    }

    dispatch({
      type: "urlCurrent",
      payload: location.pathname,
    });

    GetPackages(linuxPids).then((res) => {
      setDedicatedServersLinuxList(res);
      setLoading(true);
      delayTime(5000).then(() => {
        GetPackages(winodwsPids).then((respo) => {
          setDedicatedServersWindowsList(respo);
          setLoading(true);
          GetActivePromotions().then((ress) => {
            setActivePromotions(ress);
          });
        });
      });
    });
  }, [currencyName]);

  const buttonAddToCart = (e, item) => {
    e.preventDefault();
    dispatch({
      type: "addingCycle",
      payload: {
        packageId: item.pid,
        packageCycle: getPackageBaseCycle(item, currencyName.api_id),
      },
    });
    dispatch({
      type: "packageCart",
      payload: item,
    });

    //redirecting to the new page
    navigate("/dedicated-hostname");
  };

  const InPakistanData = {
    Heading: "Dedicated Server In Pakistan",
    subHeading: "Secure, Reliable, and Incredibly Fast VPS Servers",
    peragraph: [
      <>
        <p>
          At&nbsp;
          <Link to="/" target="_blank">
            WebSouls
          </Link>
          , we offer the best dedicated web hosting packages that come with a
          lightning-fast load time and ultimate security. Not only do we provide
          the best and top-class hosting services, but purchasing a dedicated
          server in Pakistan from us would come with numerous additional
          benefits in the form of our amazing ‘bundle boosters’.
        </p>
        <p>
          We enable our clients to experience&nbsp;
          <Link to="/shared-hosting" target="_blank">
            affordable web hosting services
          </Link>
          &nbsp;in Lahore, Karachi, Islamabad, and other cities around the
          nation. Our company also provides our clients with the option to
          purchase a hosting plan online.&nbsp;
        </p>
        <p>
          By buying a dedicated server hosting plan from us, you’d get flexible
          server configurations, paired up with SSD and HDD storage options as
          well as root access for complete control at a low price.
        </p>
        <p>
          In addition to this, being one of the best dedicated server hosting
          providers in Pakistan, our plans offer optimal performance with FREE
          initial server setup. Plus, with our quality customer support,
          built-in security, and extremely easy to use control panel, our
          dedicated hosting package is actually what every website owner looks
          for in a host.
        </p>
        <p>
          The best part is, we provide our customers with reliable and powerful
          dedicated server hosting at incredibly low prices in Pakistan. So, if
          you’re wondering how to keep your business website running smoothly
          while you have exclusive access to the server and its resources at a
          cheap price, buy a dedicated server from us! However, in case you
          don’t want a whole server to yourself, our&nbsp;
          <Link to="/business-hosting" target="_blank">
            business hosting plan
          </Link>
          &nbsp;can be your ultimate go-to option,&nbsp;
          <Link to="/contactus" target="_blank">
            contact us
          </Link>
          &nbsp;now!
        </p>
      </>,
    ],
  };
  const technicalSpecificationsData = {
    Heading: "Technical Specifications",
    subHeading:
      "Harnessing the Power of Technology to Deliver a Dependable Experience",
    box: [
      {
        id: 0,
        icon: "icon ws-server",
        title: "Bare metal servers",
        peragraph:
          "Offering the privileges of complete control of a bare metal server. Harness the full processing power and enjoy maximum performance dedicated just to you.",
      },
      {
        id: 1,
        icon: "icon ws-ssd",
        title: "SATA or SSD Drives",
        peragraph:
          "Our available hard drive options have got you covered for when you want blazing-fast speed and tons of storage capacity. Choose whatever suits you best!",
      },
      {
        id: 2,
        icon: "icon ws-disk",
        title: "Disk mirroring",
        peragraph:
          "Our dedicated servers include RAID-1 configuration to provide you with optimal performance, high availability, and data redundancy in case of disk failure.",
      },
      {
        id: 3,
        icon: "icon ws-quick",
        title: "Easy-to-Use Control Panel",
        peragraph:
          "Being an official partner of cPanel, we know all the particulars of world’s largest control panel and prefer it due to reasons including its easy usability and unique features.",
      },
      {
        id: 4,
        icon: "icon ws-provision",
        title: "Quick Provisioning",
        peragraph:
          "Buying a dedicated server web hosting package from us means no to long days spent waiting to start. We’d provision your server the same day you purchase it from us! ",
      },
      {
        id: 5,
        icon: "icon ws-managed-service",
        title: "Managed Services",
        peragraph:
          "All our hosting plans come with a free initial server setup. Our team can also provide you with server management services and fully managed support if you need us to.",
      },
    ],
  };
  const GoingOnlineData = {
    Heading: "Not Sure? Explore More!",
    subHeading: "Hosting Solutions Focused on Your Needs",
    box: [
      {
        id: 0,
        topTitle: "Need Something Affordable?",
        Title: "Try VPS Hosting",
        buttonLink: "/vps-hosting",
      },
      {
        id: 1,
        topTitle: "Need Something Simpler?",
        Title: "Try WordPress Hosting",
        buttonLink: "/wordpress-hosting-in-pakistan",
      },
    ],
  };

  const BundelBooster2Data = {
    Title: "Bundle Boosters",
    subTitle: "Select Your Server, Configure It, and Move on!",
    Heading1: "Licenses",
    LicensesItems: [
      {
        id: 1,
        name: "R1Soft Agent License",
        price: "Rs 2,870/mon",
        Blink: "https://billing.websouls.com/submitticket.php?step=2&deptid=2",
      },
      {
        id: 2,
        name: "Softaculous for VPS",
        price: "Rs 615/mon",
        Blink: "https://billing.websouls.com/submitticket.php?step=2&deptid=2",
      },
      {
        id: 3,
        name: "Softaculous for Dedicated Server",
        price: "Rs 1,025/mon",
        Blink: "https://billing.websouls.com/submitticket.php?step=2&deptid=2",
      },
      {
        id: 4,
        name: "cPanel Admin Cloud (Upto 5 Accounts)",
        price: "Rs 3,485s/mon",
        Blink: "https://billing.websouls.com/submitticket.php?step=2&deptid=2",
      },
      {
        id: 5,
        name: "cPanel BareMetal Server (Upto 100 Accounts)",
        price: "Rs 9,225/mon",
        Blink: "https://billing.websouls.com/submitticket.php?step=2&deptid=2",
      },
    ],
    Heading2: "Managed Support",
    LicensesManagedSupport: [
      {
        id: 0,
        name: "Fully Managed Support - Dedicated",
        price: "Rs 1,5375/mon",
        Blink: "https://billing.websouls.com/submitticket.php?step=2&deptid=2",
      },
      {
        id: 1,
        name: "Fully Managed Support - VPS",
        price: "Rs 6,150/mon",
        Blink: "https://billing.websouls.com/submitticket.php?step=2&deptid=2",
      },
    ],
  };
  const WhyItWorthData = {
    Heading: "Why It’s Worth It?",
    subHeading: "Everything You Need to Handle Your Exploding Growth",
    box: [
      {
        id: 0,
        heading: "Faster Speed",
        subHeading: "Powerful CPU, High I/O Disks",
        icon: "icon ws-fast",
      },
      {
        id: 1,
        heading: "Enhanced Security",
        subHeading: "Proactive Server Monitoring",
        icon: "icon ws-secure-b",
      },
      {
        id: 2,
        heading: "Better Performance",
        subHeading: "Maximum Uptime Guarantee",
        icon: "icon ws-quick",
      },
    ],
  };

  return (
    <>
      <Helmet>
        <title>
          Dedicated Server in Pakistan | Web Server in Pakistan | WebSouls
        </title>
        <meta
          name="title"
          content="Dedicated Server in Pakistan | Web Server in Pakistan | WebSouls"
        />
        <meta
          name="description"
          content="WebSouls providing cheap dedicated servers in Pakistan. Our ultra-fast & secure web server in Pakistan gives freedom of resources & reliability."
        />
        <meta name="keywords" content="" />
        <script type="application/ld+json">
          {`{
  "@context": "https://schema.org",
  "@type": "Organization",
  "name": "WebSouls",
  "alternateName": "Web Hosting Pakistan",
  "url": "https://www.websouls.com/",
  "logo": "https://www.websouls.com/public/uploads/images/logo_1589438878.png",
  "contactPoint": [{
    "@type": "ContactPoint",
    "telephone": "+92 321 407 7991",
    "contactType": "technical support",
    "areaServed": "PK",
    "availableLanguage": ["en","Urdu"]
  },{
    "@type": "ContactPoint",
    "telephone": "+92 322 525 2352",
    "contactType": "sales",
    "areaServed": "PK",
    "availableLanguage": ["en","Urdu"]
  }],
  "sameAs": [
    "https://www.facebook.com/WebSouls/",
    "https://www.twitter.com/futuresouls",
    "https://www.instagram.com/websouls.pk/",
    "https://www.linkedin.com/company/websouls/",
    "https://github.com/websouls",
    "https://www.websouls.com/"
  ]
}`}
        </script>
        <script type="application/ld+json">
          {`{
  "@context": "https://schema.org/", 
  "@type": "Product", 
  "name": "Dedicated Server Hosting - WebSouls",
  "image": "https://www.websouls.com/public/uploads/images/logo_1589438878.png",
  "description": "At WebSouls, we offer the best dedicated web hosting packages that come with a lightning fast load time and ultimate security. Not only do we provide the best and top-class hosting services, but getting a dedicated server in Pakistan from us would come with numerous additional benefits in the form of our amazing ‘bundle boosters’.",
  "brand": "WebSouls",
  "sku": "Dedicated Server Hosting",
  "mpn": "Dedicated Server Hosting",
  "offers": {
    "@type": "AggregateOffer",
    "url": "https://www.websouls.com/dedicated-server",
    "priceCurrency": "PKR",
    "lowPrice": "28000",
    "highPrice": "53500",
    "offerCount": "3"
  },
  "aggregateRating": {
    "@type": "AggregateRating",
    "ratingValue": "4.9",
    "bestRating": "5",
    "worstRating": "4",
    "ratingCount": "2802"
  }
}`}
        </script>
        <script type="application/ld+json">
          {`{
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "What is dedicated server hosting?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "With a dedicated server hosting package, you can have exclusive access to the server and all its resources. In a sense, you’d have full control of your server and can tailor it to fit your business requirements. In addition to this, dedicated server hosting provides you with an increasingly high level of performance, reliability, security, and speed."
    }
  },{
    "@type": "Question",
    "name": "Do i need to buy a dedicated server?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "You should go for dedicated server hosting if you want full control of your hosting environment and need optimal performance. Contact our support team for further details."
    }
  },{
    "@type": "Question",
    "name": "Can i manage my dedicated server through a web based control panel?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Yes, you can. We are the official partner of cPanel in Pakistan and offer FREE installation and a completely FREE initial server setup so that you can enjoy complete GUI access of their control panel."
    }
  },{
    "@type": "Question",
    "name": "Can i upgrade my VPS hosting plan to dedicated server?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Yes. If you have a VPS server account at WebSouls, it’s quite easy to upgrade it to a dedicated server. Our team will help you through the process and we will migrate all your data to your new dedicated server."
    }
  }]
}`}
        </script>
        <link rel="canonical" href="https://websouls.com/dedicated-server" />
      </Helmet>
      <section className="ssliderhost banner-size">
        <div className="container">
          <div className="row">
            <h2 className="first-heading">It’s Hosting For Pros</h2>
            <h5 className="second-heading">
            Dedicating the Best of Everything to You
            </h5>

            <ul className="nav nav-tabs" id="myTab" role="tablist">
              <li className="nav-item " role="presentation">
                <button
                  className="nav-link active"
                  id="linux-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#linux"
                  type="button"
                  role="tab"
                  aria-controls="linux"
                  aria-selected="true"
                >
                  <i className="fa fa-linux" />
                  Linux
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="windows-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#windows"
                  type="button"
                  role="tab"
                  aria-controls="windows"
                  aria-selected="false"
                >
                  <i className="fa fa-windows" />
                  Windows
                </button>
              </li>
            </ul>
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="linux"
                role="tabpanel"
                aria-labelledby="linux-tab"
              >
                <>
                  {loading ? (
                    <>
                      <Carousel
                        className="animate__animated animate__fadeIn"
                        itemPadding={[8, 10, 8, 10]}
                        breakPoints={breakPoints}
                        pagination={true}
                      >
                        {dedicatedServersLinuxList?.map((item) => (
                          <React.Fragment key={item?.id}>
                            <div
                              style={{
                                backgroundColor: "transparent",
                                border: "1px solid #D2D2D2",
                                borderRadius: 5,
                              }}
                              className="col-lg-4 pacakge-one text-start"
                            >
                              <div className="package-name ">
                                <h3>{item?.name}</h3>
                                <p className="package-namepaaaa">
                                  {" "}
                                  {item.fname}
                                </p>
                              </div>
                              <div
                                style={{ marginBottom: 0 }}
                                className="package-one-price checkss"
                              >
                                {getdedicatedPrice(
                                  item?.currency
                                    ?.filter(
                                      (fil) =>
                                        fil?.currency === currencyName?.api_id
                                    )
                                    ?.map(
                                      (pr) =>
                                        pr[
                                          getPackageBaseCycle(
                                            item,
                                            currencyName?.api_id
                                          )
                                        ]
                                    ),
                                  item?.pid,
                                  getPackageBaseCycle(
                                    item,
                                    currencyName?.api_id
                                  ),
                                  getActivePromotions,
                                  currencyName?.prefix
                                )}
                              </div>
                              <ul>
                                {item.packageFeatures &&
                                  item.packageFeatures[0]?.features.map(
                                    (elss) => (
                                      <li>
                                        <font>
                                          <strong
                                            style={{
                                              color: "rgb(0, 88, 128)",
                                              fontSize: 20,
                                              fontWeight: 400,
                                              paddingRight: 8,
                                            }}
                                          >
                                            ✓{" "}
                                          </strong>
                                        </font>

                                        {elss.includes("_") ? (
                                          <>
                                            {elss.split("_")[0]}
                                            {
                                              <i className="fa fa-info-circle">
                                                <span
                                                  className="litooltip"
                                                  dangerouslySetInnerHTML={{
                                                    __html: elss.split("_")[1],
                                                  }}
                                                />
                                              </i>
                                            }
                                          </>
                                        ) : (
                                          elss
                                        )}
                                      </li>
                                    )
                                  )}
                              </ul>

                              <button
                                onClick={(e) => buttonAddToCart(e, item)}
                                className="text "
                              >
                                Add To Cart
                              </button>
                            </div>
                          </React.Fragment>
                        ))}
                      </Carousel>
                    </>
                  ) : (
                    <>
                      <Carousel
                        itemPadding={[8, 10, 8, 10]}
                        breakPoints={breakPoints}
                        pagination={true}
                      >
                        <div
                          className="col-lg-4 pacakge-one text-start "
                          style={{
                            backgroundColor: "transparent",
                            border: "1px solid rgb(210, 210, 210)",
                            borderRadius: 5,
                          }}
                        >
                          <div className="package-name ">
                            <h3>
                              <Skeleton width={200} />
                            </h3>
                            <p>
                              <Skeleton width={100} />
                            </p>
                          </div>
                          <div
                            className="package-one-price checkss"
                            style={{ marginBottom: 0 }}
                          >
                            <div className="discount">
                              <h4 className="">
                                <b>
                                  <Skeleton width={300} />
                                </b>
                              </h4>
                            </div>
                            <div className="package-one-price">
                              <span className="amount">
                                {" "}
                                <Skeleton width={190} />
                              </span>
                            </div>
                          </div>
                          <ul>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                          </ul>
                          <Skeleton
                            width={200}
                            style={{ paddingTop: 20, marginTop: 10 }}
                          />
                        </div>
                        <div
                          className="col-lg-4 pacakge-one text-start "
                          style={{
                            backgroundColor: "transparent",
                            border: "1px solid rgb(210, 210, 210)",
                            borderRadius: 5,
                          }}
                        >
                          <div className="package-name ">
                            <h3>
                              <Skeleton width={200} />
                            </h3>
                            <p>
                              <Skeleton width={100} />
                            </p>
                          </div>
                          <div
                            className="package-one-price checkss"
                            style={{ marginBottom: 0 }}
                          >
                            <div className="discount">
                              <h4 className="">
                                <b>
                                  <Skeleton width={300} />
                                </b>
                              </h4>
                            </div>
                            <div className="package-one-price">
                              <span className="amount">
                                {" "}
                                <Skeleton width={190} />
                              </span>
                            </div>
                          </div>
                          <ul>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                          </ul>
                          <Skeleton
                            width={200}
                            style={{ paddingTop: 20, marginTop: 10 }}
                          />
                        </div>
                        <div
                          className="col-lg-4 pacakge-one text-start "
                          style={{
                            backgroundColor: "transparent",
                            border: "1px solid rgb(210, 210, 210)",
                            borderRadius: 5,
                          }}
                        >
                          <div className="package-name ">
                            <h3>
                              <Skeleton width={200} />
                            </h3>
                            <p>
                              <Skeleton width={100} />
                            </p>
                          </div>
                          <div
                            className="package-one-price checkss"
                            style={{ marginBottom: 0 }}
                          >
                            <div className="discount">
                              <h4 className="">
                                <b>
                                  <Skeleton width={300} />
                                </b>
                              </h4>
                            </div>
                            <div className="package-one-price">
                              <span className="amount">
                                {" "}
                                <Skeleton width={190} />
                              </span>
                            </div>
                          </div>
                          <ul>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                          </ul>
                          <Skeleton
                            width={200}
                            style={{ paddingTop: 20, marginTop: 10 }}
                          />
                        </div>
                      </Carousel>
                    </>
                  )}
                </>
              </div>
              <div
                className="tab-pane fade show"
                id="windows"
                role="tabpanel"
                aria-labelledby="windows-tab"
              >
                <>
                  {loading ? (
                    <>
                      <Carousel
                        className="animate__animated animate__fadeIn"
                        itemPadding={[8, 10, 8, 10]}
                        breakPoints={breakPoints}
                        pagination={true}
                      >
                        {dedicatedServersWindowsList?.map((item) => (
                          <React.Fragment key={item?.id}>
                            <div
                              style={{
                                backgroundColor: "transparent",
                                border: "1px solid #D2D2D2",
                                borderRadius: 5,
                              }}
                              className="col-lg-4 pacakge-one text-start"
                            >
                              <div className="package-name ">
                                <h3>{item?.name}</h3>
                                <p className="package-namepaaaa">
                                  {" "}
                                  {item.fname}
                                </p>
                              </div>
                              <div
                                style={{ marginBottom: 0 }}
                                className="package-one-price checkss"
                              >
                                {getdedicatedPrice(
                                  item?.currency
                                    ?.filter(
                                      (fil) =>
                                        fil?.currency === currencyName?.api_id
                                    )
                                    ?.map(
                                      (pr) =>
                                        pr[
                                          getPackageBaseCycle(
                                            item,
                                            currencyName?.api_id
                                          )
                                        ]
                                    ),
                                  item?.pid,
                                  getPackageBaseCycle(
                                    item,
                                    currencyName.api_id
                                  ),
                                  getActivePromotions,
                                  currencyName?.prefix
                                )}
                              </div>
                              <ul>
                                {item.packageFeatures &&
                                  item.packageFeatures[0]?.features.map(
                                    (elss) => (
                                      <li>
                                        <font>
                                          <strong
                                            style={{
                                              color: "rgb(0, 88, 128)",
                                              fontSize: 20,
                                              fontWeight: 400,
                                              paddingRight: 8,
                                            }}
                                          >
                                            ✓{" "}
                                          </strong>
                                        </font>

                                        {elss.includes("_") ? (
                                          <>
                                            {elss.split("_")[0]}
                                            {
                                              <i className="fa fa-info-circle">
                                                <span
                                                  className="litooltip"
                                                  dangerouslySetInnerHTML={{
                                                    __html: elss.split("_")[1],
                                                  }}
                                                />
                                              </i>
                                            }
                                          </>
                                        ) : (
                                          elss
                                        )}
                                      </li>
                                    )
                                  )}
                              </ul>

                              <button
                                onClick={(e) => buttonAddToCart(e, item)}
                                className="text "
                              >
                                Add To Cart
                              </button>
                            </div>
                          </React.Fragment>
                        ))}
                      </Carousel>
                    </>
                  ) : (
                    <>
                      <Carousel
                        itemPadding={[8, 10, 8, 10]}
                        breakPoints={breakPoints}
                        pagination={true}
                      >
                        <div
                          className="col-lg-4 pacakge-one text-start "
                          style={{
                            backgroundColor: "transparent",
                            border: "1px solid rgb(210, 210, 210)",
                            borderRadius: 5,
                          }}
                        >
                          <div className="package-name ">
                            <h3>
                              <Skeleton width={200} />
                            </h3>
                            <p>
                              <Skeleton width={100} />
                            </p>
                          </div>
                          <div
                            className="package-one-price checkss"
                            style={{ marginBottom: 0 }}
                          >
                            <div className="discount">
                              <h4 className="">
                                <b>
                                  <Skeleton width={300} />
                                </b>
                              </h4>
                            </div>
                            <div className="package-one-price">
                              <span className="amount">
                                {" "}
                                <Skeleton width={190} />
                              </span>
                            </div>
                          </div>
                          <ul>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                          </ul>
                          <Skeleton
                            width={200}
                            style={{ paddingTop: 20, marginTop: 10 }}
                          />
                        </div>
                        <div
                          className="col-lg-4 pacakge-one text-start "
                          style={{
                            backgroundColor: "transparent",
                            border: "1px solid rgb(210, 210, 210)",
                            borderRadius: 5,
                          }}
                        >
                          <div className="package-name ">
                            <h3>
                              <Skeleton width={200} />
                            </h3>
                            <p>
                              <Skeleton width={100} />
                            </p>
                          </div>
                          <div
                            className="package-one-price checkss"
                            style={{ marginBottom: 0 }}
                          >
                            <div className="discount">
                              <h4 className="">
                                <b>
                                  <Skeleton width={300} />
                                </b>
                              </h4>
                            </div>
                            <div className="package-one-price">
                              <span className="amount">
                                {" "}
                                <Skeleton width={190} />
                              </span>
                            </div>
                          </div>
                          <ul>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                          </ul>
                          <Skeleton
                            width={200}
                            style={{ paddingTop: 20, marginTop: 10 }}
                          />
                        </div>
                        <div
                          className="col-lg-4 pacakge-one text-start "
                          style={{
                            backgroundColor: "transparent",
                            border: "1px solid rgb(210, 210, 210)",
                            borderRadius: 5,
                          }}
                        >
                          <div className="package-name ">
                            <h3>
                              <Skeleton width={200} />
                            </h3>
                            <p>
                              <Skeleton width={100} />
                            </p>
                          </div>
                          <div
                            className="package-one-price checkss"
                            style={{ marginBottom: 0 }}
                          >
                            <div className="discount">
                              <h4 className="">
                                <b>
                                  <Skeleton width={300} />
                                </b>
                              </h4>
                            </div>
                            <div className="package-one-price">
                              <span className="amount">
                                {" "}
                                <Skeleton width={190} />
                              </span>
                            </div>
                          </div>
                          <ul>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                          </ul>
                          <Skeleton
                            width={200}
                            style={{ paddingTop: 20, marginTop: 10 }}
                          />
                        </div>
                      </Carousel>
                    </>
                  )}
                </>
              </div>
            </div>
          </div>
        </div>
      </section>
      <WhyItWoth Data={WhyItWorthData} />
      <DomainTips Data={technicalSpecificationsData} />
      <GoingOnline Data={GoingOnlineData} />
      {/* <BundelBooster2 Data={BundelBooster2Data} /> */}
      <CustomizeServer />
      <WhyChooseUs />
      <InPakistan Data={InPakistanData} />
      <Faq Data={faqData} />
      <WillingForHelp helpData={WillingToHelpData} />
    </>
  );
};

export default DedicatedServers;
