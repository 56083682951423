import React from "react";

const KeepingYouUp = ({ Data }) => {
  return (
    <section className="registration pt-100">
      <h2 className="first-heading">{Data.heading}</h2>
      <h5 className="second-heading">{Data.subHeading}</h5>
      <hr className="divider scnd_divider mb-4 mx-auto" />
      <div className="container">
        <div className="row">
          {Data.box.map((item) => (
            <div className="col-md-4">
              <div className="hosting_srvcs">
                <h3>{item.heading}</h3>
                <p>{item.peragraph}</p>
                <a style={{ textDecoration: "none" }} href={item.link}>
                  Learn More
                  <i style={{ fontSize: 15, marginLeft: 10 }} className="fa fa-arrow-right" />
                </a>
                <div
                  className="bg_overlay"
                  
                />
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default KeepingYouUp;
