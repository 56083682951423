import React from "react";
import shortid from "shortid";

const WhyChooseUsThird = (props) => {
  const { Data } = props;
  return (
    <section className="v2-why-choose-us ">
      <div className="container">
        <h2 className="text-center first-heading">{Data?.Heading}</h2>
        <h5 className="text-center second-heading">{Data?.subHeading}</h5>
        <hr className="divider scnd_divider mx-auto mb-4" />
        <div className="row justify-content-center">
          {Data?.box?.map((item) => (
            <div key={shortid.generate()} className="col-md-4 justify-content-center whychooseUsGap">
              <div className="v2-servce-text">
                <div className="display-tableCell vertical-align-middle text-center">
                  <div className={item?.icon} />
                  <p>{item?.title}</p>
                </div>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default React.memo(WhyChooseUsThird);
