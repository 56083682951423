import React from "react";

const AllPlanIncludes = ({plansData}) => {
  return (
    <section style={{ marginBottom: 100 }} className="all_plans">
      <div className="container">
        <h2 className="first-heading">{plansData.Heading}</h2>
        <h5 className="second-heading"> {plansData.SubHeading}</h5>
        <hr className="divider scnd_divider mx-auto  mb-5" />
        <div className="row">
          {
            plansData.Box.map((items)=> (
              <div className="col-sm-12 col-md-6 col-lg-4">
              <div className="plans_types">
                <div className="icon_box">
                  <div className={items.icon} />
                </div>
                <div className="align_data">
                  <h4>{items.heading}</h4>
                  <p>{items.subHeading}</p>
                </div>
              </div>
            </div>
            ))
          }
       
          {/* <div className="col-sm-12 col-md-6 col-lg-4">
            <div className="plans_types">
              <div className="icon_box">
                <div className="icon ws-proactive" />
              </div>
              <div className="align_data">
                <h4>Proactive Support</h4>
                <p>(Phone, Chat, Tickets)</p>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-4">
            <div className="plans_types">
              <div className="icon_box">
                <div className="icon ws-email-b" />
              </div>
              <div className="align_data">
                <h4>Personalized Email</h4>
                <p>(Example: info@yourdomain.com)</p>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-4">
            <div className="plans_types">
              <div className="icon_box">
                <div className="icon ws-easy" />
              </div>
              <div className="align_data">
                <h4>Easy To Use</h4>
                <p>(Hosting Panel)</p>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-4">
            <div className="plans_types">
              <div className="icon_box">
                <div className="icon ws-disk-b" />
              </div>
              <div className="align_data">
                <h4>Easy Management</h4>
                <p>(Database management from cPanel)</p>
              </div>
            </div>
          </div>
          <div className="col-sm-12 col-md-6 col-lg-4">
            <div className="plans_types">
              <div className="icon_box">
                <div className="icon ws-chain" />
              </div>
              <div className="align_data">
                <h4>Swift Upgrading</h4>
                <p>(CPU, RAM, I/O etc)</p>
              </div>
            </div>
          </div> */}
        </div>
      </div>
      <style
        dangerouslySetInnerHTML={{
          __html:
            "\n    @media  only screen and  (max-width: 479px){\n.dedicated_bnr .tab-content #linux .owl-carousel .owl-nav button.owl-prev {\n    display: block!important;\n    color: white;\n    bottom: 0;\n    top: 100%;\n    left: 40%;\n    font-size: 30px;\n}\n.dedicated_bnr .tab-content #linux .owl-carousel .owl-nav button.owl-next{\n    display: block!important;\n    color: white;\n    bottom: 0;\n    top: 100%;\n    right: 40%;\n    font-size: 30px;\n}\n    ",
        }}
      />
    </section>
  );
};

export default AllPlanIncludes;
