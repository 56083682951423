import React, { useState, useEffect } from "react";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import BannerTwo from "../../reuseable/Banner2";
import BlogLoader from "./BlogLoader";
import PostLoaderss from "./PostLoader";
import {
  GetAllBlogs,
  GetAllBlogsById,
  GetrecentBlogs,
} from "../../../services/Network.js";
import Pagination from "../../reuseable/Pagination";
import { paginate } from "../../../services/paginate.js";

const Blog = () => {
  const [posts, setPosts] = useState([]);
  const [recentposts, setRecentPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  const [postLoader, setpostLoader] = useState(true);
  const [pageSize, setPageSize] = useState(10);
  const [currentPage, setCurrentPage] = useState(1);
  const navigate = useNavigate();
  const bannerData = {
    heading: "Latest Insights",
    peragraph: "Expert Suggestions, Tips, and Advice",
  };

  const PF = `${process.env.REACT_APP_BASE_URL}images/`;

  useEffect(() => {
    let isCancelled = false;
    if (isCancelled === false) setLoading(true);
    GetAllBlogs()
      .then((res) => setPosts(res))
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));

    GetrecentBlogs()
      .then((resr) => setRecentPosts(resr))
      .catch((err) => console.log(err))
      .finally(() => setpostLoader(false));

    // clean up render
    return () => {
      isCancelled = true;
    };
  }, []);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [currentPage]);

  const openPost = (el) => {
    navigate(``);
  };

  const handelPageChange = (e, page) => {
    e.preventDefault();
    setCurrentPage(page);
  };

  let newPosts = [];
  for (let i = 0; i < posts.length; i++) {
    const element = posts[i].isActive;
    if (element === true) {
      newPosts.push(posts[i]);
    }
  }
  const blogs = paginate(newPosts, currentPage, pageSize);

  function dateFormat(inputDate, format) {
    //parse the input date
    const date = new Date(inputDate);

    //extract the parts of the date
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    //replace the month
    format = format.replace("MM", month.toString().padStart(2, "0"));

    //replace the year
    if (format.indexOf("yyyy") > -1) {
      format = format.replace("yyyy", year.toString());
    } else if (format.indexOf("yy") > -1) {
      format = format.replace("yy", year.toString().substr(2, 2));
    }

    //replace the day
    format = format.replace("dd", day.toString().padStart(2, "0"));

    return format;
  }

  return (
    <>
      <Helmet>
        <title>
          Web Hosting Blog | Web Hosting Tips & Tricks | WebSouls Blog
        </title>
        <meta
          name="title"
          content="Web Hosting Blog | Web Hosting Tips & Tricks | WebSouls Blog"
        />
        <meta
          name="description"
          content="Get the latest Insights, tips & tricks for your web presence. Check out WebSouls recent blogs & knowledge based articles on web hosting, digital marketing & more."
        />
        <meta name="keywords" content="" />
      </Helmet>
      <BannerTwo Data={bannerData} />
      <section className="faq_tabs">
        <div className="container">
          <div className="row">
            <div className="col-md-12 col-sm-12 col-xs-12">
              <ul className="nav-tabs faq_tabs nav pt-5 pb-5" role="tablist">
                <li className="nav-item">
                  <a href="#" id="all_faq" className="active nav-link">
                    All
                  </a>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-md-8 col-ms-8 col-xs-12">
              <div className="tab-content pt-0">
                <div
                  className="tab-pane active fade show"
                  id="all"
                  aria-labelledby="all_faq"
                >
                  <div className="blog_category">
                    <div className="row">
                      {loading ? (
                        <>
                          <BlogLoader />
                        </>
                      ) : (
                        <>
                          {blogs.length !== 0 ? (
                            <>
                              {blogs &&
                                blogs
                                  .sort((a, b) => a.createdAt - b.createdAt)
                                  .map((el) => (
                                    <>
                                      <div
                                        className="col-md-4 col-sm-6 col-xs-6 mb-3 "
                                        key={el._id}
                                      >
                                        <div className="blog_img">
                                          <img
                                            src={PF + el.image}
                                            className="lazy"
                                            alt={el.image}
                                          />
                                        </div>
                                      </div>
                                      <div className="col-md-8 col-sm-6 col-xs-6">
                                        <div className="blog_text">
                                          <span />
                                          <Link
                                             to={`/blog/${el.slug}`}
                                            style={{ cursor: "pointer" }}
                                          >
                                            <h5>{el.title}</h5>
                                          </Link>
                                          <p className="mt-3">{el.excrept}</p>
                                        </div>
                                        <div className="author">
                                          <h6>
                                            <span style={{ fontSize: "13px" }}>
                                              By&nbsp;{el.author}&nbsp;-&nbsp;
                                              {dateFormat(
                                                el.createdAt,
                                                "dd-MM-yyyy"
                                              )}
                                              ;
                                              <span />
                                            </span>
                                          </h6>
                                        </div>
                                      </div>
                                    </>
                                  ))}
                            </>
                          ) : (
                            <p>No Posts To Display</p>
                          )}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
              <Pagination
                itemCount={posts.length}
                pageSize={pageSize}
                currentPage={currentPage}
                onPageChange={handelPageChange}
              />
            </div>
            <div className="col-md-4 col-ms-8 col-xs-12">
              <div className="blog_articles_section">
                <div className="row">
                  <div className="col-md-12">
                    <form
                      method="GET"
                      action="https://www.websouls.com/blog/blog"
                      acceptCharset="UTF-8"
                    >
                      <div className="input-group">
                        <input
                          type="text"
                          className="form-control"
                          defaultValue=""
                          name="keyword"
                          placeholder="Search"
                        />
                        <div className="input-group-append">
                          <span>
                            <button type="submit">
                              <i className="fa fa-search" />
                            </button>
                          </span>
                        </div>
                      </div>
                    </form>
                  </div>
                  <div className="col-md-12">
                    <div className="blog_articales">
                      <span>Latest Articles</span>
                      {postLoader ? (
                        <>
                          <PostLoaderss />
                        </>
                      ) : (
                        <>
                          {recentposts &&
                            recentposts.map((el) => (
                              <>
                                <div
                                  className="first_article"
                                  // onClick={}
                                >
                                  <div className="articl_img">
                                    <img
                                      src={PF + el.image}
                                      className="lazy"
                                      alt={el.image}
                                    />
                                  </div>
                                  <div className="article_text">
                                    <span>
                                      <h6 style={{ display: "inline" }}>
                                        {" "}
                                        By{" "}
                                      </h6>
                                      {el.author} - {el.updatedAt}.
                                    </span>
                                    <span>
                                      {" "}
                                      {el.author} - {el.updatedAt}
                                    </span>
                                    <Link
                                      to={`/blog/${el.slug}`}
                                      // style={{ cursor: "pointer" }}
                                    >
                                      <h5>{el.title}</h5>
                                    </Link>
                                  </div>
                                </div>
                              </>
                            ))}
                        </>
                      )}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Blog;

