import React, { useState, useEffect } from "react";
import { SendPopupEmail, SendPopupEmailSeo } from "../../../services/Network.js";
import Carousel from "react-elastic-carousel";
import { useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
const PopupSlider = ({ data }) => {
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [webUrl, setWebUrl] = useState("");
  const [status, setStatus] = useState(true);
  const [pageUrl] = useState("yahoo.com");

  const [popup, setPopup] = useState(false);
  const [formData, setFormData] = useState([]);
  const { currentUrl } = useSelector((state) => state.currentUrl);
  const location = useLocation();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch({
      type: "urlCurrent",
      payload: location.pathname,
    });
    if (data.pakageArray.length <= 3) {
      setStatus(false);
    }
  }, []);

  const onSubmit = (e) => {
    e.preventDefault();

    let typ = "";
    if (currentUrl == "/seo-services") {
      typ = "SEO";
    } else {
      typ = "Development";
    }
    if (location.pathname === "/seo-services") {
      if (name && phone && email !== "" && webUrl !== "") {
        SendPopupEmailSeo(
          name,
          phone,
          email,
          webUrl,
          formData.pid,
          formData.package_name,
          pageUrl,
          typ
        ).then((resss) => {
          setName("");
          setPhone("");
          setEmail("");
          setWebUrl("");
        });
      }
      if (name && phone && email !== "") setPopup(false);
    } else {
      if (name && phone && email !== "") {
        SendPopupEmail(
          name,
          phone,
          email,
          formData.pid,
          formData.package_name,
          pageUrl,
          typ
        ).then((resss) => {
          setName("");
          setPhone("");
          setEmail("");
        });
      }
      if (name && phone && email !== "") setPopup(false);
    }
  };

  const OpenPopup = (e, item) => {
    e.preventDefault();
    setFormData(item);
    setPopup(true);
  };

  const ClosePopup = (e) => {
    e.preventDefault();
    setPopup(false);
  };

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2 },
    { width: 768, itemsToShow: 3 },
    { width: 1200, itemsToShow: 3 },
  ];

  return (
    <>
      <section className="ssliderhost banner-size  ">
        {popup ? (
          <section
            className="pop_up"
            id="popup_target_email"
            style={{ display: "block" }}
          >
            <div className="hide_popup" onClick={(e) => ClosePopup(e)} />
            <div className="center_popup width_change">
              <div className="container">
                <div className="row">
                  <div className="col-md-12 col-sm-12 col-xs-12 pl-0 pr-0">
                    <div className="popup_body hosting_plans_popup ecommerce_solution">
                      <span
                        style={{ cursor: "pointer", color: "black" }}
                        className="close_popup"
                        onClick={(e) => ClosePopup(e)}
                      >
                        X
                      </span>
                      <div className="row">
                        <div className="col-md-12">
                          <h3>
                            Want to Take Over the Web?{" "}
                            <span className="white_span" />
                          </h3>
                          <p>
                            <span className="blue_span">Let’s Do it!</span>
                          </p>
                          <ul>
                            <li>
                              <form method="post">
                                <input
                                  type="hidden"
                                  name="_token"
                                  defaultValue="JvQ3lLTa6lRpCYtXDOrhCIlPPVCtjvxahsR7cfS9"
                                />
                                <input
                                  value={name}
                                  onChange={(e) => setName(e.target.value)}
                                  type="text"
                                  id="full_name"
                                  required=""
                                  placeholder="Enter your full name"
                                  name={305}
                                />
                                <input
                                  value={phone}
                                  onChange={(e) => setPhone(e.target.value)}
                                  type="text"
                                  id="phone"
                                  required=""
                                  placeholder="Enter your phone number"
                                  name={347}
                                />
                                <input
                                  value={email}
                                  onChange={(e) => setEmail(e.target.value)}
                                  type="text"
                                  id="email"
                                  required=""
                                  placeholder="Enter your email address"
                                  name={85}
                                />
                                <>
                                  {location.pathname === "/seo-services" ? (
                                    <>
                                      <input
                                        value={webUrl}
                                        onChange={(e) =>
                                          setWebUrl(e.target.value)
                                        }
                                        type="text"
                                        id="email"
                                        required=""
                                        placeholder="Enter your website URL"
                                        name={85}
                                      />{" "}
                                    </>
                                  ) : null}
                                </>

                                <input
                                  value={formData.pid}
                                  type="hidden"
                                  id="pe_product_id"
                                  defaultValue={310}
                                  name={966}
                                />
                                <input
                                  value={formData.package_name}
                                  type="hidden"
                                  id="pe_package_name"
                                  defaultValue="STARTUP"
                                  name={640}
                                />
                                <input
                                  value={pageUrl}
                                  type="hidden"
                                  id="page_url"
                                  defaultValue="/ecommerce-solution"
                                  name={191}
                                />
                                <button
                                  onClick={(e) => onSubmit(e)}
                                  type="submit"
                                  className="text mt-3"
                                  data-badge="bottomleft"
                                  data-sitekey="6LcqVawZAAAAAMLoXFi4PcikBY_UQKl_Xdo1cCzw"
                                  data-callback="onSubmit"
                                >
                                  Let’s Talk
                                </button>
                              </form>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : null}
        <div className="container">
          <div className="row">
            <h2 className="first-heading">{data?.heading1}</h2>
            <h5 className="second-heading">{data?.heading2}</h5>
            <hr className="divider scnd_divider mx-auto mb-4" />
            <Carousel
              className="animate__animated animate__fadeIn"
              itemsToShow={3}
              itemPadding={[8, 10, 8, 10]}
              breakPoints={breakPoints}
              pagination={true}
              showArrows={status}
            >
              {data?.pakageArray?.map((item) => (
                <React.Fragment key={item.pid}>
                  <div
                    style={{
                      backgroundColor: "transparent",
                      border: "1px solid #D2D2D2",
                      borderRadius: 5,
                    }}
                    className="col-md-12 col-sm-12 col-xs-12 pacakge-one text-start"
                  >
                    <div className="package-name ">
                      <h3>{item?.package_name}</h3>
                      <p>{item?.package_plans}</p>
                    </div>
                    <div className="discount">
                      <h4 className="p-2">{data?.OnCall}</h4>
                    </div>
                    <ul>
                      {item?.planList?.map((el, index) => (
                        <li key={index}>
                          {" "}
                          <font>
                            <strong
                              style={{
                                color: "#005880",
                                fontSize: 20,
                                fontWeight: 400,
                                paddingRight: 8,
                              }}
                            >
                              ✓{" "}
                            </strong>
                          </font>{" "}
                          {el}
                        </li>
                      ))}
                    </ul>
                    <form>
                      <input
                        type="hidden"
                        name="product_id"
                        defaultValue={item?.pid}
                      />
                      <input
                        type="hidden"
                        name="product_id"
                        defaultValue={item?.package_name}
                      />
                      <button
                        type="submit"
                        className="text"
                        onClick={(e, value) => OpenPopup(e, item)}
                      >
                        Get Started
                      </button>
                    </form>
                  </div>
                </React.Fragment>
              ))}
            </Carousel>
          </div>
        </div>
      </section>
    </>
  );
};

export default React.memo(PopupSlider);
