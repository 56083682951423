import React from "react";
import _ from "lodash";

const Pagination = (props) => {
  const { itemCount, pageSize, onPageChange, currentPage } = props;

  const pageCount = Math.ceil(itemCount / pageSize);
  if (pageCount === 1) return null;
  const pages = _.range(1, pageCount + 1);

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="clients_pagination">
          <nav aria-label="Page navigation example">
            <ul className="pagination" role="navigation">
              <li
                className="page-item disabled"
                aria-disabled="true"
                aria-label="« Previous"
              >
                <span className="page-link" aria-hidden="true">
                  ‹
                </span>
              </li>
              {pages.map((page) => (
                <li
                  style={{ cursor: "pointer" }}
                  className={
                    page === currentPage ? "page-item active" : "page-item"
                  }
                  aria-current="page"
                  key={page}
                >
                  <span
                    className="page-link"
                    onClick={(e) => onPageChange(e, page)}
                  >
                    {page}
                  </span>
                </li>
              ))}

              <li className="page-item">
                <a
                  className="page-link"
                  href="#"
                  rel="next"
                  aria-label="Next »"
                >
                  ›
                </a>
              </li>
            </ul>
          </nav>
        </div>
      </div>
    </div>
  );
};

export default Pagination;
