import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { GetPackages } from "../../../services/Network.js";
import BundelBooster from "./includes/BussinessWebsite/BundelBooster";
import DomainMigration from "./includes/BussinessWebsite/DomainMigration";
import GoingOnline from "./includes/BussinessWebsite/GoingOnline";
import PopupSlider from "../../reuseable/includes/PopupSlider";
import InPakistan from "./includes/BussinessWebsite/InPakistan";
import AllPlanIncludes from "./includes/BussinessWebsite/AllPlanIncludes";
import WillingForHelp from "../../reuseable/WillingForHelp";
import WhyChooseUsSecond from "../../reuseable/WhyChooseUsSecond";
import Faq from "../../reuseable/Faq";
import CompareBuisnessPlans from "../../reuseable/CompareBuisnessPlans";

const BussinessWebsite = () => {
  const [comparePlansState, setComparePlansState] = useState([]);
  const [pidsss] = useState([181, 125, 181]);
  const data = {
    Heading: "Got A Tired Website?",
    SubHeading: "We can freshen it up!",
    ButtonName: "Migrate Now!",
  };
  const allplans = {
    Heading: "All Plans Include",
    SubHeading: "Everything you Need for an Online Store that Actually Sells",
    Box: [
      {
        id: 0,
        heading: "Mobile Responsive",
        subHeading: "",
        icon: "icon ws-responsive",
      },
      {
        id: 1,
        heading: "SEO Friendly",
        subHeading: "",
        icon: "icon ws-location",
      },
      {
        id: 2,
        heading: "SM Integration",
        subHeading: "",
        icon: "icon ws-sm-icons",
      },
      {
        id: 3,
        heading: "Easy To Use CMS",
        subHeading: "",
        icon: "icon ws-easy",
      },
      {
        id: 4,
        heading: "Cutting Edge Design",
        subHeading: "",
        icon: "icon ws-pen",
      },
      {
        id: 5,
        heading: "Secure And Reliable",
        subHeading: "",
        icon: "icon ws-secure-b-1",
      },
    ],
  };
  const BundleData = {
    Heading: "Bundle Boosters",
    SubHeading: "Complete Your Package with a Little Something Extra",
    Bundles: [
      {
        id: 0,
        bundleHeading: "SEO",
        bundleParagh:
          "We can optimize your site for giant search engines like Google and help you rank higher on Search Engine Result Pages for increased web traffic, higher conversions, boosted sales, and maximized revenue generation.",
        bundleImage: "assests/img/SEO-services-in-Pakistan.png",
        bundleId: "seo-tab",
        bundleDataTarget: "#seo",
        bundleAriaControl: "seo",
        bundleParaghId: "seo",
        bundleParaghAriaLable: "seo-tab",
        class: "tab-pane fade show active",
        buttonClass: "nav-link active",
      },
      {
        id: 1,
        bundleHeading: "Content Writing",
        bundleParagh:
          "Our team of professional content writers is adept in creating just the right kind of content that can help improve your search engine rankings, attract more customers, and resonate across your audience’s minds.",
        bundleImage: "assests/img/Professional-Content-Writting-Services.png",
        bundleId: "content-Writing-tab",
        bundleDataTarget: "#content",
        bundleAriaControl: "content",
        bundleParaghId: "content",
        bundleParaghAriaLable: "content-Writing-tab",
        class: "tab-pane fade show",
        buttonClass: "nav-link",
      },
      {
        id: 2,
        bundleHeading: "Social Media",
        bundleParagh:
          "We know how important it is to stay active on social media nowadays. Our certified social media marketers can target your customers across various social media platforms and persuade them to buy from you.",
        bundleImage: "assests/img/Social-Media-Marketing-Services.png",
        bundleId: "social-media-tab",
        bundleDataTarget: "#social",
        bundleAriaControl: "social",
        bundleParaghId: "social",
        bundleParaghAriaLable: "social-media-tab",
        class: "tab-pane fade show",
        buttonClass: "nav-link",
      },
      {
        id: 3,
        bundleHeading: "SSL Certificate",
        bundleParagh:
          "We provide our clients with SSL certificates to help them keep their site, and the data of their customers safe from hackers. An SSL is just what you need to build your customers’ trust and get those sales going!",
        bundleImage: "assests/img/SSL-certificate-in-Pakistan.png",
        bundleId: "ssl-certificates-tab",
        bundleDataTarget: "#ssl",
        bundleAriaControl: "ssl",
        bundleParaghId: "ssl",
        bundleParaghAriaLable: "ssl-certificates-tab",
        class: "tab-pane fade show",
        buttonClass: "nav-link",
      },
    ],
  };
  const WillingToHelpData = {
    heading: "Ready, Willing and Able to Help!",
    subHeading:
      "Whether it’s a question, query, or you want to discuss a project, the WebSouls team is at your service anytime, at all times!",
    buttonOne: "Call: +92 423 523 8871 - 73",
    buttonTwo: " Email Us",
    image: "assests/img/Muhammad-Shehryar-Team-Websouls.png",
  };
  const GoingOnlineData = {
    Heading: "Going Online? You’d Need More!",
    subHeading: "We Don’t Just Build Websites, We Create an Impact",
    box: [
      {
        id: 0,
        topTitle: "Need Email Hosting?",
        Title: "Try Business Hosting",
        buttonLink: "/business-hosting",
      },
      {
        id: 1,
        topTitle: "Need Something Simpler?",
        Title: "Try WordPress Hosting",
        buttonLink: "/wordpress-hosting-in-pakistan",
      },
    ],
  };
  const InPakistanData = {
    Heading: "Website Development in Pakistan",
    subHeading: "A Digital Face That’s More Than Perfect",
    peragraph: [
      <>
        <p>
          <Link to="/">WebSouls</Link> is a well-known and widely trusted name
          when it comes to reliable web hosting in Pakistan and website
          development services. We’ve successfully helped numerous cooperate and
          government organizations in building a strong web identity with our
          web designing and development services that we offer in Lahore,
          Karachi, Islamabad, and in fact, throughout the entire nation.
        </p>
        <p>
          Our team comes to work everyday with the aim of making going digital
          easier, simpler, and swifter for businesses in Pakistan. Whether you
          wish to run an online store or want to turn your blog into a business,
          we’re here to help you throughout your digital journey!
        </p>
        <p>
          The WebSouls team puts in a lot of time and effort, firstly in
          understanding our clients’ needs, and then turning their ideas into a
          web-based reality. In addition to this, we can also help you go online
          with fastest <Link to="/shared-hosting">web hosting in Lahore</Link>,
          Karachi, Islamabad &amp; other major cities of Pakistan.
        </p>
        <span id="dots">...</span>
        <span id="more">
          <p>
            Our company offers web design and software development packages
            that’re highly competitive and come at the best price rates with
            numerous amazing features included, such as SEO-friendly websites,
            mobile responsiveness, and web designs that are increasingly
            interactive.
          </p>
          <p>
            We can design and develop custom websites for clients as per their
            needs and requirements. We also offer copywriting and SEO content
            writing services, alongside domain name registration and web
            hosting. Which means, you can completely rely on us when it comes to
            taking your business online. Moreover, in addition to being one of
            the leading 
            <strong style={{ color: "grey" }}>
              web development companies in Lahore
            </strong>
            , we also provide <Link to="/seo-services">SEO Services</Link> and
            social media marketing services.
          </p>
          <p>
            Our SEO services are designed to get you results. Whether your goal
            is to get more customers, rank on Google’s first page or promote
            your brand online, our SEO plans provide you with the ideal
            solution. Best of all, we integrate our SEO with web design and
            marketing, providing a complete digital solution for your business.
          </p>
          <p>
            We’re more than just creative designers and developers, we’re
            Pakistan’s No. 1 web hosting agency offering high-quality yet
            cost-effective services ranging from hosting solutions to 
            <Link to="/ecommerce-solution">ecommerce websites</Link> and domain
            name registration.
          </p>
          <p>
            Moreover, hosting with us would be 100% carefree since we promise
            99.9% uptime and are cPanel’s official partner in Pakistan. Ur
            numerous web hosting packages have been designed to provide maximum
            value and exceptional performance for websites of all sizes.
          </p>
          <p>
            We also know that a good business website requires premium hosting,
            which is why we offer enough resources to ensure your business
            website runs smoothly. Our corporate hosting packages are great for
            business websites and have everything you need to scale your
            business online. We also offer dedicated and VPS hosting packages if
            you want to run multiple websites under your business name.
          </p>
          <p>
            So, if you want to take your business in the digital world, buy the
            best of our web hosting and&nbsp;
            <strong style={{ color: "grey" }}>
              website development packages
            </strong>
            and make your business grow exponentially!
          </p>
          <p>
            The websites that we develop are all social media integrated and SEO
            friendly. We can also help you build an online store at the most
            affordable price rates available in market.
          </p>
          <p>
            With our affordable custom website plans, you won’t have to go for
            low-quality website themes again. So, whether you want a custom
            template, unique forms, more landing pages or other website feature,
            we’ve got the ideal solution for you.&nbsp;
            <Link to=".contactus">Contact us</Link> today to get the custom
            website of your dreams!.
          </p>
          <p>
            At Websouls, we believe in empowering our clients so they can make
            the best use of all the premium website features we provide. We’ll
            even offer you a training session explaining how to use different
            website features so you can manage your website more effectively.
            We’ll also answer all your questions regarding website management,
            so you’ll have complete confidence in managing the website of your
            dreams.
          </p>
          <p>
            If you are struggling with low conversion rates or poor customer
            feedback, then we’ve got a solution for you. Our website design team
            is experienced in helping you create a website that is optimized for
            conversions. We also design the best landing pages to optimize your
            Google ads or Facebook ads conversion rate.
          </p>
          <p>
            We know that slight changes in your website layout, design and
            features can make a world of difference in your conversion rate and
            customer experience. This is why, our&nbsp;
            <strong style={{ color: "grey" }}>
              expert web development
            </strong>{" "}
            team will only add the best website features designed to optimize
            your website for conversions and give the customer an experience to
            remember!
          </p>
          <p>
            When it comes to website ranking factors, UX design is crucial. We
            design websites with user experience in mind. Our websites are easy
            to navigate and have the best tools for your business requirements.
            So, whether your goal is to give a great brand impression, improve
            your sales numbers or increase customer engagement, we’ll create the
            perfect website for you!
          </p>
        </span>
        <a className="read-more-link d-block d-md-none d-lg-none d-xl-none d-xxl-none">
          <h2 className="text-center pt-2">
            <button
              onClick={() => myFunction()}
              id="myBtn"
              className="readmoreMobile"
            >
              Read More
            </button>
          </h2>
        </a>
      </>,
    ],
  };
  const faqData = {
    heading: "Frequently Asked Questions",
    subHeading: "Dedicated to Helping You Succeed!",
    faqs: [
      {
        id: 0,
        title: "What is a responsive web design?",
        desc: "A responsive web design means your site will adjust to screens of all sizes and look perfect on every device, such as smartphones, tablets, and laptops.",
        faqId: "FAQ1",
        dataBsTarget: "#faqOne",
        ariaControls: "faqOne",
        ariaLabelledby: "FAQ1",
        faqParaghId: "faqOne",
      },
      {
        id: 1,
        title: "How can you help me grow my website?",
        desc: "We offer various services that can help increase your web traffic and boost conversions, including search engine optimization, content writing, and social media marketing. Moreover, we can also provide you with an SSL certificate which can result in higher rankings and more sales online.",
        faqId: "FAQ2",
        dataBsTarget: "#faqTwo",
        ariaControls: "faqTwo",
        ariaLabelledby: "FAQ2",
        faqParaghId: "faqTwo",
      },
      {
        id: 2,
        title: "Do you redesign existing websites?",
        desc: "Yes, absolutely! We love playing with designs and can give an old, outdated website a completely fresh look. We can help you in not just attracting visitors, but also in engaging them and making them stay longer on your site.",
        faqId: "FAQ3",
        dataBsTarget: "#faqThree",
        ariaControls: "faqThree",
        ariaLabelledby: "FAQ3",
        faqParaghId: "faqThree",
      },
      {
        id: 3,
        title: "How can SEO help my business?",
        desc: "Search Engine Optimization is the key to increased web traffic, which can ultimately lead to higher conversions, elevated sales, and unhindered business growth! In addition to this, if your website ranks higher on Google, customers are more likely to trust your brand and end up buying from you.",
        faqId: "FAQ4",
        dataBsTarget: "#faqFour",
        ariaControls: "faqFour",
        ariaLabelledby: "FAQ4",
        faqParaghId: "faqFour",
      },
    ],
  };

  const dataPlans = {
    heading1: "If You Can Dream It, We Can Web It",
    heading2: "Your Website is Just a Beginning – We Make Sure It’s Perfect!",
    OnCall: "Get Quote on Call",
    pakageArray: [
      {
        pid: 181,
        package_name: "BUSINESS",
        package_plans: "POPULAR ",
        planList: [
          "100% Dynamic Website ",
          "Cutting Edge Design ",
          "Upto 20 Content Pages ",
          "3 Professionaly Designed Banners ",
          " Integrated Social Pages ",
          "SEO Friendly Online Store ",
          " Fully Mobile Responsive ",
          " Upto 3 Contact Forms ",
          " On Demand Training Session ",
        ],
      },
      {
        pid: 180,
        package_name: "BASIC",
        package_plans: "ESSENTIAL ",
        planList: [
          "100% Dynamic Website ",
          "Cutting Edge Design ",
          "Upto 10 Content Pages ",
          "1 Professionaly Designed Banners ",
          " Integrated Social Pages ",
          "SEO Friendly Website",
          " Fully Mobile Responsive ",
          " 1 Contact Forms ",
          " On Demand Training Session ",
        ],
      },
      {
        pid: 183,
        package_name: "CUSTOM",
        package_plans: "BEST VALUE",
        planList: [
          "100% Dynamic Website ",
          "Custom Niche Based Design ",
          "Page Management ",
          "Creative Logo Design ",
          " Custom Designed Sliders ",
          "SEO Friendly Website",
          " Fully Mobile Responsive ",
          " Custom CMS With Admin Panel ",
          " Client Portfolio ",
        ],
      },
    ],
  };

  const BuisnessData = {
    Box: [
      {
        id: 0,
        heading: "BUSINESS",
        subHeading: "On Call./",
      },
      {
        id: 1,
        heading: "BASIC",
        subHeading: "On Call./",
      },
      {
        id: 2,
        heading: "CUSTOM",
        subHeading: "On Call./",
      },
    ],
  };

  var dots = document.getElementById("dots");
  var moreText = document.getElementById("more");
  var btnText = document.getElementById("myBtn");

  useEffect(() => {
    myFunction();
  }, [btnText, moreText, dots]);

  function myFunction() {
    var dots = document.getElementById("dots");
    var moreText = document.getElementById("more");
    var btnText = document.getElementById("myBtn");
    let widnowWidth = window.innerWidth;
    if (widnowWidth < 500) {
      if (dots.style.display === "inline") {
        dots.style.display = "none";
        btnText.innerHTML = "Read More";
        moreText.style.display = "none";
      } else {
        dots.style.display = "inline";
        btnText.innerHTML = "Read Less";
        moreText.style.display = "inline";
      }
    } else {
      moreText.style.display = "inline";
    }
  }

  return (
    <>
      <Helmet>
        <title>Web Design Pakistan | Web Development Pakistan | WebSouls</title>
        <meta
          name="title"
          content="Web Design Pakistan | Web Development Pakistan | WebSouls"
        />
        <meta
          name="description"
          content="Looking for web design in Pakistan? WebSouls is the best choice for web development in Pakistan and web design in Pakistan. WebSouls is one of the best software companies in Pakistan."
        />
        <meta name="keywords" content="" />
        <script type="application/ld+json">
          {`{
  "@context": "https://schema.org",
  "@type": "Organization",
  "name": "WebSouls",
  "alternateName": "Web Hosting Pakistan",
  "url": "https://www.websouls.com/",
  "logo": "https://www.websouls.com/public/uploads/images/logo_1589438878.png",
  "contactPoint": [{
    "@type": "ContactPoint",
    "telephone": "+92 321 407 7991",
    "contactType": "technical support",
    "areaServed": "PK",
    "availableLanguage": ["en","Urdu"]
  },{
    "@type": "ContactPoint",
    "telephone": "+92 322 525 2352",
    "contactType": "sales",
    "areaServed": "PK",
    "availableLanguage": ["en","Urdu"]
  }],
  "sameAs": [
    "https://www.facebook.com/WebSouls/",
    "https://www.twitter.com/futuresouls",
    "https://www.instagram.com/websouls.pk/",
    "https://www.linkedin.com/company/websouls/",
    "https://github.com/websouls",
    "https://www.websouls.com/"
  ]
}`}
        </script>
        <script type="application/ld+json">
          {`{
  "@context": "https://schema.org/", 
  "@type": "Product", 
  "name": "Web Development Pakistan - WebSouls",
  "image": "https://www.websouls.com/public/uploads/images/logo_1589438878.png",
  "description": "WebSouls is a well-known and widely trusted name when it comes to reliable web hosting in Pakistan and website development services. We’ve successfully helped numerous cooperate and government organizations in building a strong web identity with our web designing and development services that we offer in Lahore, Karachi, Islamabad, and in fact, throughout the entire nation.",
  "brand": "WebSouls",
  "sku": "Web Development Pakistan",
  "mpn": "Web Development Pakistan",
  "offers": {
    "@type": "AggregateOffer",
    "url": "https://www.websouls.com/web-development",
    "priceCurrency": "PKR",
    "lowPrice": "49999",
    "highPrice": "90000",
    "offerCount": "3"
  },
  "aggregateRating": {
    "@type": "AggregateRating",
    "ratingValue": "4.9",
    "bestRating": "4.9",
    "worstRating": "4.1",
    "ratingCount": "2145"
  }
}`}
        </script>
        <script type="application/ld+json">
          {`{
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "What is a responsive web design?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "A responsive web design means your site will adjust to screens of all sizes and look perfect on every device, such as smartphones, tablets, and laptops."
    }
  },{
    "@type": "Question",
    "name": "How can you help me grow my website?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "We offer various services that can help increase your web traffic and boost conversions, including search engine optimization, content writing, and social media marketing. Moreover, we can also provide you with an SSL certificate which can result in higher rankings and more sales online."
    }
  },{
    "@type": "Question",
    "name": "Do you redesign existing websites?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Yes, absolutely! We love playing with designs and can give an old, outdated website a completely fresh look. We can help you in not just attracting visitors, but also in engaging them and making them stay longer on your site."
    }
  },{
    "@type": "Question",
    "name": "How can SEO help my business?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Search Engine Optimization is the key to increased web traffic, which can ultimately lead to higher conversions, elevated sales, and unhindered business growth! In addition to this, if your website ranks higher on Google, customers are more likely to trust your brand and end up buying from you."
    }
  }]
}`}
        </script>
        <link rel="canonical" href="https://websouls.com/web-development" />
      </Helmet>
      <PopupSlider data={dataPlans} />
      <AllPlanIncludes plansData={allplans} />
      <BundelBooster bundleData={BundleData} />
      <GoingOnline Data={GoingOnlineData} />
      {/* <WhyChooseUsSecond /> */}
      <CompareBuisnessPlans BuisnessData={BuisnessData} />
      <DomainMigration Data={data} />
      <InPakistan Data={InPakistanData} />
      <Faq Data={faqData} />
      <WillingForHelp helpData={WillingToHelpData} />
    </>
  );
};

export default BussinessWebsite;
