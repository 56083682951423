import React from "react";
import TestimonSlide from "./includes/TestimonSlide";

const Testimonails = () => {
  return (
    <section className="testimonials pt-100">
      <div className="container">
        <h2 className="first-heading">Our Soul Interest</h2>
        <h5 className="second-heading">We Make Customers for a Living</h5>
        <hr className="divider scnd_divider mx-auto mb-4" />

        <div className="row">
          <TestimonSlide />
        </div>
      </div>
    </section>
  );
};

export default React.memo(Testimonails);