import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import axios from "axios";
import { Link } from "react-router-dom";
import DomainMigration from "./includes/AeDomain/DomainMigration";
import DomainSearchGlobal from "../../reuseable/DomainSearchGlobal";
import DomainTipAe from "./includes/AeDomain/DomainTipAe";
import Faq from "../../reuseable/Faq";
import GoingOnline from "./includes/AeDomain/GoingOnline";
import InPakistan from "./includes/AeDomain/InPakistan";
import WillingForHelp from "../../reuseable/WillingForHelp";
import DomainExtraction from "./includes/AeDomain/DomainExtraction";
import BundelBooster from "./includes/AeDomain/BundelBooster";

const AEDomains = () => {
  const [spotlight, setspotlight] = useState([]);
  const { currencyName } = useSelector((state) => state.currencyName);

  useEffect(() => {
    const sptlds = `${process.env.REACT_APP_BASE_URL}api/currencies/aetlds`;
    axios
      .post(sptlds, null, {
        params: {
          page: 0,
          limit: 20,
          typ: "domainregister",
        },
      })
      .then((response) => {
        setspotlight(response.data);
      })
      .catch((err) => console.warn(err));
  }, [currencyName]);

  const BundleData = {
    Heading: "Domain Renewals & Transfers",
    SubHeading: "Keeping Your Website, and Your Business, Alive",
    Bundles: [
      {
        bundleHeading: "Transfer .ae domain",
        bundleParagh: [
          <>
            <p>
              We know getting stuck can be terrible. So, to help you out, and to
              keep your site running smoothly, we’re offering hassle-free domain
              name transfer without any charges at all.
            </p>
            <p>
              Ready to take on the web with us? Send your domain authorisation
              code right away!s
            </p>
          </>,
        ],
        bundleImage: "assests/img/Transfer-pk-Domain.png",
        class: "tab-pane fade show active",
        buttonClass: "nav-link active",
        bundleId: "dedicated-ip-tab",
        bundleDataTarget: "#dedicated",
        bundleAriaControl: "dedicated",
        bundleParaghId: "dedicated",
        bundleParaghAriaLable: "dedicated-ip-tab",
      },
      {
        bundleHeading: "Domain Renewals",
        bundleParagh: [
          <>
            <p>
              Having your domain accidently expired can be a horrifying
              nightmare. We can help you stay on the World Wide Web with the
              help of our auto-renewal service.
            </p>
            <p>So, are you stumbling? Grab our hand!</p>
          </>,
        ],
        bundleImage: "assests/img/Transfer-pk-Domain.png",
        class: "tab-pane fade show",
        buttonClass: "nav-link",
        bundleId: "malware-scanning-tab",
        bundleDataTarget: "#malware",
        bundleAriaControl: "malware",
        bundleParaghId: "malware",
        bundleParaghAriaLable: "malware-scanning-tab",
      },
    ],
  };
  const GoingOnlineData = {
    Heading: "Going Online? You’d Need More!",
    subHeading: "We Don’t Just Build Websites, We Create an Impact",
    box: [
      {
        id: 0,
        topTitle: "Need Email Hosting?",
        Title: "Try Business Hosting",
        buttonLink: "/business-hosting",
      },
      {
        id: 1,
        topTitle: "Need Something Simpler?",
        Title: "Try WordPress Hosting",
        buttonLink: "/wordpress-hosting-in-pakistan",
      },
    ],
  };
  const technicalSpecificationsData = {
    Heading: "Don’t Be Stumped. We’ve Got You Covered!",
    subHeading: "Domain Name Tips from Experts",
    box: [
      {
        id: 0,
        icon: "icon ws-quick",
        title: "Easy-to-Remember",
        peragraph:
          "It’s great if your domain name includes a keyword to help with SEO, but make sure to keep it simple, catchy, and memorable. Avoid going for a name that’s way too long or complicated.",
      },
      {
        id: 1,
        icon: "icon ws-brand",
        title: "Resonates with Your Brand",
        peragraph:
          "Nothing is better than a domain name that’s brandable, highly unique, and resonates with your business and company goals. Try and keep it relevant to the purpose of your website",
      },
      {
        id: 2,
        icon: "icon ws-time-a",
        title: "Don’t Waste Time",
        peragraph:
          "When it comes to domain names, they get registered pretty fast. So, if you’ve found your ideal one, don’t waste any more time, just hurry up and register before anyone else does!",
      },
    ],
  };
  const data = {
    Heading: "Quit Worrying About Migration Hassles!",
    SubHeading:
      "Our experts can transfer your website and get you onboard for FREE. With us, migration is like ripping off a band aid – quick and painless!",
    ButtonName: "Migrate Now!",
  };
  const InPakistanData = {
    Heading: ".ae Domain Registration in Pakistan",
    subHeading: "The Right Domain Name Can Get You the Right Visitors",
    peragraph: [
      <>
        <p>
          As the&nbsp;
          <Link to="/" target="_blank">
            <strong>no. 1 web hosting service provider in Pakistan</strong>
          </Link>
          , WebSouls proudly holds max market share of domains in the nation,
          and our team can be of great assistance in helping you find your ideal
          domain name at the best price without much hassle.
        </p>
        <p data-pm-slice="1 1 []">
          You can easily purchase an ae website domain with us, go for a{" "}
          <Link to="/buy-pk-domain">.pk domain registration</Link>, or get a
          .uae domain while we assure your domain name booking doesn’t get
          accidently expired with the help of our auto-renewal service. In
          addition to this, we also keep our customers’ domain names safe by
          blocking both malicious and accidental domain transfers.
        </p>
        <p>
          Moreover, we offer ae top-level domains at the best prices possible,
          and you can get an ae domain registration just with a few clicks. Not
          just this, but we also provide our clients with FREE&nbsp;
          <Link to="/domain-transfer" target="_blank">
            <strong>domain transfer</strong>
          </Link>
          &nbsp;in case they already have a domain registered with any other
          company and want to migrate. How to transfer your domain? Just contact
          our team and we’d manage it all!
        </p>
        <p>
          All in all, team WebSouls can really do the heavy-lifting for you
          while you focus on your business growth. So, if you’re still confused
          about where to buy an .ae domain at the best rates, let us assist you
          in the best way possible. Don’t believe us? Check out what our
          customers think!
        </p>
      </>,
    ],
  };
  const faqData = {
    heading: "Frequently Asked Questions",
    subHeading: "Dedicated to Helping You Succeed!",
    faqs: [
      {
        id: 0,
        title: "What is a domain name?",
        desc: "A domain name is an address that allows your visitors to find you on web. While choosing a domain name, make sure it’s easy to remember, catchy, and reflects your business and company goals.",
        faqId: "FAQ1",
        dataBsTarget: "#faqOne",
        ariaControls: "faqOne",
        ariaLabelledby: "FAQ1",
        faqParaghId: "faqOne",
      },
      {
        id: 1,
        title: "Which domain extensions are available?",
        desc: "With us you can find the perfect domain name with a variety of extensions to choose from, including .com, .pk, .edu, .gov, .net, .us, .biz, .info, .ae, .co.uk, and many more.",
        faqId: "FAQ2",
        dataBsTarget: "#faqTwo",
        ariaControls: "faqTwo",
        ariaLabelledby: "FAQ2",
        faqParaghId: "faqTwo",
      },
      {
        id: 2,
        title: "How is auto-renewal helpful?",
        desc: "Our auto-renewal service protects your domain from getting accidentally lost or expiring. We make sure that your domain names are always protected.",
        faqId: "FAQ3",
        dataBsTarget: "#faqThree",
        ariaControls: "faqThree",
        ariaLabelledby: "FAQ3",
        faqParaghId: "faqThree",
      },
      {
        id: 3,
        title: "Can I transfer my domain name to WebSouls?",
        desc: "Of course, you can! We offer free domain transfer facility for all our customers. Even if you are new to WebSouls, just contact us via phone, email, or live chat and allow our dedicated customer support executives to help you out. You can also visit us at one of our offices in Lahore or Karachi.",
        faqId: "FAQ4",
        dataBsTarget: "#faqFour",
        ariaControls: "faqFour",
        ariaLabelledby: "FAQ4",
        faqParaghId: "faqFour",
      },
    ],
  };
  const WillingToHelpData = {
    heading: "Ready, Willing and Able to Help!",
    subHeading:
      "Whether it’s a question, query, or you want to discuss a project, the WebSouls team is at your service anytime, at all times!",
    buttonOne: "Call: +92 423 523 8871 - 73",
    buttonTwo: " Email Us",
    image: "assests/img/Net-Ops-Engineer-Haris-Mukhtar.png",
  };

  const domainSearch = {
    Heading: "A Perfect Beginning For A Perfect Website",
    SubHeading: "Your First Step in the Online World!",
  };

  return (
    <>
      <Helmet>
        <title>
          Buy ae Domain | ae Domain Registration in Pakistan| WebSouls
        </title>
        <meta
          name="title"
          content="Buy ae Domain | ae Domain Registration in Pakistan| WebSouls"
        />
        <meta
          name="description"
          content="Buy ae Domain name in Pakistan from WebSouls. Visit us for ae Domain registration or Purchase remotely from Lahore, Karachi, and Islamabad or from any other city."
        />
        <meta name="keywords" content="" />
        <script type="application/ld+json">
          {`{
  "@context": "https://schema.org",
  "@type": "Organization",
  "name": "WebSouls",
  "alternateName": "Web Hosting Pakistan",
  "url": "https://www.websouls.com/",
  "logo": "https://www.websouls.com/public/uploads/images/logo_1589438878.png",
  "contactPoint": [{
    "@type": "ContactPoint",
    "telephone": "+92 321 407 7991",
    "contactType": "technical support",
    "areaServed": "PK",
    "availableLanguage": ["en","Urdu"]
  },{
    "@type": "ContactPoint",
    "telephone": "+92 322 525 2352",
    "contactType": "sales",
    "areaServed": "PK",
    "availableLanguage": ["en","Urdu"]
  }],
  "sameAs": [
    "https://www.facebook.com/WebSouls/",
    "https://www.twitter.com/futuresouls",
    "https://www.instagram.com/websouls.pk/",
    "https://www.linkedin.com/company/websouls/",
    "https://github.com/websouls",
    "https://www.websouls.com/"
  ]
}`}
        </script>
        <script type="application/ld+json">
          {`{
  "@context": "https://schema.org/", 
  "@type": "Product", 
  "name": "AE Domain Registration - WebSouls",
  "image": "https://www.websouls.com/public/uploads/images/logo_1589438878.png",
  "description": "As the no. 1 web hosting service provider in Pakistan, WebSouls proudly holds max market share of domains in the nation, and our team can be of great assistance in helping you find your ideal domain name at the best price without much hassle.

You can easily buy an ae website domain with us, or go for a .pk domain registration while we assure your domain name doesn’t get accidently expired with the help of our auto-renewal service. In addition to this, we also keep our customers’ domain names safe by blocking both malicious and accidental domain transfers.",
  "brand": "WebSouls",
  "sku": "AE Domain Registration",
  "mpn": "AE Domain Registration",
  "offers": {
    "@type": "AggregateOffer",
    "url": "https://www.websouls.com/buy-ae-domains",
    "priceCurrency": "PKR",
    "lowPrice": "8000",
    "highPrice": "20400",
    "offerCount": "4"
  },
  "aggregateRating": {
    "@type": "AggregateRating",
    "ratingValue": "4.9",
    "bestRating": "4.9",
    "worstRating": "3.9",
    "ratingCount": "2591"
  }
}`}
        </script>
        <script type="application/ld+json">
          {`{
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "What is domain name?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "A domain name is an address that allows your visitors to find you on web. While choosing a domain name, make sure it’s easy to remember, catchy, and reflects your business and company goals."
    }
  },{
    "@type": "Question",
    "name": "Which domain extensions are available?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "With us you can find the perfect domain name with a variety of extensions to choose from, including .com, .pk, .edu, .gov, .net, .us, .biz, .info, .ae, .co.uk, and many more."
    }
  },{
    "@type": "Question",
    "name": "How is auto renewal helpful?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Our auto-renewal service protects your domain from getting accidentally lost or expiring. We make sure that your domain names are always protected."
    }
  },{
    "@type": "Question",
    "name": "Can i transfer my domain name to WebSouls?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Of course, you can! We offer free domain transfer facility for all our customers. Even if you are new to WebSouls, just contact us via phone, email, or live chat and allow our dedicated customer support executives to help you out. You can also visit us at one of our offices in Lahore or Karachi."
    }
  }]
}`}
        </script>
        <link rel="canonical" href="https://websouls.com/buy-ae-domains" />
      </Helmet>
      <DomainSearchGlobal domainSearch={domainSearch} />
      <DomainExtraction spotlight={spotlight} />
      <BundelBooster bundleData={BundleData} />
      <GoingOnline Data={GoingOnlineData} />
      <DomainTipAe Data={technicalSpecificationsData} />
      <DomainMigration Data={data} />
      <InPakistan Data={InPakistanData} />
      <Faq Data={faqData} />
      <WillingForHelp helpData={WillingToHelpData} />
    </>
  );
};

export default AEDomains;
