import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getPriceBundleBoster } from "../../services/Helper.js";
import { useDispatch, useSelector } from "react-redux";

const CompareBuisnessPlans = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { BuisnessData } = props;
  const [getActivePromotions, setActivePromotions] = useState([]);
  //reducx states
  const { currencyName } = useSelector((state) => state.currencyName);
  const { billingCycleHosting } = useSelector(
    (state) => state.billingCycleHosting
  );

  const addingComparePlans = (e, item) => {
    e.preventDefault();

    dispatch({
      type: "addingCycle",
      payload: {
        packageId: item.pid,
        packageCycle: "annually",
      },
    });
    dispatch({
      type: "packageCart",
      payload: item,
    });

    //redirecting to the new page
    navigate("/free-domain");
  };

  return (
    <section className="sec-compare-plans d-none d-xs-none d-sm-none d-md-block d-lg-block pt-100">
      <div className="container-fluid">
        <h2 className="text-center first-heading">Compare Our Plans </h2>
        <h5 className="text-center second-heading">
          Make Your Website a Safer Place Affordably
        </h5>
        <hr className="divider scnd_divider mx-auto" />
        {/* Responsive compare price plan starts*/}
        <div className="table-responsive">
          <table className="table text-center">
            <thead>
              <tr>
                <td />
                {BuisnessData?.Box.map((item) => (
                  <React.Fragment key={item.id}>
                    <td>
                      <h3>{item?.heading}</h3>
                      {item?.subHeading}
                      {/* <span className="packages_price">
                        <button
                          onClick={(e) => addingComparePlans(e, item)}
                          className="orderButton"
                          type="submit"
                        >
                          Order Now
                        </button>
                      </span> */}
                    </td>
                  </React.Fragment>
                ))}
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>RESPONSIVE DESIGN</td>
                <td>✓ </td>
                <td>✓ </td>
                <td>✓ </td>
              </tr>
              <tr>
                <td>CONTENT MANAGMENT SYSTEM</td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
              </tr>
              <tr>
                <td>SOCIAL MEDIA INTEGRATION</td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
              </tr>
              <tr>
                <td>GOOGLE MAP INTEGRATION</td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
              </tr>
              <tr>
                <td>PHOTO GALLERY</td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
              </tr>
              <tr>
                <td>INITIAL SEO SETUP</td>
                <td>
                  <font>
                    <strong>✗ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
              </tr>
              <tr>
                <td>FACEBOOK CHAT</td>
                <td>
                  <font>
                    <strong>✗ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
              </tr>
              <tr>
                <td>BLOG</td>
                <td>
                  <font>
                    <strong>✗ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
              </tr>
              <tr>
                <td>SITEMAP</td>
                <td>
                  <font>
                    <strong>✗ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✗ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
              </tr>
              <tr>
                <td>CUSTOM HOMEPAGE DESIGN</td>
                <td>
                  <font>
                    <strong>✗ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✗ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
              </tr>
              <tr>
                <td>LEAD GENERATION FORM</td>
                <td>
                  <font>
                    <strong>✗ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✗ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
              </tr>
              <tr>
                <td>SUBMISSION FOR SEARCH ENGINE</td>
                <td>
                  <font>
                    <strong>✗ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✗ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
              </tr>
              <tr>
                <td>GOOGLE SEARCH CONSOLE SETUP</td>
                <td>
                  <font>
                    <strong>✗ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✗ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
              </tr>
              <tr>
                <td>NEWSLETTER INTEGRATION</td>
                <td>
                  <font>
                    <strong>✗ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✗ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
              </tr>
              <tr>
                <td>GOOGLE ANALYTICS INTEGRATION</td>
                <td>
                  <font>
                    <strong>✗ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✗ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
              </tr>
              <tr>
                <td>FACEBOOK PIXEL SETUP</td>
                <td>
                  <font>
                    <strong>✗ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✗ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
};

export default React.memo(CompareBuisnessPlans);
