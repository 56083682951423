import { createReducer } from "@reduxjs/toolkit";
import {
  packagesFeatures,
  packagesFirstFeature,
  getPackageBaseCycle,
  delayTime,
  getdedicatedPrice,getAddonPrice
} from "./Helper";

const studentInitialState = {
  currencyName: {
    _id: "62878e42c58f1f206c473aa6",
    api_id: 3,
    code: "PKR",
    prefix: "Rs",
    suffix: "/-",
    format: 4,
    rate: `${process.env.REACT_APP_PKR_RATE}`,
    createdAt: "2022-05-20T12:49:06.354Z",
    updatedAt: "2022-05-20T12:49:06.354Z",
    __v: 0,
  },
};

export const currencyReducer = createReducer(studentInitialState, {
  addCurrency: (state, action) => {
    state.currencyName = action.payload;

    if (state.currencyName) {
      let strincurrencyName = JSON.stringify(state.currencyName);
      localStorage.setItem("currencyName", strincurrencyName);
    }
  },
});

const userDomainState = { domainNameClient: [] };
export const userDomainReducer = createReducer(userDomainState, {
  clientDomainName: (state, action) => {
    state.domainNameClient.push(action.payload);

    if (state.domainNameClient) {
      let strindomainNameClient = JSON.stringify(state.domainNameClient);
      localStorage.setItem("domainNameClient", strindomainNameClient);
    }
  },
  resetclientDomainName: (state, action) => {
    state.domainNameClient = userDomainState.domainNameClient;
  },
});

const currentUrlState = { currentUrl: "/new" };
export const currentUrlReducer = createReducer(currentUrlState, {
  urlCurrent: (state, action) => {
    state.currentUrl = action.payload;
  },
});

const billingCycleState = {
  billingCycleHosting: "annually",
  billingCycleDomain: "montly",
};

export const billingCycleSetReducer = createReducer(billingCycleState, {
  hostingBillingCycle: (state, action) => {
    state.billingCycleHosting = action.payload;
  },
});

const packagesBillingCycleState = { packagesCycles: [] };

export const packagesBillingCycleSetReducer = createReducer(
  packagesBillingCycleState,
  {
    packagesCycles: (state, action) => {
      state.packagesCycles = [...state.packagesCycles, action.payload];
    },

    UpdatePackagesCycles: (state, action) => {
      // let tempPackage = state.packagesCycles.filter(
      //   (pa) => pa.pid == action.payload.item.package_id
      // );

      state.packagesCycles.find(
        (value) => value.packageId === action.payload.item.pid
      ).packageCycle = action.payload.cycle;
    },
  }
);

const packageCartState = {
  packageCart: [],
  addonsExrra: [],
  freeDomain: [],
  addingCycle: [],
  updatingCycle: [],
  count: 1000,
};

function getRandomNumber() {
  let min = 1000;
  let max = 9999;
  return Math.round(Math.random() * (max - min) + min);
}
export const packageCartSetReducer = createReducer(packageCartState, {
  packageCart: (state, action) => {
    if (!action.payload.addedTag) {
      let currentTimestamp = state.count++; //getRandomNumber(); //Date.now();
      let newobj = {
        id: action.payload.id,
        addonids: action.payload.addonids,
        addons: action.payload.addons,
        currency: action.payload.currency,
        fname: action.payload.fname,
        freedomain: action.payload.freedomain,
        name: action.payload.name,
        packageFeatures: action.payload.packageFeatures,
        pid: action.payload.pid,
        title: action.payload.title,
        tid: currentTimestamp,
        addedTag: 1,
      };
      state.packageCart = [...state.packageCart, newobj];
      ////////// adding cycle

      let adObj = {
        tid: newobj.tid,
        packageId: newobj.pid,
        packageCycle: getPackageBaseCycle(action.payload, 3),
      };
      //////////// this check for new tab
      state.addingCycle = state.addingCycle.filter(
        (item) => item.tid !== newobj.tid
      );
      ///////////////
      const packageCylyeExits = state.addingCycle.find(
        (o) => o.tid === newobj.tid
      );
      if (!packageCylyeExits) {
        state.addingCycle = [...state.addingCycle, adObj];
      }
      if (state.addingCycle) {
        localStorage.removeItem("addingCycle");
        let strinadding = JSON.stringify(state.addingCycle);
        localStorage.setItem("addingCycle", strinadding);
      }
      ////
    }
    if (state.packageCart) {
      let strinpackageCart = JSON.stringify(state.packageCart);
      localStorage.setItem("packageCart", strinpackageCart);
    }
  },

  GetHostingpackageCart: (state, action) => {
    let currentTimestamp = Date.now();
    let newobj = {};
    newobj.tid = currentTimestamp;
    newobj = action.payload;

    // const packageExits = state.packageCart.find(
    //   (o) => o.pid === action.payload.pid
    // );
    // if (!packageExits) {

    state.packageCart = [...state.packageCart, newobj];

    // }

    if (state.packageCart) {
      let strinpackageCart = JSON.stringify(state.packageCart);
      localStorage.setItem("packageCart", strinpackageCart);
    }
  },

  DomainUpdatepackageCart: (state, action) => {
    let currentTimestamp = state.count++; //Date.now();
    let newobj = {
      id: action.payload.id,
      addonids: action.payload.addonids,
      addons: action.payload.addons,
      currency: action.payload.currency,
      fname: action.payload.fname,
      freedomain: action.payload.freedomain,
      name: action.payload.name,
      packageFeatures: action.payload.packageFeatures,
      pid: action.payload.pid,
      title: action.payload.title,
      tid: currentTimestamp,
      domainCount: action.payload.domainCount,
      addedTag: 1,
    };

    if (state.packageCart.length > 0) {
      const GetLastIndex = state.packageCart.length - 1;

      if (
        state.packageCart[GetLastIndex]?.freedomain?.length &&
        action.dcount > 0
      ) {
        state.packageCart = [...state.packageCart, newobj];
      } else {
        const arr = state.packageCart[GetLastIndex];
        arr.pid = action.payload.pid;
        arr.title = action.payload.title;
        arr.name = action.payload.name;
        arr.currency = action.payload.currency;
        arr.tid = currentTimestamp;
      }
    } else {
      state.packageCart = [...state.packageCart, newobj];
    }
    /////////// adding cycle

    let adObj = {
      tid: currentTimestamp,
      packageId: newobj.pid,
      packageCycle: action.addCycle, //getPackageBaseCycle(action.payload, 3),
    };
    const packageCylyeExits = state.addingCycle.find(
      (o) => o.tid === currentTimestamp
    );
    if (!packageCylyeExits) {
      state.addingCycle = [...state.addingCycle, adObj];
    }
    if (state.addingCycle) {
      let strinadding = JSON.stringify(state.addingCycle);
      localStorage.setItem("addingCycle", strinadding);
    }
    ////

    if (state.packageCart) {
      localStorage.removeItem("packageCart");
      let strinpackageCart = JSON.stringify(state.packageCart);
      localStorage.setItem("packageCart", strinpackageCart);
    }
  },

  addonsExrra: (state, action) => {
    const GetLastIndex = state.packageCart.length - 1;

    // state.packageCart[GetLastIndex].addons = [
    //   ...state.packageCart[GetLastIndex].addons,
    //   action.payload,
    // ];
 var allActivePermissions = JSON.parse(localStorage.getItem("pr56m"));
    const addonsExists = state.packageCart[GetLastIndex].addons.find(
      (o) => o.id === action.payload.id
    );

    
     //////////// my code 
     var inn= action.payload.id;
     var newLoad = {};
     newLoad.id =action.payload.id;
     newLoad.allowqty =action.payload.allowqty;
     newLoad.autoactivate =action.payload.autoactivate;
     newLoad.autolinkby =action.payload.autolinkby;
     newLoad.billingcycle =action.payload.billingcycle;
     newLoad.recurring =action.payload.recurring;
     newLoad.created_at =action.payload.created_at;
     newLoad.customAddonField =action.payload.customAddonField;
     newLoad.description =action.payload.description;
     newLoad.downloads =action.payload.downloads;
     newLoad.hidden =action.payload.hidden;
     newLoad.module =action.payload.module;
     newLoad.name =action.payload.name;
     newLoad.packages =action.payload.packages;
    
    var usdp ={};  
    var pkrp ={};
    if(action.payload.pricing[1].monthly>0){
      let fixPrice = getAddonPrice(action.payload.pricing[1]?.monthly,inn,'monthly',allActivePermissions,1)
      if(fixPrice[3]){
        usdp.monthly= getAddonPrice(action.payload.pricing[1]?.monthly,inn,'monthly',allActivePermissions,1)[1];
      }else{
        usdp.monthly= getAddonPrice(action.payload.pricing[1]?.monthly,inn,'monthly',allActivePermissions,1)[0];
      }
     
    }
    if(action.payload.pricing[1].quarterly>0){
      let fixPrice = getAddonPrice(action.payload.pricing[1]?.quarterly,inn,'quarterly',allActivePermissions,1);
      if(fixPrice[3]){
        usdp.quarterly= getAddonPrice(action.payload.pricing[1]?.quarterly,inn,'quarterly',allActivePermissions,1)[1];
      }else{
        usdp.quarterly= getAddonPrice(action.payload.pricing[1]?.quarterly,inn,'quarterly',allActivePermissions,1)[0];
      }
     
    }
    if(action.payload.pricing[1].semiannually>0){
      let fixPrice = getAddonPrice(action.payload.pricing[1]?.semiannually,inn,'semiannually',allActivePermissions,1);
      if(fixPrice[3]){
        usdp.semiannually= getAddonPrice(action.payload.pricing[1]?.semiannually,inn,'semiannually',allActivePermissions,1)[1];
      }else{
        usdp.semiannually= getAddonPrice(action.payload.pricing[1]?.semiannually,inn,'semiannually',allActivePermissions,1)[0];
      }
  
    }
    if(action.payload.pricing[1].annually>0){
      let fixPrice = getAddonPrice(action.payload.pricing[1]?.annually,inn,'annually',allActivePermissions,1);
      if(fixPrice[3]){
        usdp.annually= getAddonPrice(action.payload.pricing[1]?.annually,inn,'annually',allActivePermissions,1)[1];
      }else{
        usdp.annually= getAddonPrice(action.payload.pricing[1]?.annually,inn,'annually',allActivePermissions,1)[0];
      }
    }
    if(action.payload.pricing[1].biennially>0){
      let fixPrice =getAddonPrice(action.payload.pricing[1]?.biennially,inn,'biennially',allActivePermissions,1);
      if(fixPrice[3]){
        usdp.biennially= getAddonPrice(action.payload.pricing[1]?.biennially,inn,'biennially',allActivePermissions,1)[1];
      }else{
        usdp.biennially= getAddonPrice(action.payload.pricing[1]?.biennially,inn,'biennially',allActivePermissions,1)[0];
      }
    }
    if(action.payload.pricing[1].triennially>0){
      let fixPrice = getAddonPrice(action.payload.pricing[1]?.triennially,inn,'triennially',allActivePermissions,1);
      if(fixPrice[3]){
        usdp.triennially= getAddonPrice(action.payload.pricing[1]?.triennially,inn,'triennially',allActivePermissions,1)[1];
      }else{
        usdp.triennially= getAddonPrice(action.payload.pricing[1]?.triennially,inn,'triennially',allActivePermissions,1)[0];
      }
    }
    usdp.asetupfee = action.payload.pricing[1].asetupfee;
    usdp.bsetupfee = action.payload.pricing[1].bsetupfee;
    usdp.msetupfee = action.payload.pricing[1].msetupfee;
    usdp.qsetupfee = action.payload.pricing[1].qsetupfee;
    usdp.ssetupfee = action.payload.pricing[1].ssetupfee;
    usdp.tsetupfee = action.payload.pricing[1].tsetupfee;
    usdp.type = action.payload.pricing[1].type;
    usdp.relid = action.payload.pricing[1].relid;

    if(action.payload.pricing[3].monthly>0){
      let fixPrice = getAddonPrice(action.payload.pricing[3]?.monthly,inn,'monthly',allActivePermissions,3);
      if(fixPrice[3]){
        pkrp.monthly= getAddonPrice(action.payload.pricing[3]?.monthly,inn,'monthly',allActivePermissions,3)[1];
      }else{
        pkrp.monthly= getAddonPrice(action.payload.pricing[3]?.monthly,inn,'monthly',allActivePermissions,3)[0];
      }

    }
    if(action.payload.pricing[3].quarterly>0){
      let fixPrice = getAddonPrice(action.payload.pricing[3]?.quarterly,inn,'quarterly',allActivePermissions,3);
      if(fixPrice[3]){
        pkrp.quarterly= getAddonPrice(action.payload.pricing[3]?.quarterly,inn,'quarterly',allActivePermissions,3)[1];
      }else{
        pkrp.quarterly= getAddonPrice(action.payload.pricing[3]?.quarterly,inn,'quarterly',allActivePermissions,3)[0];
      }
    
    }
    if(action.payload.pricing[3].semiannually>0){
      let fixPrice = getAddonPrice(action.payload.pricing[3]?.semiannually,inn,'semiannually',allActivePermissions,3);
      if(fixPrice[3]){
        pkrp.semiannually= getAddonPrice(action.payload.pricing[3]?.semiannually,inn,'semiannually',allActivePermissions,3)[1];
      }else{
        pkrp.semiannually= getAddonPrice(action.payload.pricing[3]?.semiannually,inn,'semiannually',allActivePermissions,3)[0];
      }
     
    }
    if(action.payload.pricing[3].annually>0){
      let fixPrice = getAddonPrice(action.payload.pricing[3]?.annually,inn,'annually',allActivePermissions,3);
      if(fixPrice[3]){
        pkrp.annually= getAddonPrice(action.payload.pricing[3]?.annually,inn,'annually',allActivePermissions,3)[1];
      }else{
        pkrp.annually= getAddonPrice(action.payload.pricing[3]?.annually,inn,'annually',allActivePermissions,3)[0];
      }
    }
    if(action.payload.pricing[3].biennially>0){
      let fixPrice = getAddonPrice(action.payload.pricing[3]?.biennially,inn,'biennially',allActivePermissions,3);
      if(fixPrice[3]){
        pkrp.biennially= getAddonPrice(action.payload.pricing[3]?.biennially,inn,'biennially',allActivePermissions,3)[1];
      }else{
        pkrp.biennially= getAddonPrice(action.payload.pricing[3]?.biennially,inn,'biennially',allActivePermissions,3)[0];
      }

    }
    if(action.payload.pricing[3].triennially>0){
      let fixPrice = getAddonPrice(action.payload.pricing[3]?.triennially,inn,'triennially',allActivePermissions,3);
      if(fixPrice[3]){
        pkrp.triennially= getAddonPrice(action.payload.pricing[3]?.triennially,inn,'triennially',allActivePermissions,3)[1];
      }else{
        pkrp.triennially= getAddonPrice(action.payload.pricing[3]?.triennially,inn,'triennially',allActivePermissions,3)[0];
      }
    }
    pkrp.asetupfee = action.payload.pricing[3].asetupfee;
    pkrp.bsetupfee = action.payload.pricing[3].bsetupfee;
    pkrp.msetupfee = action.payload.pricing[3].msetupfee;
    pkrp.qsetupfee = action.payload.pricing[3].qsetupfee;
    pkrp.ssetupfee = action.payload.pricing[3].ssetupfee;
    pkrp.tsetupfee = action.payload.pricing[3].tsetupfee;
    pkrp.type = action.payload.pricing[3].type;
    pkrp.relid = action.payload.pricing[3].relid;
    var newPricing =[];
    newPricing[1]=usdp;
    newPricing[3]=pkrp;
    newLoad.pricing = newPricing;




///////////////////

    
    if (!addonsExists) {
      state.packageCart[GetLastIndex].addons = [
        ...state.packageCart[GetLastIndex].addons,
        newLoad,
      ];
    }
    if (state.packageCart) {
      localStorage.removeItem("packageCart");
      let strinpackageCart = JSON.stringify(state.packageCart);
      localStorage.setItem("packageCart", strinpackageCart);
    }
  },
  removeAddons: (state, action) => {
    const GetLastIndex = state.packageCart.length - 1;
    state.packageCart[GetLastIndex].addons = state.packageCart[
      GetLastIndex
    ].addons.filter((item) => item.id !== action.payload.id);

    if (state.packageCart) {
      localStorage.removeItem("packageCart");
      let strinpackageCart = JSON.stringify(state.packageCart);
      localStorage.setItem("packageCart", strinpackageCart);
    }
  },

  freeDomain: (state, action) => {
    const GetLastIndex = state.packageCart.length - 1;

    state.packageCart[GetLastIndex].freedomain = [action.payload];

    if (state.packageCart) {
      localStorage.removeItem("packageCart");
      let strinpackageCart = JSON.stringify(state.packageCart);
      localStorage.setItem("packageCart", strinpackageCart);
    }
  },

  removePackageCart: (state, action) => {
    localStorage.removeItem("packageCart");

    state.packageCart = state.packageCart.filter(
      (item) => item.tid !== action.payload.tid
    );
    if (state.packageCart) {
      let stringpackageCart = JSON.stringify(state.packageCart);
      localStorage.setItem("packageCart", stringpackageCart);
    }
  },

  removePackageAddingCycle: (state, action) => {
    localStorage.removeItem("addingCycle");

    state.addingCycle = state.addingCycle.filter(
      (item) => item.tid !== action.payload.tid
    );
    if (state.addingCycle) {
      let strinadding = JSON.stringify(state.addingCycle);
      localStorage.setItem("addingCycle", strinadding);
    }
  },
  removePackageAddingCycleNew: (state, action) => {
    localStorage.removeItem("addingCycle");

    state.addingCycle = state.addingCycle.filter(
      (item) => item.tid !== action.payload
    );
    if (state.addingCycle) {
      let strinadding = JSON.stringify(state.addingCycle);
      localStorage.setItem("addingCycle", strinadding);
    }
  },

  removefreeDomainPackageCart: (state, action) => {
    const GetLastIndex = state.packageCart.length - 1;

    state.packageCart[GetLastIndex].freedomain = state.packageCart[
      GetLastIndex
    ].freedomain.filter(
      (item) => item.domainName !== action.payload.domainName
    );

    // state.packageCart[GetLastIndex].freedomain = [action.payload];
  },

  addingCycle: (state, action) => {
    if (action.payload.tid) {
      const packageCylyeExits = state.addingCycle.find(
        (o) => o.tid === action.payload.tid
      );
      if (!packageCylyeExits) {
        state.addingCycle = [...state.addingCycle, action.payload];
      }
      if (state.addingCycle) {
        let strinadding = JSON.stringify(state.addingCycle);
        localStorage.setItem("addingCycle", strinadding);
      }
    }
  },

  updateubngsaCycker: (state, action) => {
    localStorage.removeItem("addingCycle");
    const splitPid = action.payload.split(".").pop();
    const splitCycle = action.payload.split(".").shift();

    for (const obj of state.addingCycle) {
      if (obj.tid == splitPid) {
        obj.packageCycle = splitCycle;

        break;
      }
    }
    if (state.addingCycle) {
      let strinadding = JSON.stringify(state.addingCycle);
      localStorage.setItem("addingCycle", strinadding);
    }
  },

  resetpackageCart: (state, action) => {
    state.packageCart = packageCartState.packageCart;
    state.addonsExrra = packageCartState.addonsExrra;
    state.freeDomain = packageCartState.freeDomain;
    state.addingCycle = packageCartState.addingCycle;
    state.updatingCycle = packageCartState.updatingCycle;
  },
});

const domainSearchCartState = {
  domainSearchCart: [],
  addingCycleDomain: [],
  updatingCycleDomain: [],
  tempdomainSearchCart: [],
  tempdomainaddingCycle: [],
};

export const domainSearchCartSetReducer = createReducer(domainSearchCartState, {
  domainSearchCart: (state, action) => {
    let tr = false;
    const checkDomainExits = state.domainSearchCart.some(
      (o) => o.domainName === action.payload.domainName
    );
    let localPac = JSON.parse(localStorage.getItem("packageCart"));
    if (localPac !== null || localPac !== undefined) {
      for (let i = 0; i < localPac?.length; i++) {
        if (localPac[i]?.freedomain) {
          tr = localPac[i].freedomain.some(
            (t) => t.domainName === action.payload.domainName
          );
          break;
        }
      }
    }

    ////return;

    if (!checkDomainExits && !tr) {
      state.domainSearchCart = [...state.domainSearchCart, action.payload];
    }
    if (state.domainSearchCart) {
      let stringdomainSearchCart = JSON.stringify(state.domainSearchCart);
      localStorage.setItem("domainSearchCart", stringdomainSearchCart);
    }
  },
  tempdomainSearchCart: (state, action) => {
    state.tempdomainSearchCart = [
      ...state.tempdomainSearchCart,
      action.payload,
    ];
    if (state.tempdomainSearchCart) {
      let stringtempdomainSearchCart = JSON.stringify(
        state.tempdomainSearchCart
      );
      localStorage.setItem("tempdomainSearchCart", stringtempdomainSearchCart);
    }
  },

  tempdomainaddingCycle: (state, action) => {
    state.tempdomainaddingCycle = [
      ...state.tempdomainaddingCycle,
      action.payload,
    ];
    if (state.tempdomainaddingCycle) {
      let stringtempdomainaddingCycle = JSON.stringify(
        state.tempdomainaddingCycle
      );
      localStorage.setItem(
        "tempdomainaddingCycle",
        stringtempdomainaddingCycle
      );
    }
  },

  addingCycleDomain: (state, action) => {
    // localStorage.removeItem("addingCycleDomain");
    const domainsCycleExists = state.addingCycleDomain.find(
      (os) => os.domainName === action.payload.domainName
    );
    if (!domainsCycleExists) {
      state.addingCycleDomain = [...state.addingCycleDomain, action.payload];
    }
    if (state.addingCycleDomain) {
      let stringaddingCycleDomain = JSON.stringify(state.addingCycleDomain);

      // return;
      localStorage.setItem("addingCycleDomain", stringaddingCycleDomain);
    }
  },

  removeCyleDomain: (state, action) => {
    localStorage.removeItem("addingCycleDomain");

    state.addingCycleDomain = state.addingCycleDomain.filter(
      (item) => item.domainName !== action.payload.domainName
    );
    if (state.addingCycleDomain) {
      let stringaddingCycleDomain = JSON.stringify(state.addingCycleDomain);
      localStorage.setItem("addingCycleDomain", stringaddingCycleDomain);
    }
  },

  updatingCycleDomain: (state, action) => {
    localStorage.removeItem("addingCycleDomain");
    const splitDomainName = action.payload.split("_").pop();
    const splitCycle = action.payload.split("_").shift();

    for (const obj of state.addingCycleDomain) {
      if (obj.domainName === splitDomainName) {
        obj.packageCycle = splitCycle;

        break;
      }
    }
    if (state.addingCycleDomain) {
      let stringaddingCycleDomain = JSON.stringify(state.addingCycleDomain);
      localStorage.setItem("addingCycleDomain", stringaddingCycleDomain);
    }
  },

  removeDomainCart: (state, action) => {
    localStorage.removeItem("domainSearchCart");
    state.domainSearchCart = state.domainSearchCart.filter(
      (item) => item.domainName !== action.payload.domainName
    );
    if (state.domainSearchCart) {
      let stringdomainSearchCart = JSON.stringify(state.domainSearchCart);
      localStorage.setItem("domainSearchCart", stringdomainSearchCart);
    }
  },

  removeTempDomainCart: (state, action) => {
    localStorage.removeItem("tempdomainSearchCart");
    state.tempdomainSearchCart = state.tempdomainSearchCart.filter(
      (item) => item.domainName !== action.payload.domainName
    );
    if (state.domainSearchCart) {
      let stringTempDomainSearchCart = JSON.stringify(
        state.tempdomainSearchCart
      );
      localStorage.setItem("tempdomainSearchCart", stringTempDomainSearchCart);
    }
  },

  removeTempDomainCycle: (state, action) => {
    localStorage.removeItem("tempdomainaddingCycle");
    state.tempdomainaddingCycle = state.domainSearchCart.filter(
      (item) => item.domainName !== action.payload.domainName
    );
    if (state.domainSearchCart) {
      let stringTempDomainCycle = JSON.stringify(state.tempdomainaddingCycle);
      localStorage.setItem("tempdomainaddingCycle", stringTempDomainCycle);
    }
  },

  removeId: (state, action) => {
    for (const obj of state.domainSearchCart) {
      if (obj.domainName === action.payload.domainName) {
        obj.domainAddonTitle = "";
        obj.domainAddon = [];

        break;
      }
    }
  },

  addId: (state, action) => {
    for (const obj of state.domainSearchCart) {
      if (obj.domainName === action.payload.domaintld) {
        obj.domainAddonTitle = "Privacy Protection";
        obj.domainAddon = action.payload.dresult.domainAddon[0];

        break;
      }
    }
  },

  resetdomainSearchCart: (state, action) => {
    state.domainSearchCart = domainSearchCartState.domainSearchCart;
    state.addingCycleDomain = domainSearchCartState.addingCycleDomain;
    state.updatingCycleDomain = domainSearchCartState.updatingCycleDomain;
    state.tempdomainSearchCart = domainSearchCartState.tempdomainSearchCart;
    state.tempdomainaddingCycle = domainSearchCartState.tempdomainaddingCycle;
  },
});

const packageAddonsState = { addonCart: [] };

export const addonsCartSetReducer = createReducer(packageAddonsState, {
  addonCart: (state, action) => {
    state.addonCart = [...state.addonCart, action.payload];
  },
  resetaddonCart: (state, action) => {
    state.addonCart = packageAddonsState.addonCart;
  },
});

const clientDetailsState = { clientDetails: [] };

export const clientDetailsSetReducer = createReducer(clientDetailsState, {
  clientDetails: (state, action) => {
    state.clientDetails = action.payload;

    if (state.clientDetails) {
      let strinclientDetails = JSON.stringify(state.clientDetails);
      localStorage.setItem("clientDetails", strinclientDetails);
    }
  },
  resetclientDetails: (state, action) => {
    state.clientDetails = clientDetailsState.clientDetails;
  },
});

const cartBillingDetails = { cartPayment: [] };

export const cartBillingDetailsSetReducer = createReducer(cartBillingDetails, {
  cartPayment: (state, action) => {
    state.cartPayment = action.payload;
  },
  resetcartPayment: (state, action) => {
    state.cartPayment = cartBillingDetails.cartPayment;
  },
});


const webErrorHandel = { isError: false };

export const webErrorHandelReducer = createReducer(webErrorHandel, {
  isError: (state, action) => {
    state.isError = action.payload;
  },
  resetError: (state, action) => {
    state.isError = webErrorHandel.isError;
  },
});