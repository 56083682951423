import React from "react";
import Carousel from "react-elastic-carousel";

const ComapnySlide = () => {
  const breakPoints = [
    { width: 1, itemsToShow: 2, itemsToScroll: 2 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2 },
    { width: 768, itemsToShow: 6 },
    { width: 1200, itemsToShow: 6 },
  ];
  return (
    <Carousel
      className="animate__animated animate__fadeIn"
      itemPadding={[8, 10, 8, 10]}
      breakPoints={breakPoints}
      pagination={true}
      showArrows={false}
    >
      <div className="item">
        <div className="slidrimg">
          <img
            style={{ maxWidth: "100%" }}
            src={
              process.env.PUBLIC_URL + "/assests/img/clients/logo-1-(107).webp"
            }
            className="lazy"
            title="logo-1-(107)"
            alt="logo-1-(107)"
          />
        </div>
      </div>
      <div className="item">
        <div className="slidrimg">
          <img
            style={{ maxWidth: "100%" }}
            src={
              process.env.PUBLIC_URL + "/assests/img/clients/logo-1-(116).webp"
            }
            className="lazy"
            title="logo-1-(116)"
            alt="logo-1-(116)"
          />
        </div>
      </div>
      <div className="item">
        <div className="slidrimg">
          <img
            style={{ maxWidth: "100%" }}
            src={process.env.PUBLIC_URL + "/assests/img/clients/logo1-(33).webp"}
            className="lazy"
            title="logo1-(33)"
            alt="logo1-(33)"
          />
        </div>
      </div>
      <div className="item">
        <div className="slidrimg">
          <img
            style={{ maxWidth: "100%" }}
            src={process.env.PUBLIC_URL + "/assests/img/clients/logo1-(7).webp"}
            className="lazy"
            title="logo1-(7)"
            alt="logo1-(7)"
          />
        </div>
      </div>
      <div className="item">
        <div className="slidrimg">
          <img
            style={{ maxWidth: "100%" }}
            src={
              process.env.PUBLIC_URL + "/assests/img/clients/logo-1-(141).webp"
            }
            className="lazy"
            title="logo-1-(141)"
            alt="logo-1-(141)"
          />
        </div>
      </div>
      <div className="item">
        <div className="slidrimg">
          <img
            style={{ maxWidth: "100%" }}
            src={
              process.env.PUBLIC_URL + "/assests/img/clients/logo-1-(137).webp"
            }
            className="lazy"
            title="logo-1-(137)"
            alt="logo-1-(137)"
          />
        </div>
      </div>
      <div className="item">
        <div className="slidrimg">
          <img
            style={{ maxWidth: "100%" }}
            src={
              process.env.PUBLIC_URL + "/assests/img/clients/logo-1-(50).webp"
            }
            className="lazy"
            title="logo-1-(50)"
            alt="logo-1-(50)"
          />
        </div>
      </div>
      <div className="item">
        <div className="slidrimg">
          <img
            style={{ maxWidth: "100%" }}
            src={
              process.env.PUBLIC_URL + "/assests/img/clients/logo-1-(119).webp"
            }
            className="lazy"
            title="logo-1-(119)"
            alt="logo-1-(119)"
          />
        </div>
      </div>
      {/* <div className="item">
        <div className="slidrimg">
          <img
            style={{ maxWidth: "100%" }}
            src={
              process.env.PUBLIC_URL + "/assests/img/clients/logo-1-(144).webp"
            }
            className="lazy"
            title="logo-1-(144)"
            alt="logo-1-(144)"
          />
        </div>
      </div> */}
    </Carousel>
  );
};

export default React.memo(ComapnySlide);
