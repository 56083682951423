import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import shortid from "shortid";
import { useNavigate, useLocation } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import Carousel from "react-elastic-carousel";
import {
  getPrice,
  packagesFeatures,
  packagesFirstFeature,
} from "../../services/Helper.js";

const PlanCrousels = ({ homePackagesList, getActivePromotions, loading }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();
  const [status] = useState(true);

  //reducx states
  const { currencyName } = useSelector((state) => state.currencyName);
  const { billingCycleHosting } = useSelector(
    (state) => state.billingCycleHosting
  );

  //crousel settings

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2 },
    { width: 768, itemsToShow: 3 },
    { width: 1200, itemsToShow: 3 },
  ];

  useEffect(() => {
    dispatch({
      type: "urlCurrent",
      payload: location.pathname,
    });
  }, [currencyName]);

  const buttonAddToCart = (e, item) => {
    e.preventDefault();
    dispatch({
      type: "addingCycle",
      payload: {
        packageId: item.pid,
        packageCycle: "annually",
      },
    });
    dispatch({
      type: "packageCart",
      payload: item,
    });

    //redirecting to the new page
    navigate("/free-domain");
  };

  return (
    <section className="bg-wave plan-section text-center pt-100">
      <h2 className="first-heading ">Keeping You Up And Running</h2>
      <h1 className="second-heading">
        Pick a Web Hosting Plan That Best Suits Your Needs
      </h1>
      <hr className="divider scnd_divider mx-auto mb-4" />
      <div className="container">
        <div className="row row-cols-1 mb-3 ">
          {loading && loading ? (
            <>
              <Carousel
                className="animate__animated animate__fadeIn"
                itemPadding={[8, 10, 8, 10]}
                breakPoints={breakPoints}
                pagination={true}
                showArrows={status}
              >
                {homePackagesList &&
                  homePackagesList.map((item) => (
                    <React.Fragment key={shortid.generate()}>
                      <div
                        style={{
                          backgroundColor: "transparent",
                          border: "1px solid #D2D2D2",
                          borderRadius: 5,
                        }}
                        className="col-lg-4 pacakge-one text-start "
                      >
                        <div className="package-name ">
                          <h3>{item && item.name}</h3>
                          <p className="package-namepaaaa"> {item.fname}</p>
                        </div>
                        <div
                          style={{ marginBottom: 0 }}
                          className="package-one-price checkss"
                        >
                          {getPrice(
                            item?.currency
                              ?.filter(
                                (fil) => fil?.currency === currencyName?.api_id
                              )
                              ?.map((pr) => pr[billingCycleHosting]),
                            item?.pid,
                            billingCycleHosting,
                            getActivePromotions,
                            currencyName?.prefix
                          )}
                        </div>
                        <ul>
                          {item.packageFeatures &&
                            item.packageFeatures[0]?.features.map((elss) => (
                              <li key={shortid.generate()}>
                                <font>
                                  <strong
                                    style={{
                                      color: "rgb(0, 88, 128)",
                                      fontSize: 20,
                                      fontWeight: 400,
                                      paddingRight: 8,
                                    }}
                                  >
                                    ✓{" "}
                                  </strong>
                                </font>

                                {elss.includes("_") ? (
                                  <>
                                    {elss.split("_")[0]}
                                    {
                                      <i className="fa fa-info-circle">
                                        <span
                                          className="litooltip"
                                          dangerouslySetInnerHTML={{
                                            __html: elss.split("_")[1],
                                          }}
                                        />
                                      </i>
                                    }
                                  </>
                                ) : (
                                  elss
                                )}
                              </li>
                            ))}
                        </ul>

                        <button
                          onClick={(e) => buttonAddToCart(e, item)}
                          className="text "
                        >
                          Add To Cart
                        </button>
                      </div>
                    </React.Fragment>
                  ))}
              </Carousel>
            </>
          ) : (
            <>
              <Carousel
                itemPadding={[8, 10, 8, 10]}
                breakPoints={breakPoints}
                pagination={true}
              >
                <div
                  className="col-lg-4 pacakge-one text-start "
                  style={{
                    backgroundColor: "transparent",
                    border: "1px solid rgb(210, 210, 210)",
                    borderRadius: 5,
                  }}
                >
                  <div className="package-name ">
                    <h3>
                      <Skeleton width={200} />
                    </h3>
                    <p>
                      <Skeleton width={100} />
                    </p>
                  </div>
                  <div
                    className="package-one-price checkss"
                    style={{ marginBottom: 0 }}
                  >
                    <div className="discount">
                      <h4 className="">
                        <b>
                          <Skeleton width={300} />
                        </b>
                      </h4>
                    </div>
                    <div className="package-one-price">
                      <span className="amount">
                        {" "}
                        <Skeleton width={190} />
                      </span>
                    </div>
                  </div>
                  <ul>
                    <li>
                      <Skeleton style={{ width: "100%" }} />
                    </li>
                    <li>
                      <Skeleton style={{ width: "100%" }} />
                    </li>
                    <li>
                      <Skeleton style={{ width: "100%" }} />
                    </li>
                    <li>
                      <Skeleton style={{ width: "100%" }} />
                    </li>
                    <li>
                      <Skeleton style={{ width: "100%" }} />
                    </li>
                    <li>
                      <Skeleton style={{ width: "100%" }} />
                    </li>
                    <li>
                      <Skeleton style={{ width: "100%" }} />
                    </li>
                    <li>
                      <Skeleton style={{ width: "100%" }} />
                    </li>
                  </ul>
                  <Skeleton
                    width={200}
                    style={{ paddingTop: 20, marginTop: 10 }}
                  />
                </div>
                <div
                  className="col-lg-4 pacakge-one text-start "
                  style={{
                    backgroundColor: "transparent",
                    border: "1px solid rgb(210, 210, 210)",
                    borderRadius: 5,
                  }}
                >
                  <div className="package-name ">
                    <h3>
                      <Skeleton width={200} />
                    </h3>
                    <p>
                      <Skeleton width={100} />
                    </p>
                  </div>
                  <div
                    className="package-one-price checkss"
                    style={{ marginBottom: 0 }}
                  >
                    <div className="discount">
                      <h4 className="">
                        <b>
                          <Skeleton width={300} />
                        </b>
                      </h4>
                    </div>
                    <div className="package-one-price">
                      <span className="amount">
                        {" "}
                        <Skeleton width={190} />
                      </span>
                    </div>
                  </div>
                  <ul>
                    <li>
                      <Skeleton style={{ width: "100%" }} />
                    </li>
                    <li>
                      <Skeleton style={{ width: "100%" }} />
                    </li>
                    <li>
                      <Skeleton style={{ width: "100%" }} />
                    </li>
                    <li>
                      <Skeleton style={{ width: "100%" }} />
                    </li>
                    <li>
                      <Skeleton style={{ width: "100%" }} />
                    </li>
                    <li>
                      <Skeleton style={{ width: "100%" }} />
                    </li>
                    <li>
                      <Skeleton style={{ width: "100%" }} />
                    </li>
                    <li>
                      <Skeleton style={{ width: "100%" }} />
                    </li>
                  </ul>
                  <Skeleton
                    width={200}
                    style={{ paddingTop: 20, marginTop: 10 }}
                  />
                </div>
                <div
                  className="col-lg-4 pacakge-one text-start "
                  style={{
                    backgroundColor: "transparent",
                    border: "1px solid rgb(210, 210, 210)",
                    borderRadius: 5,
                  }}
                >
                  <div className="package-name ">
                    <h3>
                      <Skeleton width={200} />
                    </h3>
                    <p>
                      <Skeleton width={100} />
                    </p>
                  </div>
                  <div
                    className="package-one-price checkss"
                    style={{ marginBottom: 0 }}
                  >
                    <div className="discount">
                      <h4 className="">
                        <b>
                          <Skeleton width={300} />
                        </b>
                      </h4>
                    </div>
                    <div className="package-one-price">
                      <span className="amount">
                        {" "}
                        <Skeleton width={190} />
                      </span>
                    </div>
                  </div>
                  <ul>
                    <li>
                      <Skeleton style={{ width: "100%" }} />
                    </li>
                    <li>
                      <Skeleton style={{ width: "100%" }} />
                    </li>
                    <li>
                      <Skeleton style={{ width: "100%" }} />
                    </li>
                    <li>
                      <Skeleton style={{ width: "100%" }} />
                    </li>
                    <li>
                      <Skeleton style={{ width: "100%" }} />
                    </li>
                    <li>
                      <Skeleton style={{ width: "100%" }} />
                    </li>
                    <li>
                      <Skeleton style={{ width: "100%" }} />
                    </li>
                    <li>
                      <Skeleton style={{ width: "100%" }} />
                    </li>
                  </ul>
                  <Skeleton
                    width={200}
                    style={{ paddingTop: 20, marginTop: 10 }}
                  />
                </div>
              </Carousel>
            </>
          )}
        </div>
      </div>
    </section>
  );
};

export default PlanCrousels;
