import React from 'react'
import ComapnySlide from '../../reuseable/includes/ComapnySlide'

const CompanySlider = () => {
  return (
    <section className="why-choose-us pt-100 pb-50" >
      <div className="container">
        <h2 className="text-center first-heading">The Company We Keep</h2>
        <h5 className="text-center second-heading">
          Providing the Perfect Stress-Free Solutions That Uplift Brands
        </h5>
        <hr className="divider scnd_divider mx-auto mb-4" />
        <div className="row">
        <ComapnySlide />
        </div>
      </div>
    </section>
  )
}

export default CompanySlider