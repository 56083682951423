import React from 'react';
import Carousel from "react-elastic-carousel";

const DomainTips = ({Data}) => {

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2 },
    { width: 768, itemsToShow: 3 },
    { width: 1200, itemsToShow: 3 },
  ];


  return (
    <section  className="technical_specification pt-100 margin-b-0 margin-t-0">
    <div className="container">
        <h2 className="first-heading">{Data?.Heading}</h2>
        <h5 className="second-heading">{Data?.subHeading}</h5>
        <hr className="divider scnd_divider mx-auto margin-b-0" />
        <div className="d-none d-md-block d-lg-block d-xl-block d-xxl-block">
          <div className="row">
            <ul>
              {Data?.box?.map((el) => (
                <li className="col-md-4">
                  <div className="secure_server">
                    <div className={el?.icon} />
                    <h5>{el?.title}</h5>
                    <p>{el?.peragraph}</p>
                  </div>
                </li>
              ))}
            </ul>
          </div>
        </div>
        <div className="d-block d-md-none d-lg-none d-xl-none d-xxl-none">
          <div className="row">
            <Carousel
              className="animate__animated animate__fadeIn"
              itemPadding={[8, 10, 8, 10]}
              breakPoints={breakPoints}
              pagination={true}
              showArrows={true}
            >
              {Data?.box?.map((el) => (
                <div className="col-md-4">
                  <div className="secure_server">
                    <div className={el?.icon} />
                    <h5>{el?.title}</h5>
                    <p>{el?.peragraph}</p>
                  </div>
                </div>
              ))}
            </Carousel>
          </div>
        </div>
      </div>
  </section>
  )
}

export default React.memo(DomainTips)