import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import {  toast } from 'react-toastify';
import { useLocation } from "react-router-dom";
import Carousel from "react-elastic-carousel";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import {
  getPrice,
  packagesFeatures,
  packagesFirstFeature,
  DomainTld,
} from "../../services/Helper";
import { GetPackages, GetActivePromotions } from "../../services/Network.js";

const SimpleCrousel = (props) => {
  const { getActivePromotions, packagesList, loading } = props;

  const dispatch = useDispatch();
  const location = useLocation();

  //reducx states
  const { currencyName } = useSelector((state) => state.currencyName);
  const { billingCycleHosting } = useSelector(
    (state) => state.billingCycleHosting
  );

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2 },
    { width: 768, itemsToShow: 3 },
    { width: 1200, itemsToShow: 3 },
  ];
  const { packageCart } = useSelector((state) => state.packageCart);
  const { domainSearchCart } = useSelector((state) => state.domainSearchCart);
  const { addingCycle } = useSelector((state) => state.addingCycle);

  useEffect(() => {
    dispatch({
      type: "urlCurrent",
      payload: location.pathname,
    });

  }, [currencyName, packageCart, domainSearchCart]);

  const buttonAddToCart = (e, item) => {
    e.preventDefault();

    if (!item.domainCount) {
      item.domainCount = domainSearchCart?.length;
    }
    if(domainSearchCart.length > 0){
      var domainLastIndex = domainSearchCart?.length - 1;
      var tld = DomainTld(domainSearchCart[domainLastIndex]?.domainName);
    }
    var bilcycle = "annually";
    if (tld?.includes("pk")) {
      bilcycle = "biennially";
    }

    //return;
    // dispatch({
    //   type: "addingCycle",
    //   payload: {
    //     packageId: item.pid,
    //     packageCycle: bilcycle,
    //   },
    // });
    // dispatch({
    //   type: "removePackageAddingCycle",
    //   payload: item,
    // });
   

 
    const addd = JSON.parse(localStorage.getItem("addingCycle"));
    const pk = JSON.parse(localStorage.getItem("packageCart"));
    for (var i = 0; i < addd.length; i++) {
      let adpid = addd[i]?.tid;
      const packageCylyeExits = pk.find((o) => o.tid === adpid);
      if (!packageCylyeExits) {
        item.tid = adpid;
        dispatch({
          type: "removePackageAddingCycle",
          payload: item,
        });
      }
    }
    dispatch({
      type: "DomainUpdatepackageCart",
      payload: item,
      dcount: domainSearchCart?.length,
      addCycle:bilcycle
    });
    toast.info('Successfully Added To Cart', {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
      });
    //redirecting to the new page
    // navigate("/domain", { state: { prevRoute: location.pathname } });
  };

  const addedtoCartCheckPackage = (pid) => {
    const lastIndex = packageCart.length - 1;

    const dexist = packageCart[lastIndex]?.pid === pid;
    if (dexist) {
      // return 1;  I have chenged this
      return 0;
    } else {
      return 0;
    }
  };

  return (
    <>
      {loading ? (
        <>
          <Carousel
            className="animate__animated animate__fadeIn"
            itemPadding={[8, 10, 8, 10]}
            breakPoints={breakPoints}
            pagination={true}
            showArrows={false}
          >
            {packagesList &&
              packagesList.map((item, index) => (
                <React.Fragment key={index}>
                  <div
                    style={{
                      backgroundColor: "transparent",
                      border: "1px solid #D2D2D2",
                      borderRadius: 5,
                    }}
                    className="col-md-12 col-sm-12 col-xs-12 pacakge-one text-start"
                  >
                    <div className="package-name ">
                      <h3>{item.name}</h3>
                      <p className="package-namepaaaa"> {item.fname}</p>
                    </div>

                    {getPrice(
                      item.currency
                        .filter((fil) => fil.currency === currencyName.api_id)
                        .map((pr) => pr[billingCycleHosting]),
                      item.pid,
                      billingCycleHosting,
                      getActivePromotions,
                      currencyName.prefix
                    )}

                    <ul>
                      {item.packageFeatures &&
                        item.packageFeatures[0]?.features.map((elss) => (
                          <li>
                            <font>
                              <strong
                                style={{
                                  color: "rgb(0, 88, 128)",
                                  fontSize: 18,
                                  fontWeight: 400,
                                  paddingRight: 8,
                                }}
                              >
                                ✓{" "}
                              </strong>
                            </font>

                            {elss.includes("_") ? (
                              <>
                                {elss.split("_")[0]}
                                {
                                  <i className="fa fa-info-circle">
                                    <span
                                      className="litooltip"
                                      dangerouslySetInnerHTML={{
                                        __html: elss.split("_")[1],
                                      }}
                                    />
                                  </i>
                                }
                              </>
                            ) : (
                              elss
                            )}
                          </li>
                        ))}
                    </ul>

                    <form>
                      {addedtoCartCheckPackage(item.pid) === 1 ? (
                        <>
                          <button
                            className="text mb-3 pacakgenotAllowed"
                            onClick={(e) => buttonAddToCart(e, item)}
                            type="submit"
                          >
                            Added To Cart
                          </button>
                        </>
                      ) : (
                        <>
                          <button
                            className="text mb-3"
                            onClick={(e) => buttonAddToCart(e, item)}
                            type="submit"
                          >
                            Add To Cart
                          </button>
                        </>
                      )}
                    </form>
                  </div>
                </React.Fragment>
              ))}
          </Carousel>
        </>
      ) : (
        <>
          <Carousel
            itemPadding={[8, 10, 8, 10]}
            breakPoints={breakPoints}
            pagination={true}
            showArrows={false}
            className="animate__animated animate__fadeOut"
          >
            <div
              className="col-lg-4 pacakge-one text-start "
              style={{
                backgroundColor: "transparent",
                border: "1px solid rgb(210, 210, 210)",
                borderRadius: 5,
              }}
            >
              <div className="package-name ">
                <h3>
                  <Skeleton width={200} />
                </h3>
                <p>
                  <Skeleton width={100} />
                </p>
              </div>
              <div
                className="package-one-price checkss"
                style={{ marginBottom: 0 }}
              >
                <div className="discount">
                  <h4 className="">
                    <b>
                      <Skeleton width={300} />
                    </b>
                  </h4>
                </div>
                <div className="package-one-price">
                  <span className="amount">
                    {" "}
                    <Skeleton width={190} />
                  </span>
                </div>
              </div>
              <ul>
                <li>
                  <Skeleton style={{ width: "100%" }} />
                </li>
                <li>
                  <Skeleton style={{ width: "100%" }} />
                </li>
                <li>
                  <Skeleton style={{ width: "100%" }} />
                </li>
                <li>
                  <Skeleton style={{ width: "100%" }} />
                </li>
                <li>
                  <Skeleton style={{ width: "100%" }} />
                </li>
                <li>
                  <Skeleton style={{ width: "100%" }} />
                </li>
                <li>
                  <Skeleton style={{ width: "100%" }} />
                </li>
                <li>
                  <Skeleton style={{ width: "100%" }} />
                </li>
              </ul>
              <Skeleton width={200} style={{ paddingTop: 20, marginTop: 10 }} />
            </div>
            <div
              className="col-lg-4 pacakge-one text-start "
              style={{
                backgroundColor: "transparent",
                border: "1px solid rgb(210, 210, 210)",
                borderRadius: 5,
              }}
            >
              <div className="package-name ">
                <h3>
                  <Skeleton width={200} />
                </h3>
                <p>
                  <Skeleton width={100} />
                </p>
              </div>
              <div
                className="package-one-price checkss"
                style={{ marginBottom: 0 }}
              >
                <div className="discount">
                  <h4 className="">
                    <b>
                      <Skeleton width={300} />
                    </b>
                  </h4>
                </div>
                <div className="package-one-price">
                  <span className="amount">
                    {" "}
                    <Skeleton width={190} />
                  </span>
                </div>
              </div>
              <ul>
                <li>
                  <Skeleton style={{ width: "100%" }} />
                </li>
                <li>
                  <Skeleton style={{ width: "100%" }} />
                </li>
                <li>
                  <Skeleton style={{ width: "100%" }} />
                </li>
                <li>
                  <Skeleton style={{ width: "100%" }} />
                </li>
                <li>
                  <Skeleton style={{ width: "100%" }} />
                </li>
                <li>
                  <Skeleton style={{ width: "100%" }} />
                </li>
                <li>
                  <Skeleton style={{ width: "100%" }} />
                </li>
                <li>
                  <Skeleton style={{ width: "100%" }} />
                </li>
              </ul>
              <Skeleton width={200} style={{ paddingTop: 20, marginTop: 10 }} />
            </div>
            <div
              className="col-lg-4 pacakge-one text-start "
              style={{
                backgroundColor: "transparent",
                border: "1px solid rgb(210, 210, 210)",
                borderRadius: 5,
              }}
            >
              <div className="package-name ">
                <h3>
                  <Skeleton width={200} />
                </h3>
                <p>
                  <Skeleton width={100} />
                </p>
              </div>
              <div
                className="package-one-price checkss"
                style={{ marginBottom: 0 }}
              >
                <div className="discount">
                  <h4 className="">
                    <b>
                      <Skeleton width={300} />
                    </b>
                  </h4>
                </div>
                <div className="package-one-price">
                  <span className="amount">
                    {" "}
                    <Skeleton width={190} />
                  </span>
                </div>
              </div>
              <ul>
                <li>
                  <Skeleton style={{ width: "100%" }} />
                </li>
                <li>
                  <Skeleton style={{ width: "100%" }} />
                </li>
                <li>
                  <Skeleton style={{ width: "100%" }} />
                </li>
                <li>
                  <Skeleton style={{ width: "100%" }} />
                </li>
                <li>
                  <Skeleton style={{ width: "100%" }} />
                </li>
                <li>
                  <Skeleton style={{ width: "100%" }} />
                </li>
                <li>
                  <Skeleton style={{ width: "100%" }} />
                </li>
                <li>
                  <Skeleton style={{ width: "100%" }} />
                </li>
              </ul>
              <Skeleton width={200} style={{ paddingTop: 20, marginTop: 10 }} />
            </div>
          </Carousel>
        </>
      )}
    </>
  );
};

export default SimpleCrousel;
