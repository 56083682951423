import React from "react";
import Skeleton from "react-loading-skeleton";

const PostLoader = () => {
  return (
    <div className="">
      <div className="d-flex">
        <Skeleton height={100} width={180} />
        <Skeleton style={{ width: "230px", marginLeft: 30, marginTop: 15 }} />
      </div>
    </div>
  );
};

export default PostLoader;
