import React from "react";
import { Link } from "react-router-dom";

const NavTabs = () => {
  return (
    <div className="container pt-100">
      <div className="d-flex align-items-start">
        <div className="row w-100 border shadow-lg bg-body rounded">
          <div
            className="col-md-3 nav-button"
            style={{ backgroundColor: "#ebf2f5" }}
          >
            <div
              className="nav flex-column nav-pills"
              id="v-pills-tab"
              role="tablist"
              aria-orientation="vertical"
            >
              <button
                className="nav-link py-3 active text-black navActive"
                id="v-pills-home-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-home"
                type="button"
                role="tab"
                aria-controls="v-pills-home"
                aria-selected="true"
              >
                <img
                  alt=""
                  src="assests/img/credit-card-payment.png"
                  className="nav-tab-img img-fluid"
                />
                <br />
                <spna className="d-sm-block">Credit Card</spna>
              </button>
              <button
                className="nav-link py-3 button-notactive navActive text-black"
                id="v-pills-profile-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-profile"
                type="button"
                role="tab"
                aria-controls="v-pills-profile"
                aria-selected="false"
              >
                <img
                  alt=""
                  src="assests/img/paypal.png"
                  className="nav-tab-img img-fluid"
                />
                <br />
                <spna className="d-sm-block">By Paypal</spna>
              </button>
              <button
                className="nav-link py-3 navActive button-notactive text-black"
                id="v-pills-messages-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-messages"
                type="button"
                role="tab"
                aria-controls="v-pills-messages"
                aria-selected="false"
              >
                <img
                  alt=""
                  src="assests/img/online-banking.png"
                  className="nav-tab-img img-fluid"
                />
                <br />
                <spna className="d-sm-block">Online Banking</spna>
              </button>
              <button
                className="nav-link py-3 navActive button-notactive text-black"
                id="v-pills-settings-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-settings"
                type="button"
                role="tab"
                aria-controls="v-pills-settings"
                aria-selected="false"
              >
                <img
                  alt=""
                  src="assests/img/cash-payment.png"
                  className="nav-tab-img img-fluid"
                />
                <br />
                <spna className="d-sm-block">Cash Payment</spna>
              </button>
              <button
                className="nav-link py-3 navActive button-notactive text-black"
                id="v-pills-cheque-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-cheque"
                type="button"
                role="tab"
                aria-controls="v-pills-cheque"
                aria-selected="false"
              >
                <img
                  alt=""
                  src="assests/img/cheque.png"
                  className="nav-tab-img img-fluid "
                />
                <br />
                <spna className="d-sm-block">By cheque</spna>
              </button>
              <button
                className="nav-link py-3 navActive button-notactive text-black"
                id="v-pills-mobile-tab"
                data-bs-toggle="pill"
                data-bs-target="#v-pills-mobile"
                type="button"
                role="tab"
                aria-controls="v-pills-mobile"
                aria-selected="false"
              >
                <img
                  alt=""
                  src="assests/img/Mobile-payment.png"
                  className="nav-tab-img img-fluid"
                />
                <br />
                <spna className="d-sm-block">Mobile Payment</spna>
              </button>
            </div>
          </div>
          <div className="col-md-9 px-5 pt-5">
            <div className="tab-content" id="v-pills-tabContent">
              <div
                className="tab-pane fade show active"
                id="v-pills-home"
                role="tabpanel"
                aria-labelledby="v-pills-home-tab"
              >
                <>
                  <div className="payment_text">
                    <img
                      alt=""
                      src="assests/img/240x130credit-card-payment.png"
                      className="w-100% mb-3"
                    />
                    <p className="pt-5">
                      To avail any of our services online, you can easily make a
                      payment via credit card by logging into the Billing Area
                      at&nbsp;
                      <Link to="/">www.websouls.com</Link>. In case you’ve lost
                      your login info, please contact&nbsp;
                      <a href="mailto:sales@websouls.com">sales@websouls.com</a>
                      &nbsp; immediately.
                    </p>
                    <p>
                      All the payment methods listed on our website are safe and
                      easy. Also, you may be able to avail some amazing
                      discounts for online customers.
                    </p>
                    <p>
                      So, whether you’re making a new order, or just renewing a
                      previous one, simply login into the Billing Area to make a
                      secure online payment.
                    </p>
                    <p>
                      Note: Kindly inform us after making a successful online
                      payment.
                    </p>
                  </div>
                  <div className="dashed_border"></div>
                </>
              </div>
              <div
                className="tab-pane fade"
                id="v-pills-profile"
                role="tabpanel"
                aria-labelledby="v-pills-profile-tab"
              >
                <>
                  <div className="payment_text">
                    <img
                      alt=""
                      src="assests/img/240x130paypal.png"
                      className="nav-tab-img img-fluid"
                    />
                    <br />
                    <p className="pt-5">
                      All our customers can use PayPal for the means of making a
                      secure online payment. All you need to do is go to the
                      Client Area to make a quick payment through PayPal.
                    </p>
                    <p>
                      Note: Kindly inform us after making a successful online
                      payment.
                    </p>
                  </div>
                  <div className="dashed_border"></div>
                </>
              </div>
              <div
                className="tab-pane fade"
                id="v-pills-messages"
                role="tabpanel"
                aria-labelledby="v-pills-messages-tab"
              >
                <>
                  <div className="payment_text">
                    <img
                      alt=""
                      src="assests/img/240x130online-banking.png"
                      className="nav-tab-img img-fluid"
                    />
                    <br />
                    <p className="pt-5">
                      If you want to transfer the payment amount via your bank
                      account, you can make a direct bank transfer to any of the
                      following accounts:
                    </p>
                    <p>
                      • WebSouls, Habib Bank Ltd., A/C No. 01957900259103,
                      Cavalry Ground Branch, LAHORE (Code 0195)
                    </p>
                    <p>
                      • WebSouls, Muslim Commercial Bank Ltd., A/C No.
                      0140101010013446, Defence Branch, Lahore
                    </p>
                    <p>
                      Note: Kindly inform us after making a successful payment.
                    </p>
                  </div>
                  <div className="dashed_border"></div>
                </>
              </div>
              <div
                className="tab-pane fade"
                id="v-pills-settings"
                role="tabpanel"
                aria-labelledby="v-pills-settings-tab"
              >
                <>
                  <div className="payment_text">
                    <img
                      alt=""
                      src="assests/img/240x130cash-payment.png"
                      className="nav-tab-img img-fluid"
                    />
                    <br />
                    <p className="pt-5">
                      For those of our valuable customers who’re comfortable in
                      making a direct cash payment, please visit us at our
                      office in Karachi or Lahore. Our complete office address
                      is:
                    </p>
                    <p>
                      <strong>Lahore Office:</strong> Office # 22, 2nd Office
                      Floor, IT Tower, Behind Hafeez Center, 73-E-1 Hali Road,
                      Gulberg III, Lahore.
                    </p>
                    <p>
                      <strong>Karachi Office:</strong> Bilal Arcade Building No.
                      19-D Office No. 1 Khy-E-Shamsheer St No. 10 Khada Market
                      DHA Phase 5 Karachi.
                    </p>
                  </div>
                  <div className="dashed_border"></div>
                </>
              </div>
              <div
                className="tab-pane fade"
                id="v-pills-cheque"
                role="tabpanel"
                aria-labelledby="v-pills-cheque-tab"
              >
                <>
                  <div className="payment_text">
                    <img
                      alt=""
                      src="assests/img/240x130cheque.png"
                      className="nav-tab-img img-fluid"
                    />
                    <br />
                    <p className="pt-5">
                      To make a direct payment with cheque, you can address the
                      cheque to “WebSouls” and send it on any one of our office
                      addresses:
                    </p>
                    <p>
                      <strong>Lahore Office:</strong> Office # 22, 2nd Office
                      Floor, IT Tower, Behind Hafeez Center, 73-E-1 Hali Road,
                      Gulberg III, Lahore. .
                    </p>
                    <p>
                      <strong>Karachi Office:</strong> Bilal Arcade Building No.
                      19-D Office No. 1 Khy-E-Shamsheer St No. 10 Khada Market
                      DHA Phase 5 Karachi.
                    </p>
                    <p>
                      Note: Kindly inform us after making a successful payment.
                      Also, don’t forget to mention the cheque number.
                    </p>
                  </div>
                  <div className="dashed_border"></div>
                </>
              </div>
              <div
                className="tab-pane fade"
                id="v-pills-mobile"
                role="tabpanel"
                aria-labelledby="v-pills-mobile-tab"
              >
                <>
                  <div className="payment_text">
                    <img
                      alt=""
                      src="assests/img/240x130Mobile-payment.png"
                      className="nav-tab-img img-fluid"
                    />
                    <br />
                    <p className="pt-5">
                      To pay for any of our services via a mobile app, you can
                      make a payment through Easy Paisa, Mobi Cash, Warid Paisa,
                      U-Paisa, Time Pay, UBL Omni, or HBL Express. Our bank
                      account details are:
                    </p>
                    <p>
                      Bank Account: WebSouls, Habib Bank Ltd., A/C No.
                      01957900259103, Cavalry Ground Branch, LAHORE (Code 0195)
                    </p>
                    <p>
                      Note: Kindly inform us after making a successful payment.
                    </p>
                  </div>
                  <div className="dashed_border"></div>
                </>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavTabs;
