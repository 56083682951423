import React from "react";
import { Link, NavLink } from "react-router-dom";

const ContactUsComp = () => {
  return (
    <section className="sales_inquiry pt-100">
      <div className="container">
        <div className="row ">
          {/* Start */}
          <div className="col-md-3 mb-4">
            <div className="serving_since">
              <div className="img_circle">
                <div className="icon ws-chat" />
              </div>
              <hr className="img_btm" />
              <h4>LIVE CHAT</h4>
              <p className="w-100 text-center justify-content-center">
                Reach out to our dedicated support team through live chat and
                get the answers to your questions.
              </p>
            </div>
            <a href="javascript:void(Tawk_API.toggle())" className="FaqButton">
              Start Chat
            </a>
          </div>
          {/* Start */}
          <div className="col-md-3 mb-4">
            <div className="serving_since">
              <div className="img_circle">
                <div className="icon ws-support-b" />
              </div>
              <hr className="img_btm" />
              <h4>TECHNICAL SUPPORT</h4>
              <p className="w-100 text-start justify-content-center">
                <a className="text-decoration-none text-black" href="#">
                  Tel # +92 42 35238871-73
                  <br />
                  Mobile # 0321-4077991&nbsp;/&nbsp;0321-4776910&nbsp;
                  <br />
                  Email:
                </a>
                <a
                  className="text-decoration-none text-black"
                  href="mailto:support@websouls.com"
                >
                  support@websouls.com
                </a>
                <br />
                Timing: (8AM to 12AM)
              </p>
            </div>
            <a
              href="https://billing.websouls.com/submitticket.php?step=2&deptid=1"
              className="FaqButton"
            >
              Submit Ticket
            </a>
          </div>
          {/* Start */}
          <div className="col-md-3 mb-4">
            <div className="serving_since">
              <div className="img_circle">
                <div className="icon ws-handshake" />
              </div>
              <hr className="img_btm" />
              <h4>PRE-SALES ENQUIRIES</h4>
              <p className="w-100 text-start justify-content-center">
                <a className="text-decoration-none text-black" href="#">
                  Tel # +92 42 35238871-73
                  <br />
                  Mobile # 0321 4776996&nbsp;/&nbsp;0322 5252352&nbsp;
                  <br />
                  Email:
                </a>
                <a
                  className="text-decoration-none text-black"
                  href="mailto:sales@websouls.com"
                >
                  sales@websouls.com
                </a>
                <br />
                Timing: (9AM to 7PM)
              </p>
            </div>
            <a
              href="https://billing.websouls.com/submitticket.php?step=2&deptid=2"
              className="FaqButton"
            >
              Open a Ticket
            </a>
          </div>
          {/* Start */}
          <div className="col-md-3 mb-4">
            <div className="serving_since">
              <div className="img_circle">
                <div className="icon ws-billing" />
              </div>
              <hr className="img_btm" />
              <h4>BILLING SUPPORT</h4>
              <p>
                <a href="#" className="text-decoration-none text-black">
                  Tel # +92 42 35238871-73
                  <br />
                  Mobile # 0321 4776996&nbsp;/&nbsp;0322 5252352&nbsp;
                  <br />
                  Email:&nbsp;
                </a>
                <a
                  className="text-decoration-none text-black"
                  href="mailto:billing@websouls.com"
                >
                  billing@websouls.com
                </a>
                <br />
                Timing: (9AM to 6PM)
              </p>
            </div>
            <a
              href="https://billing.websouls.com/submitticket.php?step=2&deptid=3"
              className="FaqButton"
            >
              Open a Ticket
            </a>
          </div>
        </div>
        <div className="row ">
          {/* Start */}
          <div className="col-md-3 mb-4">
            <div className="serving_since">
              <div className="img_circle">
                <div className="icon ws-career  " />
              </div>
              <hr className="img_btm" />
              <h4>CAREERS AT WEBSOULS</h4>
              <p>
                <a href="#" className="text-decoration-none text-black">
                  Tel # +92 42 35238871-73
                  <br />
                  Mobile # +92 321 9300011
                  <br />
                  Email:&nbsp;
                </a>
                <a
                  href="mailto:hr@websouls.com"
                  className="text-decoration-none text-black"
                >
                  hr@websouls.com
                </a>
                <br />
                Timing: (9AM to 6PM)
              </p>
            </div>
            <a
              href="https://billing.websouls.com/submitticket.php?step=2&deptid=5"
              className="FaqButton"
              target="_blank"
            >
              Open A Ticket
            </a>
          </div>
          {/* Start */}
          <div className="col-md-3 mb-4">
            <div className="serving_since">
              <div className="img_circle">
                <div className="icon ws-graph" />
              </div>
              <hr className="img_btm" />
              <h4>BUSINESS DEVELOPMENT</h4>
              <p>
                Whether you’re starting a new business, or focused on growing
                the one you own, we can help!
              </p>
            </div>
            <a
              href="https://billing.websouls.com/submitticket.php?step=2&deptid=5"
              className="FaqButton"
            >
              Let’s Collaborate
            </a>
          </div>
          {/* Start */}
          <div className="col-md-3 mb-4">
            <div className="serving_since">
              <div className="img_circle">
                <div className="icon ws-complaint" />
              </div>
              <hr className="img_btm" />
              <h4>Abuse Complaints</h4>
              <p>
                <a href="#" className="text-decoration-none text-black">
                  Tel # +92 42 35238871-73
                  <br />
                  Mobile # 0321-4077991 / 0321-4776910
                  <br />
                  Email:&nbsp;
                </a>
                <a
                  href="mailto:abuse@websouls.com"
                  className="text-decoration-none text-black"
                >
                  abuse@websouls.com
                </a>
                <br />
                Timing: (8AM to 12AM)
              </p>
            </div>
            <a
              href="https://billing.websouls.com/submitticket.php?step=2&deptid=6"
              className="FaqButton"
            >
              Open a Ticket
            </a>
          </div>
          {/* Start */}
          <div className="col-md-3 mb-4">
            <div className="serving_since">
              <div className="img_circle">
                <div className="icon ws-feedback" />
              </div>
              <hr className="img_btm" />
              <h4>Customer’s Feedback</h4>
              <p>
                We love to hear what our clients think of us, and welcome
                suggestions to provide the best services.
              </p>
            </div>
            <a
              href="https://billing.websouls.com/submitticket.php?step=2&deptid=5"
              className="FaqButton"
            >
              Open a Ticket
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default ContactUsComp;
