import { configureStore } from "@reduxjs/toolkit";
import {
  currencyReducer,
  packageCartSetReducer,
  domainSearchCartSetReducer,
  addonsCartSetReducer,
  billingCycleSetReducer,
  packagesBillingCycleSetReducer,
  clientDetailsSetReducer,
  cartBillingDetailsSetReducer,
  currentUrlReducer,
  userDomainReducer,
  webErrorHandelReducer
} from "./Reducer";
const store = configureStore({
  reducer: {
    currencyName: currencyReducer,
    packageCart: packageCartSetReducer,
    domainSearchCart: domainSearchCartSetReducer,
    addonCart: addonsCartSetReducer,
    billingCycleHosting: billingCycleSetReducer,
    billingCycleDomain: billingCycleSetReducer,
    packagesCycles: packagesBillingCycleSetReducer,
    updatingCycle: packageCartSetReducer,
    addingCycle: packageCartSetReducer,
    addingCycleDomain: domainSearchCartSetReducer,
    removeCyleDomain: domainSearchCartSetReducer,
    clientDetails: clientDetailsSetReducer,
    cartPayment: cartBillingDetailsSetReducer,
    currentUrl: currentUrlReducer,
    domainNameClient: userDomainReducer,
    tempdomainSearchCart: domainSearchCartSetReducer,
    tempdomainaddingCycle: domainSearchCartSetReducer,
    resetdomainSearchCart: domainSearchCartSetReducer,
    resetaddonCart: addonsCartSetReducer,
    resetclientDetails: clientDetailsSetReducer,
    resetcartPayment: cartBillingDetailsSetReducer,
    resetpackageCart: packageCartSetReducer,
    resetclientDomainName: userDomainReducer,
    isError: webErrorHandelReducer,
    resetError: webErrorHandelReducer
  },
});

export default store;
