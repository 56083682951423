import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import {
   SSOToken
  } from "../../services/Network.js";
const Thankyou = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const [paymentmethod, setpaymentmethod] = useState("");
  const [order_total, setorderTotal] = useState("");
  const [order_id, setorder_id] = useState("");
  const [invoice_id, setinvoice_id] = useState("");
  const [fullName, setifullName] = useState("");
  const clientIDs = localStorage.getItem("client_id");
  useEffect(() => {
    if (location.key === "default") {
      navigate("/");
    }
    setpaymentmethod(location.state?.paymentmethod);
    setorder_id(location.state?.order_id);
    setinvoice_id(location.state?.invoice_id);
    setifullName(location.state?.fullNmae);
    setorderTotal(location.state?.order_total);
    setTimeout(() => {
       SSOToken(clientIDs, location.state?.invoice_id).then((resss) => {
        //console.log("result ",resss);
        //return;
        localStorage.setItem("ssco", resss.access_token);
        localStorage.removeItem("ssco");
        localStorage.removeItem("total");
        window.location.replace(resss.redirect_url);
        
        
      });
      }, 4000);





  }, []);

  const GoBack = () => {
    navigate("/");
    window.location.reload();
  };
  let data = "";
   
    data = (
      <>
        <div className="online_banking_system">
          <h4>
            {/* <img src="assests/img/cash-payment.png" className="lazy" alt="" /> */}
            {paymentmethod}
          </h4>
          <ul>
            <li>
              <p>Dear {fullName}, </p>
            </li>
            <li>
              <p>Thankyou! We have received your order. </p>
            </li>
            <li>
              <p>Your Order Number is {order_id} . </p>
            </li>
            <li>
              <p>
                Your Invoice Number is {invoice_id} . We've also sent you
                invoice at your email ID.
              </p>
            </li>
            <li>
              <p>
                Order Total {order_total}
                
              </p>
            </li>
            
          </ul>
           
        </div>
      </>
    );
   

  

  return (
    <div className="">
      <div className="row">
        <div className="col-md-8 col-sm-12 col-xs-12 mt-5 mx-auto">
          <div className="billing_datail">{data}</div>
        </div>
      </div>
    </div>
  );
};

export default Thankyou;
