import React from "react";
import Skeleton from "react-loading-skeleton";

const BlogLoader = () => {
  return (
    <>
      <>
        <div className="col-md-6 col-sm-6 col-xs-6 mb-3">
          <div className="blog_img">
            <Skeleton style={{ width: "100%", height: "240px" }} />
          </div>
        </div>
        <div className="col-md-6 col-sm-6 col-xs-6">
          <div className="mt-3">
            <span />
            <a style={{ cursor: "pointer" }}>
              <h5>
                <Skeleton />
              </h5>
            </a>
            <p />
          </div>
          <div className="">
            <h6>
              <span>
                <Skeleton style={{ width: "70%" }} />
                <Skeleton style={{ width: "50%", marginTop: "24px" }} />
                <span />
              </span>
            </h6>
          </div>
        </div>
        <div className="col-md-6 col-sm-6 col-xs-6 mb-3">
          <div className="blog_img">
            <Skeleton style={{ width: "100%", height: "240px" }} />
          </div>
        </div>
        <div className="col-md-6 col-sm-6 col-xs-6">
          <div className="mt-3">
            <span />
            <a style={{ cursor: "pointer" }}>
              <h5>
                <Skeleton />
              </h5>
            </a>
            <p />
          </div>
          <div className="">
            <h6>
              <span>
                <Skeleton style={{ width: "70%" }} />
                <Skeleton style={{ width: "50%", marginTop: "24px" }} />
                <span />
              </span>
            </h6>
          </div>
        </div>
        <div className="col-md-6 col-sm-6 col-xs-6 mb-3">
          <div className="blog_img">
            <Skeleton style={{ width: "100%", height: "240px" }} />
          </div>
        </div>
        <div className="col-md-6 col-sm-6 col-xs-6">
          <div className="mt-3">
            <span />
            <a style={{ cursor: "pointer" }}>
              <h5>
                <Skeleton />
              </h5>
            </a>
            <p />
          </div>
          <div className="">
            <h6>
              <span>
                <Skeleton style={{ width: "70%" }} />
                <Skeleton style={{ width: "50%", marginTop: "24px" }} />
                <span />
              </span>
            </h6>
          </div>
        </div>
        <div className="col-md-6 col-sm-6 col-xs-6 mb-3">
          <div className="blog_img">
            <Skeleton style={{ width: "100%", height: "240px" }} />
          </div>
        </div>
        <div className="col-md-6 col-sm-6 col-xs-6">
          <div className="mt-3">
            <span />
            <a style={{ cursor: "pointer" }}>
              <h5>
                <Skeleton />
              </h5>
            </a>
            <p />
          </div>
          <div className="">
            <h6>
              <span>
                <Skeleton style={{ width: "70%" }} />
                <Skeleton style={{ width: "50%", marginTop: "24px" }} />
                <span />
              </span>
            </h6>
          </div>
        </div>
      </>
    </>
  );
};

export default BlogLoader;
