import React from "react";

const TrustedIndus = () => {
  return (
    <section className="sec-trusted-by-indes">
      <div className="container">
        <h2 className="text-center">Trusted By Industry Leaders</h2>
        <h5 className="text-center">
          Assisting our clients in becoming the best version of themselves
        </h5>
        <hr className="divider mx-auto" />
        <div className="row brand-row ">
          <div className="col-6 col-sm-4 col-lg-2 mb-4">
            <div style={{ backgroundColor: "#f8f8f8" }}>
              <img
                loading="lazy"
                src="assests/img/Bata.png"
                className="max-width"
                title="Bata"
                alt="bata"
                width="100%"
                height="100%"
              />
            </div>
          </div>
          <div className="col-6 col-sm-4 col-lg-2 mb-4">
            <div style={{ backgroundColor: "#f8f8f8" }}>
              <img
                loading="lazy"
                src="assests/img/ellan.png"
                className="max-width"
                title="Elan"
                alt="elan"
                width="100%"
                height="100%"
              />
            </div>
          </div>
          <div className="col-6 col-sm-4 col-lg-2 mb-4">
            <div style={{ backgroundColor: "#f8f8f8" }}>
              <img
                loading="lazy"
                src="assests/img/rosepetal.png"
                className="max-width"
                title="Rosepetal"
                alt="Rosepetal"
                width="100%"
                height="100%"
              />
            </div>
          </div>
          <div className="col-6 col-sm-4 col-lg-2 mb-4">
            <div style={{ backgroundColor: "#f8f8f8" }}>
              <img
                loading="lazy"
                src="assests/img/treet.png"
                className="max-width"
                title="Treet"
                alt="Treet"
                width="100%"
                height="100%"
              />
            </div>
          </div>
          <div className="col-6 col-sm-4 col-lg-2 mb-4">
            <div style={{ backgroundColor: "#f8f8f8" }}>
              <img
                loading="lazy"
                src="assests/img/menu.png"
                className="max-width"
                title="Menu"
                alt="Menu"
                width="100%"
                height="100%"
              />
            </div>
          </div>
          <div className="col-6 col-sm-4 col-lg-2 mb-4">
            <div style={{ backgroundColor: "#f8f8f8" }}>
              <img
                loading="lazy"
                src="assests/img/honda.png"
                className="max-width"
                title="hondafort"
                alt="hondafort"
                width="100%"
                height="100%"
              />
            </div>
          </div>
        </div>
        <div className="row brand-row">
          <div className="col-6 col-sm-4 col-lg-2 mb-4">
            <div style={{ backgroundColor: "#f8f8f8" }}>
              <img
                loading="lazy"
                src="assests/img/dhalahore.png"
                className="max-width"
                title="DhaLahore"
                alt="DhaLahore"
                width="100%"
                height="100%"
              />
            </div>
          </div>
          <div className="col-6 col-sm-4 col-lg-2 mb-4">
            <div style={{ backgroundColor: "#f8f8f8" }}>
              <img
                loading="lazy"
                src="assests/img/orga.png"
                className="max-width"
                title="ogra"
                alt="orga"
                width="100%"
                height="100%"
              />
            </div>
          </div>
          <div className="col-6 col-sm-4 col-lg-2 mb-4">
            <div style={{ backgroundColor: "#f8f8f8" }}>
              <img
                loading="lazy"
                src="assests/img/suzuki.png"
                className="max-width"
                title="suzuki"
                alt="suzuki"
                width="100%"
                height="100%"
              />
            </div>
          </div>
          <div className="col-6 col-sm-4 col-lg-2 mb-4">
            <div style={{ backgroundColor: "#f8f8f8" }}>
              <img
                loading="lazy"
                src="assests/img/bonanza.png"
                className="max-width"
                title="bonanza"
                alt="bonanza"
                width="100%"
                height="100%"
              />
            </div>
          </div>
          <div className="col-6 col-sm-4 col-lg-2 mb-4">
            <div
              style={{ backgroundColor: "#f8f8f8" }}
              width="100%"
              height="100%"
            >
              <img
                loading="lazy"
                src="assests/img/hotelone.png"
                className="max-width"
                title="Hotelone"
                alt="hotelone"
                width="100%"
                height="100%"
              />
            </div>
          </div>
          <div className="col-6 col-sm-4 col-lg-2 mb-4">
            <div style={{ backgroundColor: "#f8f8f8" }}>
              <img
                loading="lazy"
                src="assests/img/bahriatown.png"
                className="max-width"
                title="Bahria"
                alt="bahriatown"
                width="100%"
                height="100%"
              />
            </div>
          </div>
        </div>
        <div className="row brand-row">
          <div className="col-6 col-sm-4 col-lg-2 mb-4">
            <div
              style={{ backgroundColor: "#f8f8f8" }}
              width="100%"
              height="100%"
            >
              <img
                loading="lazy"
                src="assests/img/chughtai.png"
                className="max-width"
                title="Chughtai Lab"
                alt="chughtai"
                width="100%"
                height="100%"
              />
            </div>
          </div>
          <div className="col-6 col-sm-4 col-lg-2 mb-4">
            <div style={{ backgroundColor: "#f8f8f8" }}>
              <img
                loading="lazy"
                src="assests/img/igi.png"
                className="max-width"
                title="IGI"
                alt="IGI"
                width="100%"
                height="100%"
              />
            </div>
          </div>
          <div className="col-6 col-sm-4 col-lg-2 mb-4">
            <div style={{ backgroundColor: "#f8f8f8" }}>
              <img
                loading="lazy"
                src="assests/img/zoom.png"
                className="max-width"
                title="Zoom"
                alt="zoom"
                width="100%"
                height="100%"
              />
            </div>
          </div>
          <div className="col-6 col-sm-4 col-lg-2 mb-4">
            <div style={{ backgroundColor: "#f8f8f8" }}>
              <img
                loading="lazy"
                src="assests/img/kissan.png"
                className="max-width"
                title="Kisan"
                alt="kissan"
                width="100%"
                height="100%"
              />
            </div>
          </div>
          <div className="col-6 col-sm-4 col-lg-2 mb-4">
            <div style={{ backgroundColor: "#f8f8f8" }}>
              <img
                loading="lazy"
                src="assests/img/nust.png"
                className="max-width"
                title="Nust"
                alt="logo-1-(119)"
                width="100%"
                height="100%"
              />
            </div>
          </div>
          <div className="col-6 col-sm-4 col-lg-2 mb-4">
            <div style={{ backgroundColor: "#f8f8f8" }}>
              <img
                loading="lazy"
                src="assests/img/tevta.png"
                className="max-width"
                title="Tevta"
                alt="tevta"
                width="100%"
                height="100%"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default TrustedIndus;
