import React from "react";
import { Helmet } from "react-helmet";
import BannerTwo from "../reuseable/Banner2";
import NavTabs from "../reuseable/navTabs";

const PaymentPage = () => {
  const bannerData = {
    heading: "Online Payment Or Local Transfers",
    peragraph: "Making Everything Easier for You!",
  };
  return (
    <>
      <Helmet>
        <title>
          PayPal | Credit Card | Online Banking | Mobile Payments | WebSouls
        </title>
        <meta
          name="title"
          content="PayPal | Credit Card | Online Banking | Mobile Payments | WebSouls"
        />
        <meta
          name="description"
          content="WebSouls offer multiple payment gateways for the ease of our customers such as PayPal, Credit Cards, Online Banking, Mobile Payments, Cash at WebSouls Office, or pay through Cheque."
        />
        <meta name="keywords" content="" />
        <link rel="canonical" href="https://websouls.com/payment-methods" />
      </Helmet>
      <BannerTwo Data={bannerData} />
      <NavTabs />
      <section className="payment_method pt-100">
        <div className="container">
          <div className="row">
            <div className="col-md-6 col-sm-12 col-xs-12 d-none d-md-block d-lg-block d-xl-block">
              <div className="payment_mthod_img">
                <img
                  src="/assests/img/web-hosting-secure-payment-gateways.png"
                  className="lazy img-fluid"
                  title="web-hosting-secure-payment-gateways"
                  alt="web-hosting-secure-payment-gateways"
                />
              </div>
            </div>
            <div className="col-md-6 col-sm-12 col-xs-12">
              <div className="payment_method_detail">
                <h3>We Accept Payment All Ways, Always</h3>
                <p />
                <p>
                  Offering Secure Payment Gateways for Stress-Free Transactions
                </p>
                <p>&nbsp;</p>
                <ul>
                  <li>
                    <strong>Reliable Payment:</strong> We offer reliable and safe payment
                    processing services.
                  </li>
                  <li>
                  <strong>24/7 Customer Care:</strong> Our team is fully dedicated to assist
                    you at all times.
                  </li>
                  <li>
                  <strong>Reasonable Price:</strong> No one can beat our insanely affordable
                    pricing plans!
                  </li>
                  <li>
                  <strong>Zero Security Risks:</strong> With us at WebSouls, your secrecy is
                    fully guaranteed.
                  </li>
                </ul>
                <p />
                <ul></ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default PaymentPage;
