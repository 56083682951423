import React, { useState } from "react";
import { Link } from "react-router-dom";

const DomainPricingRegistration = () => {
  const [readMore, setReadMore] = useState(false);

  const extraContent = (
    <>
      <p>
        We can manage your domain and all its aspects while allowing you to lock
        the perfect domain name for your website in order to steer clear of
        unauthorized transfers. You can then unlock it and transfer your
        preferred name whenever you like. In addition to this, we also offer
        auto-renewal services to keep your domain from being accidentally lost
        or expired.
      </p>
      <p>
        With us, you can also become a domain reseller or domain name provider
        in Pakistan. We ensure you complete privacy and protection of your
        domain and also offer best&nbsp;
        <Link to="#" target="_blank">
          web hosting in Pakistan
        </Link>
        &nbsp;at affordable costs. So, conduct a domain search now and let us
        serve you at our best!
      </p>
    </>
  );
  const linkName = readMore ? (
    <>
      <button className="readmoreMobile">Read Less</button>
    </>
  ) : (
    <>
      <button className="readmoreMobile">Read More</button>
    </>
  );

  return (
    <section className="sec-domain-reg pt-100">
      <div className="container">
        <h2 className="text-center first-heading">
          Domain Registration in Pakistan
        </h2>
        <h5 className="text-center second-heading">
          The Right Domain Name Can Get You the Right Visitors
        </h5>
        <hr className="divider scnd_divider mx-auto mb-4" />
        <div className="d-none d-md-block d-lg-block d-xl-block">
          <div className="text-domain-reg">
            <p>
              Buying the perfect domain for your business website has never been
              so easy before in Pakistan. With our online domain checker
              at&nbsp;
              <Link to="#" target="_blank">
                WebSouls
              </Link>
              &nbsp;you can look up for the perfect domain name for your site,
              find a suitable web address, and register your ideal domain at
              cheap rates in Pakistan.
            </p>
            <p>
              If you want to buy a domain in Lahore, Karachi, Islamabad, or any
              place else, all you have to do is visit one of our offices and let
              our experts guide you through the process. Apart from this, you
              can get all the information about our domain registration services
              and pricing from our website or by contacting us through live
              chat, phone call, or&nbsp;
              <Link to="#">email</Link>. We respond to all our customer tickets
              within no time.
            </p>
            <p>
              We can manage your domain and all its aspects while allowing you
              to lock the perfect domain name for your website in order to steer
              clear of unauthorized transfers. You can then unlock it and
              transfer your preferred name whenever you like. In addition to
              this, we also offer auto-renewal services to keep your domain from
              being accidentally lost or expired.
            </p>
            <p>
              With us, you can also become a domain reseller or domain name
              provider in Pakistan. We ensure you complete privacy and
              protection of your domain and also offer best&nbsp;
              <Link to="#" target="_blank">
                web hosting in Pakistan
              </Link>
              &nbsp;at affordable costs. So, conduct a domain search now and let
              us serve you at our best!
            </p>
          </div>
        </div>
        <div className="d-block d-md-none d-lg-none d-xl-none d-xxl-none">
          <div className="text-domain-reg">
            <p>
              Buying the perfect domain for your business website has never been
              so easy before in Pakistan. With our online domain checker
              at&nbsp;
              <Link to="#" target="_blank">
                WebSouls
              </Link>
              &nbsp;you can look up for the perfect domain name for your site,
              find a suitable web address, and register your ideal domain at
              cheap rates in Pakistan.
            </p>
            <p>
              If you want to buy a domain in Lahore, Karachi, Islamabad, or any
              place else, all you have to do is visit one of our offices and let
              our experts guide you through the process. Apart from this, you
              can get all the information about our domain registration services
              and pricing from our website or by contacting us through live
              chat, phone call, or&nbsp;
              <Link to="#">email</Link>. We respond to all our customer tickets
              within no time.
            </p>
            {readMore && extraContent}
            <a
              className="read-more-link"
              onClick={() => {
                setReadMore(!readMore);
              }}
            >
              <h2 className="text-center pt-2">{linkName}</h2>
            </a>
          </div>
        </div>
      </div>
    </section>
  );
};

export default React.memo(DomainPricingRegistration);
