import React, { useState, useEffect } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import CartOrder from "./CartOrder";
import { DomainTld } from "../../services/Helper";
import { DomainSearchApi } from "../../services/Network.js";
import ScrollToTop from "./ScrollToTop";

const postsPerPage = 10;
let arrayForHoldingDomains = [];

const DomainSearch = () => {
  //router hook
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const currentURL = location.pathname;

  //reducer hook

  const { currencyName } = useSelector((state) => state.currencyName);
  const { domainSearchCart } = useSelector((state) => state.domainSearchCart);
  const { packageCart } = useSelector((state) => state.packageCart);

  const [domainFindName, setDomainFindName] = useState("");
  const [packageBypass, setpackageBypass] = useState("");
  const [loading, setLoading] = useState(true);
  const [aloading, setALoading] = useState(true);
  const [DomainResults, setDomainResults] = useState([]);
  const [postsToShow, setPostsToShow] = useState([]);
  const [next, setNext] = useState(9);
  const [scrolState, setScrollstate] = useState(false);
  const [isPk, setIsPk] = useState(false);

  const [visible, setVisible] = useState(9);

  const [idShow, setIdshow] = useState("");

  const showMoreItems = (e) => {
    e.preventDefault();
    setVisible((prevValue) => prevValue + 6);
  };

  const [newData, setNewData] = useState([]);

  const [tlds, setTlds] = useState("net,com");

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, [scrolState]);

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    setTimeout(() => {
      if (packageCart.length > 0) {
        let frd = 0;
        for (let f = 0; f < packageCart.length; f++) {
          if (packageCart[f].freedomain.length > 0) {
            frd = 1;
            setIdshow("d-none");
            break;
          }
        }
      }
    }, 2000);
  }, [packageCart]);

  useEffect(() => {
    if (location.key === "default") {
      navigate("/");
    }
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
    setScrollstate(!scrolState);
    document.getElementById("top_bar_scroll").scrollIntoView();
    dispatch({
      type: "urlCurrent",
      payload: location.pathname,
    });
    //////  free domains

    //////////
    let sp = location.state?.name?.split(".");

    let domainwit;

    if (sp?.length === 1) {
      if (location.state.prevPath === "/buy-ae-domains") {
        domainwit = sp[0] + ".ae";
      } else if (location.state.prevPath === "/buy-pk-domain") {
        domainwit = sp[0] + ".pk";
      } else {
        domainwit = sp[0] + ".com";
      }
    } else {
      domainwit = location?.state?.name;
    }

    setDomainFindName(domainwit);
    setpackageBypass(location?.state?.var);
    setPostsToShow([]);

    DomainSearchApi(domainwit, tlds, "domainregister").then((res) => {
      const searchedDomain = res.searchedDomain.includes(".pk");
      // console.log("ispk:",searchedDomain)
      setIsPk(searchedDomain);
      localStorage.removeItem("f");
      setDomainResults(res);
      const slicedPosts = res?.suggestions?.slice(0, postsPerPage);
      arrayForHoldingDomains = [...arrayForHoldingDomains, ...slicedPosts];
      setPostsToShow(res?.suggestions);

      //if domain is priemuim
      if (res?.ispremium === "yes") {
        //if domain is priemuim and avaible
        if (res?.available === 1) {
          setTimeout(() => {
            dispatch({
              type: "addingCycleDomain",
              payload: {
                domainName: res.searchedDomain,
                packageCycle: "annually",
              },
            });
            dispatch({
              type: "domainSearchCart",
              payload: {
                name: "domainregister",
                domainName: res.searchedDomain,
                price: res.price,
                ispremium: res.ispremium,
                value: 1,
                // domainAddonTitle: "Privacy Protection",
                // domainAddon: res.domainAddon[0],
              },
            });
          }, 1000);
          setLoading(false);
        }
      } else {
        ////here

        if (res.available === 8) {
          setLoading(false);
        }
        if (res.available === 5) {
          setLoading(false);
        }
        if (res.available === 2) {
          setLoading(false);
        }
        if (currencyName.api_id === 3) {
          if (res.available === 0) {
            setLoading(false);
          } else {
            let priceGet = res?.price[0];

            if (priceGet.annually === "") {
              if (res?.available === 1) {
                setTimeout(() => {
                  dispatch({
                    type: "addingCycleDomain",
                    payload: {
                      domainName: res.searchedDomain,
                      packageCycle: "biennially",
                    },
                  });
                  dispatch({
                    type: "domainSearchCart",
                    payload: {
                      name: "domainregister",
                      domainName: res.searchedDomain,
                      price: res.price,
                      ispremium: res.ispremium,
                      value: 1,
                    },
                  });
                  dispatch({
                    type: "tempdomainSearchCart",
                    payload: {
                      name: "domainregister",
                      domainName: res.searchedDomain,
                      price: res.price,
                      ispremium: res.ispremium,
                      // domainAddonTitle: "Privacy Protection",
                      // domainAddon: res.domainAddon[0],
                      value: 1,
                    },
                  });
                  dispatch({
                    type: "tempdomainaddingCycle",
                    payload: {
                      domainName: res.searchedDomain,
                      packageCycle: "annually",
                    },
                  });
                }, 1000);
                setLoading(false);
              }
            } else {
              setLoading(false);
              if (res?.available === 1) {
                setTimeout(() => {
                  dispatch({
                    type: "addingCycleDomain",
                    payload: {
                      domainName: res.searchedDomain,
                      packageCycle: "annually",
                    },
                  });
                  dispatch({
                    type: "domainSearchCart",
                    payload: {
                      name: "domainregister",
                      domainName: res.searchedDomain,
                      price: res.price,
                      ispremium: res.ispremium,
                      // domainAddonTitle: "Privacy Protection",
                      // domainAddon: res.domainAddon[0],
                      value: 1,
                    },
                  });
                  dispatch({
                    type: "tempdomainSearchCart",
                    payload: {
                      name: "domainregister",
                      domainName: res.searchedDomain,
                      price: res.price,
                      ispremium: res.ispremium,
                      // domainAddonTitle: "Privacy Protection",
                      // domainAddon: res.domainAddon[0],
                      value: 1,
                    },
                  });
                  dispatch({
                    type: "tempdomainaddingCycle",
                    payload: {
                      domainName: res.searchedDomain,
                      packageCycle: "annually",
                    },
                  });
                }, 1000);
                setLoading(false);
              }
            }
          }
        } else {
          if (res.available === 0) {
            setLoading(false);
          } else {
            let priceGet = res?.price[1];

            if (priceGet.annually === "") {
              if (res?.available === 1) {
                setTimeout(() => {
                  dispatch({
                    type: "addingCycleDomain",
                    payload: {
                      domainName: res.searchedDomain,
                      packageCycle: "biennially",
                    },
                  });
                  dispatch({
                    type: "domainSearchCart",
                    payload: {
                      name: "domainregister",
                      domainName: res.searchedDomain,
                      price: res.price,
                      ispremium: res.ispremium,
                      //  domainAddonTitle: "Privacy Protection",
                      //  domainAddon: res.domainAddon[0],
                      value: 1,
                    },
                  });
                  dispatch({
                    type: "tempdomainSearchCart",
                    payload: {
                      name: "domainregister",
                      domainName: res.searchedDomain,
                      price: res.price,
                      ispremium: res.ispremium,
                      // domainAddonTitle: "Privacy Protection",
                      // domainAddon: res.domainAddon[0],
                      value: 1,
                    },
                  });
                  dispatch({
                    type: "tempdomainaddingCycle",
                    payload: {
                      domainName: res.searchedDomain,
                      packageCycle: "annually",
                    },
                  });
                }, 1000);
                //  setLoading(false);
              }
            } else {
              if (res?.available === 1) {
                setTimeout(() => {
                  dispatch({
                    type: "addingCycleDomain",
                    payload: {
                      domainName: res.searchedDomain,
                      packageCycle: "annually",
                    },
                  });
                  dispatch({
                    type: "domainSearchCart",
                    payload: {
                      name: "domainregister",
                      domainName: res.searchedDomain,
                      price: res.price,
                      ispremium: res.ispremium,
                      // domainAddonTitle: "Privacy Protection",
                      // domainAddon: res.domainAddon[0],
                      value: 1,
                    },
                  });
                  dispatch({
                    type: "tempdomainSearchCart",
                    payload: {
                      name: "domainregister",
                      domainName: res.searchedDomain,
                      price: res.price,
                      ispremium: res.ispremium,
                      // domainAddonTitle: "Privacy Protection",
                      // domainAddon: res.domainAddon[0],
                      value: 1,
                    },
                  });
                  dispatch({
                    type: "tempdomainaddingCycle",
                    payload: {
                      domainName: res.searchedDomain,
                      packageCycle: "annually",
                    },
                  });
                }, 1000);
                //  setLoading(false);
              }
            }
          }
          setLoading(false);
        }
      }
      //setLoading(false);
    });
    // setLoading(false);

    if (DomainResults?.length === 0) {
    } else {
      if (DomainResults?.ispremium === "yes") {
        const domainName = DomainResults.searchedDomain;
        const domainPrice = DomainResults.price[0];
        Object.assign(domainPrice, {
          annually: DomainResults.price[0].annually,
          biennially: DomainResults.price[0].annually * 2,
          triennially: DomainResults.price[0].annually * 3,
          fourlyprice: DomainResults.price[0].annually * 4,
          fivelyprice: DomainResults.price[0].annually * 5,
          sixlyprice: DomainResults.price[0].annually * 6,
          sevenlyprice: DomainResults.price[0].annually * 7,
          eightlyprice: DomainResults.price[0].annually * 8,
          ninelyprice: DomainResults.price[0].annually * 9,
          tenlyprice: DomainResults.price[0].annually * 10,
        });
        //

        dispatch({
          type: "domainSearchCart",
          payload: {
            name: "domainregister",
            domainName: domainName,
            price: domainPrice,
            ispremium: "yes",
            value: 1,
          },
        });
      } else {
        const domainName = DomainResults?.searchedDomain;
        let domainAddingfs = DomainResults?.price?.filter(
          (delpri) => delpri?.currency === currencyName?.api_id
        );
        let newPrice = DomainResults?.price?.filter(
          (el) => el?.currency === currencyName?.api_id
        );
        let check = [];
        if (domainAddingfs && domainAddingfs[0]) {
          check = domainAddingfs[0] && [domainAddingfs[0]];
        }
        //checking start from hererere

        for (const ob of check) {
          if (ob?.monthly > 0) {
            dispatch({
              type: "addingCycleDomain",
              payload: {
                domainName: domainName,
                packageCycle: "montly",
              },
            });
            dispatch({
              type: "domainSearchCart",
              payload: {
                name: "domainregister",
                domainName: domainName,
                price: DomainResults.price,
                ispremium: "no",
                value: 1,
              },
            });
            dispatch({
              type: "tempDomainAdd",
              payload: [
                {
                  name: "domainregister",
                  domainName: domainName,
                  price: DomainResults.price,
                  ispremium: "no",
                  value: 1,
                },
              ],
            });
            break;
          }
          if (ob?.annually > 0) {
            dispatch({
              type: "addingCycleDomain",
              payload: {
                domainName: domainName,
                packageCycle: "annually",
              },
            });
            dispatch({
              type: "domainSearchCart",
              payload: {
                name: "domainregister",
                domainName: domainName,
                price: DomainResults.price,
                ispremium: "no",
                value: 1,
              },
            });
            dispatch({
              type: "tempDomainAdd",
              payload: [
                {
                  name: "domainregister",
                  domainName: domainName,
                  price: DomainResults.price,
                  ispremium: "no",
                  value: 1,
                },
              ],
            });
            break;
          }
          if (ob?.biennially > 0) {
            dispatch({
              type: "addingCycleDomain",
              payload: {
                domainName: domainName,
                packageCycle: "biennially",
              },
            });
            dispatch({
              type: "domainSearchCart",
              payload: {
                name: "domainregister",
                domainName: domainName,
                price: DomainResults.price,
                ispremium: "no",
                value: 1,
              },
            });
            dispatch({
              type: "tempDomainAdd",
              payload: [
                {
                  name: "domainregister",
                  domainName: domainName,
                  price: DomainResults.price,
                  ispremium: "no",
                  value: 1,
                },
              ],
            });
            break;
          }
        }
      }
    }
    const timeId = setTimeout(() => {
      // After 3 seconds set the show value to false
      setALoading(false);
    }, 1000);

    return () => {
      clearTimeout(timeId);
    };
  }, []);

  const loopWithSlice = (start, end) => {
    const slicedPosts = DomainResults?.suggestions?.slice(start, end);
    arrayForHoldingDomains = [...arrayForHoldingDomains, ...slicedPosts];
    setPostsToShow(arrayForHoldingDomains);
  };

  const handleShowMorePosts = () => {
    loopWithSlice(next, next + postsPerPage);
    setNext(next + postsPerPage);
  };

  const handelsrAddDomin = (e, index) => {
    e.preventDefault();
    let chek = document?.getElementById("idchk")?.checked;
    let newPrice = index?.price;

    if (index?.searchedDomain?.includes(".pk")) {
      dispatch({
        type: "addingCycleDomain",
        payload: {
          domainName: index.searchedDomain,
          packageCycle: "biennially",
        },
      });
    } else {
      dispatch({
        type: "addingCycleDomain",
        payload: {
          domainName: index.searchedDomain,
          packageCycle: "annually",
        },
      });
    }

    if (chek) {
      dispatch({
        type: "domainSearchCart",
        payload: {
          name: "domainregister",
          domainName: index.searchedDomain,
          price: newPrice,
          ispremium: "no",
          // domainAddonTitle: "Privacy Protection",
          // domainAddon: index.domainAddon[0],
          value: 1,
        },
      });
      dispatch({
        type: "tempDomainAdd",
        payload: [
          {
            name: "domainregister",
            domainName: index.searchedDomain,
            price: newPrice,
            ispremium: "no",
            // domainAddonTitle: "Privacy Protection",
            // domainAddon: index.domainAddon[0],
            value: 1,
          },
        ],
      });
    } else {
      dispatch({
        type: "domainSearchCart",
        payload: {
          name: "domainregister",
          domainName: index.searchedDomain,
          price: newPrice,
          ispremium: "no",

          value: 1,
        },
      });
      dispatch({
        type: "tempDomainAdd",
        payload: [
          {
            name: "domainregister",
            domainName: index.searchedDomain,
            price: newPrice,
            ispremium: "no",

            value: 1,
          },
        ],
      });
    }

    //navigating to cart

    // navigate("/cart");
  };

  const handelAddDomin = (e, index, els) => {
    e.preventDefault();
    let chek = document?.getElementById("idchk")?.checked;
    let newPrice = DomainResults?.suggestions[index]?.prices;
    let ali = DomainResults?.suggestions[index];
    let pkDomainCheck = els?.domainName?.includes(".pk");

    if (pkDomainCheck) {
      dispatch({
        type: "addingCycleDomain",
        payload: {
          domainName: els.domainName,
          packageCycle: "biennially",
        },
      });

      dispatch({
        type: "domainSearchCart",
        payload: {
          name: "domainregister",
          domainName: els.domainName,
          price: newPrice,
          ispremium: "no",

          value: 1,
        },
      });
    } else {
      dispatch({
        type: "addingCycleDomain",
        payload: {
          domainName: els.domainName,
          packageCycle: "annually",
        },
      });
      dispatch({
        type: "tempdomainSearchCart",
        payload: {
          name: "domainregister",
          domainName: els.domainName,
          price: newPrice,
          ispremium: "no",
          value: 1,
          // domainAddonTitle: "Privacy Protection",
          // domainAddon: ali.domainAddon[0],
        },
      });
      dispatch({
        type: "tempdomainaddingCycle",
        payload: {
          domainName: els.domainName,
          packageCycle: "annually",
        },
      });
      if (chek) {
        dispatch({
          type: "domainSearchCart",
          payload: {
            name: "domainregister",
            domainName: els.domainName,
            price: newPrice,
            ispremium: "no",
            // domainAddonTitle: "Privacy Protection",
            // domainAddon: ali.domainAddon[0],
            value: 1,
          },
        });
      } else {
        dispatch({
          type: "domainSearchCart",
          payload: {
            name: "domainregister",
            domainName: els.domainName,
            price: newPrice,
            ispremium: "no",

            value: 1,
          },
        });
      }

      dispatch({
        type: "tempDomainAdd",
        payload: [
          {
            name: "domainregister",
            domainName: els.domainName,
            price: newPrice,
            ispremium: "no",
            // domainAddonTitle: "Privacy Protection",
            // domainAddon: els.domainAddon[0],
            value: 1,
          },
        ],
      });
    }

    //navigating to cart

    // navigate("/cart");
  };

  const addedtoCartCheck = (els) => {
    // const dexist = domainSearchCart?.filter(
    //   (dm) => dm?.domainName === els?.domainName
    // );

    // if (dexist && dexist.length > 0) {
    //   return "Added to cart";
    // } else {
    //   return "Add to cart";
    //ddd }
    const dexist =
      domainSearchCart &&
      domainSearchCart?.filter((dm) => dm?.domainName === els?.domainName);
    const pexist =
      packageCart &&
      packageCart?.filter(
        (pm) =>
          pm?.freedomain && pm.freedomain[0]?.domainName === els?.domainName
      );

    if ((dexist && dexist?.length > 0) || (pexist && pexist?.length > 0)) {
      //return "Added to cart";
      return "Added to cart";
    } else {
      //return "Add to cart";
      return "Add to cart";
    }
  };
  const addedtoCartChecksearch = (els) => {
    const dexist =
      domainSearchCart &&
      domainSearchCart?.filter((dm) => dm?.domainName === els?.searchedDomain);
    const pexist =
      packageCart &&
      packageCart?.filter(
        (pm) =>
          pm?.freedomain &&
          pm?.freedomain[0]?.domainName === els?.searchedDomain
      );

    if ((dexist && dexist?.length > 0) || (pexist && pexist?.length > 0)) {
      //return "Added to cart";
      return 1;
    } else {
      //return "Add to cart";
      return 0;
    }
  };
  const addedtoCartidChk = (domain) => {
    const dexist = domainSearchCart?.filter((dm) => dm?.domainName === domain);

    //if (dexist && dexist[0].domainAddon.length > 0) {
    return (
      <>
        {" "}
        <input
          id="idchk"
          type="checkbox"
          name="newchk"
          className={`${idShow} domainCheckMobile `}
          defaultChecked={true}
          onClick={(e) => handelAddDominId(e, domain, DomainResults)}
        />
      </>
    );
  };
  const handelAddDominId = (e, domain, domainSearchCart) => {
    let chek = document?.getElementById("idchk")?.checked;
    if (chek) {
    } else {
    }
  };

  const handelGetDomain = (e) => {
    e.preventDefault();

    if (DomainResults?.ispremium === "yes") {
      const domainName = DomainResults?.searchedDomain;
      const domainPrice = DomainResults?.price[0];
      Object.assign(domainPrice, {
        biennially: DomainResults.price[0].annually * 2,
        triennially: DomainResults.price[0].annually * 3,
        fourlyprice: DomainResults.price[0].annually * 4,
        fivelyprice: DomainResults.price[0].annually * 5,
        sixlyprice: DomainResults.price[0].annually * 6,
        sevenlyprice: DomainResults.price[0].annually * 7,
        eightlyprice: DomainResults.price[0].annually * 8,
        ninelyprice: DomainResults.price[0].annually * 9,
        tenlyprice: DomainResults.price[0].annually * 10,
      });
      //

      dispatch({
        type: "domainSearchCart",
        payload: {
          name: "domainregister",
          domainName: domainName,
          price: domainPrice,
          ispremium: "yes",
          value: 1,
        },
      });
      dispatch({
        type: "tempdomainSearchCart",
        payload: [
          {
            name: "domainregister",
            domainName: domainName,
            price: domainPrice,
            ispremium: "no",
            value: 1,
          },
        ],
      });
      // navigate("/cart");
    } else {
      const domainName = DomainResults?.searchedDomain;
      let domainAddingfs = DomainResults?.price?.filter(
        (delpri) => delpri?.currency === currencyName?.api_id
      );
      let newPrice = DomainResults?.price.filter(
        (el) => el?.currency === currencyName?.api_id
      );

      const check = [domainAddingfs[0]];

      //checking start from hererere

      for (const ob of check) {
        if (ob?.monthly > 0) {
          dispatch({
            type: "addingCycleDomain",
            payload: {
              domainName: domainName,
              packageCycle: "montly",
            },
          });
          dispatch({
            type: "domainSearchCart",
            payload: {
              name: "domainregister",
              domainName: domainName,
              price: DomainResults.price,
              ispremium: "no",
              value: 1,
            },
          });
          dispatch({
            type: "tempdomainSearchCart",
            payload: [
              {
                name: "domainregister",
                domainName: domainName,
                price: DomainResults.price,
                ispremium: "no",
                value: 1,
              },
            ],
          });
          break;
        }
        if (ob?.annually > 0) {
          dispatch({
            type: "addingCycleDomain",
            payload: {
              domainName: domainName,
              packageCycle: "annually",
            },
          });
          dispatch({
            type: "domainSearchCart",
            payload: {
              name: "domainregister",
              domainName: domainName,
              price: DomainResults.price,
              ispremium: "no",
              value: 1,
            },
          });
          dispatch({
            type: "tempdomainSearchCart",
            payload: [
              {
                name: "domainregister",
                domainName: domainName,
                price: DomainResults.price,
                ispremium: "no",
                value: 1,
              },
            ],
          });
          break;
        }
        if (ob?.biennially > 0) {
          dispatch({
            type: "addingCycleDomain",
            payload: {
              domainName: domainName,
              packageCycle: "biennially",
            },
          });
          dispatch({
            type: "domainSearchCart",
            payload: {
              name: "domainregister",
              domainName: domainName,
              price: DomainResults.price,
              ispremium: "no",
              value: 1,
            },
          });
          dispatch({
            type: "tempdomainSearchCart",
            payload: [
              {
                name: "domainregister",
                domainName: domainName,
                price: DomainResults.price,
                ispremium: "no",
                value: 1,
              },
            ],
          });
          break;
        }
      }

      //  navigate("/cart");
    }
  };

  const searchResults = (e) => {
    document.getElementById("top_bar_scroll").scrollIntoView();
    setScrollstate(!scrolState);
    e.preventDefault();
    if (e.type === "click") {
      document.getElementById("check_search").blur();
      window["scrollTo"]({ top: 0, behavior: "smooth" });
    }
    setLoading(true);
    setDomainResults(null);
    setDomainFindName(null);
    setPostsToShow([]);
    setVisible(9);
    arrayForHoldingDomains = [];
    let spp = domainFindName?.split(".");
    let domainwitt;
    //location.state.name

    if (spp?.length === 1) {
      domainwitt = spp[0] + ".com";
    } else {
      domainwitt = domainFindName;
    }
    setDomainFindName(domainwitt);
    navigate("/domain", { state: { name: domainwitt } });
    if ("scrollRestoration" in window.history) {
      window.scrollTo(0, 0);
    }
    //window.history.replaceState({}, domainwitt)

    DomainSearchApi(domainwitt, tlds, "domainregister").then((response) => {
      const searchedDomain = response.searchedDomain.includes(".pk");
      // console.log("ispk:",searchedDomain)
      setIsPk(searchedDomain);
      setDomainResults(response);
      localStorage.removeItem("f");
      arrayForHoldingDomains = [];
      const slicedPosts = response?.suggestions?.slice(0, postsPerPage);
      arrayForHoldingDomains = [...arrayForHoldingDomains, ...slicedPosts];
      setPostsToShow(response?.suggestions);

      //if domain is premium

      if (response?.ispremium === "yes") {
        if (response?.available === 1) {
          setTimeout(() => {
            dispatch({
              type: "addingCycleDomain",
              payload: {
                domainName: response.searchedDomain,
                packageCycle: "annually",
              },
            });
            dispatch({
              type: "domainSearchCart",
              payload: {
                name: "domainregister",
                domainName: response.searchedDomain,
                price: response.price,
                ispremium: response.ispremium,
                value: 1,
                // domainAddonTitle: "Privacy Protection",
                // domainAddon: response.domainAddon[0],
              },
            });
          }, 1000);
          setLoading(false);
        }
      } else {
        if (response.available === 8) {
          setLoading(false);
        }
        if (response.available === 5) {
          setLoading(false);
        }
        if (response.available === 2) {
          setLoading(false);
        }
        if (currencyName.api_id === 3) {
          if (response.available === 0) {
            setLoading(false);
          } else {
            let priceGet = response?.price[0];

            if (priceGet.annually === "") {
              if (response?.available === 1) {
                setTimeout(() => {
                  dispatch({
                    type: "addingCycleDomain",
                    payload: {
                      domainName: response.searchedDomain,
                      packageCycle: "biennially",
                    },
                  });
                  dispatch({
                    type: "domainSearchCart",
                    payload: {
                      name: "domainregister",
                      domainName: response.searchedDomain,
                      price: response.price,
                      ispremium: response.ispremium,
                      value: 1,
                    },
                  });
                  dispatch({
                    type: "tempdomainSearchCart",
                    payload: {
                      name: "domainregister",
                      domainName: response.searchedDomain,
                      price: response.price,
                      ispremium: response.ispremium,
                      // domainAddonTitle: "Privacy Protection",
                      // domainAddon: response.domainAddon[0],
                      value: 1,
                    },
                  });
                  dispatch({
                    type: "tempdomainaddingCycle",
                    payload: {
                      domainName: response.searchedDomain,
                      packageCycle: "annually",
                    },
                  });
                }, 1000);
                setLoading(false);
              }
            } else {
              if (response?.available === 1) {
                setTimeout(() => {
                  dispatch({
                    type: "addingCycleDomain",
                    payload: {
                      domainName: response.searchedDomain,
                      packageCycle: "annually",
                    },
                  });
                  dispatch({
                    type: "domainSearchCart",
                    payload: {
                      name: "domainregister",
                      domainName: response.searchedDomain,
                      price: response.price,
                      ispremium: response.ispremium,
                      value: 1,
                      // domainAddonTitle: "Privacy Protection",
                      // domainAddon: response.domainAddon[0],
                    },
                  });
                  dispatch({
                    type: "tempdomainSearchCart",
                    payload: {
                      name: "domainregister",
                      domainName: response.searchedDomain,
                      price: response.price,
                      ispremium: response.ispremium,
                      // domainAddonTitle: "Privacy Protection",
                      // domainAddon: response.domainAddon[0],
                      value: 1,
                    },
                  });
                  dispatch({
                    type: "tempdomainaddingCycle",
                    payload: {
                      domainName: response.searchedDomain,
                      packageCycle: "annually",
                    },
                  });
                }, 1000);
                setLoading(false);
              }
            }
          }
        } else {
          if (response.available === 0) {
            setLoading(false);
          } else {
            let priceGet = response?.price[1];

            if (priceGet.annually === "") {
              if (response?.available === 1) {
                setTimeout(() => {
                  dispatch({
                    type: "addingCycleDomain",
                    payload: {
                      domainName: response.searchedDomain,
                      packageCycle: "biennially",
                    },
                  });
                  dispatch({
                    type: "domainSearchCart",
                    payload: {
                      name: "domainregister",
                      domainName: response.searchedDomain,
                      price: response.price,
                      ispremium: response.ispremium,
                      // domainAddonTitle: "Privacy Protection",
                      // domainAddon: response.domainAddon[0],
                      value: 1,
                    },
                  });
                  dispatch({
                    type: "tempdomainSearchCart",
                    payload: {
                      name: "domainregister",
                      domainName: response.searchedDomain,
                      price: response.price,
                      ispremium: response.ispremium,
                      // domainAddonTitle: "Privacy Protection",
                      // domainAddon: response.domainAddon[0],
                      value: 1,
                    },
                  });
                  dispatch({
                    type: "tempdomainaddingCycle",
                    payload: {
                      domainName: response.searchedDomain,
                      packageCycle: "annually",
                    },
                  });
                }, 1000);
                setLoading(false);
              }
            } else {
              if (response?.available === 1) {
                setTimeout(() => {
                  dispatch({
                    type: "addingCycleDomain",
                    payload: {
                      domainName: response.searchedDomain,
                      packageCycle: "annually",
                    },
                  });
                  dispatch({
                    type: "domainSearchCart",
                    payload: {
                      name: "domainregister",
                      domainName: response.searchedDomain,
                      price: response.price,
                      ispremium: response.ispremium,
                      value: 1,
                      // domainAddonTitle: "Privacy Protection",
                      // domainAddon: response.domainAddon[0],
                    },
                  });
                  dispatch({
                    type: "tempdomainSearchCart",
                    payload: {
                      name: "domainregister",
                      domainName: response.searchedDomain,
                      price: response.price,
                      ispremium: response.ispremium,
                      // domainAddonTitle: "Privacy Protection",
                      // domainAddon: response.domainAddon[0],
                      value: 1,
                    },
                  });
                  dispatch({
                    type: "tempdomainaddingCycle",
                    payload: {
                      domainName: response.searchedDomain,
                      packageCycle: "annually",
                    },
                  });
                }, 1000);
                setLoading(false);
              }
            }
          }
          setLoading(false);
        }

        // if (response?.searchedDomain?.includes(".pk")) {
        //   //if domain is avaiable
        //   if (response?.available === 1) {
        //     setTimeout(() => {
        //       dispatch({
        //         type: "addingCycleDomain",
        //         payload: {
        //           domainName: response.searchedDomain,
        //           packageCycle: "biennially",
        //         },
        //       });
        //       dispatch({
        //         type: "domainSearchCart",
        //         payload: {
        //           name: "domainregister",
        //           domainName: response.searchedDomain,
        //           price: response.price,
        //           ispremium: response.ispremium,
        //           value: 1,
        //         },
        //       });
        //     }, 1000);
        //     setLoading(false);
        //   }
        // } else {
        //   //if domain is not pk and avaible too
        //   if (response?.available === 1) {
        //     setTimeout(() => {
        //       dispatch({
        //         type: "addingCycleDomain",
        //         payload: {
        //           domainName: response.searchedDomain,
        //           packageCycle: "annually",
        //         },
        //       });
        //       dispatch({
        //         type: "domainSearchCart",
        //         payload: {
        //           name: "domainregister",
        //           domainName: response.searchedDomain,
        //           price: response.price,
        //           ispremium: response.ispremium,
        //           domainAddonTitle: "Privacy Protection",
        //           domainAddon: response.domainAddon[0],
        //           value: 1,
        //         },
        //       });
        //       dispatch({
        //         type: "tempdomainSearchCart",
        //         payload: {
        //           name: "domainregister",
        //           domainName: response.searchedDomain,
        //           price: response.price,
        //           ispremium: response.ispremium,
        //           domainAddonTitle: "Privacy Protection",
        //           domainAddon: response.domainAddon[0],
        //           value: 1,
        //         },
        //       });
        //       dispatch({
        //         type: "tempdomainaddingCycle",
        //         payload: {
        //           domainName: response.searchedDomain,
        //           packageCycle: "annually",
        //         },
        //       });
        //     }, 1000);
        //     setLoading(false);
        //   }
        // }
      }
      //setLoading(false);
    });
    //setLoading(false);
  };

  const formatter = new Intl.NumberFormat("en-US", {
    minimumFractionDigits: 2,
    maximumFractionDigits: 2,
  });

  return (
    <>
      <ScrollToTop />
      <section style={{ margin: "0" }} className="psty">
        <div className="container">
          <div className="row">
            <div className="col-lg-12">
              <div className="selection_tab search_domain_div">
                <form onSubmit={(e) => searchResults(e)}>
                  <div className="input-group mb-5 mb-sm-5">
                    <input
                      type="search"
                      name="search"
                      id="check_search"
                      className="form-control"
                      placeholder="Search your domain here..."
                      aria-labelledby="search-domain"
                      aria-label="Search your domain here"
                      style={{ lineHeight: "1.5", borderRadius: 5 }}
                      value={domainFindName}
                      onChange={(e) => setDomainFindName(e.target.value)}
                    />
                    <button
                      style={{
                        height: 50,
                        position: "absolute",
                        top: 5,
                        right: 5,
                      }}
                      className="checkdomian input-group-text"
                      disabled={domainFindName === ""}
                      onClick={(e) => searchResults(e)}
                      type="submit"
                    >
                      <i className="fa fa-search" />
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>

        <div className="container">
          <div className="row mb-5">
            <CartOrder currentURL={currentURL} name={packageBypass} />
            <div className="col-lg-7 col-xl-8 col-xxl-8 ">
              {loading ? (
                <>
                  <div
                    className={
                      !loading
                        ? "searched-result box-border mb-2"
                        : "searched-result box-border mb-2 newcolorss"
                    }
                  >
                    <h6 className="result-message p-2 p-sm-1 p-md-4 p-lg-4 ">
                      <Skeleton />
                    </h6>
                  </div>
                </>
              ) : (
                <>
                  {DomainResults && DomainResults?.available === 1 ? (
                    <>
                      <div className="searched-result box-border mb-2">
                        <h6 className="result-message p-2 p-sm-1 p-md-4 p-lg-4 ">
                          Congratulations! Your domain{" "}
                          <b>
                            {DomainResults && DomainResults.searchedDomain}{" "}
                          </b>{" "}
                          {DomainResults.ispremium === "yes" ? (
                            <>Premium</>
                          ) : (
                            ""
                          )}{" "}
                          is available
                        </h6>
                        <div>
                          <span className="d-flex justify-content-between  pt-4 pb-4 px-2 px-sm-1 px-md-4 px-lg-4 ">
                            <h3 className="cart-heading3">
                              {DomainResults && DomainResults?.searchedDomain}
                            </h3>

                            <button
                              className={
                                addedtoCartChecksearch(DomainResults) === 1
                                  ? "button notAllowed"
                                  : "button "
                              }
                              onClick={(e) =>
                                handelsrAddDomin(e, DomainResults)
                              }
                            >
                              {aloading ? (
                                <>
                                  <span className="button notAllowed">
                                    Added to cart
                                  </span>{" "}
                                </>
                              ) : addedtoCartChecksearch(DomainResults) == 1 ? (
                                "Added to cart"
                              ) : (
                                "Add to cart"
                              )}{" "}
                              {/* {addedtoCartChecksearch(DomainResults)==2 || addedtoCartChecksearch(DomainResults)==1 ?"Added to cart ":"Add to cart"} */}
                              &nbsp;
                            </button>
                          </span>
                        </div>
                      </div>

                      {/* <span
                        className={`${idShow} d-flex   pt-4 pb-4 px-2 px-sm-1 px-md-1 px-lg-1`}
                      >
                        {DomainTld(DomainResults?.searchedDomain).includes(
                          "pk"
                        ) ? (
                          ""
                        ) : (
                          <>
                            {addedtoCartidChk(DomainResults?.searchedDomain)}{" "}
                            {currencyName.api_id === 3 ? (
                              <p className={`${idShow} domaiPrivacy `}>
                                Add Domain Privacy + Protection to each domain
                                for {currencyName.prefix}
                                {
                                  DomainResults.domainAddon[0]
                                    ?.find((el) => el.currency == 3)
                                    ?.ssetupfee.split(".")[0]
                                }{" "}
                                /yr.
                              </p>
                            ) : (
                              <p className={`${idShow} domaiPrivacy `}>
                                Add Domain Privacy + Protection to each domain
                                for {currencyName?.prefix} {""}
                                {
                                  DomainResults.domainAddon[0]?.find(
                                    (el) => el?.currency == 1
                                  )?.ssetupfee
                                }{" "}
                                /yr.
                              </p>
                            )}
                          </>
                        )}
                      </span> */}
                    </>
                  ) : null}
                  {DomainResults && DomainResults?.available === 0 ? (
                    <>
                      <div className="searched-result box-border mb-2">
                        <h6 className="result-message p-2 p-sm-1 p-md-4 p-lg-4 ">
                          Your Desired Domain{" "}
                          <b>
                            "{DomainResults && DomainResults.searchedDomain}"{" "}
                          </b>{" "}
                          is already registered.
                        </h6>
                      </div>
                      {/* <span
                        className={`${idShow} d-flex   pt-4 pb-4 px-2 px-sm-1 px-md-1 px-lg-1`}
                      >
                        {DomainTld(DomainResults?.searchedDomain).includes(
                          "pk"
                        ) ? (
                          ""
                        ) : (
                          <>
                            {addedtoCartidChk(DomainResults?.searchedDomain)}{" "}
                            {currencyName.api_id === 3 ? (
                              <p className={`${idShow} domaiPrivacy `}>
                                Add Domain Privacy + Protection to each domain
                                for {currencyName.prefix}
                                {
                                  DomainResults.domainAddon[0]
                                    ?.find((el) => el.currency == 3)
                                    ?.ssetupfee.split(".")[0]
                                }{" "}
                                /yr.
                              </p>
                            ) : (
                              <p className={`${idShow} domaiPrivacy `}>
                                Add Domain Privacy + Protection to each domain
                                for {currencyName?.prefix} {""}
                                {
                                  DomainResults.domainAddon[0]?.find(
                                    (el) => el?.currency == 1
                                  )?.ssetupfee
                                }{" "}
                                /yr.
                              </p>
                            )}
                          </>
                        )}
                      </span> */}
                    </>
                  ) : null}
                  {DomainResults && DomainResults?.available === 2 ? (
                    <>
                      <div className="searched-result box-border mb-2">
                        <h6 className="result-message p-2 p-sm-1 p-md-4 p-lg-4 ">
                          PLEASE SEARCH A VALID TLD
                        </h6>
                      </div>
                    </>
                  ) : null}
                  {DomainResults && DomainResults?.available === 4 ? (
                    <>
                      <div className="searched-result box-border mb-2">
                        <h6 className="result-message p-2 p-sm-1 p-md-4 p-lg-4 ">
                          Please search valid domain consists of 60 characters.
                        </h6>
                      </div>
                    </>
                  ) : null}
                  {DomainResults && DomainResults?.available === 5 ? (
                    <>
                      <div className="searched-result box-border mb-2">
                        <h6 className="result-message p-2 p-sm-1 p-md-4 p-lg-4 ">
                          PLEASE SEARCH VALID DOMAIN NAME/TLD. YOU CAN ONLY USE
                          "-" IN DOMAIN NAME.
                        </h6>
                      </div>
                    </>
                  ) : null}
                  {DomainResults && DomainResults?.available === 8 ? (
                    <>
                      <div className="searched-result box-border mb-2">
                        <h6 className="result-message p-2 p-sm-1 p-md-4 p-lg-4 ">
                          .PK domain can not be ordered with less than 4
                          characters. However you can register .COM.PK with a
                          single character too.
                        </h6>
                      </div>
                    </>
                  ) : null}
                </>
              )}
              {isPk ? (
                <>
                  {DomainResults &&
                  DomainResults?.searchedDomain?.includes(".edu.pk") ? (
                    <>
                      <div className="box-border box-border-sm mb-5 p-xs-2 p-sm-1 p-md-4 p-lg-4 ">
                        <h5 className="mb-3">
                          To register a .EDU.PK domain, following documents are
                          required to activate the domain:
                        </h5>
                        <ul style={{ listStyle: "disc", padding: "20px" }}>
                          <li>
                            A letter of authorization signed by the
                            Principal/Head of the institute on the institute's
                            original stationary letterhead (no photocopies or
                            laser printed letterheads).
                          </li>
                          <li>
                            A proof/claim of being a registered and active
                            educational institute (i-e copy of registration
                            documents with a government education body etc).
                          </li>
                          <li>
                            A CNIC copy of the authority signing the
                            authorization letter.
                          </li>
                        </ul>
                      </div>
                    </>
                  ) : null}
                </>
              ) : (
                <>
                  {loading ? (
                    <div className="box-border box-border-sm mb-5 p-xs-2 p-sm-1 p-md-4 p-lg-4 ">
                      <table className="table">
                        <>
                          <tbody>
                            <tr>
                              <td className="table-items">
                                <Skeleton
                                  className="adjust-width-domainName"
                                  width={200}
                                />
                              </td>
                              <td className="text-end table-items">
                                <Skeleton
                                  className="adjust-width"
                                  width={200}
                                />
                              </td>
                              <td className="text-end">
                                <Skeleton
                                  className="adjust-width"
                                  width={200}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td className="table-items">
                                <Skeleton
                                  className="adjust-width-domainName"
                                  width={200}
                                />
                              </td>
                              <td className="text-end table-items">
                                <Skeleton
                                  className="adjust-width"
                                  width={200}
                                />
                              </td>
                              <td className="text-end">
                                <Skeleton
                                  className="adjust-width"
                                  width={200}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td className="table-items">
                                <Skeleton
                                  className="adjust-width-domainName"
                                  width={200}
                                />
                              </td>
                              <td className="text-end table-items">
                                <Skeleton
                                  className="adjust-width"
                                  width={200}
                                />
                              </td>
                              <td className="text-end">
                                <Skeleton
                                  className="adjust-width"
                                  width={200}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td className="table-items">
                                <Skeleton
                                  className="adjust-width-domainName"
                                  width={200}
                                />
                              </td>
                              <td className="text-end table-items">
                                <Skeleton
                                  className="adjust-width"
                                  width={200}
                                />
                              </td>
                              <td className="text-end">
                                <Skeleton
                                  className="adjust-width"
                                  width={200}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td className="table-items">
                                <Skeleton
                                  className="adjust-width-domainName"
                                  width={200}
                                />
                              </td>
                              <td className="text-end table-items">
                                <Skeleton
                                  className="adjust-width"
                                  width={200}
                                />
                              </td>
                              <td className="text-end">
                                <Skeleton
                                  className="adjust-width"
                                  width={200}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td className="table-items">
                                <Skeleton
                                  className="adjust-width-domainName"
                                  width={200}
                                />
                              </td>
                              <td className="text-end table-items">
                                <Skeleton
                                  className="adjust-width"
                                  width={200}
                                />
                              </td>
                              <td className="text-end">
                                <Skeleton
                                  className="adjust-width"
                                  width={200}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td className="table-items">
                                <Skeleton
                                  className="adjust-width-domainName"
                                  width={200}
                                />
                              </td>
                              <td className="text-end table-items">
                                <Skeleton
                                  className="adjust-width"
                                  width={200}
                                />
                              </td>
                              <td className="text-end">
                                <Skeleton
                                  className="adjust-width"
                                  width={200}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td className="table-items">
                                <Skeleton
                                  className="adjust-width-domainName"
                                  width={200}
                                />
                              </td>
                              <td className="text-end table-items">
                                <Skeleton
                                  className="adjust-width"
                                  width={200}
                                />
                              </td>
                              <td className="text-end">
                                <Skeleton
                                  className="adjust-width"
                                  width={200}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td className="table-items">
                                <Skeleton
                                  className="adjust-width-domainName"
                                  width={200}
                                />
                              </td>
                              <td className="text-end table-items">
                                <Skeleton
                                  className="adjust-width"
                                  width={200}
                                />
                              </td>
                              <td className="text-end">
                                <Skeleton
                                  className="adjust-width"
                                  width={200}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td className="table-items">
                                <Skeleton
                                  className="adjust-width-domainName"
                                  width={200}
                                />
                              </td>
                              <td className="text-end table-items">
                                <Skeleton
                                  className="adjust-width"
                                  width={200}
                                />
                              </td>
                              <td className="text-end">
                                <Skeleton
                                  className="adjust-width"
                                  width={200}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td className="table-items">
                                <Skeleton
                                  className="adjust-width-domainName"
                                  width={200}
                                />
                              </td>
                              <td className="text-end table-items">
                                <Skeleton
                                  className="adjust-width"
                                  width={200}
                                />
                              </td>
                              <td className="text-end">
                                <Skeleton
                                  className="adjust-width"
                                  width={200}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td className="table-items">
                                <Skeleton
                                  className="adjust-width-domainName"
                                  width={200}
                                />
                              </td>
                              <td className="text-end table-items">
                                <Skeleton
                                  className="adjust-width"
                                  width={200}
                                />
                              </td>
                              <td className="text-end">
                                <Skeleton
                                  className="adjust-width"
                                  width={200}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td className="table-items">
                                <Skeleton
                                  className="adjust-width-domainName"
                                  width={200}
                                />
                              </td>
                              <td className="text-end table-items">
                                <Skeleton
                                  className="adjust-width"
                                  width={200}
                                />
                              </td>
                              <td className="text-end">
                                <Skeleton
                                  className="adjust-width"
                                  width={200}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td className="table-items">
                                <Skeleton
                                  className="adjust-width-domainName"
                                  width={200}
                                />
                              </td>
                              <td className="text-end table-items">
                                <Skeleton
                                  className="adjust-width"
                                  width={200}
                                />
                              </td>
                              <td className="text-end">
                                <Skeleton
                                  className="adjust-width"
                                  width={200}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td className="table-items">
                                <Skeleton
                                  className="adjust-width-domainName"
                                  width={200}
                                />
                              </td>
                              <td className="text-end table-items">
                                <Skeleton
                                  className="adjust-width"
                                  width={200}
                                />
                              </td>
                              <td className="text-end">
                                <Skeleton
                                  className="adjust-width"
                                  width={200}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td className="table-items">
                                <Skeleton
                                  className="adjust-width-domainName"
                                  width={200}
                                />
                              </td>
                              <td className="text-end table-items">
                                <Skeleton
                                  className="adjust-width"
                                  width={200}
                                />
                              </td>
                              <td className="text-end">
                                <Skeleton
                                  className="adjust-width"
                                  width={200}
                                />
                              </td>
                            </tr>
                            <tr>
                              <td className="table-items">
                                <Skeleton
                                  className="adjust-width-domainName"
                                  width={200}
                                />
                              </td>
                              <td className="text-end table-items">
                                <Skeleton
                                  className="adjust-width"
                                  width={200}
                                />
                              </td>
                              <td className="text-end">
                                <Skeleton
                                  className="adjust-width"
                                  width={200}
                                />
                              </td>
                            </tr>
                          </tbody>
                        </>
                      </table>
                    </div>
                  ) : null}
                  {(DomainResults && DomainResults?.available === 1) ||
                  DomainResults?.available === 0 ? (
                    <div className="box-border box-border-sm mb-5 p-xs-2 p-sm-1 p-md-4 p-lg-4 ">
                      <h4 className="table-heading pb-4  text-uppercase">
                        More Suggestions
                      </h4>
                      <table className="table">
                        {loading ? (
                          <>
                            <tbody>
                              <tr>
                                <td className="table-items">
                                  <Skeleton
                                    className="adjust-width-domainName"
                                    width={200}
                                  />
                                </td>
                                <td className="text-end table-items">
                                  <Skeleton
                                    className="adjust-width"
                                    width={200}
                                  />
                                </td>
                                <td className="text-end">
                                  <Skeleton
                                    className="adjust-width"
                                    width={200}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td className="table-items">
                                  <Skeleton
                                    className="adjust-width-domainName"
                                    width={200}
                                  />
                                </td>
                                <td className="text-end table-items">
                                  <Skeleton
                                    className="adjust-width"
                                    width={200}
                                  />
                                </td>
                                <td className="text-end">
                                  <Skeleton
                                    className="adjust-width"
                                    width={200}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td className="table-items">
                                  <Skeleton
                                    className="adjust-width-domainName"
                                    width={200}
                                  />
                                </td>
                                <td className="text-end table-items">
                                  <Skeleton
                                    className="adjust-width"
                                    width={200}
                                  />
                                </td>
                                <td className="text-end">
                                  <Skeleton
                                    className="adjust-width"
                                    width={200}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td className="table-items">
                                  <Skeleton
                                    className="adjust-width-domainName"
                                    width={200}
                                  />
                                </td>
                                <td className="text-end table-items">
                                  <Skeleton
                                    className="adjust-width"
                                    width={200}
                                  />
                                </td>
                                <td className="text-end">
                                  <Skeleton
                                    className="adjust-width"
                                    width={200}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td className="table-items">
                                  <Skeleton
                                    className="adjust-width-domainName"
                                    width={200}
                                  />
                                </td>
                                <td className="text-end table-items">
                                  <Skeleton
                                    className="adjust-width"
                                    width={200}
                                  />
                                </td>
                                <td className="text-end">
                                  <Skeleton
                                    className="adjust-width"
                                    width={200}
                                  />
                                </td>
                              </tr>
                              <tr>
                                <td className="table-items">
                                  <Skeleton
                                    className="adjust-width-domainName"
                                    width={200}
                                  />
                                </td>
                                <td className="text-end table-items">
                                  <Skeleton
                                    className="adjust-width"
                                    width={200}
                                  />
                                </td>
                                <td className="text-end">
                                  <Skeleton
                                    className="adjust-width"
                                    width={200}
                                  />
                                </td>
                              </tr>
                            </tbody>
                          </>
                        ) : (
                          <tbody>
                            <>
                              {DomainResults &&
                              DomainResults.length == 0 ? null : (
                                <>
                                  {postsToShow
                                    .slice(0, visible)
                                    .map((els, index) => (
                                      <React.Fragment key={index}>
                                        <tr key={els.domainName}>
                                          <td className="table-items">
                                            {" "}
                                            {els.domainName}
                                          </td>
                                          <td className="text-end table-items">
                                            {currencyName.api_id === 3 ? (
                                              <>
                                                {els &&
                                                  els.prices
                                                    .filter(
                                                      (elpri) =>
                                                        elpri?.currency ===
                                                        currencyName?.api_id
                                                    )
                                                    .map((elssss) => (
                                                      <>
                                                        {elssss &&
                                                        elssss.tld.includes(
                                                          "pk"
                                                        ) ? (
                                                          <>
                                                            {
                                                              currencyName?.prefix
                                                            }
                                                            {
                                                              formatter
                                                                .format(
                                                                  elssss.biennially.split(
                                                                    "."
                                                                  )[0]
                                                                )
                                                                .split(".")[0]
                                                            }
                                                          </>
                                                        ) : (
                                                          <>
                                                            {
                                                              currencyName.prefix
                                                            }
                                                            {
                                                              formatter
                                                                .format(
                                                                  elssss &&
                                                                    elssss.annually.split(
                                                                      "."
                                                                    )[0]
                                                                )
                                                                .split(".")[0]
                                                            }
                                                          </>
                                                        )}
                                                      </>
                                                    ))}
                                              </>
                                            ) : (
                                              <>
                                                {els &&
                                                  els.prices
                                                    .filter(
                                                      (elpri) =>
                                                        elpri.currency ===
                                                        currencyName.api_id
                                                    )
                                                    .map((elssss) => (
                                                      <>
                                                        {elssss &&
                                                        elssss.tld.includes(
                                                          "pk"
                                                        ) ? (
                                                          <>
                                                            {
                                                              currencyName.prefix
                                                            }
                                                            {elssss.biennially}
                                                          </>
                                                        ) : (
                                                          <>
                                                            {
                                                              currencyName.prefix
                                                            }
                                                            {elssss.annually}
                                                          </>
                                                        )}
                                                      </>
                                                    ))}
                                              </>
                                            )}
                                          </td>
                                          <td className="text-end">
                                            <button
                                              className={
                                                addedtoCartCheck(els) ===
                                                "Added to cart"
                                                  ? "button d-none d-sm-none d-md-block d-lg-block float-end notAllowed"
                                                  : "button d-none d-sm-none d-md-block d-lg-block float-end "
                                              }
                                              onClick={(e) =>
                                                handelAddDomin(e, index, els)
                                              }
                                            >
                                              {addedtoCartCheck(els)} &nbsp;
                                              <i className="fa fa-arrow-right"></i>
                                            </button>
                                            <button
                                              className={
                                                addedtoCartCheck(els) ===
                                                "Added to cart"
                                                  ? "button d-block d-sm-block d-md-none d-lg-none float-end notAllowed"
                                                  : "button d-block d-sm-block d-md-none d-lg-none float-end "
                                              }
                                              onClick={(e) =>
                                                handelAddDomin(e, index, els)
                                              }
                                            >
                                              <i className="fa fa-cart-plus"></i>
                                            </button>
                                          </td>
                                        </tr>
                                      </React.Fragment>
                                    ))}
                                </>
                              )}
                            </>
                          </tbody>
                        )}
                      </table>

                      <>
                        {loading ? null : (
                          <>
                            {postsToShow &&
                            postsToShow.length === visible ? null : (
                              <>
                                {visible <= 19 ? (
                                  <div className="text-center">
                                    <Link
                                      to="#"
                                      className=" text mb-0"
                                      onClick={(e) => showMoreItems(e)}
                                    >
                                      Load more{" "}
                                      <i
                                        className="fa fa-angle-down"
                                        aria-hidden="true"
                                      ></i>
                                    </Link>
                                  </div>
                                ) : null}
                              </>
                            )}
                          </>
                        )}
                      </>
                    </div>
                  ) : null}
                </>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default DomainSearch;
