import React from 'react'

const BundelBooster = ({ bundleData }) => {
  return (
    <section className="pt-100 bundle-booster d-none d-md-block  mb-0">
    <h2 className="text-center first-heading">{bundleData?.Heading}</h2>
    <h5 className="text-center second-heading">{bundleData?.SubHeading}</h5>
    <hr className="divider scnd_divider mx-auto mb-5" />
    <div className="bundel-booster-tabs-set">
      {/* Full NavTab for Bundle Booster Starts from here */}
      <ul className="nav nav-tabs" id="myTab" role="tablist">
        {bundleData?.Bundles?.map((item) => (
          <li className="nav-item w-25per" role="presentation">
            <button
              className={item?.buttonClass}
              id={item?.bundleId}
              data-bs-toggle="tab"
              data-bs-target={item?.bundleDataTarget}
              type="button"
              role="tab"
              aria-controls={item?.bundleAriaControl}
              aria-selected="true"
            >
              {item?.bundleHeading}
            </button>
          </li>
        ))}
      </ul>
      <div className="tab-content" id="myTabContent">
        {bundleData?.Bundles?.map((el) => (
          <div
            className={el?.class}
            id={el?.bundleParaghId}
            role="tabpanel"
            aria-labelledby={el?.bundleParaghAriaLable}
          >
            {/* Bundle Booster First Tab Content Starts from here */}
            <div className="display_table">
              <div className="display_table_cell">
                <div className="container">
                  <div className="row">
                    <div className="col-md-6 m-auto">
                      <h4>{el?.bundleHeading}</h4>
                      <p>{el?.bundleParagh}</p>
                    </div>
                    <div className="col-md-6 text-end">
                      <img
                        className="img-fluid"
                        src={el?.bundleImage}
                        alt=""
                        srcSet=""
                      />
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/* Bundle Booster First Tab Content Ends here */}
          </div>
        ))}
      </div>
      {/* Full NavTab for Bundle Booster Ends here */}
    </div>
  </section>
  )
}

export default React.memo(BundelBooster)