import React from "react";

const NewBanner = ({ Data }) => {
  return (
    <section className="banner-one banner-one-bg mt-0">
      <div className="container">
        <div className="row no-gutters">
          <div className="col-xl-5 col-lg-6 col-md-6 col-sm-12 m-auto">
            <div className="align_landing_text">
              <h2>SEO Campaigns That Truly Work</h2>
              <p>Competitive Analysis</p>
              <p>Website In-Depth Analysis</p>
              <p>Link Building</p>
              <p>Ranking Guarantee</p>
              <p>Targeted Keywords</p>
              <p>Monthly Reporting</p>

              <h5 className="price-text">
                <span style={{ textDecoration: "line-through" }}>
                  {Data.Tprice}
                </span>
                <span style={{ fontWeight: 700 }}>{Data.Dprice}/mo Only</span>
              </h5>

              <a className="FaqButton" href="#">
                Get a Quote
              </a>
            </div>
          </div>
          <div className="col-xl-7 col-lg-6 col-md-6 col-sm-12 img-box d-none d-md-block">
            <img
              src="assests/img/object-banner.png"
              alt="seo comapins"
              width="100%"
              height="100%"
            />
          </div>
        </div>
      </div>
    </section>
  );
};

export default NewBanner;
