import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";
import shortid from "shortid";
import { test2, currecnyDiffPrice } from "../../services/Helper";
const WeGotMore = ({ popList, ccList, allList }) => {
  const { currencyName } = useSelector((state) => state.currencyName);

  return (
    <section className="sec-we-got-more  pt-100">
      <div className="container">
        <h2 className="text-center first-heading">We’ve Got More!</h2>
        <h5 className="text-center second-heading">
          Bringing You Domain Extensions That Can Tell Your Story
        </h5>
        <hr className="divider scnd_divider mx-auto mb-4" />
        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12 pr-0">
            <div className="domain-what-nav-tabs">
              <ul className="nav nav-tabs" id="myTab" role="tablist">
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link active"
                    id="popular-tabs-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#popular-tabs"
                    type="button"
                    role="tab"
                    aria-controls="popular-tabs"
                    aria-selected="true"
                  >
                    Popluar
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="profile-tabs-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#profile-tabs"
                    type="button"
                    role="tab"
                    aria-controls="profile-tabs"
                    aria-selected="false"
                  >
                    ccTLD
                  </button>
                </li>
                <li className="nav-item" role="presentation">
                  <button
                    className="nav-link"
                    id="other-tabs-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#other-tabs"
                    type="button"
                    role="tab"
                    aria-controls="other-tabs"
                    aria-selected="false"
                  >
                    Other
                  </button>
                </li>
              </ul>
              <div className="tab-content" id="myTabContent">
                <div
                  className="tab-pane fade active show"
                  id="popular-tabs"
                  role="tabpanel"
                  aria-labelledby="home-tab"
                >
                  {/*Pricing Section Starts from here*/}
                  <div className="nav-tabs-price row">
                    {popList &&
                      popList.map((el, index) => (
                        <React.Fragment key={shortid.generate()}>
                          <div className="col-md-3 col-sm-6 col-xs-12 p-0 setTab">
                            <div className="d-table text-center text_alignment text-center">
                              <div className="d-table-cell align-middle">
                                <h4>.{el.name}</h4>
                                <h5>
                                  {currencyName.prefix}
                                  <span>
                                    {el.curency
                                      ?.filter(
                                        (fil) =>
                                          fil.currency === currencyName.api_id
                                      )
                                      .map((pr) => (
                                        <>
                                          {pr.annually === ""
                                            ? test2(
                                                currecnyDiffPrice(
                                                  pr.biennially,
                                                  currencyName.api_id
                                                )
                                              )
                                            : test2(
                                                currecnyDiffPrice(
                                                  pr.annually,
                                                  currencyName.api_id
                                                )
                                              )}
                                          {pr.annually === "" ? "/2yrs" : "/yr"}
                                        </>
                                      ))}
                                  </span>
                                  <span className="steric">*</span>
                                </h5>
                              </div>
                              <div className="navTabs_price_overlay">
                                <div className="d-table">
                                  <div className="d-table-cell align-middle">
                                    <Link to="/addDomain" className="btn">
                                      Register
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </React.Fragment>
                      ))}
                  </div>

                  {/*Pricing Section ends here*/}
                </div>
                <div
                  className="tab-pane fade"
                  id="profile-tabs"
                  role="tabpanel"
                  aria-labelledby="profile-tab"
                >
                  {/*Pricing Section Starts from here*/}
                  <div className="nav-tabs-price row">
                    {ccList &&
                      ccList.map((el, index) => (
                        <React.Fragment key={shortid.generate()}>
                          <div className="col-md-3 col-sm-6 col-xs-12 p-0 setTab">
                            <div className="d-table text-center text_alignment text-center">
                              <div className="d-table-cell align-middle">
                                <h4>.{el.name}</h4>
                                <h5>
                                  {currencyName.prefix}
                                  <span>
                                    {el.curency
                                      .filter(
                                        (fil) =>
                                          fil.currency === currencyName.api_id
                                      )
                                      .map((pr) => (
                                        <>
                                          {pr.annually === ""
                                            ? test2(
                                                currecnyDiffPrice(
                                                  pr.biennially,
                                                  currencyName.api_id
                                                )
                                              )
                                            : test2(
                                                currecnyDiffPrice(
                                                  pr.annually,
                                                  currencyName.api_id
                                                )
                                              )}
                                          {pr.annually === "" ? "/2yrs" : "/yr"}
                                        </>
                                      ))}
                                  </span>
                                  <span className="steric">*</span>
                                </h5>
                              </div>
                              <div className="navTabs_price_overlay">
                                <div className="d-table">
                                  <div className="d-table-cell align-middle">
                                    <Link to="/addDomain" className="btn">
                                      Register
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </React.Fragment>
                      ))}
                  </div>
                  {/*Pricing Section ends here*/}
                </div>
                <div
                  className="tab-pane fade"
                  id="other-tabs"
                  role="tabpanel"
                  aria-labelledby="contact-tab"
                >
                  {/*Pricing Section Starts from here*/}
                  <div className="nav-tabs-price row">
                    {allList &&
                      allList.map((el, index) => (
                        <React.Fragment key={shortid.generate()}>
                          <div className="col-md-3 col-sm-6 col-xs-12 p-0 setTab">
                            <div className="d-table text-center text_alignment text-center">
                              <div className="d-table-cell align-middle">
                                <h4>.{el.name}</h4>
                                <h5>
                                  {currencyName.prefix}
                                  <span>
                                    {el.curency
                                      .filter(
                                        (fil) =>
                                          fil.currency === currencyName.api_id
                                      )
                                      ?.map((pr) => (
                                        <>
                                          {pr.annually === ""
                                            ? test2(
                                                currecnyDiffPrice(
                                                  pr.biennially,
                                                  currencyName.api_id
                                                )
                                              )
                                            : test2(
                                                currecnyDiffPrice(
                                                  pr.annually,
                                                  currencyName.api_id
                                                )
                                              )}
                                          {pr.annually === "" ? "/2yrs" : "/yr"}
                                        </>
                                      ))}
                                  </span>
                                  <span className="steric">*</span>
                                </h5>
                              </div>
                              <div className="navTabs_price_overlay">
                                <div className="d-table">
                                  <div className="d-table-cell align-middle">
                                    <Link to="/addDomain" className="btn">
                                      Register
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </React.Fragment>
                      ))}
                  </div>
                  {/*Pricing Section ends here*/}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default React.memo(WeGotMore);
