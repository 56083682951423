import React, { useEffect, useState,useLayoutEffect } from "react";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import { GetPackages, GetActivePromotions,GetPackagesTest } from "../../services/Network.js";
import Banner from "../reuseable/Banner";
import CompanySlider from "../reuseable/CompanySlider";
import DomainSearchHome from "../reuseable/DomainSearchHome";
import PlanCrousels from "../reuseable/PlanCrousels";
import Testimonails from "./../reuseable/Testimonails";
import RightHosting from "./Home/RightHosting";
import WhyChooseUs from "./Home/WhyChooseUs";

const Home = () => {
  const { currencyName } = useSelector((state) => state.currencyName);
  const [packageRecomended, setPackageRecomended] = useState([]);
  const [homePackagesList, setHomePackagesList] = useState([]);
  const [getActivePromotions, setActivePromotions] = useState([]);
  const [rightHostingPid] = useState([184, 107, 324, 280]);
  const [homePackagePid] = useState([184, 107, 130, 280]);
  const [loading, setLoading] = useState(false);

useLayoutEffect(() => {
    GetPackagesTest("184,107,324,280").then((res) => {
    });
  }, []);

  useEffect(() => {
 GetPackagesTest("184,107,324,280").then((res) => {
    });
    GetActivePromotions().then((ress) => {
      localStorage.setItem("pr56m", JSON.stringify(ress));
      setActivePromotions(ress);
    });
    GetPackages(rightHostingPid).then((res) => {
      setPackageRecomended(res);
      GetPackages(homePackagePid).then((respo) => {
        setHomePackagesList(respo);
        setLoading(true);
      });
    });
  }, [currencyName]);

  return (
    <>
      <Helmet>
        <title>
          Web Hosting Pakistan | Shared Hosting | VPS & Dedicated Server |
          WebSouls
        </title>
        <meta
          name="title"
          content="Web Hosting Pakistan | Shared Hosting | VPS & Dedicated Server | WebSouls"
        />
        <meta
          name="description"
          content="WebSouls providing Web Hosting in Pakistan. We Offer affordable Shared Hosting Plans in Lahore, Karachi and Islamabad. Get cheap VPS Hosting and dedicated hosting servers today."
        />
        <meta name="keywords" content="" />
        <link rel="canonical" href="https://websouls.com/" />
      </Helmet>
      <Banner />
      <DomainSearchHome />
      <RightHosting/>
      <PlanCrousels
        homePackagesList={homePackagesList}
        getActivePromotions={getActivePromotions}
        loading={loading}
      />
      {/* <HomeBannerSecond /> */}
      <WhyChooseUs />
      <Testimonails />
      <CompanySlider />
    </>
  );
};

export default Home;
