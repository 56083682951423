import React from "react";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { test2, currecnyDiffPrice } from "../../../../../services/Helper";

const DomainExtraction = ({ spotlight }) => {
  const { currencyName } = useSelector((state) => state.currencyName);

  return (
    <section className="domain-extraction pt-100 mt-0">
      <div className="container">
        <h2 className="text-center first-heading">
          Domain Extensions that Stand Out
        </h2>
        <h5 className="text-center second-heading">
          Domain TLDs That Can Become Your Brandable Web Identity
        </h5>
        <hr className="divider scnd_divider mx-auto mb-4" />
        <div className="row">
          {spotlight?.map((el, index) => (
            <React.Fragment key={index}>
              <div className="col-md-3 col-sm-3 col-xs-12">
                <div className="extraction">
                  <Link to="" className="d-table text-decoration-0">
                    <div className="d-table-cell align-middle text-center">
                      <div>.</div>
                      {el?.name}
                    </div>
                  </Link>
                  <span className="text-center">
                    {currencyName?.prefix}
                    {el?.curency
                      ?.filter((fil) => fil?.currency === currencyName?.api_id)
                      ?.map((pr) => (
                        <>
                          {pr?.annually === ""
                            ? test2(
                                currecnyDiffPrice(
                                  pr?.biennially,
                                  currencyName?.api_id
                                )
                              )
                            : test2(
                                currecnyDiffPrice(
                                  pr?.annually,
                                  currencyName?.api_id
                                )
                              )}
                          {pr?.annually === "" ? "/2yrs" : "/yr"}
                        </>
                      ))}
                  </span>

                  <div className="register-price-overlay">
                    <div className="d-table">
                      <div className="d-table-cell align-middle text-center">
                        <Link to="/addDomain" className="btn">
                          Register
                        </Link>
                      </div>
                    </div>
                  </div>
                  {/* Domain Register On Hover Div Ends*/}
                </div>
              </div>
            </React.Fragment>
          ))}
        </div>
      </div>
    </section>
  );
};

export default React.memo(DomainExtraction);
