import React from 'react'

const GMap = () => {
  return (
    <section className="map mt-0 mb-0 p-0">
      <iframe
        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3401.299468008993!2d74.33882831558044!3d31.51593398137111!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3919046885d8fc63%3A0x7ffaa45a8fa577c5!2sWebSouls!5e0!3m2!1sen!2s!4v1564570465580!5m2!1sen!2s"
        width={600}
        height={450}
        frameBorder={0}
        style={{ border: 0 }}
        allowFullScreen=""
      />
    </section>
  );
}

export default GMap