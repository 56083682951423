import React from "react";
import Carousel from "react-elastic-carousel";

const WhyChooseUs = () => {
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2 },
    { width: 768, itemsToShow: 3 },
    { width: 1200, itemsToShow: 3 },
  ];

  return (
    <section className="why_us_simple pt-100">
      <div className="container">
        <h2 className="first-heading">Why Choose Us?</h2>
        <h5 className="second-heading">
          Hosting Plans Fueled With the Latest Technology
        </h5>
        <hr className="divider scnd_divider mx-auto mb-4" />
        <div className="d-none d-md-block d-lg-block d-xl-block">
          <div className="row">
            <div className="col-md-4 brdr_lft_rght">
              <div className="serving_since">
                <div className="img_circle">
                  <div className="icon ws-serve-b" />
                </div>
                <hr className="img_btm" />
                <h4>Serving Since 2002</h4>
                <p>
                  A UK based web hosting company, we’ve been in the industry for
                  more than a decade now and offer everything your website needs
                  to run smoothly.
                </p>
              </div>
            </div>
            <div className="col-md-4 brdr_lft_rght">
              <div className="serving_since">
                <div className="img_circle">
                  <div className="icon ws-cpanel" />
                </div>
                <hr className="img_btm" />
                <h4>cPanel’s Official Partner</h4>
                <p>
                  We’ve brought together technology and hosting experts with
                  years of experience, to provide a hosting platform that’s
                  complete with cPanel.
                </p>
              </div>
            </div>
            <div className="col-md-4 brdr_lft_rght">
              <div className="serving_since">
                <div className="img_circle">
                  <div className="icon ws-clients" />
                </div>
                <hr className="img_btm" />
                <h4>1000+ Corporate Clients</h4>
                <p>
                  With our customer-centric approach, we take pride making a
                  difference for over 1000 corporate and govt organizations whom
                  we continue to serve.
                </p>
              </div>
            </div>
            <div className="col-md-4 brdr_lft_rght">
              <div className="serving_since">
                <div className="img_circle">
                  <div className="icon ws-scripts" />
                </div>
                <hr className="img_btm" />
                <h4>Bash Scripts</h4>
                <p>
                  The security of our customers is our top priority and we use
                  many custom scripts to accomplish the challenging tasks. Our
                  clients never have to fear cyber-attacks.
                </p>
              </div>
            </div>
            <div className="col-md-4 brdr_lft_rght">
              <div className="serving_since">
                <div className="img_circle">
                  <div className="icon ws-email-c" />
                </div>
                <hr className="img_btm" />
                <h4>Smooth Email Service</h4>
                <p>
                  Our email hosting service is more than smooth – it’s fast,
                  reliable, and designed to protect businesses from viruses,
                  helping them grow every day.
                </p>
              </div>
            </div>
            <div className="col-md-4 brdr_lft_rght">
              <div className="serving_since">
                <div className="img_circle">
                  <div className="icon ws-uptime" />
                </div>
                <hr className="img_btm" />
                <h4>99.9% Uptime Guarantee</h4>
                <p>
                  Our ongoing website support is aimed at helping businesses
                  stay up and running 24/7, while we monitor performance around
                  the clock.
                </p>
              </div>
            </div>
          </div>
        </div>
        <div className="d-block d-md-none d-lg-none d-xl-none">
          <div className="row">
            <Carousel
              className="animate__animated animate__fadeIn"
              itemPadding={[8, 10, 8, 10]}
              breakPoints={breakPoints}
              pagination={true}
              showArrows={false}
            >
              <div className="col-md-4 brdr_lft_rght">
                <div className="serving_since">
                  <div className="img_circle">
                    <div className="icon ws-serve-b" />
                  </div>
                  <hr className="img_btm" />
                  <h4>Serving Since 2002</h4>
                  <p>
                    A UK based web hosting company, we’ve been in the industry
                    for more than a decade now and offer everything your website
                    needs to run smoothly.
                  </p>
                </div>
              </div>
              <div className="col-md-4 brdr_lft_rght">
                <div className="serving_since">
                  <div className="img_circle">
                    <div className="icon ws-cpanel" />
                  </div>
                  <hr className="img_btm" />
                  <h4>cPanel Official Partner</h4>
                  <p>
                    We’ve brought together technology and hosting experts with
                    years of experience, to provide a hosting platform that’s
                    complete with cPanel.
                  </p>
                </div>
              </div>
              <div className="col-md-4 brdr_lft_rght">
                <div className="serving_since">
                  <div className="img_circle">
                    <div className="icon ws-clients" />
                  </div>
                  <hr className="img_btm" />
                  <h4>1000+ Corporate Clients</h4>
                  <p>
                    With our customer-centric approach, we pride in making a
                    difference for over 1000 corporate and govt organizations
                    whom we continue to serve.
                  </p>
                </div>
              </div>
              <div className="col-md-4 brdr_lft_rght">
                <div className="serving_since">
                  <div className="img_circle">
                    <div className="icon ws-scripts" />
                  </div>
                  <hr className="img_btm" />
                  <h4>Bash Scripts</h4>
                  <p>
                    The security of our customers is our top priority and we use
                    many custom scripts to accomplish the challenging tasks. Our
                    clients never have to fear cyber-attacks.
                  </p>
                </div>
              </div>
              <div className="col-md-4 brdr_lft_rght">
                <div className="serving_since">
                  <div className="img_circle">
                    <div className="icon ws-email-c" />
                  </div>
                  <hr className="img_btm" />
                  <h4>Smooth Email Service</h4>
                  <p>
                    Our email hosting service is more than smooth – it’s fast,
                    reliable, and designed to protect businesses from viruses,
                    helping them grow every day.
                  </p>
                </div>
              </div>
              <div className="col-md-4 brdr_lft_rght">
                <div className="serving_since">
                  <div className="img_circle">
                    <div className="icon ws-uptime" />
                  </div>
                  <hr className="img_btm" />
                  <h4>99.9% Uptime Guarantee</h4>
                  <p>
                    Our ongoing website support is aimed at helping businesses
                    stay up and running 24/7, while we monitor performance
                    around the clock.
                  </p>
                </div>
              </div>
            </Carousel>
          </div>
        </div>
      </div>
    </section>
  );
};

export default React.memo(WhyChooseUs);
