import React from "react";

const Vision = () => {
  return (
    <section className="vision_section pt-100">
      <div className="container">
        <div className="row">
          <div className="col-md-6">
            <div className="vision_text border-right">
              <img
                src="assests/img/Web-Hosting-Comapany-Since-2002.png"
                className="d-none d-md-block"
                title="Web-Hosting-Comapany-Since-2002"
                alt="Web-Hosting-Comapany-Since-2002"
              />
            </div>
          </div>
          <div className="col-md-6">
            <div className="col-md-12">
              <div className="vision_text">
                <div className="icon ws-mission mb-0 pb-0" />
                <h3>Our Mission</h3>
                <p>
                  Striving to make our customers succeed is what we do every
                  day. We’re the no. 1 web hosting company of Pakistan, and our
                  mission is to make going online easier, simpler, and quick
                  with our powerful hosting solutions.
                </p>
              </div>
            </div>

            <div className="col-md-12">
              <div className="vision_text mb-3">
                <div className="icon ws-vision mb-0 pb-0" />
                <h3>Our Vision</h3>
                <p>
                  At WebSouls, we believe hosting is the Soul of a strong web
                  presence, and that’s what we work to provide our clients with.
                  Our aim is to deliver not just better, but the best in
                  everything we do!
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Vision;
