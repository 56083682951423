import React from "react";
import Carousel from "react-elastic-carousel";
import ReactReadMoreReadLess from "react-read-more-read-less";
const TestimonSlide = () => {
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2 },
    { width: 768, itemsToShow: 3 },
    { width: 1200, itemsToShow: 3 },
  ];

  return (
    <Carousel
      className="animate__animated animate__fadeIn"
      itemPadding={[8, 10, 8, 10]}
      breakPoints={breakPoints}
      pagination={true}
      showArrows={false}
    >
      <div className="item">
        <div className="col-md-12">
          <div className="testimonial_one">
            <div className="client_comment">
              <div className="icon ws-comas" />
              <ReactReadMoreReadLess
                charLimit={200}
                readMoreText={"Read more..."}
                readLessText={"Read less"}
                readMoreClassName="read-more-less--more"
                readLessClassName="read-more-less--less"
              >
                I was worried because your staff was in Lahore-Pakistan you
                wouldn't be able to do the work. Could a team from Lahore do the
                job? However, you have been great, and I am so glad I found you
                online. Finding Websouls was one of the best decisions.As a
                result of using your service, I found you to be knowledgeable,
                fast, friendly, and inexpensive. It was a great business
                decision to choose you!
              </ReactReadMoreReadLess>
            </div>
            <div className="client_img">
              <div className="img_block">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/assests/img/testimonial/1aaaaa.webp"
                  }
                  className="lazy"
                  title="1aaaaa"
                  alt="1aaaaa"
                />
              </div>
              <div className="client_name">
                <h4>Usman Qureshi</h4>
                <p>CEO - JustOne </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="item">
        <div className="col-md-12">
          <div className="testimonial_one">
            <div className="client_comment">
              <div className="icon ws-comas" />
              <ReactReadMoreReadLess
                charLimit={200}
                readMoreText={"Read more..."}
                readLessText={"Read less"}
                readMoreClassName="read-more-less--more"
                readLessClassName="read-more-less--less"
              >
                We have been using Websouls for our critical servers and for all
                our domains for years, and have always been able to count on them. Their technology just work , and their
                  support is&nbsp; Phenomenal highly recommend.
              </ReactReadMoreReadLess>
             
            </div>
            <div className="client_img">
              <div className="img_block">
                <img
                  src={
                    process.env.PUBLIC_URL + "/assests/img/testimonial/a-2.webp"
                  }
                  className="lazy"
                  title="a-2"
                  alt="a-2"
                />
              </div>
              <div className="client_name">
                <h4>Noman Sharif</h4>
                <p>Director IT (PRACS) - Pakistan Railway </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="item">
        <div className="col-md-12">
          <div className="testimonial_one">
            <div className="client_comment">
              <div className="icon ws-comas" />
              <ReactReadMoreReadLess
                charLimit={200}
                readMoreText={"Read more..."}
                readLessText={"Read less"}
                readMoreClassName="read-more-less--more"
                readLessClassName="read-more-less--less"
              >
                It has been more than 13 years that I am using Websouls services
                including shared hosting and VPS. There has not been a single moment when I have thought of changing the service
                  provider. Day or night I have received equal quality service.
                  The levels these guys will go for customer satisfaction is
                  unlimited. How they do it I don’t know but I am a very very
                  very happy customer.
                </ReactReadMoreReadLess>
             
            </div>
            <div className="client_img">
              <div className="img_block">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/assests/img/testimonial/ahmedzaki-e.webp"
                  }
                  className="lazy"
                  title="ahmedzaki-e"
                  alt="ahmedzaki-e"
                />
              </div>
              <div className="client_name">
                <h4>Syed Ahmed Zaki Naqvi</h4>
                <p>Manager M.I.S - Punjab Educational Endowment Fund </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="item">
        <div className="col-md-12">
          <div className="testimonial_one">
            <div className="client_comment">
              <div className="icon ws-comas" />
              <ReactReadMoreReadLess
                charLimit={200}
                readMoreText={"Read more..."}
                readLessText={"Read less"}
                readMoreClassName="read-more-less--more"
                readLessClassName="read-more-less--less"
              >
                 I always feel comfortable in dealing with Websouls as their
                staff always try to provide personalized services and supportive towards the customers which makes E-Commerce
                  exposure excellent for me and my students. As the services of
                  Web souls help my students to exercise their skills in the
                  field of E-Commerce. Moreover Miss Shazia as an active member
                  of Websouls always provides peace of mind to her customers
                  with customized services. I wish Websouls and Miss Shazia best
                  of luck.
                </ReactReadMoreReadLess>
            
            </div>
            <div className="client_img">
              <div className="img_block">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/assests/img/testimonial/waqas-zaki.webp"
                  }
                  className="lazy"
                  title="waqas-zaki"
                  alt="waqas-zaki"
                />
              </div>
              <div className="client_name">
                <h4>Waqas Zaki</h4>
                <p>Professor - UCP </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="item">
        <div className="col-md-12">
          <div className="testimonial_one">
            <div className="client_comment">
              <div className="icon ws-comas" />
              <ReactReadMoreReadLess
                charLimit={200}
                readMoreText={"Read more..."}
                readLessText={"Read less"}
                readMoreClassName="read-more-less--more"
                readLessClassName="read-more-less--less"
              >
                I’m extremely happy with the services and support that I get
                from WebSouls. Their response time is really good, and I’m truly overwhelmed by the quality of hosting services that
                  I’ve been receiving so far. Plus, their hosting packages are
                  amazing and totally worth it. Highly recommended!
              </ReactReadMoreReadLess>
              
            </div>
            <div className="client_img">
              <div className="img_block">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/assests/img/testimonial/1593850714.jpg"
                  }
                  className="lazy"
                  title={1593850714}
                  alt={1593850714}
                />
              </div>
              <div className="client_name">
                <h4>Aqeel Ashraf</h4>
                <p>CEO - Enzee Textile </p>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="item">
        <div className="col-md-12">
          <div className="testimonial_one">
            <div className="client_comment">
              <div className="icon ws-comas" />
              <ReactReadMoreReadLess
                charLimit={200}
                readMoreText={"Read more..."}
                readLessText={"Read less"}
                readMoreClassName="read-more-less--more"
                readLessClassName="read-more-less--less"
              >
                I chose WebSouls because a friend recommended. It’s been a long
                period of time since I’ve been availing their hosting service. The team is super-friendly and collaborative, and
                  they’ve got the best hosting plans that are quite affordable
                  considering everything that’s included, and the reliability
              </ReactReadMoreReadLess>
            
            </div>
            <div className="client_img">
              <div className="img_block">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/assests/img/testimonial/Aslam.webp"
                  }
                  className="lazy"
                  title="Aslam"
                  alt="Aslam"
                />
              </div>
              <div className="client_name">
                <h4>Muhammad Aslam</h4>
                <p>CEO - Infotech 4 IT </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Carousel>
  );
};

export default React.memo(TestimonSlide);
