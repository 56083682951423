import React from "react";

const CustomizeServer = () => {
  return (
    <section className="sec-customized-plan pt-100">
      <div className="display_table bg-customized-plan">
        <div className="display_table_cell">
          <div className="container">
            <div className="sec-customzied-text">
              <h3 className="first-heading">
                Customize Your Server Plan Yourself
              </h3>
              <p className="second-heading">
                Let one of our inhouse hosting experts help you choose
                a&nbsp;plan that fits your needs. Or, create a&nbsp;custom plan
                yourself while we assist you!
              </p>
              <button className="livechat" style={{ marginRight: 15 }}>
                {/* <img
                  className="pe-3"
                  src={process.env.PUBLIC_URL + "/assests/img/chat.png"}
                  loading="lazy"
                  width={40}
                  height={40}
                  title="chat"
                  alt="chat"
                /> */}
                <i
                  style={{ paddingRight: 5, fontSize: 18 }}
                  className="fa fa-envelope-o"
                  aria-hidden="true"
                ></i>
                Live Chat
              </button>
              <button className="getintouch">
                <i
                  style={{ paddingRight: 5, fontSize: 18 }}
                  className="fa fa-commenting-o"
                  aria-hidden="true"
                ></i>
                {/* <img
                  className="pe-3"
                  src={process.env.PUBLIC_URL + "/assests/img/mail.png"}
                  loading="lazy"
                  width={40}
                  height={40}
                  title="chat"
                  alt="chat"
                /> */}
                Get in Touch
              </button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default CustomizeServer;
