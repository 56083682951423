import React, { useState, useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Helmet } from "react-helmet";
import { useDispatch, useSelector } from "react-redux";
import AllPlanInclude from "./includes/SharedHosting/AllPlanInclude";
import BundelBooster from "./includes/SharedHosting/BundelBooster";
import DomainMigration from "./includes/SharedHosting/DomainMigration";
import Faq from "../../reuseable/Faq";
import GoingOnline from "./includes/SharedHosting/GoingOnline";
import InPakistan from "./includes/SharedHosting/InPakistan";
import WhyChooseUsThird from "./includes/SharedHosting/WhyChooseUsThird";
import WillingForHelp from "../../reuseable/WillingForHelp";
import Skeleton from "react-loading-skeleton";
import { Link } from "react-router-dom";
import Carousel from "react-elastic-carousel";
import DomainTips from "./includes/SharedHosting/DomainTips";
import {
  getPrice,
  packagesFeatures,
  packagesFirstFeature,
  delayTime,
  getPackageBaseCycle,
  getPKPrice,
} from "../../../services/Helper.js";
import { GetPackages, GetActivePromotions } from "../../../services/Network.js";
import shortid from "shortid";
import ComparePlans from "./includes/PkHosting/ComparePlans";

const PkHosting = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const data = {
    Heading: "Quit Worrying About Migration Hassles!",
    SubHeading:
      "Our experts can transfer your website and get you onboard for FREE. With us, migration is like ripping off a band aid – quick and painless!",
    ButtonName: "Migrate Now!",
  };
  const allplans = {
    Heading: "All Plans Include",
    SubHeading: "Hosting Solutions Focused on Cost-Effective Reliability",
    Box: [
      {
        id: 0,
        heading: "1-Click Installation",
        subHeading: "(WordPress, Joomla, Drupal etc)",
        icon: "icon ws-1-click-install-script",
      },
      {
        id: 1,
        heading: "Proactive Support",
        subHeading: "(Phone, Chat, Tickets)",
        icon: "icon ws-proactive",
      },
      {
        id: 2,
        heading: "Personalized Email",
        subHeading: "(Example: info@yourdomain.com)",
        icon: "icon ws-email-b",
      },
      {
        id: 3,
        heading: "Free SSL Certificate",
        subHeading: "(Secure your digital future)",
        icon: "icon ws-easy",
      },
      {
        id: 4,
        heading: "Easy Management",
        subHeading: "(Database management from cPanel)",
        icon: "icon ws-disk-b",
      },
      {
        id: 5,
        heading: "Swift Upgrading",
        subHeading: "(CPU, RAM, I/O etc.)",
        icon: "icon ws-chain",
      },
    ],
  };
  const BundleData = {
    Heading: "Bundle Boosters",
    SubHeading: "Complete Your Package with a Little Something Extra",
    Bundles: [
      {
        bundleHeading: "Backup & Restore",
        bundleParagh:
          "Website backups mean more than just peace of mind - it’s the difference between a business failing and a business succeeding, no matter what. With our daily automatic backups, we can help you bring your website back to life with just one click in case of any accidental data loss.",
        bundleImage: "assests/img/Backup-&-Restore-services.png",
        bundleId: "backupAndRestore-tab",
        bundleDataTarget: "#backupAndRestore",
        bundleAriaControl: "backupAndRestore",
        bundleParaghId: "backupAndRestore",
        bundleParaghAriaLable: "backupAndRestore-tab",
        class: "tab-pane fade show active",
        buttonClass: "nav-link active",
      },
      {
        bundleHeading: "SSL Certificate",
        bundleParagh:
          "Keeping our clients’ data secure is essential, but keeping the data of their customers safe is what we really take pride in. By making SSL certification easy, we help businesses in building the trust of their customers, turning their website into a lead generating machine with higher rate of conversions, guaranteed.",
        bundleImage: "assests/img/Purchase-SSL-Certificate-in-Pakistan.png",
        bundleId: "ssl-certificate-tab",
        bundleDataTarget: "#ssl-tabs-certificate",
        bundleAriaControl: "ssl-tabs-certificates",
        bundleParaghId: "ssl-tabs-certificate",
        bundleParaghAriaLable: "ssl-certificate-tab",
        class: "tab-pane fade show",
        buttonClass: "nav-link",
      },
      {
        bundleHeading: "Dedicated IP",
        bundleParagh:
          "Helping our clients in building their online presence on their own unique IP address, which would ultimately allow them to get an SSL certificate with ease, and improve their email deliverability. Also, through providing a dedicated IP address, we enable our customers to access their site anytime, even when their domain name isn’t available.",
        bundleImage: "assests/img/Dedicated-IP-Price-In-Pakistan.png",
        bundleId: "dedicated-ip-tab",
        bundleDataTarget: "#dedicated",
        bundleAriaControl: "dedicated",
        bundleParaghId: "dedicated",
        bundleParaghAriaLable: "dedicated-ip-tab",
        class: "tab-pane fade show",
        buttonClass: "nav-link",
      },
      {
        bundleHeading: "Website Security",
        bundleParagh:
          "Helping our clients in building their online presence on their own unique IP address, which would ultimately allow them to get an SSL certificate with ease, and improve their email deliverability. Also, through providing a dedicated IP address, we enable our customers to access their site anytime, even when their domain name isn’t available.",
        bundleImage: "assests/img/Website-Security-in-Pakistan.png",
        bundleId: "web-security-tab",
        bundleDataTarget: "#web-security",
        bundleAriaControl: "web-security",
        bundleParaghId: "web-security",
        bundleParaghAriaLable: "web-security-tab",
        class: "tab-pane fade show",
        buttonClass: "nav-link",
      },
    ],
  };
  const faqData = {
    heading: "Frequently Asked Questions",
    subHeading: "Dedicated to Helping You Succeed!",
    faqs: [
      {
        id: 0,
        title: "What is pk hosting and what makes it special?",
        desc: `'pk hosting' is designed to simplify your online journey. Match your hosting duration with your .PK
        domain's 2-year registration, eliminating the hassle of separate renewals. Plus, enjoy our amazing offers,
        including FREE SSL, FREE .pk domain, and daily malware scans.`,
        faqId: "FAQ1",
        dataBsTarget: "#faqOne",
        ariaControls: "faqOne",
        ariaLabelledby: "FAQ1",
        faqParaghId: "faqOne",
      },
      {
        id: 1,
        title:
          "What's the benefit of aligning hosting with a 2-year .PK domain?",
        desc: `When you sync your hosting tenure with your .PK domain, you avoid the complexity of separate renewals. Your online presence remains hassle-free, secure, and cost-effective.`,
        faqId: "FAQ2",
        dataBsTarget: "#faqTwo",
        ariaControls: "faqTwo",
        ariaLabelledby: "FAQ2",
        faqParaghId: "faqTwo",
      },
      {
        id: 2,
        title:
          "What is the tenure of `pk hosting` plans, and ca n I renew my hosting annually?",
        desc: `'pk hosting' plans have a minimum tenure of 2 years, aligning with the 2-year .PK domain registration period. While other hosting plans can be renewed annually, we recommend enjoying the 2-year package to make the most of your FREE .PK domain.`,
        faqId: "FAQ3",
        dataBsTarget: "#faqThree",
        ariaControls: "faqThree",
        ariaLabelledby: "FAQ3",
        faqParaghId: "faqThree",
      },
      {
        id: 3,
        title:
          "Are there any discounts or value additions with 'pk hosting' plans?",
        desc: `Absolutely! 'pk hosting' plans offer incredible value with discounts. You receive a FREE .pk domain, a FREE SSL certificate with every plan ensuring secure data transmission, and our 2 advanced packages include FREE daily malware scans for enhanced security.`,
        faqId: "FAQ4",
        dataBsTarget: "#faqFour",
        ariaControls: "faqFour",
        ariaLabelledby: "FAQ4",
        faqParaghId: "faqFour",
      },
      {
        id: 4,
        title:
          "Can I upgrade my 'pk hosting' plan in the future if my website's needs change?",
        desc: `Certainly! We understand that your web hosting needs may evolve. you can easily upgrade your 'pk hosting' plan within the 'pk hosting' category to accommodate growth while still enjoying the benefits of a 2-year hosting duration.`,
        faqId: "FAQ5",
        dataBsTarget: "#faqFive",
        ariaControls: "faqFive",
        ariaLabelledby: "FAQ5",
        faqParaghId: "faqFive",
      },
    ],
  };
  const WillingToHelpData = {
    heading: "Ready, Willing and Able to Help!",
    subHeading:
      "Whether it’s a question, query, or you want to discuss a project, the WebSouls team is at your service anytime, at all times!",
    buttonOne: "Call: +92 423 523 8871 - 73",
    buttonTwo: " Email Us",
    image: "assests/img/Net-Ops-Engineer-Haris-Mukhtar.png",
  };
  const ChoseUsData = {
    Heading: "Why Choose Us?",
    subHeading: "Hosting Plans Fueled With the Latest Technology",
    box: [
      {
        icon: "icon ws-serve-b",
        title: "Serving Since 2002",
      },
      {
        icon: "icon ws-cpanel",
        title: "cPanel Official Partner",
      },
      {
        icon: "icon ws-clients",
        title: "1000+ Corporate Clients",
      },
      {
        icon: "icon ws-scripts",
        title: "Bash Scripts",
      },
      {
        icon: "icon ws-email-c",
        title: "Smooth Email Service",
      },
    ],
  };
  const technicalSpecificationsData = {
    Heading: "Technical Specifications",
    subHeading:
      "Harnessing the Power of Technology to Deliver a Dependable Experience",
    box: [
      {
        id: 0,
        icon: "icon ws-recycle",
        title: "Proactive Monitoring",
        peragraph:
          "We offer 24/7 security monitoring and DDos protection to keep all your business and personal data safe and secure from spam, malware, and hackers.",
      },
      {
        id: 1,
        icon: "icon ws-rack",
        title: "Guaranteed Server Uptime",
        peragraph:
          "You can experience the real 99.99% uptime with us, paired up with our dedicated customer support and servers that offer blazing-fast speed at all times.",
      },
      {
        id: 2,
        icon: "icon ws-scripts",
        title: "Languages & Performance",
        peragraph:
          "Our hosting plans come with high level performance and we also support numerous programming languages including PHP, CSS, HTML, and many more.",
      },
      {
        id: 3,
        icon: "icon ws-processor",
        title: "Fast and High Core CPUs",
        peragraph:
          "We can keep your site online and always running smoothly with our high core CPU, Ram and Disk IO limits. Experience the best speed load times with us!",
      },
      {
        id: 4,
        icon: "icon ws-1-click-install-script",
        title: "1-Click install Scripts",
        peragraph:
          "We offer 1-click app install so that you can easily build your website while using your favourite app. Our clients can install all the applications they love with just a single click!",
      },
      {
        id: 5,
        icon: "icon ws-ftp",
        title: "Highly Secure FTP Access",
        peragraph:
          "We provide FTP (File Transfer Protocol) access so that you can upload your website files with ease while making sure all your site data is safe and secure.",
      },
    ],
  };
  const InPakistanData = {
    Heading: "Pk Hosting in Pakistan",
    subHeading: "Hosting by the Experts, for the Experts",
    peragraph: [
      <>
        <p>
          At Websouls, we believe in providing you with hosting solutions that
          not only meet your needs but exceed your expectations. That&#39;s why
          we&#39;re excited to introduce our &#39;pk hosting&#39; plans that
          come with a free .pk domain and a free SSL certificate.
        </p>
        <strong>Why Choose PK Hosting:</strong>
        <p></p>
        <p>
          We understand that managing your web presence can sometimes be a
          hassle—separate renewals, unexpected costs, and security concerns.
          That&#39;s why we&#39;ve crafted &#39;pk hosting&#39; with you in
          mind.
        </p>
        <ul style={{ paddingLeft: 25 }}>
          <li style={{ listStyle: "inside" }}>
            <strong>Free .PK Domain:</strong> Unlike other hosting providers, we
            offer you a FREE .PK domain registration with every &#39;pk
            hosting&#39; plan. Say goodbye to domain renewal hassles every year;
            .PK domains come with a minimum 2-year registration period,
            providing you with uninterrupted online presence.
          </li>
          <li style={{ listStyle: "inside" }}>
            <strong>Seamless Synchronization:</strong> Did you know that .PK
            domains come with a default minimum registration period of 2 years?
            Now, you can align your hosting tenure with your .PK domain,
            eliminating the need for separate renewals and hassles.
          </li>
          <li style={{ listStyle: "inside" }}>
            <strong>Unmatched Value:</strong> Our &#39;pk hosting&#39; plans are
            loaded with introductory offers and discounts, making this the
            perfect time to elevate your online presence without breaking the
            bank.
          </li>
          <li style={{ listStyle: "inside" }}>
            <strong>FREE SSL Security:</strong> We take your website&#39;s
            security seriously. That&#39;s why we include a FREE SSL certificate
            with every &#39;pk hosting&#39; plan, ensuring your visitors&#39;
            data is encrypted and your reputation remains untarnished.
          </li>
          <li style={{ listStyle: "inside" }}>
            <strong>Daily Malware Scan:</strong>With our advanced &#39;pk
            hosting&#39; packages, enjoy the added peace of mind with a FREE
            daily malware scan. Stay one step ahead of threats and keep your
            website clean and secure.
          </li>
        </ul>
        <p></p>
        <strong>Features of PK Hosting Plans:</strong>
        <p></p>
        <ul style={{ paddingLeft: 25 }}>
          <li style={{ listStyle: "inside" }}>
            <strong>Lightning-Fast Performance:</strong> Our hosting
            infrastructure ensures blazing-fast website speeds, optimized for
            local and global visitors.
          </li>
          <li style={{ listStyle: "inside" }}>
            <strong>Reliability You Can Count On:</strong> With 99.9% uptime,
            your website is always accessible to your audience, making sure you
            never miss out on opportunities.
          </li>
          <li style={{ listStyle: "inside" }}>
            <strong>Local Support:</strong> Our pro-active customer support team
            is here for you 24/7. Have questions or need assistance? We&#39;re
            just a call or message away.
          </li>
        </ul>
        <p></p>
        <strong>Get Started with PK Hosting Today:</strong>
        <p></p>

        <p>
          Ready to experience the power of &#39;pk hosting&#39;? Choose a plan
          that suits your needs and enjoy a FREE .PK domain along with a FREE
          SSL certificate. With a minimum tenure of 2 years, you&#39;ll enjoy
          peace of mind and unbeatable value.
        </p>

        <span id="dots">...</span>
        <span id="more">
          <p>
            Take the next step in your digital journey—explore our &#39;pk
            hosting&#39; plans now and secure your online future with Websouls.
          </p>
        </span>
        <a className="read-more-link ">
          <h2 className="text-center pt-2">
            <button
              onClick={() => myFunction()}
              id="myBtn"
              className="readmoreMobile"
            >
              Read More
            </button>
          </h2>
        </a>
      </>,
    ],
  };
  const GoingOnlineData = {
    Heading: "Not Sure? Explore More!",
    subHeading: "Hosting Solutions Focused on Your Needs",
    box: [
      {
        id: 0,
        topTitle: "Need More Power?",
        Title: "Try Business Hosting",
        buttonLink: "/business-hosting",
      },
      {
        id: 1,
        topTitle: "Need Something Simpler?  ",
        Title: "Try WordPress Hosting",
        buttonLink: "/wordpress-hosting-in-pakistan",
      },
    ],
  };

  const { currencyName } = useSelector((state) => state.currencyName);
  const { billingCycleHosting } = useSelector(
    (state) => state.billingCycleHosting
  );

  const [pidsss, setPid] = useState([355, 354, 353, 352]);
  const [linuxPids, setLiniuxPids] = useState([355, 354, 353, 352]);
  const [winodwsPids, setWindowsPids] = useState([355, 354, 353, 352]);
  const [bussinessHostingLinuxList, setBussinesHostingLinuxList] = useState([]);
  const [bussinessHostingWindowsList, setBussinesHostingWindowsList] = useState(
    []
  );
  const [getActivePromotions, setActivePromotions] = useState([]);
  const [statusOne, setStatusOne] = useState(true);
  const [statusTwo, setStatusTwo] = useState(true);
  const [loading, setLoading] = useState(false);
  const [comparePlansState, setComparePlansState] = useState([]);

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2 },
    { width: 768, itemsToShow: 3 },
    { width: 1200, itemsToShow: 3 },
  ];
  var dots = document.getElementById("dots");
  var moreText = document.getElementById("more");
  var btnText = document.getElementById("myBtn");

  useEffect(() => {
    myFunction();
  }, [btnText, moreText, dots]);

  useEffect(() => {
    dispatch({
      type: "urlCurrent",
      payload: location.pathname,
    });
  }, []);

  function myFunction() {
    var dots = document.getElementById("dots");
    var moreText = document.getElementById("more");
    var btnText = document.getElementById("myBtn");

    if (dots.style.display === "none") {
      dots.style.display = "inline";
      btnText.innerHTML = "Read more";
      moreText.style.display = "none";
    } else {
      dots.style.display = "none";
      btnText.innerHTML = "Read less";
      moreText.style.display = "inline";
    }
  }

  useEffect(() => {
    if (linuxPids.length <= 3) {
      setStatusOne(false);
    }
    if (winodwsPids.length <= 3) {
      setStatusTwo(false);
    }
    GetActivePromotions().then((ress) => {
      setActivePromotions(ress);
    });
    GetPackages(linuxPids).then((res) => {
      setBussinesHostingLinuxList(res);
      setLoading(true);
      // delayTime(5000).then(() => {
      GetPackages(winodwsPids).then((respo) => {
        setBussinesHostingWindowsList(respo);
        setComparePlansState(respo);
        setLoading(true);
      });
      // });
    });
  }, [currencyName]);

  const buttonAddToCart = (e, item) => {
    e.preventDefault();
    dispatch({
      type: "addingCycle",
      payload: {
        packageId: item.pid,
        packageCycle: "biennially",
      },
    });
    dispatch({
      type: "packageCart",
      payload: item,
    });

    //redirecting to the new page
    navigate("/free-domain");
  };

  return (
    <>
      <Helmet>
        <title>
          Buy free.PK Domain with Hosting in Pakistan - WebSouls
        </title>
        <meta
          name="title"
          content="Buy free.PK Domain with Hosting in Pakistan - WebSouls"
        />
        <meta
          name="description"
          content="Get a Free.PK Domain with Hosting at WebSouls - Your one-stop solution for web hosting and domain registration in Pakistan. Launch your website with a unique.PK domain, coupled with powerful hosting services. Grab this exclusive offer today."
        />
        <meta name="keywords" content="" />

        <script type="application/ld+json">{`
        {
          "@context": "https://schema.org",
          "@type": "Organization",
          "name": "WebSouls",
          "alternateName": "Web Hosting Pakistan",
          "url": "https://www.websouls.com/",
          "logo": "https://www.websouls.com/public/uploads/images/logo_1589438878.png",
          "contactPoint": [{
            "@type": "ContactPoint",
            "telephone": "+92 321 407 7991",
            "contactType": "technical support",
            "areaServed": "PK",
            "availableLanguage": ["en","Urdu"]
          },{
            "@type": "ContactPoint",
            "telephone": "+92 322 525 2352",
            "contactType": "sales",
            "areaServed": "PK",
            "availableLanguage": ["en","Urdu"]
          }],
          "sameAs": [
            "https://www.facebook.com/WebSouls/",
            "https://www.twitter.com/futuresouls",
            "https://www.instagram.com/websouls.pk/",
            "https://www.linkedin.com/company/websouls/",
            "https://github.com/websouls",
            "https://www.websouls.com/"
          ]
        }
    `}</script>
        <script type="application/ld+json">{`
        {
          "@context": "https://schema.org/", 
          "@type": "Product", 
          "name": "Shared Hosting Pakistan - WebSouls",
          "image": "https://websouls.com/public/uploads/images/footer_logo_1589438878.png",
          "description": "WebSouls brings you highly affordable web hosting solutions in Pakistan that’re exceedingly secure, with unprecedented customer support and a guarantee of 99.99% up-time, all at the best possible price!",
          "brand": "WebSouls",
          "sku": "Pakistani Web Hosting",
          "mpn": "Pakistani Web Hosting",
          "offers": {
            "@type": "AggregateOffer",
            "url": "https://www.websouls.com/shared-hosting",
            "priceCurrency": "PKR",
            "lowPrice": "10900",
            "highPrice": "28000",
            "offerCount": "3"
          },
          "aggregateRating": {
            "@type": "AggregateRating",
            "ratingValue": "5",
            "bestRating": "5",
            "worstRating": "4",
            "ratingCount": "2927"
          }
        }
    `}</script>
        <script type="application/ld+json">{`
        {
          "@context": "https://schema.org",
          "@type": "FAQPage",
          "mainEntity": [{
            "@type": "Question",
            "name": "What is Shared Hosting?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Shared web hosting is an affordable hosting plan that’s perfect for small businesses and start-ups. Multiple website owners share resources of a single server to make their sites available on the internet. WebSouls offers various shared hosting packages that are light on the wallet and can fulfil all your website needs."
            }
          },{
            "@type": "Question",
            "name": "Do I need to have prior technical knowledge for shared hosting?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "No prior technical knowledge is required for shared hosting. Just after you’ve ordered your service, we’d send you your login details as well as all the necessary information required to start your hosting journey.  In addition to this, we also offer quality support through live chat, email, and phone, so you can always hit us up in case of any questions."
            }
          },{
            "@type": "Question",
            "name": "How does shared hosting differ from business hosting?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "The key difference between shared and businesses hosting is the number of clients per server. Apart from this, there are also certain limitations, such as the hourly emails. However, your website won’t be affected in any other way if you decide to go for shared server space."
            }
          },{
            "@type": "Question",
            "name": "Would I be able to upgrade my package later?",
            "acceptedAnswer": {
              "@type": "Answer",
              "text": "Yes, you can always upgrade your package through a quick process and there’s no downtime. If you register more domains or need to use your account for hosting more websites, it’s quite easy to upgrade your package from Premium to Standard and to Professional hosting plan."
            }
          }]
        }
    `}</script>
        <link rel="canonical" href="https://websouls.com/shared-hosting" />
      </Helmet>
      <section className="ssliderhost banner-size  ">
        <div className="container">
          <div className="row">
            <h2 className="first-heading">
              Get a Free .PK Domain &amp; SSL Certificate!
            </h2>
            <h5 className="second-heading">
              Elevate your web presence with our &#39;pk hosting&#39; plans, now
              with the added value!
            </h5>

            <ul className="nav nav-tabs" id="myTab" role="tablist">
              <li className="nav-item " role="presentation">
                <button
                  className="nav-link active"
                  id="linux-tab"
                  data-bs-toggle="tab"
                  data-bs-target="#linux"
                  type="button"
                  role="tab"
                  aria-controls="linux"
                  aria-selected="true"
                >
                  <i className="fa fa-linux" />
                  Linux
                </button>
              </li>
            </ul>
            <div className="tab-content" id="myTabContent">
              <div
                className="tab-pane fade show active"
                id="linux"
                role="tabpanel"
                aria-labelledby="linux-tab"
              >
                <>
                  {loading ? (
                    <>
                      <Carousel
                        className="animate__animated animate__fadeIn"
                        itemPadding={[8, 10, 8, 10]}
                        breakPoints={breakPoints}
                        pagination={true}
                        showArrows={statusOne}
                      >
                        {bussinessHostingLinuxList?.map((item) => (
                          <React.Fragment key={shortid.generate()}>
                            <div
                              style={{
                                backgroundColor: "transparent",
                                border: "1px solid #D2D2D2",
                                borderRadius: 5,
                              }}
                              className="col-lg-4 pacakge-one text-start"
                            >
                              <div className="package-name ">
                                <h3>{item?.name}</h3>
                                <p className="package-namepaaaa">
                                  {" "}
                                  {item.fname}
                                </p>
                              </div>
                              <div
                                style={{ marginBottom: 0 }}
                                className="package-one-price checkss"
                              >
                                {getPKPrice(
                                  item?.currency
                                    ?.filter(
                                      (fil) =>
                                        fil?.currency === currencyName?.api_id
                                    )
                                    ?.map(
                                      (pr) =>
                                        pr[
                                          getPackageBaseCycle(
                                            item,
                                            currencyName?.api_id
                                          )
                                        ]
                                    ),
                                  item?.pid,
                                  getPackageBaseCycle(
                                    item,
                                    currencyName?.api_id
                                  ),
                                  getActivePromotions,
                                  currencyName?.prefix
                                )}
                              </div>
                              <ul>
                                {item.packageFeatures &&
                                  item.packageFeatures[0]?.features.map(
                                    (elss) => (
                                      <li key={shortid.generate()}>
                                        <font>
                                          <strong
                                            style={{
                                              color: "rgb(0, 88, 128)",
                                              fontSize: 20,
                                              fontWeight: 400,
                                              paddingRight: 8,
                                            }}
                                          >
                                            ✓{" "}
                                          </strong>
                                        </font>

                                        {elss.includes("_") ? (
                                          <>
                                            {elss.split("_")[0]}
                                            {
                                              <i className="fa fa-info-circle">
                                                <span
                                                  className="litooltip"
                                                  dangerouslySetInnerHTML={{
                                                    __html: elss.split("_")[1],
                                                  }}
                                                />
                                              </i>
                                            }
                                          </>
                                        ) : (
                                          elss
                                        )}
                                      </li>
                                    )
                                  )}
                              </ul>

                              <button
                                onClick={(e) => buttonAddToCart(e, item)}
                                className="text "
                              >
                                Add To Cart
                              </button>
                            </div>
                          </React.Fragment>
                        ))}
                      </Carousel>
                    </>
                  ) : (
                    <>
                      <Carousel
                        itemPadding={[8, 10, 8, 10]}
                        breakPoints={breakPoints}
                        pagination={true}
                      >
                        <div
                          className="col-lg-4 pacakge-one text-start "
                          style={{
                            backgroundColor: "transparent",
                            border: "1px solid rgb(210, 210, 210)",
                            borderRadius: 5,
                          }}
                        >
                          <div className="package-name ">
                            <h3>
                              <Skeleton width={200} />
                            </h3>
                            <p>
                              <Skeleton width={100} />
                            </p>
                          </div>
                          <div
                            className="package-one-price checkss"
                            style={{ marginBottom: 0 }}
                          >
                            <div className="discount">
                              <h4 className="">
                                <b>
                                  <Skeleton width={300} />
                                </b>
                              </h4>
                            </div>
                            <div className="package-one-price">
                              <span className="amount">
                                {" "}
                                <Skeleton width={190} />
                              </span>
                            </div>
                          </div>
                          <ul>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                          </ul>
                          <Skeleton
                            width={200}
                            style={{ paddingTop: 20, marginTop: 10 }}
                          />
                        </div>
                        <div
                          className="col-lg-4 pacakge-one text-start "
                          style={{
                            backgroundColor: "transparent",
                            border: "1px solid rgb(210, 210, 210)",
                            borderRadius: 5,
                          }}
                        >
                          <div className="package-name ">
                            <h3>
                              <Skeleton width={200} />
                            </h3>
                            <p>
                              <Skeleton width={100} />
                            </p>
                          </div>
                          <div
                            className="package-one-price checkss"
                            style={{ marginBottom: 0 }}
                          >
                            <div className="discount">
                              <h4 className="">
                                <b>
                                  <Skeleton width={300} />
                                </b>
                              </h4>
                            </div>
                            <div className="package-one-price">
                              <span className="amount">
                                {" "}
                                <Skeleton width={190} />
                              </span>
                            </div>
                          </div>
                          <ul>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                          </ul>
                          <Skeleton
                            width={200}
                            style={{ paddingTop: 20, marginTop: 10 }}
                          />
                        </div>
                        <div
                          className="col-lg-4 pacakge-one text-start "
                          style={{
                            backgroundColor: "transparent",
                            border: "1px solid rgb(210, 210, 210)",
                            borderRadius: 5,
                          }}
                        >
                          <div className="package-name ">
                            <h3>
                              <Skeleton width={200} />
                            </h3>
                            <p>
                              <Skeleton width={100} />
                            </p>
                          </div>
                          <div
                            className="package-one-price checkss"
                            style={{ marginBottom: 0 }}
                          >
                            <div className="discount">
                              <h4 className="">
                                <b>
                                  <Skeleton width={300} />
                                </b>
                              </h4>
                            </div>
                            <div className="package-one-price">
                              <span className="amount">
                                {" "}
                                <Skeleton width={190} />
                              </span>
                            </div>
                          </div>
                          <ul>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                            <li>
                              <Skeleton style={{ width: "100%" }} />
                            </li>
                          </ul>
                          <Skeleton
                            width={200}
                            style={{ paddingTop: 20, marginTop: 10 }}
                          />
                        </div>
                      </Carousel>
                    </>
                  )}
                </>
              </div>
            </div>
          </div>
        </div>
      </section>
      <AllPlanInclude plansData={allplans} />
      <BundelBooster bundleData={BundleData} />
      <GoingOnline Data={GoingOnlineData} />
      <WhyChooseUsThird Data={ChoseUsData} />
      <DomainTips Data={technicalSpecificationsData} />
      <ComparePlans comparePlansState={bussinessHostingLinuxList} />
      <DomainMigration Data={data} />
      <InPakistan Data={InPakistanData} />
      <Faq Data={faqData} />
      <WillingForHelp helpData={WillingToHelpData} />
    </>
  );
};

export default PkHosting;
