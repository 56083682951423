import React from "react";
import { Helmet } from "react-helmet";
import BannerTwo from "../reuseable/Banner2";
import ContactUsComp from "../reuseable/contactusComp";
import GetInTouch from "../reuseable/GetInTouch";
import GMap from "../reuseable/GMap";

const ContactUs = () => {
  const bannerData = {
    heading: "Let’s Collaborate!",
    peragraph: "Here to Help You Move Ahead, Faster!",
  };
  return (
    <>
      <Helmet>
        <title>Live Chat | Telephonic Support | Email Support | WebSouls</title>
        <meta
          name="title"
          content="Live Chat | Telephonic Support | Email Support | WebSouls"
        />
        <meta
          name="description"
          content="WebSouls customers can reach us via multiple ways. You can connect us through Live Chat, you can call us, or can open a ticket in relevant department."
        />
        <meta name="keywords" content="" />
        <link rel="canonical" href="https://websouls.com/contactus" />
      </Helmet>
      <BannerTwo Data={bannerData} />
      <ContactUsComp />
      <GetInTouch />
      <GMap />
    </>
  );
};

export default React.memo(ContactUs);
