import React from "react";
import { useLocation } from "react-router-dom";

const InPakistan = ({ Data }) => {
  
  const location = useLocation()
  return (
    <section className={location.pathname === "/dedicated-server" ? "pakistan_hosting " : "pakistan_hosting pt-100"}>
      <div className="container">
        <h1 className="first-heading">{Data?.Heading}</h1>
        <h5 className="second-heading">{Data?.subHeading}</h5>
        <hr className="divider scnd_divider mx-auto" />
        <div className="websouls_experience">
          <p>{Data?.peragraph}</p>
        </div>
      </div>
    </section>
  );
};

export default InPakistan;
