import axios from "axios";

export const GetPackagesTest = (Pids) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}api/currencies/dump_test?p_id=${Pids}`)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error.response.data);
      });
  });
};

export const GetCurrencies = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}api/currencies/all`)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error.response.data);
      });
  });
};

export const GetustomerIp = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}api/currencies/ip`)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error.response.data);
      });
  });
};
export const GetustomerCityNew = (ip) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${process.env.REACT_APP_GEO_IP_URL}${ip}`)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error.response.data);
      });
  });
};

export const GetCustomerCityFromNode = (ip) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}api/currencies/ip-city/${ip}`)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error.response.data);
      });
  });
};



export const GetustomerCity = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        "https://api.ipgeolocation.io/ipgeo?apiKey=e724a6391973430fb2f9eb62c4025d72"
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error.response.data);
      });
  });
};

export const GetPackages = (Pids) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}api/currencies/dump`, {
        p_id: Pids,
      })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error.response.data);
      });
  });
};

export const GetPackagess = async (Pids) => {
  const response = await axios.post(
    `${process.env.REACT_APP_BASE_URL}api/currencies/packages`,
    null,
    {
      params: {
        p_id: Pids,
      },
    }
  );
  return response;
};

export const GetPackageAddons = (pid) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${process.env.REACT_APP_BILLING_URL}custom/package_addon_node.php?package_id=${pid}`
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error.response.data);
      });
  });
};

export const GetPackageAddonsCustomName = (pid) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${process.env.REACT_APP_BILLING_URL}custom/package_addon_node.php?package_id=${pid}`
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error.response.data);
      });
  });
};

export const GetActivePromotions = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}api/currencies/getActivePromotions`
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error.response.data);
      });
  });
};

export const GetSpotlightTldswithPrice = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}api/currencies/getSpotlightTldswithPrice`
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error.response.data);
      });
  });
};

export const GetTldPriceNew = (tld) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}api/currencies/tldPricenew`,
        null,
        {
          params: {
            tld: tld,
            typ: "domainregister",
          },
        }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error.response.data);
      });
  });
};

export const GetAllTlds = () => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}api/currencies/alltlds`, null, {
        params: {
          page: 0,
          limit: 20,
          typ: "domainregister",
        },
      })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error.response.data);
      });
  });
};

export const DomainSearchApi = (domainName, tlds, domainType) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}api/currencies/serachDomain`,
        null,
        {
          params: {
            domain: domainName,
            suggestedTlds: tlds,
            domianTyp: domainType,
          },
        }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error.response.data);
      });
  });
};

export const GetPackageFreeDomains = (pid) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${process.env.REACT_APP_BILLING_URL}custom/package_free_domains.php?pid=${pid}`
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error.response.data);
      });
  });
};

export const DomainSearchTransfer = (domainName, tlds, domainType) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}api/currencies/serachDomainTransfer`,
        null,
        {
          params: {
            domain: domainName,
            suggestedTlds: tlds,
            domianTyp: domainType,
          },
        }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error.response.data);
      });
  });
};

export const GetAllBlogs = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}api/posts`)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error.response.data);
      });
  });
};

export const GetrecentBlogs = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}api/posts/recents`)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error.response.data);
      });
  });
};

export const GetAllBlogsById = (Id) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${process.env.REACT_APP_BASE_URL}api/posts/${Id}`)
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error.response.data);
      });
  });
};
export const LoginByEmail = (email) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}api/currencies/loginapi`, null, {
        params: {
          action: "GetUsers",
          // username: `${process.env.REACT_APP_User}`,
          // password: `${process.env.REACT_APP_Pass}`,
          search: email,
          responsetype: "json",
        },
      })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error.response.data);
      });
  });
};
export const LoginByEmaill = (email) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}api/currencies/loginapi`, null, {
        params: {
          action: "GetClientsDetails",
          // username: `${process.env.REACT_APP_User}`,
          // password: `${process.env.REACT_APP_Pass}`,
          email: email,
          responsetype: "json",
        },
      })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error.response.data);
      });
  });
};

export const AddClient = (
  customerFirstName,
  customerLastName,
  customerEmailAddress,
  customerAddress,
  customerCity,
  customerState,
  customerPostalCode,
  customerCountry,
  customerPhoneNumber,
  customerPassword,
  customerWhereToFind,
  currencyName
) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}api/currencies/loginapi`, null, {
        params: {
          action: "AddClient",
          // username: `${process.env.REACT_APP_User}`,
          // password: `${process.env.REACT_APP_Pass}`,
          firstname: customerFirstName,
          lastname: customerLastName,
          email: customerEmailAddress,
          address1: customerAddress,
          city: customerCity,
          state: customerState,
          postcode: customerPostalCode,
          country: customerCountry,
          phonenumber: customerPhoneNumber,
          password2: customerPassword,
          customfields: customerWhereToFind,
          currency: currencyName,
          responsetype: "json",
        },
      })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error.response.data);
      });
  });
};

export const ValidateLogin = (customerEmailAddress, customerPassword) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}api/currencies/loginapi`, null, {
        params: {
          action: "ValidateLogin",
          // username: `${process.env.REACT_APP_User}`,
          // password: `${process.env.REACT_APP_Pass}`,
          email: customerEmailAddress,
          password2: customerPassword,
          responsetype: "json",
        },
      })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error.response.data);
      });
  });
};
export const AddOrder = (
  clientID,
  packagePid,
  packageBillingCycle,
  packageAddonsState,
  domainTypesOrder,
  domainForOrder,
  paymentMethodSelected,
  packagePrOverride,
  domainBillingCycle,
  domainIdPro
) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}api/currencies/loginapi`, null, {
        params: {
          action: "AddOrder",
          // username: `${process.env.REACT_APP_User}`,
          // password: `${process.env.REACT_APP_Pass}`,
          clientid: clientID,
          pid: packagePid,
          billingcycle: packageBillingCycle,
          addons: packageAddonsState,
          domaintype: domainTypesOrder,
          domain: domainForOrder,
          paymentmethod: paymentMethodSelected,
          priceoverride: packagePrOverride,
          regperiod: domainBillingCycle,
          idprotection: domainIdPro,
          responsetype: "json",
        },
      })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error.response.data);
      });
  });
};

export const SSOToken = (clientIDs, invoiceid) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}api/currencies/loginapi`, null, {
        params: {
          action: "CreateSsoToken",
          // username: `${process.env.REACT_APP_User}`,
          // password: `${process.env.REACT_APP_Pass}`,
          client_id: clientIDs,
          destination: "sso:custom_redirect",
          sso_redirect_path: `viewinvoice.php?id=${invoiceid}`,
          responsetype: "json",
        },
      })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error.response.data);
      });
  });
};

export const clientAreaSSOToken = (clientIDs) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}api/currencies/loginapi`, null, {
        params: {
          action: "CreateSsoToken",
          // username: `${process.env.REACT_APP_User}`,
          // password: `${process.env.REACT_APP_Pass}`,
          client_id: clientIDs,
          destination: "sso:custom_redirect",
          sso_redirect_path: "clientarea.php",
          responsetype: "json",
        },
      })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error.response.data);
      });
  });
};

export const SendPopupEmail = (
  name,
  phone,
  email,
  packageId,
  packageName,
  pageUrl,
  typ
) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}api/currencies/sendPopupEmail`,
        null,
        {
          params: {
            name: name,
            mobile: phone,
            email: email,
            package_id: packageId,
            package_name: packageName,
            page_url: pageUrl,
            typ: typ,
          },
        }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error.response.data);
      });
  });
};

export const SendPopupEmailSeo = (
  name,
  phone,
  email,
  weburl,
  packageId,
  packageName,
  pageUrl,
  typ
) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}api/currencies/sendPopupEmail`,
        null,
        {
          params: {
            name: name,
            mobile: phone,
            email: email,
            package_id: packageId,
            package_name: packageName,
            page_url: pageUrl,
            typ: typ,
            webUrl: weburl
          },
        }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error.response.data);
      });
  });
};

export const GetPkTlds = () => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}api/currencies/pktlds`, null, {
        params: {
          page: 0,
          limit: 20,
          typ: "domainregister",
        },
      })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error.response.data);
      });
  });
};
export const GetPaymentMethods = (email) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}api/currencies/loginapi`, null, {
        params: {
          action: "GetPaymentMethods",
          // username: `${process.env.REACT_APP_User}`,
          // password: `${process.env.REACT_APP_Pass}`,
          responsetype: "json",
        },
      })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error.response.data);
      });
  });
};

export const saleApi = () => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${process.env.REACT_APP_BASE_URL}api/currencies/salesTag`
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error.response.data);
      });
  });
};


export const GetClienSevert = (amounts, currecnys, emails, names, phones, dess, stdess, invouiceId) => {
  return new Promise((resolve, reject) => {
    axios
      .post(
        `${process.env.REACT_APP_BASE_URL}create-payment-intent-customers`, null, {
        params: {
          total: amounts,
          currency: currecnys,
          email: emails,
          name: names,
          phone: phones,
          des: dess,
          stdes: stdess,
          invoiceId: invouiceId,
        },
      }
      )
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error.response.data);
      });
  });
};

export const GetInvoicePrice = (
  invoiceID
) => {
  return new Promise((resolve, reject) => {
    axios
      .post(`${process.env.REACT_APP_BASE_URL}api/currencies/loginapi`, null, {
        params: {
          action: "GetInvoice",
          // username: `${process.env.REACT_APP_User}`,
          // password: `${process.env.REACT_APP_Pass}`,
          invoiceid: invoiceID,
          responsetype: "json",
        },
      })
      .then(function (response) {
        resolve(response.data);
      })
      .catch(function (error) {
        reject(error.response.data);
      });
  });
};
