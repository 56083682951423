import React from "react";

const Passion = ({ Data }) => {
  return (
    <section style={{paddingBottom: 100}}  className="passion pt-100">
      <div className="container">
        <div className="row">
          <div className="col-md-7">
            <div className="passion_text">{Data.Text}</div>
          </div>
          <div className="col-md-5">
            <div className="passion_img">
              <img src={Data.img} className="lazy" alt="" />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Passion;
