import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import BannerTwo from "../reuseable/Banner2";
import Passion from "../reuseable/Passion";
import Vision from "../reuseable/Vision";

const About = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const bannerData = {
    heading: "The Soul Of WebSouls",
    peragraph: "We Love Hosting, Both Our Clients and Their Sites!",
  };
  const PassionData = {
    img: "assests/img/Linux-&-Windows-Hosting-Providor.png",
    Text: [
      <>
        <h3>
          Helping <span> Brands </span> Grow Since
          <br /> 2002
        </h3>
        <p>
          One of the four companies of FutureSouls Group of Companies, WebSouls
          proudly represents itself as the no. 1 web hosting services provider
          of Pakistan, delivering powerful hosting solutions for businesses of
          all sizes and budgets. However, one thing that’s similar in all our
          clients is their “Big Goals” and we put in all our best efforts,
          combined with the latest technology, to help turn their dreams into
          reality!
        </p>
        <p>
          As a result of our unprecedented hosting services, WebSouls has been
          successfully uplifting businesses since the past 18 years, helping
          them grow into just who they want to be by holding their hands and
          taking on to ourselves the responsibility of making them prosper.
        </p>
        <p>
          Being one of the few official partners of cPanel, we take pride in
          living up to fulfil our promise to our clients: dependable hosting
          that’s easy-to-use and absolutely worth every single penny.
        </p>
        <p>
          It’s the trust of our customers that keeps us going, and that is
          exactly what we work to earn. Because, we’re not just a hosting
          company, we’re your constant growth partners, and we’d do everything
          to make your digital life future-proof – at WebSouls,
          <em> We Make Customers, Not Sale!</em>
        </p>
      </>,
    ],
  };
  return (
    <>
      <Helmet>
        <title>No.1 Web Hosting Company of Pakistan | About WebSouls</title>
        <meta
          name="title"
          content="No.1 Web Hosting Company of Pakistan | About WebSouls"
        />
        <meta
          name="description"
          content="WebSouls is registered in Pakistan & UK since 2002. WebSouls have thousands of national, international and corporate clients working form many years."
        />
        <meta name="keywords" content="" />
        <link rel="canonical" href="https://websouls.com/about" />
      </Helmet>
      <BannerTwo Data={bannerData} />
      <Passion Data={PassionData} />
      <Vision />
    </>
  );
};

export default About;
