import React, { useState, useEffect } from "react";
import { useNavigate, Link, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  DomainSearchTransfer,
  GetPackageFreeDomains,
} from "../../services/Network";

import { DomainTld } from "../../services/Helper";

import CartOrder from "./CartOrder";

const WonFreeDomain = () => {
  //router hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const { currencyName } = useSelector((state) => state.currencyName);
  const { packageCart } = useSelector((state) => state.packageCart);

  const [isActive, setActive] = useState(false);
  const [isActive1, setActive1] = useState(true);
  const [isActive2, setActive2] = useState(false);
  const [isActive3, setActive3] = useState(false);
  const [isChecked, setChecked] = useState(false);

  //form states
  const [registerDomainName, setRegisterDomainName] = useState("");
  const [registerDomainTld, setRegisterDomainTld] = useState("com");
  const [transferDomainTld, setTransferDomainTld] = useState("com");
  const [transferDomainName, setTransferDomainName] = useState("");
  const [existingDomainTld, setExistingDomainTld] = useState("com");
  const [existingDomainName, setExistingDomainName] = useState("");
  const [DomainSearchResults, setDomainSearchResults] = useState([]);
  const [DomainTransferResults, setDomainTransferResults] = useState([]);
  const [radioName, setRadioName] = useState({});
  const [radioType, setRadioType] = useState("domainregister");
  const [priceDataSearchDomain, setPriceDataSearchDomain] = useState([]);
  const [reducerDomainName, setReducerDomainName] = useState("");

  useEffect(() => {
    dispatch({
      type: "urlCurrent",
      payload: location.pathname,
    });
  }, [reducerDomainName]);

  useEffect(() => {
    if (location.key === "default") {
      navigate("/");
    }
  }, []);

  //handeling Register Domain

  const handelRegisterDomain = (e) => {
    const nameConcatenate = `${registerDomainName}`;
    setReducerDomainName(nameConcatenate);

    e.preventDefault();
    navigate("/domain", { state: { name: nameConcatenate, var: "bypass" } });
  };

  //handeling transfer domain

  const handelTransferDomain = (e) => {
    const nameConcatenate = `${transferDomainName}`;
    setReducerDomainName(nameConcatenate);

    e.preventDefault();

    //checking the radio type

    if (radioName.Domain === "Domain Register") {
      setRadioType("domainregister");
    } else if (radioName.Domain === "Domain Transfer") {
      setRadioType("domaintransfer");
    }

    //searching domain

    DomainSearchTransfer(
      nameConcatenate,
      registerDomainTld,
      "domaintransfer"
    ).then((res) => {
      setDomainTransferResults(res);
      handelCartDomain(res);
      if (res.available === 1) {
        setTimeout(() => {
          navigate("/get-addons");
        }, [2000]);
      }
    });
  };

  const handelExitingDomain = (e) => {
    e.preventDefault();
    let nameConcatenate;
    if (existingDomainName && existingDomainName.includes(".")) {
      nameConcatenate = `${existingDomainName}`;
    } else {
      nameConcatenate = `${existingDomainName}.com`;
    }

    dispatch({
      type: "clientDomainName",
      payload: nameConcatenate,
    });
    setTimeout(() => {
      navigate("/get-addons");
    }, [1000]);
  };

  //continye to cart

  const handelCartDomain = (DomainTransferResults) => {
    // e.preventDefault();
    //return;
    let newPrice = DomainTransferResults?.price?.filter(
      (el) => el.currency === currencyName.api_id
    );

    var lastIndex = packageCart.length - 1;
    //checking the domain is free or not
    GetPackageFreeDomains(packageCart[lastIndex]?.pid).then((ress) => {
      // for (let i = 0; i < packageCart?.length; i++) {
      if (DomainTransferResults && DomainTransferResults.available === 1) {
        if (
          DomainTransferResults &&
          DomainTransferResults.searchedDomain.includes(ress[0]) &&
          packageCart[lastIndex]?.freedomain?.length === 0
        ) {
          dispatch({
            type: "freeDomain",
            payload: {
              name: radioName.Domain,
              domainName: DomainTransferResults.searchedDomain,
              price: DomainTransferResults.price,
            },
          });
        } else {
          // if (
          //   DomainTransferResults.searchedDomain !=
          //   packageCart[i].freedomain[0].domainName
          // ) {

          const tld = DomainTld(DomainTransferResults.searchedDomain);

          var bilcycle = "annually";
          if (tld.includes("pk")) {
            bilcycle = "biennially";
          }

          dispatch({
            type: "addingCycleDomain",
            payload: {
              domainName: DomainTransferResults.searchedDomain,
              packageCycle: bilcycle,
            },
          });
          dispatch({
            type: "domainSearchCart",
            payload: {
              name: radioName.Domain,
              domainName: DomainTransferResults.searchedDomain,
              price: DomainTransferResults.price,
            },
          });
          //}
        }
      }
      //  }
    });
  };

  //handel radio button chnage
  const handleRadioChange = (e) => {
    const { name, value } = e.target;
    setRadioName({ [name]: value });
  };

  // Style
  const searchBox = {
    backgroundColor: "#f4f4f4",
    borderRadius: 5,
    border: "1px solid #d7d7d7",
    boxShadow: "#f6f6f6 0px 0px 11px 4px",
    padding: "0px 0px 30px 0px",
  };

  const toggleChecked1 = (e) => {
    setActive1(true);
    setActive2(false);
    setActive3(false);
  };
  const toggleChecked2 = (e) => {
    setActive1(false);
    setActive2(true);
    setActive3(false);
  };
  const toggleChecked3 = (e) => {
    setActive1(false);
    setActive2(false);
    setActive3(true);
  };

  return (
    <section style={{ margin: "0" }} className="psty">
      <div className="container">
        <div className="row g-5 mb-5">
          <CartOrder />
          <div className="col-lg-7 col-xl-8 col-xxl-8 margin-top11">
            <div className="selection_tab">
              {/* for regtister domain */}

              <div
                className="first_select_tab mb-3"
                id="first_tab"
                style={isActive1 ? searchBox : null}
              >
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <label className="section-tab-heading checkbox_container active py-3">
                        <input
                          className=""
                          type="radio"
                          name="Domain"
                          value="Domain Register"
                          defaultChecked={true}
                          onClick={(e) => toggleChecked1(e.target)}
                          onChange={(e) => handleRadioChange(e)}
                        />
                        Register a new domain name
                        <span className="checkedmark m-1" />
                      </label>
                    </div>
                  </div>

                  <div
                    className="first_tab search_form"
                    style={
                      isActive1 ? { display: "block" } : { display: "none" }
                    }
                  >
                    <form onSubmit={(e) => handelRegisterDomain(e)}>
                      <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12">
                          <div className="input-group p-3">
                            <div className="input-group box-border">
                              <input
                                type="search"
                                name="search"
                                id="check_search"
                                className="form-control wondfreedomain"
                                placeholder="Search your domain here..."
                                aria-labelledby="search-domain"
                                aria-label="Search your domain here"
                                value={registerDomainName}
                                onChange={(e) =>
                                  setRegisterDomainName(e.target.value)
                                }
                              />
                              <div className="input-group-append">
                                <button
                                  disabled={registerDomainName === ""}
                                  onClick={(e) => handelRegisterDomain(e)}
                                  className="checkdomian input-group-text"
                                  type="submit"
                                  style={{ height: "100%" }}
                                >
                                  <i className="fa fa-search" />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div
                className="congratulation_section"
                id="empty-error"
                style={{ display: "none" }}
              >
                <div className="row">
                  <div className="col-md-12">
                    <div className="search_congratulation_msg search_domain_msg">
                      <div className="row">
                        <div className="col-md-1 col-sm-2 col-xs-2 pr-0">
                          <i className="fa fa-times-circle" />
                        </div>
                        <div className="col-md-8 col-sm-8 col-xs-8 pl-0 pr-0">
                          <div className="pl-4">
                            <h5 style={{ paddingTop: 10 }}>
                              Please enter the domain name first.
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {DomainSearchResults &&
              DomainSearchResults.length === 0 ? null : (
                <>
                  {DomainSearchResults &&
                  DomainSearchResults.available === 1 ? (
                    <div className="congratulation_section" id="domian-status">
                      <div className="congratulation_section">
                        <div className="row">
                          <div className="col-md-10 pr-0 pl-0 offset-md-1">
                            <div className="search_congratulation_msg">
                              <div className="row">
                                <div className="col-md-1 col-sm-2 col-xs-2 pr-0">
                                  <i className="fa fa-check-circle" />
                                </div>
                                <div className="col-md-8 col-sm-8 col-xs-8 pl-0 pr-0">
                                  <div className="pl-3">
                                    <h4>
                                      Congratulations!{" "}
                                      <span>
                                        {DomainSearchResults.searchedDomain}
                                      </span>{" "}
                                      can now be yours!
                                    </h4>
                                  </div>
                                </div>
                                <div className="col-md-3 col-sm-3 col-xs-3">
                                  <Link to="#">
                                    <button
                                      onClick={(e) => handelCartDomain(e)}
                                    >
                                      Continue
                                      <i className="fa fa-arrow-right" />
                                    </button>
                                  </Link>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </>
              )}

              {DomainSearchResults &&
              DomainSearchResults.length === 0 ? null : (
                <>
                  {DomainSearchResults &&
                  DomainSearchResults.available === 2 ? (
                    <div className="congratulation_section" id="domian-status">
                      <div className="congratulation_section">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="search_congratulation_msg search_domain_msg">
                              <div className="row">
                                <div className="col-md-1 col-sm-2 col-xs-2 pr-0">
                                  <i className="fa fa-times-circle" />
                                </div>
                                <div className="col-md-8 col-sm-8 col-xs-8 pl-0 pr-0">
                                  <div className="pl-4">
                                    <h5>Oops! Looks like </h5>
                                    <p>Search for valid Tld</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </>
              )}

              {DomainSearchResults &&
              DomainSearchResults.length === 0 ? null : (
                <>
                  {DomainSearchResults &&
                  DomainSearchResults.available === 0 ? (
                    <div className="congratulation_section" id="domian-status">
                      <div className="congratulation_section">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="search_congratulation_msg search_domain_msg">
                              <div className="row">
                                <div className="col-md-1 col-sm-2 col-xs-2 pr-0">
                                  <i className="fa fa-times-circle" />
                                </div>
                                <div className="col-md-8 col-sm-8 col-xs-8 pl-0 pr-0">
                                  <div className="pl-4">
                                    <h5>
                                      Oops! Looks like{" "}
                                      <span>
                                        {" "}
                                        {
                                          DomainSearchResults.searchedDomain
                                        }{" "}
                                      </span>
                                      is already taken
                                    </h5>
                                    <p>Search for another unique domain name</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : null}
                </>
              )}

              {/* for transfer domain */}

              <div
                className="first_select_tab mb-3"
                id="first_tab"
                style={isActive2 ? searchBox : null}
              >
                <div className="col-12">
                  <div className="form-group">
                    <label className="section-tab-heading checkbox_container active py-3">
                      <input
                        type="radio"
                        name="Domain"
                        value="Domain Transfer"
                        onClick={(e) => toggleChecked2(e.target)}
                        onChange={(e) => handleRadioChange(e)}
                      />
                      Transfer your domain from another registrar
                      <span className="checkedmark m-1" />
                    </label>
                  </div>
                  <div
                    className="first_tab search_form"
                    style={
                      isActive2 ? { display: "block" } : { display: "none" }
                    }
                  >
                    {" "}
                    <form onSubmit={(e) => handelTransferDomain(e)}>
                      <div className="row">
                        <div className="col-md-12 col-sm-12 col-xs-12">
                          <div className="input-group p-3">
                            <div className="input-group box-border">
                              <input
                                type="search"
                                name="search"
                                id="check_search"
                                className="form-control wondfreedomain"
                                placeholder="Search your domain here..."
                                aria-labelledby="search-domain"
                                aria-label="Search your domain here"
                                value={transferDomainName}
                                onChange={(e) =>
                                  setTransferDomainName(e.target.value)
                                }
                              />
                              <div className="input-group-append">
                                <button
                                  id="trd"
                                  disabled={transferDomainName === ""}
                                  onClick={(e) => handelTransferDomain(e)}
                                  className="checkdomian input-group-text"
                                  type="submit"
                                  style={{ height: "100%" }}
                                >
                                  <i className="fa fa-search" />
                                </button>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
              <div
                className="congratulation_section"
                id="empty-error"
                style={{ display: "none" }}
              >
                <div className="row">
                  <div className="col-md-12">
                    <div className="search_congratulation_msg search_domain_msg">
                      <div className="row">
                        <div className="col-md-1 col-sm-2 col-xs-2 pr-0">
                          <i className="fa fa-times-circle" />
                        </div>
                        <div className="col-md-8 col-sm-8 col-xs-8 pl-0 pr-0">
                          <div className="pl-4">
                            <h5 style={{ paddingTop: 10 }}>
                              Please enter the domain name first.
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {DomainSearchResults &&
              DomainTransferResults.length === 0 ? null : (
                <>
                  {DomainSearchResults &&
                  DomainTransferResults.available === 1 ? (
                    <div className="congratulation_section" id="domian-status">
                      <div className="congratulation_section">
                        <div className="row">
                          <div className="col-md-10 pr-0 pl-0 offset-md-1">
                            <div className="search_congratulation_msg">
                              <div className="row">
                                <div className="col-md-1 col-sm-2 col-xs-2 pr-0">
                                  <i className="fa fa-check-circle" />
                                </div>
                                <div className="col-md-8 col-sm-8 col-xs-8 pl-0 pr-0">
                                  <div className="pl-3">
                                    <h4>
                                      Congratulation! Your domain{" "}
                                      <span>
                                        {DomainTransferResults.searchedDomain}
                                      </span>{" "}
                                      is eligible for transfer!
                                    </h4>
                                    <p>
                                      Some TLDs have different transfer
                                      procedures so please contact our Presales
                                      Team for further information
                                    </p>
                                  </div>
                                </div>
                                {/* <div className="col-md-3 col-sm-3 col-xs-3">
                                  <Link to="#">
                                    <button
                                      onClick={(e) => handelCartDomain(e)}
                                    >
                                      Continue
                                      <i className="fa fa-arrow-right" />
                                    </button>
                                  </Link>
                                </div> */}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  ) : (
                    <div className="congratulation_section" id="domian-status">
                      <div className="congratulation_section">
                        <div className="row">
                          <div className="col-md-12">
                            <div className="search_congratulation_msg search_domain_msg">
                              <div className="row">
                                <div className="col-md-1 col-sm-2 col-xs-2 pr-0">
                                  <i className="fa fa-times-circle" />
                                </div>
                                <div className="col-md-8 col-sm-8 col-xs-8 pl-0 pr-0">
                                  <div className="pl-4">
                                    <h5>
                                      Not Eligible for Transfer!{" "}
                                      <span>
                                        {" "}
                                        {
                                          DomainSearchResults.searchedDomain
                                        }{" "}
                                      </span>
                                      The domain you entered does not appear to
                                      be registered. If the domain was
                                      registered recently, you may need to try
                                      again later. Alternatively, you can
                                      perform a search to register this domain.
                                    </h5>
                                    <p>Search for another unique domain name</p>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  )}
                </>
              )}

              {/* For existing domain Second */}
              <div
                className="first_select_tab mb-3"
                id="first_tab"
                style={isActive3 ? searchBox : null}
              >
                <div className="form-group">
                  <label className="section-tab-heading checkbox_container active py-3">
                    <input
                      type="radio"
                      name="Domain"
                      value="Existing Domain"
                      onClick={(e) => toggleChecked3(e.target)}
                      onChange={(e) => handleRadioChange(e)}
                    />
                    I will use my existing domain and update my nameservers
                    <span className="checkedmark m-1" />
                  </label>
                </div>
                <div
                  className="first_tab search_form"
                  style={isActive3 ? { display: "block" } : { display: "none" }}
                >
                  <form onSubmit={(e) => handelExitingDomain(e)}>
                    <div className="row">
                      <div className="col-md-12 col-sm-12 col-xs-12">
                        <div className="input-group p-3">
                          <div className="input-group box-border">
                            <input
                              type="search"
                              name="search"
                              id="check_search"
                              className="form-control wondfreedomain"
                              placeholder="Search your domain here..."
                              aria-labelledby="search-domain"
                              aria-label="Search your domain here"
                              value={existingDomainName}
                              onChange={(e) =>
                                setExistingDomainName(e.target.value)
                              }
                            />
                            <div className="input-group-append">
                              <button
                                disabled={existingDomainName === ""}
                                onClick={(e) => handelExitingDomain(e)}
                                className="checkdomian input-group-text"
                                type="submit"
                                style={{ height: "100%" }}
                              >
                                <i className="fa fa-arrow-right" />
                              </button>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div
                className="congratulation_section"
                id="empty-error"
                style={{ display: "none" }}
              >
                <div className="row">
                  <div className="col-md-12">
                    <div className="search_congratulation_msg search_domain_msg">
                      <div className="row">
                        <div className="col-md-1 col-sm-2 col-xs-2 pr-0">
                          <i className="fa fa-times-circle" />
                        </div>
                        <div className="col-md-8 col-sm-8 col-xs-8 pl-0 pr-0">
                          <div className="pl-4">
                            <h5 style={{ paddingTop: 10 }}>
                              Please enter the domain name first.
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <br />
              <br />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default WonFreeDomain;
