import React, { useState, useEffect } from "react";
import CartOrder from "./CartOrder";
import SimpleCrousel from "./SimpleCrousel";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { GetPackages, GetActivePromotions } from "../../services/Network.js";

const GetHosting = () => {
  const navigate = useNavigate();
  const location = useLocation();
  
  const [getActivePromotions, setActivePromotions] = useState([]);
  const [packagesList, setPackagesList] = useState([]);
  const [loading, setLoading] = useState(false);
  const [pidddd] = useState([125, 130, 281]);

  useEffect(() => {
    if (location.key === "default") {
      navigate("/");
    }
    GetPackages(pidddd).then((res) => {
      setLoading(false);
      setPackagesList(res);
      setLoading(true);
      GetActivePromotions().then((ress) => {
        setActivePromotions(ress);
      });
    });
  }, []);
  return (
    <section style={{ margin: "0" }} className="psty">
      <div className="container">
        <div className="row">
          <Link
            to="#"
            style={{ textDecoration: "none", color: "#005880" }}
            className="go-back"
            onClick={() => navigate(-1)}
          >
            <i className="fa fa-arrow-left"></i> Go Back
          </Link>
        </div>
        <div className="row sm-g-0 g-5 mb-5">
          <CartOrder />
          <div className="col-lg-7 col-xl-8 col-xxl-8 ">
            <h6 className="result-message p-2 p-sm-1 p-md-4 p-lg-4">
              Get a free .com Domain with a Hosting Package
            </h6>
            <div className="searched-result  mb-2">
              <SimpleCrousel
                getActivePromotions={getActivePromotions}
                packagesList={packagesList}
                loading={loading}
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GetHosting;
