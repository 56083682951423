import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import axios from "axios";
import { useDispatch } from "react-redux";

const Login = ({ handelSignupToggle, handelLoginTogglenew }) => {
  const dispatch = useDispatch();
  const [loginEmail, setLoginEmail] = useState("");
  const [LoginPassword, setLoginPassword] = useState("");
  const [loginError, setloginError] = useState("");
  const [isLoginClick, setisLoginClick] = useState(false);

  useEffect(() => {
    const getlocalStorage = localStorage.getItem("UserId");
    if (getlocalStorage !== null) {
      handelSignupToggle();
    }
  }, []);

  const submitLoginData = (e) => {
    e.preventDefault();
    setisLoginClick(true);
    localStorage.setItem("loggedClicked", 1);
    axios
      .post(`${process.env.REACT_APP_BASE_URL}api/currencies/loginapi`, null, {
        params: {
          action: "ValidateLogin",
          // username: `${process.env.REACT_APP_User}`,
          // password: `${process.env.REACT_APP_Pass}`,
          email: loginEmail,
          password2: LoginPassword,
          responsetype: "json",
        },
      })
      .then((response) => {
        // return;
        if (response.data.result == "error") {
          handelLoginTogglenew();
          setloginError(response.data.message);
          setisLoginClick(false);
          //return;
        } else {
          handelSignupToggle();
          localStorage.setItem("UserId", response.data.userid);
          // localStorage.setItem("client_id", response.data.userid);
          localStorage.setItem("UserHashPass", response.data.passwordhash);

          axios
            //.post(`/includes/api.php`, null, {
            .post(
              `${process.env.REACT_APP_BASE_URL}api/currencies/loginapi`,
              null,
              {
                params: {
                  action: "GetClientsDetails",
                  // username: `${process.env.REACT_APP_User}`,
                  // password: `${process.env.REACT_APP_Pass}`,
                  email: loginEmail,
                  stats: true,
                  responsetype: "json",
                },
              }
            )
            .then((res) => {
              localStorage.setItem("client_id", res.data.client_id);
              localStorage.setItem("email", loginEmail);
              ////

              ////
              dispatch({
                type: "clientDetails",
                payload: res.data,
              });

              if (res.data.currency === 1) {
                dispatch({
                  type: "addCurrency",
                  payload: {
                    _id: "62878e42c58f1f206c473aa5",
                    api_id: 1,
                    code: "USD",
                    prefix: "$",
                    suffix: "",
                    format: 1,
                    rate: "1.00000",
                  },
                });
              } else {
                dispatch({
                  type: "addCurrency",
                  payload: {
                    _id: "62878e42c58f1f206c473aa6",
                    api_id: 3,
                    code: "PKR",
                    prefix: "Rs",
                    suffix: "/-",
                    format: 4,
                    rate: `${process.env.REACT_APP_PKR_RATE}`,
                    createdAt: "2022-05-20T12:49:06.354Z",
                    updatedAt: "2022-05-20T12:49:06.354Z",
                    __v: 0,
                  },
                });
              }
            });
        }
      })

      .catch((err) => console.warn(err));

    //
  };

  return (
    <>
      <form className="px-3 py-3" onSubmit={(e) => submitLoginData(e)}>
        <p>
          Don't have an account? {""}
          <Link to="#" onClick={() => handelSignupToggle()}>
            Sign Up!
          </Link>
          <br />
        </p>
        <p
          style={{
            color: "red",
            fontSize: "18px",
            fontSize: "14px",
            paddingTop: "10px",
          }}
        >
          {loginError}
        </p>
        <div className="row pt-1">
          <div className="col-md-12 col-ms-12 col-xs-12">
            <div className="form-group">
              <input
                className="form-control"
                name="email"
                type="text"
                placeholder="Enter Your Email"
                value={loginEmail}
                onChange={(e) => setLoginEmail(e.target.value)}
              />
            </div>
          </div>
          <div className="col-md-12 col-ms-12 col-xs-12">
            <div className="form-group">
              <input
                className="form-control"
                name="password2"
                type="password"
                placeholder="Enter Your Password"
                value={LoginPassword}
                onChange={(e) => setLoginPassword(e.target.value)}
              />
            </div>
          </div>
          <div className="col-md-12 col-sm-12 col-xs-12">
            {isLoginClick ? (
              <button
                disabled={true}
                className="button-login ml-0 pacakgenotAllowedsubmit"
                type="submit"
              >
                <i
                  style={{ fontSize: "20px" }}
                  className="fa fa-spinner fa-spin"
                ></i>
              </button>
            ) : (
              <button className="button-login ml-0" type="submit">
                Login
              </button>
            )}
          </div>
          <div
            className="col-md-12 col-sm-12 col-xs-12"
            style={{ marginTop: "-10px" }}
          >
            <a
              href="https://billing.websouls.com/index.php?rp=/password/reset"
              target="_blank"
              className="forgot_your_password"
            >
              Forgot Your Password?
            </a>
          </div>
        </div>
      </form>
    </>
  );
};

export default Login;
