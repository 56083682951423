import { GetActivePromotions } from "./Network";

export const getPromotedPrice = (
  normalPrice,
  promoType,
  discount,
  duration,
  promocycle
) => {
  const promoDuration = promocycle?.toLowerCase()?.split(",");

  for (var i = 0; i < promoDuration?.length; i++) {
    if (duration == promoDuration[i]) {
      var pd = promoDuration[i];
      break;
    }
  }
  var promo_duration;
  if (pd == "Monthly" || pd == "monthly") {
    promo_duration = 1;
  }
  if (pd == "Quarterly" || pd == "quarterly") {
    promo_duration = 3;
  }
  if (pd == "Semi-Annually" || pd == "semiannually") {
    promo_duration = 6;
  }
  if (pd == "Annually" || pd == "annually") {
    promo_duration = 12;
  }
  if (pd == "Biennially" || pd == "biennially") {
    promo_duration = 24;
  }
  if (pd == "Triennially" || pd == "triennially") {
    promo_duration = 36;
  }

  var factor;
  if (duration == "Monthly" || duration == "monthly") {
    factor = 1;
  }
  if (duration == "Quarterly" || duration == "quarterly") {
    factor = 3;
  }
  if (duration == "Semi-Annually" || duration == "semiannually") {
    factor = 6;
  }
  if (duration == "Annually" || duration == "annually") {
    factor = 12;
  }
  if (duration == "Biennially" || duration == "biennially") {
    factor = 24;
  }
  if (duration == "Triennially" || duration == "triennially") {
    factor = 36;
  }
  //// formula
  var factordiscount = normalPrice - normalPrice * (discount / 100);
  if (factor <= 12) {
    var d = factor - promo_duration;
  }
  if (factor >= 12) {
    var d = factor / 12 - promo_duration / 12;
  }
  var price = (factordiscount + d * normalPrice).toFixed(2);

  return price;
};

export const getPackageBaseCycle = (item, currency) => {
  var bprice = "";

  const curr = item.currency;

  for (var p = 0; p < curr?.length; p++) {
    if (curr[p]?.currency == currency) {
      if (curr[p]?.monthly > 0) {
        bprice = "monthly";
        break;
      }
      if (curr[p]?.quarterly > 0) {
        bprice = "quarterly";
        break;
      }
      if (curr[p]?.semiannually > 0) {
        bprice = "semiannually";
        break;
      }
      if (curr[p]?.annually > 0) {
        bprice = "annually";
        break;
      }
      if (curr[p]?.biennially > 0) {
        bprice = "biennially";
        break;
      }
      if (curr[p]?.triennially > 0) {
        bprice = "triennially";
        break;
      }
    }
  }

  return bprice;
};

export const getdedicatedPrice = (
  normalPrice,
  pid,
  duration,
  activePromotions,
  currencyCode
) => {
  var price = [];
  if (activePromotions[0]?.appliesto?.includes(pid)) {
    const promoDuration = activePromotions[0]?.cycles
      ?.toLowerCase()
      ?.split(",");

    var discount = activePromotions[0]?.value;
    for (var i = 0; i < promoDuration?.length; i++) {
      if (duration == promoDuration[i]) {
        var pd = promoDuration[i];
        break;
      }
    }
    var promo_duration;
    if (pd == "Monthly" || pd == "monthly") {
      promo_duration = 1;
    }
    if (pd == "Quarterly" || pd == "quarterly") {
      promo_duration = 3;
    }
    if (pd == "Semi-Annually" || pd == "semiannually") {
      promo_duration = 6;
    }
    if (pd == "Annually" || pd == "annually") {
      promo_duration = 12;
    }
    if (pd == "Biennially" || pd == "biennially") {
      promo_duration = 24;
    }
    if (pd == "Triennially" || pd == "triennially") {
      promo_duration = 36;
    }

    var factor;
    var cyc;
    if (duration == "Monthly" || duration == "monthly") {
      factor = 1;
      cyc = "mon";
    }
    if (duration == "Quarterly" || duration == "quarterly") {
      factor = 3;
      cyc = "3mons";
    }
    if (duration == "Semi-Annually" || duration == "semiannually") {
      factor = 6;
      cyc = "6mons";
    }
    if (duration == "Annually" || duration == "annually") {
      factor = 12;
      cyc = "Yr";
    }
    if (duration == "Biennially" || duration == "biennially") {
      factor = 24;
      cyc = "2Yrs";
    }
    if (duration == "Triennially" || duration == "triennially") {
      factor = 36;
      cyc = "3Yrs";
    }
    //// formula
    var factordiscount = normalPrice - normalPrice * (discount / 100);
    if (factor <= 12) {
      var d = factor - promo_duration;
    }
    if (factor >= 12) {
      var d = factor / 12 - promo_duration / 12;
    }
    var p = (factordiscount + d * normalPrice).toFixed(2);
    var dc = p.split(".");
    price[0] = 1; // discount check
    price[1] = normalPrice; // normal pprice
    price[2] = p; // dis. pprice full
    price[3] = dc[0]; // dis. pprice ist
    price[4] = dc[1]; // dis. pprice 2nd
    return (
      <>
        <div className="discount">
          <h4 className="p-2">Save {discount}% </h4>
        </div>
        <div className="package-one-price">
          <span className="amount">
            {currencyCode} {""} {test(price[3])}/{cyc} &nbsp;
            <span className="amount_cut">
              <s>
                {" "}
                {currencyCode} {""}
                {price[1]}
              </s>
            </span>
          </span>
        </div>
      </>
    );
  } else {
    var dc = normalPrice[0]?.split(".");
    price[0] = 0; // discount check
    price[1] = normalPrice[0]; // normal pprice
    price[2] = normalPrice[0]; // nor pprice full
    price[3] = dc[0]; // nor. pprice is
    price[4] = dc[1]; // nor. pprice 2nd
    var cyc;
    if (duration == "Monthly" || duration == "monthly") {
      cyc = "mon";
    }
    if (duration == "Quarterly" || duration == "quarterly") {
      cyc = "3mons";
    }
    if (duration == "Semi-Annually" || duration == "semiannually") {
      cyc = "6mons";
    }
    if (duration == "Annually" || duration == "annually") {
      cyc = "yr";
    }
    if (duration == "Biennially" || duration == "biennially") {
      cyc = "2yrs";
    }
    if (duration == "Triennially" || duration == "triennially") {
      cyc = "3yrs";
    }
    return (
      <>
        {/* <div className="discount">
          <h4 className="p-2">
            <b>Free Domain</b>
          </h4>
        </div> */}
        <div className="package-one-price">
          <span className="amount">
            {currencyCode} {""}
            {currencyCode == "$" ? test(price[2]) : test(price[3])}/{cyc} &nbsp;
          </span>
        </div>
      </>
    );
  }
};

export const getSSlPrice = (
  normalPrice,
  pid,
  duration,
  activePromotions,
  currencyCode
) => {
  var price = [];
  if (activePromotions[0]?.appliesto?.includes(pid)) {
    const promoDuration = activePromotions[0]?.cycles
      ?.toLowerCase()
      ?.split(",");

    var discount = activePromotions[0]?.value;
    for (var i = 0; i < promoDuration?.length; i++) {
      if (duration == promoDuration[i]) {
        var pd = promoDuration[i];
        break;
      }
    }
    var promo_duration;
    if (pd == "Monthly" || pd == "monthly") {
      promo_duration = 1;
    }
    if (pd == "Quarterly" || pd == "quarterly") {
      promo_duration = 3;
    }
    if (pd == "Semi-Annually" || pd == "semiannually") {
      promo_duration = 6;
    }
    if (pd == "Annually" || pd == "annually") {
      promo_duration = 12;
    }
    if (pd == "Biennially" || pd == "biennially") {
      promo_duration = 24;
    }
    if (pd == "Triennially" || pd == "triennially") {
      promo_duration = 36;
    }

    var factor;
    if (duration == "Monthly" || duration == "monthly") {
      factor = 1;
    }
    if (duration == "Quarterly" || duration == "quarterly") {
      factor = 3;
    }
    if (duration == "Semi-Annually" || duration == "semiannually") {
      factor = 6;
    }
    if (duration == "Annually" || duration == "annually") {
      factor = 12;
    }
    if (duration == "Biennially" || duration == "biennially") {
      factor = 24;
    }
    if (duration == "Triennially" || duration == "triennially") {
      factor = 36;
    }
    //// formula
    var factordiscount = normalPrice - normalPrice * (discount / 100);
    if (factor <= 12) {
      var d = factor - promo_duration;
    }
    if (factor >= 12) {
      var d = factor / 12 - promo_duration / 12;
    }
    var p = (factordiscount + d * normalPrice).toFixed(2);
    var dc = p?.split(".");
    price[0] = 1; // discount check
    price[1] = normalPrice; // normal pprice
    price[2] = p; // dis. pprice full
    price[3] = dc[0]; // dis. pprice ist
    price[4] = dc[1]; // dis. pprice 2nd

    return (
      <>
        <div className="discount">
          <h4 className="p-2">Save {discount}% </h4>
        </div>
        <div className="package-one-price">
          <span className="amount">
            {currencyCode} {""} {test(price[3])}/yr &nbsp;
            <span className="amount_cut">
              <s>
                {" "}
                {currencyCode} {""}
                {price[1]}
              </s>
            </span>
          </span>
        </div>
      </>
    );
  } else {
    var dc = normalPrice[0]?.split(".");
    price[0] = 0; // discount check
    price[1] = normalPrice[0]; // normal pprice
    price[2] = normalPrice[0]; // nor pprice full
    price[3] = dc[0]; // nor. pprice is
    price[4] = dc[1]; // nor. pprice 2nd

    return (
      <>
        <div className="package-one-price">
          <span className="amount">
            {currencyCode} {""}
            {currencyCode == "$" ? test(price[2]) : test(price[3])}/yr &nbsp;
          </span>
        </div>
      </>
    );
  }
};

export const getPrice = (
  normalPrice,
  pid,
  duration,
  activePromotions,
  currencyCode
) => {
  const foundPromotion = activePromotions.find((promotion) =>
    promotion.appliesto.includes(pid)
  );


  var price = [];
  if (foundPromotion) {
    const promoDuration = foundPromotion.cycles?.toLowerCase()?.split(",");
    var discount = foundPromotion.value;
    for (var i = 0; i < promoDuration?.length; i++) {
      if (duration == promoDuration[i]) {
        var pd = promoDuration[i];
        break;
      }
    }
    var promo_duration;
    if (pd == "Monthly" || pd == "monthly") {
      promo_duration = 1;
    }
    if (pd == "Quarterly" || pd == "quarterly") {
      promo_duration = 3;
    }
    if (pd == "Semi-Annually" || pd == "semiannually") {
      promo_duration = 6;
    }
    if (pd == "Annually" || pd == "annually") {
      promo_duration = 12;
    }
    if (pd == "Biennially" || pd == "biennially") {
      promo_duration = 24;
    }
    if (pd == "Triennially" || pd == "triennially") {
      promo_duration = 36;
    }

    var factor;
    if (duration == "Monthly" || duration == "monthly") {
      factor = 1;
    }
    if (duration == "Quarterly" || duration == "quarterly") {
      factor = 3;
    }
    if (duration == "Semi-Annually" || duration == "semiannually") {
      factor = 6;
    }
    if (duration == "Annually" || duration == "annually") {
      factor = 12;
    }
    if (duration == "Biennially" || duration == "biennially") {
      factor = 24;
    }
    if (duration == "Triennially" || duration == "triennially") {
      factor = 36;
    }
    //// formula
    var factordiscount = normalPrice - normalPrice * (discount / 100);
    if (factor <= 12) {
      var d = factor - promo_duration;
    }
    if (factor >= 12) {
      var d = factor / 12 - promo_duration / 12;
    }
    var p = (factordiscount + d * normalPrice).toFixed(2);
    var dc = p?.split(".");
    price[0] = 1; // discount check
    var np = normalPrice[0]?.split(".");

    price[1] = normalPrice; // normal pprice
    price[2] = p; // dis. pprice full
    price[3] = dc[0]; // dis. pprice ist
    price[4] = dc[1]; // dis. pprice 2nd

    return (
      <>
        <div className="discount">
          <h4 className="p-2">
            <b>Save {discount}% + Free Domain</b>
          </h4>
        </div>
        <div className="package-one-price">
          <span className="amount">
            {currencyCode}
            {test(price[3])}
            {currencyCode === "$" ? "." + price[4] : ""}/yr &nbsp;
            <span className="amount_cut">
              <s>
                {" "}
                {currencyCode}
                {test(np[0])}
                {currencyCode === "$" ? "." + np[1] : ""}
              </s>
            </span>
          </span>
        </div>
      </>
    );
  } else {
    var dc = normalPrice[0]?.split(".");
    price[0] = 0; // discount check
    price[1] = normalPrice[0]; // normal pprice
    price[2] = normalPrice[0]; // nor pprice full
    price[3] = dc[0]; // nor. pprice is
    price[4] = dc[1]; // nor. pprice 2nd

    return (
      <>
        <div className="discount">
          <h4 className="p-2">
            <b>Free Domain</b>
          </h4>
        </div>
        <div className="package-one-price">
          <span className="amount">
            {currencyCode}
            {currencyCode == "$" ? test(price[2]) : test(price[3])}/yr &nbsp;
          </span>
        </div>
      </>
    );
  }
};

// export const getPriceRightHosting = (normalPrice, pid, duration, activePromotions, currencyCode) => {
//   const price = [];
//   const discount = activePromotions[0]?.appliesto?.includes(pid) ? activePromotions[0]?.value : 0;
//   const promoDuration = activePromotions[0]?.cycles?.toLowerCase()?.split(",");
//   const pdIndex = promoDuration?.indexOf(duration.toLowerCase());
//   const promoDurationInMonths = [1, 3, 6, 12, 24, 36][pdIndex];
//   const durationInMonths = [1, 3, 6, 12, 24, 36][["monthly", "quarterly", "semiannually", "annually", "biennially", "triennially"].indexOf(duration.toLowerCase())];

//   const factordiscount = normalPrice - (normalPrice * discount / 100);
//   const d = durationInMonths - promoDurationInMonths;
//   const p = (factordiscount + d * normalPrice).toFixed(2);
//   const [dollars, cents] = p.split(".");

//   price.push(discount > 0 ? 1 : 0);
//   price.push(normalPrice);
//   price.push(p);
//   price.push(dollars);
//   price.push(cents);
//   const domainPromo = discount > 0 ? `Save ${discount}% + Free Domain` : "Free Domain";

//   return (
//     <>
//       <div style={{ paddingTop: '0px' }} className="discount">
//         <h4 style={{ fontSize: '20px' }} className="p-2">
//           <b>{domainPromo}</b>
//         </h4>
//       </div>
//       <div style={{ marginBottom: 10, marginTop: 15 }} className="package-one-price">
//         <span style={{ fontSize: '20px' }} className="amount">
//           {currencyCode}{currencyCode === '$' ? dollars : test2(dollars)}{currencyCode === '$' ? '.' + cents : ''}/yr &nbsp;
//           {discount > 0 &&
//             <span className="amount_cut">
//               <s>
//                 {currencyCode}{currencyCode === '$' ? normalPrice : test2(normalPrice)}{currencyCode === '$' ? '.' + cents : ''}
//               </s>
//             </span>
//           }
//         </span>
//       </div>
//     </>
//   );
// };

export const getPriceRightHosting = (
  normalPrice,
  pid,
  duration,
  activePromotions,
  currencyCode,
  year
) => {
  var price = [];
  if (activePromotions[0]?.appliesto?.includes(pid)) {
    const promoDuration = activePromotions[0]?.cycles
      ?.toLowerCase()
      ?.split(",");

    var discount = activePromotions[0]?.value;
    for (var i = 0; i < promoDuration?.length; i++) {
      if (duration == promoDuration[i]) {
        var pd = promoDuration[i];
        break;
      }
    }
    var promo_duration;
    if (pd == "Monthly" || pd == "monthly") {
      promo_duration = 1;
    }
    if (pd == "Quarterly" || pd == "quarterly") {
      promo_duration = 3;
    }
    if (pd == "Semi-Annually" || pd == "semiannually") {
      promo_duration = 6;
    }
    if (pd == "Annually" || pd == "annually") {
      promo_duration = 12;
    }
    if (pd == "Biennially" || pd == "biennially") {
      promo_duration = 24;
    }
    if (pd == "Triennially" || pd == "triennially") {
      promo_duration = 36;
    }

    var factor;
    if (duration == "Monthly" || duration == "monthly") {
      factor = 1;
    }
    if (duration == "Quarterly" || duration == "quarterly") {
      factor = 3;
    }
    if (duration == "Semi-Annually" || duration == "semiannually") {
      factor = 6;
    }
    if (duration == "Annually" || duration == "annually") {
      factor = 12;
    }
    if (duration == "Biennially" || duration == "biennially") {
      factor = 24;
    }
    if (duration == "Triennially" || duration == "triennially") {
      factor = 36;
    }
    //// formula
    var factordiscount = normalPrice - normalPrice * (discount / 100);
    if (factor <= 12) {
      var d = factor - promo_duration;
    }
    if (factor >= 12) {
      var d = factor / 12 - promo_duration / 12;
    }
    var p = (factordiscount + d * normalPrice).toFixed(2);
    var dc = p?.split(".");
    price[0] = 1; // discount check
    var np = normalPrice?.split(".");
    price[1] = normalPrice; // normal pprice
    price[2] = p; // dis. pprice full
    price[3] = dc[0]; // dis. pprice ist
    price[4] = dc[1]; // dis. pprice 2nd

    return (
      <>
        <div style={{ paddingTop: "0px" }} className="discount">
          <h4 style={{ fontSize: "20px" }} className="p-2">
            <b>Save {discount}% + Free Domain</b>
          </h4>
        </div>
        <div
          style={{ marginBottom: 10, marginTop: 15 }}
          className="package-one-price"
        >
          <span className="amount">
            {currencyCode}
            {test(price[3])}
            {currencyCode === "$" ? "." + price[4] : ""}
            {year} &nbsp;
            <span className="amount_cut">
              <s>
                {" "}
                {currencyCode}
                {test(np[0])}
                {currencyCode === "$" ? "." + np[1] : ""}
              </s>
            </span>
          </span>
        </div>
      </>
    );
  } else {
    var dc = normalPrice?.split(".");
    // price[0] = 0; // discount check
    // price[1] = normalPrice.split(".")[0]; // normal pprice
    // price[2] = normalPrice; // nor pprice full
    // price[3] = dc[0]; // nor. pprice is
    // price[4] = dc[1]; // nor. pprice 2nd

    return (
      <>
        {currencyCode}
        {currencyCode == "$" ? test(normalPrice) : test(normalPrice)}
        {year} &nbsp;
      </>
    );
  }
};

export const getResellerPrice = (
  normalPrice,
  pid,
  duration,
  activePromotions,
  currencyCode
) => {
  var price = [];
  if (activePromotions[0]?.appliesto?.includes(pid)) {
    const promoDuration = activePromotions[0]?.cycles
      ?.toLowerCase()
      ?.split(",");

    var discount = activePromotions[0]?.value;
    for (var i = 0; i < promoDuration?.length; i++) {
      if (duration == promoDuration[i]) {
        var pd = promoDuration[i];
        break;
      }
    }
    var promo_duration;
    if (pd == "Monthly" || pd == "monthly") {
      promo_duration = 1;
    }
    if (pd == "Quarterly" || pd == "quarterly") {
      promo_duration = 3;
    }
    if (pd == "Semi-Annually" || pd == "semiannually") {
      promo_duration = 6;
    }
    if (pd == "Annually" || pd == "annually") {
      promo_duration = 12;
    }
    if (pd == "Biennially" || pd == "biennially") {
      promo_duration = 24;
    }
    if (pd == "Triennially" || pd == "triennially") {
      promo_duration = 36;
    }

    var factor;
    if (duration == "Monthly" || duration == "monthly") {
      factor = 1;
    }
    if (duration == "Quarterly" || duration == "quarterly") {
      factor = 3;
    }
    if (duration == "Semi-Annually" || duration == "semiannually") {
      factor = 6;
    }
    if (duration == "Annually" || duration == "annually") {
      factor = 12;
    }
    if (duration == "Biennially" || duration == "biennially") {
      factor = 24;
    }
    if (duration == "Triennially" || duration == "triennially") {
      factor = 36;
    }
    //// formula
    var factordiscount = normalPrice - normalPrice * (discount / 100);
    if (factor <= 12) {
      var d = factor - promo_duration;
    }
    if (factor >= 12) {
      var d = factor / 12 - promo_duration / 12;
    }
    var p = (factordiscount + d * normalPrice).toFixed(2);
    var dc = p?.split(".");
    price[0] = 1; // discount check
    price[1] = normalPrice; // normal pprice
    price[2] = p; // dis. pprice full
    price[3] = dc[0]; // dis. pprice ist
    price[4] = dc[1]; // dis. pprice 2nd

    return (
      <>
        <div className="discount">
          <h4 className="p-2">Save {discount}% </h4>
        </div>
        <div className="package-one-price">
          <span className="amount">
            {currencyCode} {""} {test(price[3])}/yr &nbsp;
            <span className="amount_cut">
              <s>
                {" "}
                {currencyCode} {""}
                {price[1]}
              </s>
            </span>
          </span>
        </div>
      </>
    );
  } else {
    var dc = normalPrice[0]?.split(".");
    price[0] = 0; // discount check
    price[1] = normalPrice[0]; // normal pprice
    price[2] = normalPrice[0]; // nor pprice full
    price[3] = dc[0]; // nor. pprice is
    price[4] = dc[1]; // nor. pprice 2nd

    return (
      <>
        <div className="package-one-price">
          <span className="amount">
            {currencyCode} {""}
            {currencyCode == "$" ? test(price[2]) : test(price[3])}/yr &nbsp;
          </span>
        </div>
      </>
    );
  }
};

export const getForCartPrice = (
  normalPrice,
  pid,
  duration,
  activePromotions,
  currentPrice
) => {
  var price = 0;
  var newpromo = activePromotions;
  if (newpromo === null) {
    return currentPrice;
  } else {
    const foundPromotion = activePromotions.find((promotion) =>
    promotion.appliesto.includes(pid)
  );
    if (foundPromotion) {
     // console.log("foundPromotion: ", foundPromotion)
      const promoDuration1 = foundPromotion?.cycles
        ?.toLowerCase()
        ?.split(",");
      
      const promoDuration = promoDuration1[0];
     // console.log("promoDuration: ", promoDuration)
      var discount = foundPromotion.value;
      //console.log("discount: ", discount)
      var pd = duration;

      var Promo_Duration;
      if (promoDuration == "Monthly" || promoDuration == "monthly") {
        Promo_Duration = 1;
      }
      if (promoDuration == "Quarterly" || promoDuration == "quarterly") {
        Promo_Duration = 3;
      }
      if (promoDuration == "Semi-Annually" || promoDuration == "semiannually") {
        Promo_Duration = 6;
      }
      if (promoDuration == "Annually" || promoDuration == "annually") {
        Promo_Duration = 12;
      }
      if (promoDuration == "Biennially" || promoDuration == "biennially") {
        Promo_Duration = 24;
      }
      if (promoDuration == "Triennially" || promoDuration == "triennially") {
        Promo_Duration = 36;
      }

      var factor;
      if (duration == "Monthly" || duration == "monthly") {
        factor = 1;
      }
      if (duration == "Quarterly" || duration == "quarterly") {
        factor = 3;
      }
      if (duration == "Semi-Annually" || duration == "semiannually") {
        factor = 6;
      }
      if (duration == "Annually" || duration == "annually") {
        factor = 12;
      }
      if (duration == "Biennially" || duration == "biennially") {
        factor = 24;
      }
      if (duration == "Triennially" || duration == "triennially") {
        factor = 36;
      }
      //// formula

      var factordiscount = normalPrice - normalPrice * (discount / 100);

      if (factor <= 12) {
        var d = factor - Promo_Duration;
      }
      if (factor > 12) {
        var d = factor / 12 - Promo_Duration / 12;
      }

      var p = (factordiscount + d * normalPrice)?.toFixed(2);
      var dc = p.split(".");
      price = p;
      // console.log("ali here: ", normalPrice - normalPrice * (discount / 100))
      // console.log("factor:", factor);
      // console.log("currentPrice: ", currentPrice)
      // console.log("normalprice: ", normalPrice)
      // console.log("factordiscount: ", factordiscount)
      // console.log("Promo_Duration: ", Promo_Duration)
      // console.log("d: ", d)
      // console.log("p: ", p)
      return p;
    } else {
      // var dc = currentPrice[0].split(".");

      return currentPrice;
    }
  }
};

export const getPackageBasePrice = (packageCart, pid, currency) => {
  var bprice = 0;
  for (var i = 0; i < packageCart?.length; i++) {
    if (packageCart[i]?.pid == pid) {
      const curr = packageCart[i]?.currency;

      for (var p = 0; p < curr?.length; p++) {
        if (curr[p]?.currency == currency) {
          if (curr[p]?.monthly > 0) {
            bprice = curr[p]?.monthly;
            break;
          }
          if (curr[p].quarterly > 0) {
            bprice = curr[p]?.quarterly;
            break;
          }
          if (curr[p].semiannually > 0) {
            bprice = curr[p]?.semiannually;
            break;
          }
          if (curr[p].annually > 0) {
            bprice = curr[p]?.annually;
            break;
          }
          if (curr[p].biennially > 0) {
            bprice = curr[p]?.biennially;
            break;
          }
          if (curr[p].triennially > 0) {
            bprice = curr[p]?.triennially;
            break;
          }
        }
      }
    }
  }

  return bprice;
};

export const getPackageMinPrice = (currency) => {
  var bprice = 0;

  const arr = [currency];

  for (const ob of arr) {
    if (ob?.monthly > 0) {
      bprice = ob?.monthly;
      break;
    }

    if (ob?.quarterly > 0) {
      bprice = ob?.quarterly;
      break;
    }
    if (ob?.semiannually > 0) {
      bprice = ob?.semiannually;
      break;
    }
    if (ob?.annually > 0) {
      bprice = ob?.annually;
      break;
    }
    if (ob?.biennially > 0) {
      bprice = ob?.biennially;
      break;
    }
    if (ob?.triennially > 0) {
      bprice = ob?.triennially;
      break;
    }
  }

  return bprice;
};

export const getAddonPriceForcart = (
  packagBillingCycle,
  addons,
  currency,
  addonSamePackageBiilingPrice
) => {
  var addonPrice = 0;

  if (addons?.billingcycle == "onetime") {
    addonPrice = addons?.pricing[currency]?.monthly;
  } else {
    var adPrice = addonSamePackageBiilingPrice;

    if (adPrice > 0) {
      addonPrice = adPrice;
    } else {
      addonPrice = addonMinPrice(addons, currency);
    }
  }
  if (currency == 3) {
    return addonPrice;
  } else {
    return addonPrice;
  }
};

export const getAddonPrice = (
  normalPrice,
  pid,
  duration,
  activePromotions,
  currencyCode
) => {
  var price = [];
  let newItem = [];
  var pii = "A" + pid;
  if (activePromotions[0]?.appliesto?.includes(pii)) {
    const promoDuration = activePromotions[0]?.cycles
      ?.toLowerCase()
      ?.split(",");
    var discount = activePromotions[0]?.value;
    for (var i = 0; i < promoDuration?.length; i++) {
      if (duration == promoDuration[i]) {
        var pd = promoDuration[i];
        break;
      }
    }
    var factor = (normalPrice * discount) / 100;

    var discounted_price = normalPrice - factor;
    newItem.push(normalPrice, discounted_price, discount, true);
    return newItem;
  } else {
    newItem.push(normalPrice, 0, 0, false);

    return newItem;
  }
};

function addonMinPrice(adons, currency) {
  var bprice = 0;
  var tad = adons?.pricing[currency];

  const arr = [tad];

  for (const ob of arr) {
    if (ob?.monthly > 0) {
      bprice = ob?.monthly;
      break;
    }

    if (ob?.quarterly > 0) {
      bprice = ob?.quarterly;
      break;
    }
    if (ob?.semiannually > 0) {
      bprice = ob?.semiannually;
      break;
    }
    if (ob?.annually > 0) {
      bprice = ob?.annually;
      break;
    }
    if (ob?.biennially > 0) {
      bprice = ob?.biennially;
      break;
    }
    if (ob?.triennially > 0) {
      bprice = ob?.triennially;
      break;
    }
  }

  return bprice;
}

export const domainAdonPrice = (billingCycle, domainAddonPrice) => {
  var price = 0;

  if (billingCycle === "annually") {
    price = domainAddonPrice;
  }
  if (billingCycle === "biennially") {
    price = domainAddonPrice * 2;
  }

  if (billingCycle === "triennially") {
    price = domainAddonPrice * 3;
  }

  if (billingCycle === "fourlyprice") {
    price = domainAddonPrice * 4;
  }

  if (billingCycle === "fivelyprice") {
    price = domainAddonPrice * 5;
  }
  if (billingCycle === "sixlyprice") {
    price = domainAddonPrice * 6;
  }

  if (billingCycle === "sevenlyprice") {
    price = domainAddonPrice * 7;
  }

  if (billingCycle === "eightlyprice") {
    price = domainAddonPrice * 8;
  }

  if (billingCycle === "ninelyprice") {
    price = domainAddonPrice * 9;
  }

  if (billingCycle === "tenlyprice") {
    price = domainAddonPrice * 10;
  }

  return price;
};

export const biilincycleval = (packagecart, pid, cid, billigcyle) => {
  const pr = packagecart
    .filter((p) => p?.pid === pid)[0]
    ?.currency.filter((c) => c?.currency == cid)[0]?.billigcyle;
};

export const packageBillingcycle = (addingcyle, tid) => {
  var billingcycle = "";
  for (var i = 0; i < addingcyle.length; i++) {
    if (addingcyle[i]?.tid == tid) {
      billingcycle = addingcyle[i]?.packageCycle;
      break;
    }
  }

  return billingcycle + "." + tid;
};

export const domainBillingcycle = (addingcyle, pid) => {
  var billingcycle = "";
  for (var i = 0; i < addingcyle?.length; i++) {
    if (addingcyle[i]?.domainName == pid) {
      billingcycle = addingcyle[i]?.packageCycle;
      break;
    }
  }

  return billingcycle + "_" + pid;
};

export const premiumDomainBillingcycle = (addingcyle, pid) => {
  var billingcycle = "";
  for (var i = 0; i < addingcyle?.length; i++) {
    if (addingcyle[i]?.domainName == pid) {
      billingcycle = addingcyle[i]?.packageCycle;
      break;
    }
  }

  return billingcycle;
};

export const getPriceRecomendations = (
  normalPrice,
  pid,
  duration,
  activePromotions,
  currencyCode
) => {
  var price = [];
  if (activePromotions[0]?.appliesto?.includes(pid)) {
    const promoDuration = activePromotions[0]?.cycles
      ?.toLowerCase()
      ?.split(",");

    var discount = activePromotions[0]?.value;
    for (var i = 0; i < promoDuration?.length; i++) {
      if (duration == promoDuration[i]) {
        var pd = promoDuration[i];
        break;
      }
    }
    var promo_duration;
    if (pd == "Monthly" || pd == "monthly") {
      promo_duration = 1;
    }
    if (pd == "Quarterly" || pd == "quarterly") {
      promo_duration = 3;
    }
    if (pd == "Semi-Annually" || pd == "semiannually") {
      promo_duration = 6;
    }
    if (pd == "Annually" || pd == "annually") {
      promo_duration = 12;
    }
    if (pd == "Biennially" || pd == "biennially") {
      promo_duration = 24;
    }
    if (pd == "Triennially" || pd == "triennially") {
      promo_duration = 36;
    }

    var factor;
    if (duration == "Monthly" || duration == "monthly") {
      factor = 1;
    }
    if (duration == "Quarterly" || duration == "quarterly") {
      factor = 3;
    }
    if (duration == "Semi-Annually" || duration == "semiannually") {
      factor = 6;
    }
    if (duration == "Annually" || duration == "annually") {
      factor = 12;
    }
    if (duration == "Biennially" || duration == "biennially") {
      factor = 24;
    }
    if (duration == "Triennially" || duration == "triennially") {
      factor = 36;
    }
    //// formula
    var factordiscount = normalPrice - normalPrice * (discount / 100);
    if (factor <= 12) {
      var d = factor - promo_duration;
    }
    if (factor >= 12) {
      var d = factor / 12 - promo_duration / 12;
    }
    var p = (factordiscount + d * normalPrice)?.toFixed(2);
    var dc = p?.split(".");
    price[0] = 1; // discount check
    price[1] = normalPrice; // normal pprice
    price[2] = p; // dis. pprice full
    price[3] = dc[0]; // dis. pprice ist
    price[4] = dc[1]; // dis. pprice 2nd

    return (
      <>
        <p className="poppamount">
          {currencyCode} {""} {price[3]}/yr &nbsp;
          <span className="popamountcut">
            <s>
              {currencyCode} {""}
              {price[1]}
            </s>
          </span>
        </p>
      </>
    );
  } else {
    var dc = normalPrice[0]?.split(".");
    price[0] = 0; // discount check
    price[1] = normalPrice[0]; // normal pprice
    price[2] = normalPrice[0]; // nor pprice full
    price[3] = dc[0]; // nor. pprice is
    price[4] = dc[1]; // nor. pprice 2nd

    return (
      <>
        <p className="poppamount">
          {currencyCode} {""} {price[3]}/yr &nbsp;
        </p>
      </>
    );
  }
};

export const packagesFeatures = (features) => {
  const final = [];
  for (var i = 0; i < features?.length; i++) {
    // if (i > 0) {
    let sp = features[i]?.split("_");
    if (sp[1]) {
      final.push(
        <li>
          <font>
            <strong
              style={{
                color: "#005880",
                fontSize: 20,
                fontWeight: 400,
                paddingRight: "8px",
              }}
            >
              ✓{" "}
            </strong>
          </font>
          {sp[0]}
          <i className="fa fa-info-circle">
            <span
              className="litooltip"
              dangerouslySetInnerHTML={{
                __html: sp[1],
              }}
            />
            {/* <span className="litooltip" >{sp[1]}</span> */}
          </i>
        </li>
      );
    } else {
      final.push(
        <li>
          <font>
            <strong
              style={{
                color: "#005880",
                fontSize: 20,
                fontWeight: 400,
                paddingRight: "8px",
              }}
            >
              ✓{" "}
            </strong>
          </font>
          {sp[0]}
        </li>
      );
    }
    //}
  }
  return <ul>{final}</ul>;
};

export const packagesFirstFeature = (features) => {
  const final = [];
  for (var i = 0; i < features?.length; i++) {
    if (i == 0) {
      let sp = features[i]?.split("_");

      final.push(<p className="package-namepaaaa">{sp[0]}</p>);
    }
  }

  return <>{final}</>;
};

export const DomainTld = (domain) => {
  let tld = "";
  let sp = domain?.split(".");

  if (sp?.length > 2) {
    tld = sp[1] + "." + sp[2];
  }
  // if(sp.length==1){
  // tld='1';
  // }
  else {
    tld = sp[1];
  }

  return tld;
};

function CommaFormatted(amount) {
  // var delimiter = ","; // replace comma if desired
  // var a = amount
  // var d = a;
  // var i = parseInt(a);
  // if(isNaN(i)) { return ''; }
  // var minus = '';
  // if(i < 0) { minus = '-'; }
  // i = Math.abs(i);
  // var n = new String(i);
  // var a = [];
  // while(n.length > 3) {
  // 	var nn = n.substr(n.length-3);
  // 	a.unshift(nn);
  // 	n = n.substr(0,n.length-3);
  // }
  // if(n.length > 0) { a.unshift(n); }
  // n = a.join(delimiter);
  // if(d.length < 1) { amount = n; }
  // else { amount = n + '.' + d; }
  // amount = minus + amount;
  return amount?.toLocaleString();
}

export const tested = (amount) => {
  const result = numberWithCommas(amount);
  const pass = result;
  return pass;
};

function numberWithCommas(x) {
  //return x.toString().replace(/\B(?<!\.\d*)(?=(\d{3})+(?!\d))/g, ",");
  return x?.toString()?.replace(/\B(?=(\d{3})+(?!\d))/g, ",");
  // return x.toLocaleString();
}

function test(x) {
  const result = numberWithCommas(x);
  const pass = result;
  return pass;
}

export const currecnyDiffPrice = (price, currency) => {
  var pr;
  if (currency === 3) {
    pr = price?.split(".")[0];
  } else {
    pr = price;
  }

  return pr;
};

export const test2 = (x) => {
  const result = numberWithCommas(x);
  const pass = result;
  return pass;
};

export const getPriceBundleBoster = (
  normalPrice,
  pid,
  duration,
  activePromotions,
  currencyCode
) => {
  const foundPromotion = activePromotions.find((promotion) =>
  promotion.appliesto.includes(pid)
);

  var price = [];
  if (foundPromotion) {
    const promoDuration = foundPromotion.cycles?.toLowerCase()?.split(",");
    var discount = foundPromotion.value;
    for (var i = 0; i < promoDuration?.length; i++) {
      if (duration == promoDuration[i]) {
        var pd = promoDuration[i];
        break;
      }
    }
    var promo_duration;
    if (pd == "Monthly" || pd == "monthly") {
      promo_duration = 1;
    }
    if (pd == "Quarterly" || pd == "quarterly") {
      promo_duration = 3;
    }
    if (pd == "Semi-Annually" || pd == "semiannually") {
      promo_duration = 6;
    }
    if (pd == "Annually" || pd == "annually") {
      promo_duration = 12;
    }
    if (pd == "Biennially" || pd == "biennially") {
      promo_duration = 24;
    }
    if (pd == "Triennially" || pd == "triennially") {
      promo_duration = 36;
    }

    var factor;
    if (duration == "Monthly" || duration == "monthly") {
      factor = 1;
    }
    if (duration == "Quarterly" || duration == "quarterly") {
      factor = 3;
    }
    if (duration == "Semi-Annually" || duration == "semiannually") {
      factor = 6;
    }
    if (duration == "Annually" || duration == "annually") {
      factor = 12;
    }
    if (duration == "Biennially" || duration == "biennially") {
      factor = 24;
    }
    if (duration == "Triennially" || duration == "triennially") {
      factor = 36;
    }
    //// formula
    var factordiscount = normalPrice - normalPrice * (discount / 100);
    if (factor <= 12) {
      var d = factor - promo_duration;
    }
    if (factor >= 12) {
      var d = factor / 12 - promo_duration / 12;
    }
    var p = (factordiscount + d * normalPrice)?.toFixed(2);
    var dc = p?.split(".");
    price[0] = 1; // discount check
    price[1] = normalPrice; // normal pprice
    price[2] = p; // dis. pprice full
    price[3] = dc[0]; // dis. pprice ist
    price[4] = dc[1]; // dis. pprice 2nd

    return (
      <>
        <span className="packages_price">
          <span className="prcie_pkr" />
          {currencyCode} {""} {test(price[3])}/yr &nbsp;
        </span>
      </>
    );
  } else {
    var dc = normalPrice[0]?.split(".");
    price[0] = 0; // discount check
    price[1] = normalPrice[0]; // normal pprice
    price[2] = normalPrice[0]; // nor pprice full
    price[3] = dc[0]; // nor. pprice is
    price[4] = dc[1]; // nor. pprice 2nd

    return (
      <>
        <span className="packages_price">
          <span className="prcie_pkr" />
          {currencyCode} {""}{" "}
          {currencyCode == "$" ? test(price[2]) : test(price[3])}/yr &nbsp;
        </span>
      </>
    );
  }
};

export const delayTime = (time) => {
  return new Promise((resolve) => setTimeout(resolve, time));
};

export const getPKPrice = (
  normalPrice,
  pid,
  duration,
  activePromotions,
  currencyCode
) => {
  const foundPromotion = activePromotions.find((promotion) =>
    promotion.appliesto.includes(pid)
  );

  var price = [];
  if (foundPromotion) {
    const promoDuration = foundPromotion.cycles?.toLowerCase()?.split(",");

    var discount = foundPromotion.value;
    for (var i = 0; i < promoDuration?.length; i++) {
      if (duration == promoDuration[i]) {
        var pd = promoDuration[i];
        break;
      }
    }

    var promo_duration;
    if (pd == "Monthly" || pd == "monthly") {
      promo_duration = 1;
    }
    if (pd == "Quarterly" || pd == "quarterly") {
      promo_duration = 3;
    }
    if (pd == "Semi-Annually" || pd == "semiannually") {
      promo_duration = 6;
    }
    if (pd == "Annually" || pd == "annually") {
      promo_duration = 12;
    }
    if (pd == "Biennially" || pd == "biennially") {
      promo_duration = 24;
    }
    if (pd == "Triennially" || pd == "triennially") {
      promo_duration = 36;
    }

    var factor;
    var cyc;
    if (duration == "Monthly" || duration == "monthly") {
      factor = 1;
      cyc = "mon";
    }
    if (duration == "Quarterly" || duration == "quarterly") {
      factor = 3;
      cyc = "3mons";
    }
    if (duration == "Semi-Annually" || duration == "semiannually") {
      factor = 6;
      cyc = "6mons";
    }
    if (duration == "Annually" || duration == "annually") {
      factor = 12;
      cyc = "Yr";
    }
    if (duration == "Biennially" || duration == "biennially") {
      factor = 24;
      cyc = "2Yrs";
    }
    if (duration == "Triennially" || duration == "triennially") {
      factor = 36;
      cyc = "3Yrs";
    }
    //// formula
    var factordiscount = normalPrice - normalPrice * (discount / 100);
    if (factor <= 12) {
      var d = factor - promo_duration;
    }
    if (factor >= 12) {
      var d = factor / 12 - promo_duration / 12;
    }
    var p = (factordiscount + d * normalPrice).toFixed(2);
    var dc = p.split(".");
    price[0] = 1; // discount check
    price[1] = normalPrice; // normal pprice
    price[2] = p; // dis. pprice full
    price[3] = dc[0]; // dis. pprice ist
    price[4] = dc[1]; // dis. pprice 2nd
    return (
      <>
        <div className="discount">
          <h4 className="p-2">
            <b>Save {discount}% + Free Domain</b>
          </h4>
        </div>
        <div className="package-one-price">
          <span className="amount">
            {currencyCode} {""} {test(price[3])}/{cyc} &nbsp;
            <span className="amount_cut">
              <s>
                {" "}
                {currencyCode} {""}
                {currencyCode === "Rs" ? (
                  <>{price[1][0].split(".")[0]}</>
                ) : (
                  <>{price[1]}</>
                )}
              </s>
            </span>
          </span>
        </div>
      </>
    );
  } else {
    var dc = normalPrice[0]?.split(".");

    price[0] = 0; // discount check
    price[1] = normalPrice[0]; // normal pprice
    price[2] = normalPrice[0]; // nor pprice full
    price[3] = dc[0]; // nor. pprice is
    price[4] = dc[1]; // nor. pprice 2nd
    var cyc;
    if (duration == "Monthly" || duration == "monthly") {
      cyc = "mon";
    }
    if (duration == "Quarterly" || duration == "quarterly") {
      cyc = "3mons";
    }
    if (duration == "Semi-Annually" || duration == "semiannually") {
      cyc = "6mons";
    }
    if (duration == "Annually" || duration == "annually") {
      cyc = "yr";
    }
    if (duration == "Biennially" || duration == "biennially") {
      cyc = "2yrs";
    }
    if (duration == "Triennially" || duration == "triennially") {
      cyc = "3yrs";
    }
    return (
      <>
        {/* <div className="discount">
          <h4 className="p-2">
            <b>Free Domain</b>
          </h4>
        </div> */}
        <div className="package-one-price">
          <span className="amount">
            {currencyCode} {""}
            {currencyCode == "$" ? test(price[2]) : test(price[3])}/{cyc} &nbsp;
          </span>
        </div>
      </>
    );
  }
};

export const getAddonFinalPrice = (value, currencyCode, cycle) => {
  const discount = value[2];
  const normalPrice = value[0];
  const discountedPrice = value[1];
  // const cycle = value[3];

  return (
    <>
      {discount ? (
        <>
          <div className="discount" style={{ paddingTop: 0 }}>
            <h4 className="p-2">
              <b>Save {discount}% + Free Domain</b>
            </h4>
          </div>
          <div className="package-one-price">
            <span className="amount">
              {currencyCode} {test(discountedPrice)}
              {cycle} &nbsp;
              <span className="amount_cut">
                <s>
                  {currencyCode === "$"
                    ? test(normalPrice)
                    : test(normalPrice).split(".")[0]}
                </s>
              </span>
            </span>
          </div>
        </>
      ) : (
        <>
          <>
            <div className="package-one-price">
              <span className="amount">
                {currencyCode === "$"
                  ? test(normalPrice)
                  : test(normalPrice).split(".")[0]}
                {cycle}
              </span>
            </div>
          </>
        </>
      )}
    </>
  );
};
