import React from 'react'
import Navbar from './Navbar';
import Topbar from './Topbar';

const Header = () => {
  return (
    <>
    <div className='w-100'>
    <Topbar />
    <Navbar />
    </div>
    </>
  )
}

export default Header