import React from 'react'
import { Link } from "react-router-dom";
const DomainMigration = ({ Data }) => {
  return (
    <section  className="sec-account-migrate ">
    <div className="container">
      <div className="row pt-100">
        <div className="col-md-6">
          <h2>{Data?.Heading}</h2>
          <p className="pb-5">{Data?.SubHeading}</p>
          <a  href="https://billing.websouls.com/submitticket.php" target="_blank">
              <button>{Data && Data.ButtonName}</button>
            </a>
        </div>
        <div className="col-md-5 offset-md-1 d-none d-md-block">
          <img
            className="img-fluid"
            src={process.env.PUBLIC_URL + "/assests/img/plane-1.png"}
            alt=""
          />
        </div>
      </div>
    </div>
  </section>
  )
}

export default React.memo(DomainMigration)