import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import AllPlanIncludes from "./includes/AllPlanIncludes";
import BundelBooster from "../../reuseable/BundelBooster";
import GoingOnline from "./includes/GoingOnline";
import WhyChooseUsSecond from "../../reuseable/WhyChooseUsSecond";
import ComparePlans from "../../reuseable/ComparePlans";
import DomainMigration from "./includes/DomainMigration";
import InPakistan from "./includes/InPakistan";
import Faq from "../../reuseable/Faq";
import WillingForHelp from "../../reuseable/WillingForHelp";
import Crousels from "../../reuseable/Crousels";
import { GetPackages, GetActivePromotions } from "../../../services/Network.js";
const SslCertificate = () => {
  const [comparePlansState, setComparePlansState] = useState([]);
  const [pidsss, setPid] = useState([159, 160, 273, 161, 294]);
  const [packages, setPackages] = useState([]);
  const [getActivePromotions, setActivePromotions] = useState([]);
  const [loading, setLoading] = useState(true);

  const data = {
    Heading: "Need A Transfer? You Got It!",
    SubHeading:
      "The WebSouls Team can migrate your SSL certificate over to our place for an improved web experience that is sure not to disappoint. And, we’d do it for FREE!",
    ButtonName: "Migrate Now!",
  };
  const allplans = {
    Heading: "All Plans Include",
    SubHeading: "SSL Solutions That Offer Supreme Protection",
    Box: [
      {
        id: 0,
        heading: "Data Protection",
        subHeading: "",
        icon: "icon ws-secure",
      },
      {
        id: 1,
        heading: "Affirmed Identity",
        subHeading: "",
        icon: "icon ws-identity",
      },
      {
        id: 2,
        heading: "Improved Rankings",
        subHeading: "",
        icon: "icon ws-graph",
      },
      {
        id: 3,
        heading: "Boosted Conversions",
        subHeading: "",
        icon: "icon ws-convert",
      },
      {
        id: 4,
        heading: "Secure Connections",
        subHeading: "",
        icon: "icon ws-hand-b",
      },
      {
        id: 5,
        heading: "Increased Site Speed",
        subHeading: "",
        icon: "icon ws-growth",
      },
    ],
  };
  const BundleData = {
    Heading: "Bundle Boosters",
    SubHeading: "Complete Your Package with a Little Something Extra",
    Bundles: [
      {
        id: 0,
        bundleHeading: "Dedicated IP",
        bundleParagh:
          "Helping our clients in building their online presence on their own unique IP address, which would ultimately allow them to get an SSL certificate with ease, and improve their email deliverability. Also, through providing a dedicated IP address, we enable our customers to access their site anytime, even when their domain name isn’t available.",
        bundleImage: "assests/img/Dedicated-IP-Price-In-Pakistan.png",
        class: "tab-pane fade show active",
        buttonClass: "nav-link active",
        bundleId: "dedicated-ip-tab",
        bundleDataTarget: "#dedicated",
        bundleAriaControl: "dedicated",
        bundleParaghId: "dedicated",
        bundleParaghAriaLable: "dedicated-ip-tab",
      },
      {
        id: 1,
        bundleHeading: "Malware Scanning",
        bundleParagh:
          "We offer complete website security services to all those who choose to host with us. Defending our clients’ websites against hackers and malware is something that we’re intently focused upon. Our automatic daily scans can detect malware and we make sure to get rid of it right away. Hosting with us is safe, secure, and utterly reliable.",
        bundleImage: "assests/img/Malware-Scanning.png",
        class: "tab-pane fade show",
        buttonClass: "nav-link",
        bundleId: "malware-scanning-tab",
        bundleDataTarget: "#malware",
        bundleAriaControl: "malware",
        bundleParaghId: "malware",
        bundleParaghAriaLable: "malware-scanning-tab",
      },
    ],
  };
  const WillingToHelpData = {
    heading: "Ready, Willing and Able to Help!",
    subHeading:
      "Whether it’s a question, query, or you want to discuss a project, the WebSouls team is at your service anytime, at all times!",
    buttonOne: "Call: +92 423 523 8871 - 73",
    buttonTwo: " Email Us",
    image: "assests/img/manager-Web-Hosting-Division-Websouls.png",
  };
  const GoingOnlineData = {
    Heading: "Going Online? You’d Need More!",
    subHeading:
      "We Don’t Just Offer Security, We Provide Complete Web Solutions",
    box: [
      {
        id: 0,
        topTitle: "Need Email Hosting?",
        Title: "Try Business Hosting",
        buttonLink: "/business-hosting",
      },
      {
        id: 1,
        topTitle: "Need Something Simpler?",
        Title: "Try WordPress Hosting",
        buttonLink: "/wordpress-hosting-in-pakistan",
      },
    ],
  };
  const InPakistanData = {
    Heading: "SSL Certificate in Pakistan",
    subHeading: "A Perfect Safety Net for Your Web Visitors",
    peragraph: [
      <>
        <p>
          WebSouls is a well-known and widely trusted name when it comes to
          reliable web hosting in Pakistan and website development services.
          We’ve successfully helped numerous cooperate and government
          organizations in building a strong web identity with our web designing
          and development services that we offer in Lahore, Karachi, Islamabad,
          and in fact, throughout the entire nation.
        </p>
        <p>
          Our team comes to work everyday with the aim of making going digital
          easier, simpler, and swifter for businesses in Pakistan. Whether you
          wish to run an online store or want to turn your blog into a business,
          we’re here to help you throughout your digital journey!
        </p>
        <p>
          The&nbsp;
          <Link to="/" target="_blank">
            <strong>WebSouls</strong>
          </Link>
          &nbsp;team puts in a lot of time and effort, firstly in understanding
          our clients’ needs, and then turning their ideas into a web-based
          reality. In addition to this, we can also help you go online with
          fastest&nbsp;
          <Link to="/shared-hosting" target="_blank">
            <strong>web hosting in Lahore</strong>
          </Link>
          , Karachi, Islamabad &amp; other major cities of Pakistan.
        </p>
        <span id="dots">...</span>
        <span id="more">
          <p>
            Our company offers web design and software development packages
            that’re highly competitive and come at the best price rates with
            numerous amazing features included, such as SEO-friendly websites,
            mobile responsiveness, and web designs that are increasingly
            interactive.
          </p>
          <p>
            We can design and develop custom websites for clients as per their
            needs and requirements. We also offer copywriting and SEO content
            writing services, alongside domain name registration and web
            hosting. Which means, you can completely rely on us when it comes to
            taking your business online. Moreover, in addition to being one of
            the leading&nbsp;
            <b>web development companies in Lahore</b>, we also provide&nbsp;
            <Link to="/seo-services">
              <b>SEO Services</b>
            </Link>
            &nbsp;and social media marketing services.
          </p>
          <p>
            Our SEO services are designed to get you results. Whether your goal
            is to get more customers, rank on Google’s first page or promote
            your brand online, our SEO plans provide you with the ideal
            solution. Best of all, we integrate our SEO with web design and
            marketing, providing a complete digital solution for your business.
          </p>
          <p>
            We’re more than just creative designers and developers, we’re
            Pakistan’s No. 1 web hosting agency offering high-quality yet
            cost-effective services ranging from hosting solutions to&nbsp;
            <Link to="/ecommerce-solution">
              <b>ecommerce websites</b>
            </Link>
            &nbsp;and domain name registration.
          </p>
          <p>
            Moreover, hosting with us would be 100% carefree since we promise
            99.9% uptime and are cPanel’s official partner in Pakistan. Ur
            numerous web hosting packages have been designed to provide maximum
            value and exceptional performance for websites of all sizes.
          </p>
          <p>
            We also know that a good business website requires premium hosting,
            which is why we offer enough resources to ensure your business
            website runs smoothly. Our corporate hosting packages are great for
            business websites and have everything you need to scale your
            business online. We also offer dedicated and VPS hosting packages if
            you want to run multiple websites under your business name.
          </p>
          <p>
            So, if you want to take your business in the digital world, buy the
            best of our web hosting and website development packages and make
            your business grow exponentially!
          </p>
          <p>
            The websites that we develop are all social media integrated and SEO
            friendly. We can also help you build an online store at the most
            affordable price rates available in market.
          </p>
          <p>
            With our affordable custom website plans, you won’t have to go for
            low-quality website themes again. So, whether you want a custom
            template, unique forms, more landing pages or other website feature,
            we’ve got the ideal solution for you. Contact us today to get the
            custom website of your dreams!.
          </p>
          <p>
            At Websouls, we believe in empowering our clients so they can make
            the best use of all the premium website features we provide. We’ll
            even offer you a training session explaining how to use different
            website features so you can manage your website more effectively.
            We’ll also answer all your questions regarding website management,
            so you’ll have complete confidence in managing the website of your
            dreams.
          </p>
          <p>
            If you are struggling with low conversion rates or poor customer
            feedback, then we’ve got a solution for you. Our website design team
            is experienced in helping you create a website that is optimized for
            conversions. We also design the best landing pages to optimize your
            Google ads or Facebook ads conversion rate.
          </p>
          <p>
            We know that slight changes in your website layout, design and
            features can make a world of difference in your conversion rate and
            customer experience. This is why, our expert web development team
            will only add the best website features designed to optimize your
            website for conversions and give the customer an experience to
            remember!
          </p>
          <p>
            When it comes to website ranking factors, UX design is crucial. We
            design websites with user experience in mind. Our websites are easy
            to navigate and have the best tools for your business requirements.
            So, whether your goal is to give a great brand impression, improve
            your sales numbers or increase customer engagement, we’ll create the
            perfect website for you!
          </p>
        </span>
        <a className="read-more-link d-block d-md-none d-lg-none d-xl-none d-xxl-none">
          <h2 className="text-center pt-2">
            <button
              onClick={() => myFunction()}
              id="myBtn"
              className="readmoreMobile"
            >
              Read More
            </button>
          </h2>
        </a>
      </>,
    ],
  };
  const faqData = {
    heading: "Frequently Asked Questions",
    subHeading: "Dedicated to Helping You Succeed!",
    faqs: [
      {
        id: 0,
        title: "What is an SSL certificate?",
        desc: "SSL stand for Secure Socket Layer and it’s a great way of validating your web identity. An SSL certificate encrypts the information that a visitor sends to, or receives from your website. This way, it keeps your customers’ sensitive data safe and protected from thieves and hackers.",
        faqId: "FAQ1",
        dataBsTarget: "#faqOne",
        ariaControls: "faqOne",
        ariaLabelledby: "FAQ1",
        faqParaghId: "faqOne",
      },
      {
        id: 1,
        title: "How can I install my SSL certificate?",
        desc: "After you’ve successfully issued your SSL certificate, we’d send you an email to let you know about it. Whatever happens next very much depends on where your site is currently hosted, and also the options you selected while getting the SSL certificate. In case you buy one of our managed hosting packages, we’d take care of all the heavy-lifting for you.",
        faqId: "FAQ2",
        dataBsTarget: "#faqTwo",
        ariaControls: "faqTwo",
        ariaLabelledby: "FAQ2",
        faqParaghId: "faqTwo",
      },
      {
        id: 2,
        title: "What kind of SSL certificate should I get for my website?",
        desc: "The type of SSL certificate that you should get depends upon various factors, including your current location, whether you own a business or a personal website, the number of domains you want to protect, and the type of hosting server you use. You can contact our sales representatives for further information and detailed guidelines.",
        faqId: "FAQ3",
        dataBsTarget: "#faqThree",
        ariaControls: "faqThree",
        ariaLabelledby: "FAQ3",
        faqParaghId: "faqThree",
      },
      {
        id: 3,
        title: "How will visitors know that my website is safe?",
        desc: "How your website is designed and developed is totally up to you. Nevertheless, your visitors will know that your site is safe for online transactions when they see the HTML code that would be displayed in their browser bar.",
        faqId: "FAQ4",
        dataBsTarget: "#faqFour",
        ariaControls: "faqFour",
        ariaLabelledby: "FAQ4",
        faqParaghId: "faqFour",
      },
    ],
  };

  useEffect(() => {
    GetPackages(pidsss).then((res) => {
      setComparePlansState(res);
      setPackages(res);
      setLoading(false);
      GetActivePromotions().then((ress) => {
        setActivePromotions(ress);
      });
    });
  }, []);

  var dots = document.getElementById("dots");
  var moreText = document.getElementById("more");
  var btnText = document.getElementById("myBtn");

  useEffect(() => {
    myFunction();
  }, [btnText, moreText, dots]);

  function myFunction() {
    var dots = document.getElementById("dots");
    var moreText = document.getElementById("more");
    var btnText = document.getElementById("myBtn");
    let widnowWidth = window.innerWidth;
    if (widnowWidth < 500) {
      if (dots.style.display === "inline") {
        dots.style.display = "none";
        btnText.innerHTML = "Read More";
        moreText.style.display = "none";
      } else {
        dots.style.display = "inline";
        btnText.innerHTML = "Read Less";
        moreText.style.display = "inline";
      }
    } else {
      moreText.style.display = "inline";
    }
  }

  return (
    <>
      <Helmet>
        <title>
          SSL Certificate Price in Pakistan | Buy SSL for Website | WebSouls
        </title>
        <meta
          name="title"
          content="SSL Certificate Price in Pakistan | Buy SSL for Website | WebSouls"
        />
        <meta
          name="description"
          content="WebSouls provides industry’s best prices for SSL Certificate in Pakistan. Buy SSL for website today and secure your digital communications."
        />
        <meta name="keywords" content="" />
        <script type="application/ld+json">
          {`{
  "@context": "https://schema.org",
  "@type": "Organization",
  "name": "WebSouls",
  "alternateName": "Web Hosting Pakistan",
  "url": "https://www.websouls.com/",
  "logo": "https://www.websouls.com/public/uploads/images/logo_1589438878.png",
  "contactPoint": [{
    "@type": "ContactPoint",
    "telephone": "+92 321 407 7991",
    "contactType": "technical support",
    "areaServed": "PK",
    "availableLanguage": ["en","Urdu"]
  },{
    "@type": "ContactPoint",
    "telephone": "+92 322 525 2352",
    "contactType": "sales",
    "areaServed": "PK",
    "availableLanguage": ["en","Urdu"]
  }],
  "sameAs": [
    "https://www.facebook.com/WebSouls/",
    "https://www.twitter.com/futuresouls",
    "https://www.instagram.com/websouls.pk/",
    "https://www.linkedin.com/company/websouls/",
    "https://github.com/websouls",
    "https://www.websouls.com/"
  ]
}`}
        </script>
        <script type="application/ld+json">
          {`{
  "@context": "https://schema.org/", 
  "@type": "Product", 
  "name": "SSL Certificate in Pakistan - WebSouls",
  "image": "https://www.websouls.com/public/uploads/images/logo_1589438878.png",
  "description": "WebSouls is a well-known and a widely trusted company providing web hosting services in Pakistan. We’re proud to represent ourselves as the no.1 web hosting services provider in the nation, and have served 1000s of corporate and government organizations with our quality services and up-to-the mark hosting solutions.

You can purchase an SSL certificate from us to make your website secure, and also to protect the sensitive data of your website visitors from hackers and thieves. In addition to this, getting an SSL certificate for your website can help you achieve higher rankings on Google’s Search Engine Result Pages, and build your customers’ trust.",
  "brand": "WebSouls",
  "sku": "SSL Certificate in Pakistan",
  "mpn": "SSL Certificate in Pakistan",
  "offers": {
    "@type": "AggregateOffer",
    "url": "https://www.websouls.com/ssl-certificates",
    "priceCurrency": "PKR",
    "lowPrice": "2800",
    "highPrice": "71000",
    "offerCount": "3"
  },
  "aggregateRating": {
    "@type": "AggregateRating",
    "ratingValue": "4.9",
    "bestRating": "5",
    "worstRating": "4.3",
    "ratingCount": "4135"
  }
}`}
        </script>
        <script type="application/ld+json">
          {`{
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "What is SSL Certificate?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "SSL stand for Secure Socket Layer and it’s a great way of validating your web identity. An SSL certificate encrypts the information that a visitor sends to, or receives from your website. This way, it keeps your customers’ sensitive data safe and protected from thieves and hackers."
    }
  },{
    "@type": "Question",
    "name": "How can I install my SSL certificate?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "After you’ve successfully issued your SSL certificate, we’d send you an email to let you know about it. Whatever happens next very much depends on where your site is currently hosted, and also the options you selected while getting the SSL certificate.
In case you buy one of our managed hosting packages, we’d take care of all the heavy lifting for you."
    }
  },{
    "@type": "Question",
    "name": "What kind of SSL certificate should I get for my website?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "The type of SSL certificate that you should get depends upon various factors, including your current location, whether you own a business or a personal website, the number of domains you want to protect, and the type of hosting server you use. You can contact our sales representatives for further information and detailed guidelines."
    }
  },{
    "@type": "Question",
    "name": "How will visitors know that my website is safe?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "How your website is designed and developed is totally up to you. Nevertheless, your visitors will know that your site is safe for online transactions when they see the HTML code that would be displayed in their browser bar."
    }
  }]
}`}
        </script>
        <link rel="canonical" href="https://websouls.com/ssl-certificates" />
      </Helmet>
      <section className="ssliderhost banner-size  ">
        <div className="container">
          <div className="row">
            <h2 className="first-heading">No More “Not Secure”</h2>
            <h5 className="second-heading">
              Win the Hearts of Your Customers with a Trustworthy Site
            </h5>
            <hr className="divider scnd_divider mx-auto  mb-5" />
            <Crousels
              packages={packages}
              getActivePromotions={getActivePromotions}
              loading={loading}
            />
          </div>
        </div>
      </section>

      <AllPlanIncludes plansData={allplans} />
      <BundelBooster bundleData={BundleData} />
      <GoingOnline Data={GoingOnlineData} />
      <WhyChooseUsSecond />
      <ComparePlans comparePlansState={comparePlansState} />
      <DomainMigration Data={data} />
      <InPakistan Data={InPakistanData} />
      <Faq Data={faqData} />
      <WillingForHelp helpData={WillingToHelpData} />
    </>
  );
};

export default SslCertificate;
