import React, { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import DomainSearchGlobal from "./includes/DomainSearchGlobal";

const AddDomain = () => {
  const location = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    if (location.key === "default") {
      navigate("/");
    }
  }, []);

  return (
    <>
      <DomainSearchGlobal />
    </>
  );
};

export default AddDomain;
