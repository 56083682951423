import React, { useState, useEffect } from "react";
import { useLocation } from "react-router-dom";
import { Helmet } from "react-helmet";
import { Link, useNavigate } from "react-router-dom";
import { useSelector, useDispatch } from "react-redux";
import { Elements } from "@stripe/react-stripe-js";
import { CardElement, useStripe, useElements } from "@stripe/react-stripe-js";
import CartOrder from "../../reuseable/CartOrder";
import PaymentMethod from "./includes/PaymentMethod";
import Signup from "./includes/Signup";
import Login from "./includes/Login";
import {
  getPrice,
  getForCartPrice,
  getPackageBasePrice,
  DomainTld,
} from "../../../services/Helper";
import {
  LoginByEmail,
  LoginByEmaill,
  GetActivePromotions,
  AddClient,
  ValidateLogin,
  GetInvoicePrice,
  AddOrder,
  SSOToken,
} from "../../../services/Network.js";
import axios from "axios";
import Stripee from "./Stripee";

const Cart = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const strip = useStripe();
  const elements = useElements();

  const currentURL = location.pathname;
  const { packageCart } = useSelector((state) => state.packageCart);
  const { domainNameClient } = useSelector((state) => state.domainNameClient);
  const { currencyName } = useSelector((state) => state.currencyName);
  const { currentUrl } = useSelector((state) => state.currentUrl);
  const { addonCart } = useSelector((state) => state.addonCart);
  const { domainSearchCart } = useSelector((state) => state.domainSearchCart);
  const { addingCycle } = useSelector((state) => state.addingCycle);
  const { addingCycleDomain } = useSelector((state) => state.addingCycleDomain);

  const [showStripeCard, setShowStripeCard] = useState(false);
  const [isSubmitedClick, setIsSubmited] = useState(false);
  const [stripeData, setStripeData] = useState(null);
  const [submitStripeButton, setSubmitStripeButton] = useState(false);

  const [onSubmittButton, setOnSubmittButton] = useState(null);
  const [loginToggle, setLoginToggle] = useState(false);
  const [signupToggle, setSignupToggle] = useState(true);
  const [preloaderInvoice, serPreloaderInvoice] = useState(false);
  const [paymentMethodSelected, setPaymentMethodSelected] = useState("stripe");

  const [clientLoginUserId, setClientLoginUserId] = useState("");
  const [customerClientId, setCustomerClientId] = useState("");
  const [clientLoginHashPass, setClientLoginHashPass] = useState("");

  const [remaningFormToggle, setRemaningFormToggle] = useState(false);
  const [hideContinueButton, setHideContinueButton] = useState(true);
  const [hidePayments, setHidePayments] = useState(true);

  const [customerFirstName, setCustomerFirstName] = useState("");
  const [customerLastName, setCustomerLastName] = useState("");
  const [customerEmailAddress, setCustomerEmailAddress] = useState("");
  const [customerPassword, setCustomerPassword] = useState("");
  const [customerCompanyName, setCustomerCompanyName] = useState("");
  const [customerPhoneNumber, setCustomerPhoneNumber] = useState("");
  const [customerAddress, setCustomerAddress] = useState("");
  const [customerCity, setCustomerCity] = useState("Lahore");
  const [customerState, setCustomerState] = useState("Punjab");
  const [customerPostalCode, setCustomerPostalCode] = useState("54000");
  const [customerCountry, setCustomerCountry] = useState("PK");
  const [customerWhereToFind, setCustomerWhereToFind] = useState("");

  const [packagePid, setPacakgesId] = useState([]);
  const [packagePrOverride, setPacakgePrOverride] = useState([]);
  const [packageBillingCycle, setPackagesBillingCycle] = useState([]);
  const [domainForOrder, setDomainForOrder] = useState([]);
  const [domainBillingCycle, setDomainBiilingCyle] = useState([]);
  const [packageAddonsState, setPacakgeAddonState] = useState([]);
  const [domainTypesOrder, setDomainTypesOrder] = useState([]);
  const [domainIdPro, setdomainIdPro] = useState([]);

  const [getActivePromotions, setActivePromotions] = useState([]);

  let arrayPidPackage = [];
  let Domain = [];
  let arrayPackageAddons = [];
  let packagePidCycle = [];
  let domainRegPeriod = [];
  let domainsTupes = [];
  let packagePriceOverrirde = [];
  let idProctectionese = [];

  useEffect(() => {
    // console.log("pid: ", packagePid);
    // console.log("billingcycle: ", packageBillingCycle);
    // console.log("addons: ", packageAddonsState);
    // console.log("domaintype: ", domainTypesOrder);
    // console.log("domain: ", domainForOrder);
    // console.log("paymentmethod: ", paymentMethodSelected);
    // console.log("priceoverride: ", packagePrOverride);
    // console.log("regperiod: ", domainBillingCycle);
    // console.log("idprotection: ", domainIdPro);

    dispatch({
      type: "urlCurrent",
      payload: location.pathname,
    });

    setTimeout(() => {
      localStorage?.removeItem("loggedClicked");
      // document?.getElementByClass("react-loading-skeleton")?.addClass("d-done");
    }, 1000);
    if (localStorage?.getItem("client_id") != null) {
      LoginByEmaill(localStorage?.getItem("email")).then((response) => {
        localStorage.setItem("first_name", response?.firstname);
        localStorage.setItem("last_name", response?.lastname);
        localStorage.setItem("email", response?.email);
        localStorage.setItem("company_name", response?.companyname);
        localStorage.setItem("phonenumber", response?.phonenumber);
        localStorage.setItem("address1", response?.address1);
        localStorage.setItem("city", response?.city);
        localStorage.setItem("state", response?.state);
        localStorage.setItem("countrycode", response?.countrycode);
        localStorage.setItem("countryname", response?.countryname);
        localStorage.setItem("postcode", response?.postcode);
        localStorage.setItem("googlemap", response?.customfields[1]?.value);

        setHidePayments(false);
        this.handleUpdate();
      });

      //}, 5000);
    }

    //getting pids of array

    for (let i = 0; i < packageCart?.length; i++) {
      arrayPidPackage?.push(packageCart[i]?.pid);
      if (packageCart[i]?.freedomain[0]) {
        idProctectionese?.push("");
        Domain?.push(packageCart[i]?.freedomain[0]?.domainName);
        domainRegPeriod?.push("");
        if (packageCart[i]?.freedomain[0]?.name === "domainregister") {
          domainsTupes?.push("register");
        }
        if (packageCart[i]?.freedomain[0]?.name === "Domain Transfer") {
          domainsTupes?.push("transfer");
        }
      }

      var str = "";
      for (let l = 0; l < packageCart[i]?.addons?.length; l++) {
        str = str + packageCart[i]?.addons[l]?.id + ",";
      }
      arrayPackageAddons?.push(str.replace(/,\s*$/, ""));
    }
    if (domainNameClient.length > 0) {
      for (var d = 0; d < domainNameClient.length; d++) {
        Domain?.push(domainNameClient[d]);
        domainRegPeriod?.push("");
        domainsTupes?.push("");
        idProctectionese?.push("");
      }
    }
    //getting domains of array
    for (let k = 0; k < domainSearchCart?.length; k++) {
      Domain?.push(domainSearchCart[k]?.domainName);

      if (domainSearchCart[k]?.name === "domainregister") {
        domainsTupes?.push("register");
      }
      if (
        domainSearchCart[k]?.domainAddon &&
        domainSearchCart[k]?.domainAddon.length > 0
      ) {
        idProctectionese?.push(true);
      } else {
        idProctectionese?.push("");
      }
      // idProctection.push(ipp);
    }

    //getting packages billing cycle
    //console.log("adsadad" ,addingCycle);
    //console.log("pcart" ,packageCart);
    for (let j = 0; j < addingCycle?.length; j++) {
      packagePidCycle?.push(addingCycle[j]?.packageCycle);

      //////////  my codde
      if (packageCart?.length > 0) {
        // console.log("ppr, packageCart: ", packageCart)
        // console.log("ppr, addingCycle[j]?.packageId: ", addingCycle[j]?.packageId)
        // console.log("ppr,  currencyName?.api_id: ",  currencyName?.api_id)
        const ppr = getForCartPrice(
          getPackageBasePrice(
            packageCart,
            addingCycle[j]?.packageId,
            currencyName?.api_id
          ),
          addingCycle[j]?.packageId,
          addingCycle[j]?.packageCycle,
          JSON.parse(localStorage.getItem("pr56m")), // getActivePromotions,
          packageCart
            ?.filter((f) => f?.tid === addingCycle[j]?.tid)
            .map((maping) =>
              maping?.currency
                .filter((fil) => fil?.currency === currencyName?.api_id)
                .map((pr) => pr[addingCycle[j]?.packageCycle])
            )
        );


        if (ppr[0] === undefined) {
          console.log("undefined");
        } else {
          var rice = 0;
          var Promotions = JSON.parse(localStorage.getItem("pr56m"));
          if (Promotions.length > 0) {
            for (var l = 0; l < Promotions.length; l++) {
              var appliesto = Promotions[l].appliesto.split(",");
              if (appliesto.includes(`${addingCycle[j]?.packageId}`)) {
                rice = ppr;
                break;
              } else {
                rice = ppr[0][0];
              }
            }
          } else {
            rice = ppr[0][0];
          }
          packagePriceOverrirde?.push(rice);
        }

        /////
      }
    }

    //domain billing cyles

    for (let ss = 0; ss < addingCycleDomain?.length; ss++) {
      if (addingCycleDomain[ss]?.packageCycle === "annually") {
        domainRegPeriod?.push(1);
      }
      if (addingCycleDomain[ss]?.packageCycle === "biennially") {
        domainRegPeriod?.push(2);
      }
      if (addingCycleDomain[ss]?.packageCycle === "triennially") {
        domainRegPeriod?.push(3);
      }
      if (addingCycleDomain[ss]?.packageCycle === "fourlyprice") {
        domainRegPeriod?.push(4);
      }
      if (addingCycleDomain[ss]?.packageCycle === "fivelyprice") {
        domainRegPeriod?.push(5);
      }
      if (addingCycleDomain[ss]?.packageCycle === "sixlyprice") {
        domainRegPeriod?.push(6);
      }
      if (addingCycleDomain[ss]?.packageCycle === "sevenlyprice") {
        domainRegPeriod?.push(7);
      }
      if (addingCycleDomain[ss]?.packageCycle === "eightlyprice") {
        domainRegPeriod?.push(8);
      }
      if (addingCycleDomain[ss]?.packageCycle === "ninelyprice") {
        domainRegPeriod?.push(9);
      }
      if (addingCycleDomain[ss]?.packageCycle === "tenlyprice") {
        domainRegPeriod?.push(10);
      }
    }

    setPacakgePrOverride(packagePriceOverrirde);
    setPacakgesId(arrayPidPackage);
    setDomainForOrder(Domain);
    setPackagesBillingCycle(packagePidCycle);
    setDomainBiilingCyle(domainRegPeriod);
    setPacakgeAddonState(arrayPackageAddons);
    setDomainTypesOrder(domainsTupes);
    setdomainIdPro(idProctectionese);
  }, [
    currencyName,
    localStorage.getItem("email"),
    localStorage.getItem("client_id"),
    domainSearchCart,
    packageCart,
    localStorage.getItem("loggedClicked"),
  ]);

  const handelLoginToggle = (e) => {
    e.preventDefault();

    setLoginToggle(true);
    setSignupToggle(false);
  };
  const handelLoginTogglenew = () => {
    setLoginToggle(true);
    setSignupToggle(false);
  };

  const handelSignupToggle = () => {
    setLoginToggle(false);
    setSignupToggle(true);
  };

  const submitingData = (e) => {
    e.preventDefault();
    setOnSubmittButton(true);
    const getlocalStorage = localStorage.getItem("UserId");

    if (localStorage.getItem("client_id") === null) {
      //client regestiration
      // LoginByEmail(customerEmailAddress).then((responseee) => {
      //   if (responseee.result === "success") {
      //     document.getElementById("salready").classList.remove("d-none");
      //     return;
      //   } else {
      //     document.getElementById("salready").classList.add("d-none");
      //   }
      AddClient(
        customerFirstName,
        customerLastName,
        customerEmailAddress,
        customerAddress,
        customerCity,
        customerState,
        customerPostalCode,
        customerCountry,
        customerPhoneNumber,
        customerPassword,
        customerWhereToFind,
        currencyName?.api_id
      ).then((response) => {
        localStorage.setItem("client_id", response?.clientid);
        localStorage.setItem("email", customerEmailAddress);
        setCustomerClientId(response?.clientid);
        ValidateLogin(customerEmailAddress, customerPassword).then((respo) => {
          setClientLoginUserId(respo?.userid);
          setClientLoginHashPass(respo?.passwordhash);
          const clientID = localStorage.getItem("client_id");
          const totalValue = localStorage.getItem("total");
          AddOrder(
            clientID,
            packagePid,
            packageBillingCycle,
            packageAddonsState,
            domainTypesOrder,
            domainForOrder,
            paymentMethodSelected,
            packagePrOverride,
            domainBillingCycle,
            domainIdPro
          ).then((ress) => {
            if (
              paymentMethodSelected == "cash" ||
              paymentMethodSelected == "paymateau" ||
              paymentMethodSelected == "banktransfer"
            ) {
              //   serPreloaderInvoice(true);
              // navigate("/preload", {
              //   state: {
              //     order_id: ress?.orderid,
              //     isOnlineMethod: true,
              //     invoice_id: ress?.invoiceid,
              //     order_total: localStorage.getItem("total"),
              //     paymentmethod: paymentMethodSelected,
              //     fullNmae: localStorage.getItem("first_name"),
              //   },
              // });

              dispatch({
                type: "resetdomainSearchCart",
              });
              dispatch({
                type: "resetaddonCart",
              });
              dispatch({
                type: "resetclientDetails",
              });
              dispatch({
                type: "resetcartPayment",
              });
              dispatch({
                type: "resetpackageCart",
              });
              dispatch({
                type: "resetclientDomainName",
              });
              navigate("/thankyou", {
                state: {
                  order_id: ress?.orderid,
                  invoice_id: ress?.invoiceid,
                  order_total: localStorage.getItem("total"),
                  paymentmethod: paymentMethodSelected,
                  fullNmae: localStorage.getItem("first_name"),
                },
              });

              setOnSubmittButton(false);
              localStorage.removeItem("total");
              localStorage.removeItem("pr56m");
              localStorage.removeItem("addingCycleDomain");
              localStorage.removeItem("setupTime");
              localStorage.removeItem("domainNameClient");
              localStorage.removeItem("packageCart");
              localStorage.removeItem("addingCycle");
              localStorage.removeItem("domainSearchCart");
              localStorage.removeItem("tempdomainaddingCycle");
              localStorage.removeItem("tempdomainSearchCart");
              return;
            }
            // serPreloaderInvoice(true);

            // navigate("/preload", {
            //   state: {
            //     order_id: ress?.orderid,
            //     isOnlineMethod: true,
            //     order_total: totalValue,
            //     invoice_id: ress?.invoiceid,
            //     paymentmethod: paymentMethodSelected,
            //     fullNmae: localStorage.getItem("first_name"),
            //   },
            // });

            dispatch({
              type: "resetdomainSearchCart",
            });
            dispatch({
              type: "resetaddonCart",
            });
            dispatch({
              type: "resetclientDetails",
            });
            dispatch({
              type: "resetcartPayment",
            });
            dispatch({
              type: "resetpackageCart",
            });
            dispatch({
              type: "resetclientDomainName",
            });

            //stripe redirection starting here
            if (paymentMethodSelected == "stripe") {
              setIsSubmited(true);
              GetInvoicePrice(ress?.invoiceid)
                .then((res) => {
                  setStripeData({
                    order_id: ress?.orderid,
                    invoice_id: ress?.invoiceid,
                    order_total: res.total,
                    paymentmethod: paymentMethodSelected,
                    fullNmae: localStorage.getItem("first_name"),
                    invoice_date: res.date,
                  });
                  setShowStripeCard(true);
                  setSubmitStripeButton(true);
                })
                .catch((err) => console.log(err));
                window.scroll(0, 0);
                window["scrollTo"]({ top: 0, behavior: "smooth" });
                window.scrollTo({
                  top: 0,
                  left: 0,
                  behavior: "smooth",
                });
            } else {
              navigate("/thankyou", {
                state: {
                  order_id: ress?.orderid,
                  invoice_id: ress?.invoiceid,
                  order_total: localStorage.getItem("total"),
                  paymentmethod: paymentMethodSelected,
                  fullNmae: localStorage.getItem("first_name"),
                },
              });
            }
            const clientIDs = localStorage.getItem("client_id");
            //SSOToken(clientIDs, ress.invoiceid).then((resss) => {
            // localStorage.setItem("ssco", resss.access_token);

            localStorage.removeItem("ssco");
            localStorage.removeItem("total");

            // window.open(response.data.redirect_url);

            localStorage.removeItem("total");
            localStorage.removeItem("addingCycleDomain");
            localStorage.removeItem("setupTime");
            localStorage.removeItem("domainNameClient");
            localStorage.removeItem("packageCart");
            localStorage.removeItem("addingCycle");
            localStorage.removeItem("domainSearchCart");
            localStorage.removeItem("tempdomainaddingCycle");
            localStorage.removeItem("tempdomainSearchCart");

            //window.location.replace(resss.redirect_url);
            // serPreloaderInvoice(true);
            localStorage.removeItem("ssco");
            localStorage.removeItem("total");

            localStorage.removeItem("total");
            localStorage.removeItem("addingCycleDomain");
            localStorage.removeItem("setupTime");
            localStorage.removeItem("domainNameClient");
            localStorage.removeItem("packageCart");
            localStorage.removeItem("addingCycle");
            localStorage.removeItem("domainSearchCart");
            localStorage.removeItem("tempdomainaddingCycle");
            localStorage.removeItem("tempdomainSearchCart");
            return;
            // });
          });
        });
      });
      // });
    } else {
      const clientIDss = localStorage.getItem("client_id");
      let totalValues = [];
      if (currencyName.api_id == 3) {
        totalValues.push(localStorage.getItem("total").split(".")[0]);
      } else {
        totalValues.push(localStorage.getItem("total"));
      }
      AddOrder(
        clientIDss,
        packagePid,
        packageBillingCycle,
        packageAddonsState,
        domainTypesOrder,
        domainForOrder,
        paymentMethodSelected,
        packagePrOverride,
        domainBillingCycle,
        domainIdPro
      ).then((ress) => {
        if (
          paymentMethodSelected == "cash" ||
          paymentMethodSelected == "paymateau" ||
          paymentMethodSelected == "banktransfer"
        ) {
          // serPreloaderInvoice(true);
          // navigate("/preload", {
          //   state: {
          //     order_id: ress?.orderid,
          //     isOnlineMethod: true,
          //     invoice_id: ress?.invoiceid,
          //     order_total: localStorage.getItem("total"),
          //     paymentmethod: paymentMethodSelected,
          //     fullNmae: localStorage.getItem("first_name"),
          //   },
          // });
          dispatch({
            type: "resetdomainSearchCart",
          });
          dispatch({
            type: "resetaddonCart",
          });
          dispatch({
            type: "resetclientDetails",
          });
          dispatch({
            type: "resetcartPayment",
          });
          dispatch({
            type: "resetpackageCart",
          });
          dispatch({
            type: "resetclientDomainName",
          });
          navigate("/thankyou", {
            state: {
              order_id: ress?.orderid,
              invoice_id: ress?.invoiceid,
              order_total: localStorage.getItem("total"),
              paymentmethod: paymentMethodSelected,
              fullNmae: localStorage.getItem("first_name"),
            },
          });
          localStorage.removeItem("total");
          localStorage.removeItem("addingCycleDomain");
          localStorage.removeItem("pr56m");
          localStorage.removeItem("setupTime");
          localStorage.removeItem("domainNameClient");
          localStorage.removeItem("packageCart");
          localStorage.removeItem("addingCycle");
          localStorage.removeItem("domainSearchCart");
          localStorage.removeItem("tempdomainaddingCycle");
          localStorage.removeItem("tempdomainSearchCart");
          return;
        }
        // serPreloaderInvoice(true);
        const clientIDs = localStorage.getItem("client_id");
        // navigate("/preload", {
        //   state: {
        //     order_id: ress?.orderid,
        //     isOnlineMethod: true,
        //     order_total: localStorage.getItem("total"),
        //     invoice_id: ress?.invoiceid,
        //     paymentmethod: paymentMethodSelected,
        //     fullNmae: localStorage.getItem("first_name"),
        //   },
        // });

        dispatch({
          type: "resetdomainSearchCart",
        });
        dispatch({
          type: "resetaddonCart",
        });
        dispatch({
          type: "resetclientDetails",
        });
        dispatch({
          type: "resetcartPayment",
        });
        dispatch({
          type: "resetpackageCart",
        });
        dispatch({
          type: "resetclientDomainName",
        });
        //stripe redirection starting here
        if (paymentMethodSelected == "stripe") {
          setIsSubmited(true);
          GetInvoicePrice(ress?.invoiceid)
            .then((res) => {
              setStripeData({
                order_id: ress?.orderid,
                invoice_id: ress?.invoiceid,
                order_total: res.total,
                paymentmethod: paymentMethodSelected,
                fullNmae: localStorage.getItem("first_name"),
                invoice_date: res.date,
              });
              setShowStripeCard(true);
              setSubmitStripeButton(true);
            })
            .catch((err) => console.log(err));
            window.scroll(0, 0);
            window["scrollTo"]({ top: 0, behavior: "smooth" });
            window.scrollTo({
              top: 0,
              left: 0,
              behavior: "smooth",
            });
        } else {
          navigate("/thankyou", {
            state: {
              order_id: ress?.orderid,
              invoice_id: ress?.invoiceid,
              order_total: localStorage.getItem("total"),
              paymentmethod: paymentMethodSelected,
              fullNmae: localStorage.getItem("first_name"),
            },
          });
        }

        //SSOToken(clientIDs, ress.invoiceid).then((resss) => {
        // localStorage.setItem("ssco", resss.access_token);

        localStorage.removeItem("ssco");
        localStorage.removeItem("total");

        localStorage.removeItem("total");
        localStorage.removeItem("addingCycleDomain");
        localStorage.removeItem("setupTime");
        localStorage.removeItem("domainNameClient");
        localStorage.removeItem("packageCart");
        localStorage.removeItem("addingCycle");
        localStorage.removeItem("domainSearchCart");
        localStorage.removeItem("tempdomainaddingCycle");
        localStorage.removeItem("tempdomainSearchCart");
        // window.open(response.data.redirect_url);

        // window.location.replace(resss.redirect_url);
        // serPreloaderInvoice(true);
        localStorage.removeItem("ssco");
        localStorage.removeItem("total");

        localStorage.removeItem("total");
        localStorage.removeItem("addingCycleDomain");
        localStorage.removeItem("setupTime");
        localStorage.removeItem("domainNameClient");
        localStorage.removeItem("packageCart");
        localStorage.removeItem("addingCycle");
        localStorage.removeItem("domainSearchCart");
        localStorage.removeItem("tempdomainaddingCycle");
        localStorage.removeItem("tempdomainSearchCart");
        return;
        //});
      });
    }
  };

  const handelRemaningFormFeild = (e) => {
    e.preventDefault();
    var bl = false;
    var fn = document.getElementById("fn").value;
    var ln = document.getElementById("ln").value;
    var em = document.getElementById("em").value;
    var pw = document.getElementById("pw").value;

    if (fn == "") {
      sessionStorage.setItem("fn", 1);
    } else {
      sessionStorage.removeItem("fn");
    }
    if (ln == "") {
      sessionStorage.setItem("ln", 1);
    } else {
      sessionStorage.removeItem("ln");
    }
    if (em == "") {
      sessionStorage.setItem("em", 1);
    } else {
      sessionStorage.removeItem("em");
    }
    if (pw == "") {
      sessionStorage.setItem("pw", 1);
    } else {
      sessionStorage.removeItem("pw");
    }

    if (fn != "" && ln != "" && em != "" && pw != "") {
      bl = true;
    }

    if (bl) {
      LoginByEmail(customerEmailAddress).then((responseee) => {
        if (responseee.result === "success" && responseee.users.length > 0) {
          document.getElementById("salready").classList.remove("d-none");
          setRemaningFormToggle(false);
          setHideContinueButton(true);
          setHidePayments(true);
          return;
        } else {
          document.getElementById("salready").classList.add("d-none");
          setRemaningFormToggle(true);
          setHideContinueButton(false);
          setHidePayments(false);
        }
      });
    }
    //  else {
    //   setRemaningFormToggle(true);
    //   setHideContinueButton(false);
    //   setHidePayments(false);
    // }
  };

  const handleUpdate = () => {
    //by calling this method react re-renders the component
    this.setState({});
  };

  const submitingDataon = (e) => {
    e.preventDefault();
    //serPreloaderInvoice(true);

    var bl = "t";
    var fn = document.getElementById("fn").value;
    var ln = document.getElementById("ln").value;
    var em = document.getElementById("em").value;
    var pw = document.getElementById("pw").value;
    var ph = document.getElementById("ph").value;
    var add = document.getElementById("add").value;
    var ct = document.getElementById("ct").value;
    var st = document.getElementById("st").value;
    var po = document.getElementById("po").value;
    var co = document.getElementById("co").value;
    var ho = document.getElementById("ho").value;
    var getSelectedValue = document.querySelector(
      'input[name="payment_method"]:checked'
    );
    const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

    if (fn == "") {
      document.getElementById("sfn").classList.remove("invisible");
    } else {
      document.getElementById("sfn").classList.add("invisible");
    }
    if (ln == "") {
      document.getElementById("sln").classList.remove("invisible");
    } else {
      document.getElementById("sln").classList.add("invisible");
    }
    if (em == "") {
      document.getElementById("sem").classList.remove("invisible");
    } else {
      document.getElementById("sem").classList.add("invisible");
    }
    if (!regex.test(em)) {
      document.getElementById("seme").classList.remove("invisible");
      bl = "";
    } else {
      document.getElementById("seme").classList.add("invisible");
    }

    if (pw == "") {
      document.getElementById("spw").classList.remove("invisible");
    } else {
      document.getElementById("spw").classList.add("invisible");
    }

    if (ph == "") {
      document.getElementById("sph").classList.remove("invisible");
      document.getElementById("ph").classList.add("formValidate");
      window.scrollTo(0, 300);
    } else {
      document.getElementById("sph").classList.add("invisible");
      document.getElementById("ph").classList.remove("formValidate");
    }
    if (add == "") {
      document.getElementById("sadd").classList.remove("invisible");
      document.getElementById("add").classList.add("formValidate");
      window.scrollTo(0, 300);
    } else {
      document.getElementById("sadd").classList.add("invisible");
      document.getElementById("add").classList.remove("formValidate");
    }
    if (ct == "") {
      document.getElementById("sct").classList.remove("invisible");
      document.getElementById("ct").classList.add("formValidate");
      window.scrollTo(0, 300);
    } else {
      document.getElementById("sct").classList.add("invisible");
      document.getElementById("ct").classList.remove("formValidate");
    }
    if (st == "") {
      document.getElementById("sst").classList.remove("invisible");
      document.getElementById("st").classList.add("formValidate");
      window.scrollTo(0, 300);
    } else {
      document.getElementById("sst").classList.add("invisible");
      document.getElementById("st").classList.remove("formValidate");
    }
    if (po == "") {
      document.getElementById("spo").classList.remove("invisible");
      document.getElementById("po").classList.add("formValidate");
      window.scrollTo(0, 300);
    } else {
      document.getElementById("spo").classList.add("invisible");
      document.getElementById("po").classList.remove("formValidate");
    }
    if (co == "") {
      document.getElementById("sco").classList.remove("invisible");
      document.getElementById("co").classList.add("formValidate");
      window.scrollTo(0, 300);
    } else {
      document.getElementById("sco").classList.add("invisible");
      document.getElementById("co").classList.remove("formValidate");
    }
    if (ho == "") {
      document.getElementById("sho").classList.remove("invisible");
      document.getElementById("ho").classList.add("formValidate");
      window.scrollTo(0, 300);
    } else {
      document.getElementById("sho").classList.add("invisible");
      document.getElementById("ho").classList.remove("formValidate");
    }

    if (getSelectedValue == null) {
      document.getElementById("spay").classList.remove("d-none");
    } else {
      document.getElementById("spay").classList.add("d-none");
    }

    if (
      fn != "" &&
      ln != "" &&
      em != "" &&
      pw != "" &&
      bl != "" &&
      bl != "" &&
      ph != "" &&
      add != "" &&
      ct != "" &&
      st != "" &&
      po != "" &&
      co != "" &&
      ho != "" &&
      getSelectedValue != null
    ) {
      // serPreloaderInvoice(true);
      submitingData(e);
    }
  };

  return (
    <>
      <Helmet>
        <title>Cart :: Websouls</title>
        <meta name="title" content="" />
        <meta name="description" content="" />
        <meta name="keywords" content="" />
        
       
      </Helmet>
      <section style={{ margin: "0" }} className="psty">
        {preloaderInvoice ? (
          <>
            {" "}
            <div id="overlayCSS">
              <p className="overlay_content">
                Please Wait! System Is Generating Invoice.
              </p>
            </div>{" "}
          </>
        ) : (
          <div className="container">
            {isSubmitedClick ? null : (
              <div className="row">
                <Link
                  to="#"
                  style={{ textDecoration: "none", color: "#005880" }}
                  className="go-back"
                  onClick={() => navigate(-1)}
                >
                  <i className="fa fa-arrow-left"></i> Go Back
                </Link>
              </div>
            )}

            <div className="row sm-g-1 g-5">
              {
                showStripeCard ? null :  <CartOrder paymentMethodSelected={paymentMethodSelected} />
              }
             
              <div className="col-lg-7 col-xl-8 col-xxl-8 ">
                {isSubmitedClick ? null : (
                  <h6 className="result-message p-2 p-sm-1 p-md-4 p-lg-4">
                    {localStorage?.getItem("first_name") ? (
                      `We Missed you, ${localStorage?.getItem("first_name")}`
                    ) : (
                      <>
                        {hideContinueButton
                          ? "Let’s Talk About You!"
                          : "Help us know you better"}
                      </>
                    )}
                  </h6>
                )}

                <div
                  className={
                    isSubmitedClick ? "" : "searched-result box-border mb-2"
                  }
                >
                  {loginToggle ? (
                    <Login
                      handelSignupToggle={handelSignupToggle}
                      handelLoginTogglenew={handelLoginTogglenew}
                    />
                  ) : null}
                  {signupToggle ? (
                    <>
                      {isSubmitedClick ? null : (
                        <Signup
                          handelLoginToggle={handelLoginToggle}
                          remaningFormToggle={remaningFormToggle}
                          setRemaningFormToggle={setRemaningFormToggle}
                          hideContinueButton={hideContinueButton}
                          setHideContinueButton={setHideContinueButton}
                          customerFirstName={customerFirstName}
                          setCustomerFirstName={setCustomerFirstName}
                          customerLastName={customerLastName}
                          setCustomerLastName={setCustomerLastName}
                          customerEmailAddress={customerEmailAddress}
                          setCustomerEmailAddress={setCustomerEmailAddress}
                          customerPassword={customerPassword}
                          customerCompanyName={customerCompanyName}
                          setCustomerCompanyName={setCustomerCompanyName}
                          setCustomerPassword={setCustomerPassword}
                          customerPhoneNumber={customerPhoneNumber}
                          setCustomerPhoneNumber={setCustomerPhoneNumber}
                          customerAddress={customerAddress}
                          setCustomerAddress={setCustomerAddress}
                          customerCity={customerCity}
                          setCustomerCity={setCustomerCity}
                          customerState={customerState}
                          setCustomerState={setCustomerState}
                          customerPostalCode={customerPostalCode}
                          setCustomerPostalCode={setCustomerPostalCode}
                          customerCountry={customerCountry}
                          setCustomerCountry={setCustomerCountry}
                          customerWhereToFind={customerWhereToFind}
                          setCustomerWhereToFind={setCustomerWhereToFind}
                          handelRemaningFormFeild={handelRemaningFormFeild}
                        />
                      )}

                      {hidePayments ? null : (
                        <>
                          {isSubmitedClick ? null : (
                            <PaymentMethod
                              paymentMethodSelected={paymentMethodSelected}
                              setPaymentMethodSelected={
                                setPaymentMethodSelected
                              }
                            />
                          )}

                          {showStripeCard ? (
                            <Stripee stripeData={stripeData} />
                          ) : null}

                          {/* <button
                      className={
                        localStorage.getItem("loggedClicked")
                          ? "d-none"
                          : "button-sigup mt-3 ml-0 mx-3"
                      }
                      onClick={(e) => submitingData(e)}
                    >
                      Submit
                    </button> */}
                  
                    {
                      isSubmitedClick ? null : <> {submitStripeButton ? null : (
                        <>
                          {localStorage.getItem("loggedClicked") ? (
                            <>
                              <button
                                className="d-none"
                                onClick={(e) => submitingDataon(e)}
                              >
                                Submit
                              </button>
                            </>
                          ) : (
                            <>
                            
                              {packageCart.length !== 0 ||
                              domainSearchCart.length !== 0 ? (
                                <>
                                  {onSubmittButton !== true ? (
                                    <>
                                      <button
                                        id="cartcheckout"
                                        className="button-sigup mt-3 ml-0 mx-3"
                                        onClick={(e) => submitingDataon(e)}
                                      >
                                        Submit
                                      </button>
                                    </>
                                  ) : (
                                    <>
                                      <button
                                        id="cartcheckout"
                                        className="button-sigup pacakgenotAllowedsubmit mt-3 ml-0 mx-3"
                                        disabled={true}
                                      >
                                        <i
                                          style={{ fontSize: "20px" }}
                                          className="fa fa-spinner fa-spin"
                                        ></i>
                                      </button>
                                    </>
                                  )}
                                </>
                              ) : (
                                <button className="button-sigup mt-3 ml-0 mx-3 pacakgenotAllowedsubmit">
                                  Submit
                                </button>
                              )}
                            </>
                          )}
                        </>
                      )} </>
                    }
                         
                        </>
                      )}
                    </>
                  ) : null}
                </div>
              </div>
            </div>
          </div>
        )}
      </section>
    </>
  );
};

export default Cart;
