import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
const Signup = (props) => {
  const [blok, setblok] = useState(false);
  useEffect(() => {
    handleBlok();
  }, [blok]);

  const handleBlok = () => {
    // var fn = document.getElementById("fname").value;
    // var ln = document.getElementById("lname").value;
    // var em = document.getElementById("email").value;
    // var pw = document.getElementById("pwd").value;
    // if (fn != "" && ln != "" && em != "" && pw != "") {
    //   setblok(true);
    // }
  };

  return (
    <>
      <form
        id="skk"
        className={
          localStorage.getItem("loggedClicked")
            ? "px-3 py-3"
            : "d-none px-3 py-3"
        }
      >
        <div className="row pt-3">
          <div className="col-md-6 col-ms-12 col-xs-12">
            <div>
              <Skeleton style={{ width: "100%" }} />
            </div>
          </div>
          <div className="col-md-6 col-ms-12 col-xs-12">
            <div>
              <Skeleton style={{ width: "100%" }} />
            </div>
          </div>
          <div className="col-md-6 col-ms-12 col-xs-12">
            <div>
              <Skeleton style={{ width: "100%" }} />
            </div>
          </div>
          <div className="col-md-6 col-ms-12 col-xs-12">
            <div>
              <Skeleton style={{ width: "100%" }} />
            </div>
          </div>
          <div className="col-md-6 col-ms-12 col-xs-12">
            <div>
              <Skeleton style={{ width: "100%" }} />
            </div>
          </div>
          <div className="col-md-6 col-ms-12 col-xs-12">
            <div>
              <Skeleton style={{ width: "100%" }} />
            </div>
          </div>
          <div className="col-md-12 col-ms-12 col-xs-12">
            <div>
              <Skeleton style={{ width: "100%" }} />
            </div>
          </div>
          <div className="col-md-6 col-ms-12 col-xs-12">
            <div>
              <Skeleton style={{ width: "100%" }} />
            </div>
          </div>
          <div className="col-md-6 col-ms-12 col-xs-12">
            <div>
              <Skeleton style={{ width: "100%" }} />
            </div>
          </div>
          <div className="col-md-6 col-ms-12 col-xs-12">
            <div>
              <Skeleton style={{ width: "100%" }} />
            </div>
          </div>
          <div className="col-md-6 col-ms-12 col-xs-12">
            <div>
              <Skeleton style={{ width: "100%" }} />
            </div>
          </div>
          <div className="col-md-12 col-ms-12 col-xs-12">
            <div>
              <Skeleton style={{ width: "100%" }} />
            </div>
          </div>
        </div>
      </form>
      <form
        className={
          localStorage.getItem("loggedClicked")
            ? "d-none"
            : "px-3 py-3 animate__animated animate__fadeIn "
        }
      >
        <p>
          Already have an account? {""}
          {localStorage.getItem("client_id") ? null : (
            <Link to="#" onClick={(e) => props.handelLoginToggle(e)}>
              Sign In!
            </Link>
          )}
        </p>
        <>
          {localStorage.getItem("client_id") ? (
            <div className="row pt-3">
              <div className="col-md-6 col-ms-12 col-xs-12">
                <div>
                  <input
                    id="fn"
                    disabled
                    className="form-control"
                    name="first_name"
                    placeholder="Enter your first name"
                    type="text"
                    value={localStorage.getItem("first_name")}
                    onChange={(e) => props.setCustomerFirstName(e.target.value)}
                  />
                </div>
                <span id="sfn" className="invisible">
                  First name field is required
                </span>
              </div>
              <div className="col-md-6 col-ms-12 col-xs-12">
                <div>
                  <input
                    id="ln"
                    disabled
                    className="form-control"
                    name="last_name"
                    placeholder="Enter your last name"
                    type="text"
                    value={localStorage.getItem("last_name")}
                    onChange={(e) => props.setCustomerLastName(e.target.value)}
                  />
                </div>
                <span id="sln" className="invisible">
                  First name field is required
                </span>
              </div>
              <div className="col-md-6 col-ms-12 col-xs-12">
                <div>
                  <input
                    disabled
                    id="em"
                    className="form-control"
                    name="email"
                    placeholder="Enter your email"
                    type="email"
                    value={localStorage.getItem("email")}
                    onChange={(e) =>
                      props.setCustomerEmailAddress(e.target.value)
                    }
                  />
                </div>
                <span id="sem" className="invisible">
                  First name field is required
                </span>
                <span id="seme" className="invisible">
                  First name field is required
                </span>
              </div>
              <div className="col-md-6 col-ms-12 col-xs-12">
                <div>
                  <input
                    disabled
                    id="pw"
                    className="form-control"
                    required=""
                    placeholder="Enter your password"
                    name="password2"
                    type="password"
                    value={"websouls"}
                    onChange={(e) => props.setCustomerPassword(e.target.value)}
                  />
                </div>
                <span id="spw" className="invisible">
                  First name field is required
                </span>
              </div>

              <>
                <div className="col-md-6 col-ms-12 col-xs-12">
                  <div>
                    <input
                      disabled
                      id="d"
                      className="form-control"
                      name="company_name"
                      placeholder="Enter your organization name"
                      type="text"
                      value={localStorage.getItem("company_name")}
                      onChange={(e) =>
                        props.setCustomerCompanyName(e.target.value)
                      }
                    />
                  </div>
                </div>
                <div className="col-md-6 col-ms-12 col-xs-12">
                  <div>
                    <input
                      disabled
                      id="ph"
                      className="form-control"
                      name="phonenumber"
                      placeholder="Enter your phone number"
                      type="text"
                      value={localStorage.getItem("phonenumber")}
                      onChange={(e) =>
                        props.setCustomerPhoneNumber(e.target.value)
                      }
                    />
                  </div>
                  <span id="sph" className="invisible">
                    First name field is required
                  </span>
                </div>
                <div className="col-md-12 col-ms-12 col-xs-12">
                  <div>
                    <input
                      id="add"
                      disabled
                      className="form-control"
                      type="text"
                      placeholder="Enter your address"
                      value={localStorage.getItem("address1")}
                      onChange={(e) => props.setCustomerAddress(e.target.value)}
                    />
                  </div>
                  <span id="sadd" className="invisible">
                    First name field is required
                  </span>
                </div>
                <div className="col-md-6 col-ms-12 col-xs-12">
                  <div>
                    <input
                      disabled
                      id="ct"
                      className="form-control"
                      name="city"
                      placeholder="Enter your city"
                      type="text"
                      value={localStorage.getItem("city")}
                      onChange={(e) => props.setCustomerCity(e.target.value)}
                    />
                  </div>
                  <span id="sct" className="invisible">
                    First name field is required
                  </span>
                </div>
                <div className="col-md-6 col-ms-12 col-xs-12">
                  <div>
                    <input
                      disabled
                      id="st"
                      className="form-control"
                      name="state"
                      placeholder="Enter your state"
                      type="text"
                      value={localStorage.getItem("state")}
                      onChange={(e) => props.setCustomerState(e.target.value)}
                    />
                  </div>
                  <span id="sst" className="invisible">
                    First name field is required
                  </span>
                </div>
                <div className="col-md-6 col-ms-12 col-xs-12">
                  <div>
                    <input
                      disabled
                      id="po"
                      className="form-control"
                      name="postal_code"
                      type="text"
                      placeholder="Postal code
                      "
                      value={
                        localStorage.getItem("postalcode")
                          ? localStorage.getItem("postalcode")
                          : "Enter the Postal code"
                      }
                      onChange={(e) =>
                        props.setCustomerPostalCode(e.target.value)
                      }
                    />
                  </div>
                  <span id="spo" className="invisible">
                    First name field is required
                  </span>
                </div>
                <div className="col-md-6 col-ms-12 col-xs-12">
                  <div>
                    <select
                      disabled
                      id="co"
                      className="form-select"
                      placeholder="Enter your country"
                      value={localStorage.getItem("countrycode")}
                      onChange={(e) => props.setCustomerCountry(e.target.value)}
                    >
                      <option value="">Enter your country</option>

                      <option value="AF">Afghanistan</option>
                      <option value="AX">Åland Islands</option>
                      <option value="AL">Albania</option>
                      <option value="DZ">Algeria</option>
                      <option value="AS">American Samoa</option>
                      <option value="AD">Andorra</option>
                      <option value="AO">Angola</option>
                      <option value="AI">Anguilla</option>
                      <option value="AQ">Antarctica</option>
                      <option value="AG">Antigua and Barbuda</option>
                      <option value="AR">Argentina</option>
                      <option value="AM">Armenia</option>
                      <option value="AW">Aruba</option>
                      <option value="AU">Australia</option>
                      <option value="AT">Austria</option>
                      <option value="AZ">Azerbaijan</option>
                      <option value="BS">Bahamas</option>
                      <option value="BH">Bahrain</option>
                      <option value="BD">Bangladesh</option>
                      <option value="BB">Barbados</option>
                      <option value="BY">Belarus</option>
                      <option value="BE">Belgium</option>
                      <option value="BZ">Belize</option>
                      <option value="BJ">Benin</option>
                      <option value="BM">Bermuda</option>
                      <option value="BT">Bhutan</option>
                      <option value="BO">
                        Bolivia, Plurinational State of
                      </option>
                      <option value="BA">Bosnia and Herzegovina</option>
                      <option value="BW">Botswana</option>
                      <option value="BV">Bouvet Island</option>
                      <option value="BR">Brazil</option>
                      <option value="IO">British Indian Ocean Territory</option>
                      <option value="BN">Brunei Darussalam</option>
                      <option value="BG">Bulgaria</option>
                      <option value="BF">Burkina Faso</option>
                      <option value="BI">Burundi</option>
                      <option value="KH">Cambodia</option>
                      <option value="CM">Cameroon</option>
                      <option value="CA">Canada</option>
                      <option value="CV">Cape Verde</option>
                      <option value="KY">Cayman Islands</option>
                      <option value="CF">Central African Republic</option>
                      <option value="TD">Chad</option>
                      <option value="CL">Chile</option>
                      <option value="CN">China</option>
                      <option value="CX">Christmas Island</option>
                      <option value="CC">Cocos (Keeling) Islands</option>
                      <option value="CO">Colombia</option>
                      <option value="KM">Comoros</option>
                      <option value="CG">Congo</option>
                      <option value="CD">
                        Congo, the Democratic Republic of the
                      </option>
                      <option value="CK">Cook Islands</option>
                      <option value="CR">Costa Rica</option>
                      <option value="CI">Côte d'Ivoire</option>
                      <option value="HR">Croatia</option>
                      <option value="CU">Cuba</option>
                      <option value="CY">Cyprus</option>
                      <option value="CZ">Czech Republic</option>
                      <option value="DK">Denmark</option>
                      <option value="DJ">Djibouti</option>
                      <option value="DM">Dominica</option>
                      <option value="DO">Dominican Republic</option>
                      <option value="EC">Ecuador</option>
                      <option value="EG">Egypt</option>
                      <option value="SV">El Salvador</option>
                      <option value="GQ">Equatorial Guinea</option>
                      <option value="ER">Eritrea</option>
                      <option value="EE">Estonia</option>
                      <option value="ET">Ethiopia</option>
                      <option value="FK">Falkland Islands (Malvinas)</option>
                      <option value="FO">Faroe Islands</option>
                      <option value="FJ">Fiji</option>
                      <option value="FI">Finland</option>
                      <option value="FR">France</option>
                      <option value="GF">French Guiana</option>
                      <option value="PF">French Polynesia</option>
                      <option value="TF">French Southern Territories</option>
                      <option value="GA">Gabon</option>
                      <option value="GM">Gambia</option>
                      <option value="GE">Georgia</option>
                      <option value="DE">Germany</option>
                      <option value="GH">Ghana</option>
                      <option value="GI">Gibraltar</option>
                      <option value="GR">Greece</option>
                      <option value="GL">Greenland</option>
                      <option value="GD">Grenada</option>
                      <option value="GP">Guadeloupe</option>
                      <option value="GU">Guam</option>
                      <option value="GT">Guatemala</option>
                      <option value="GG">Guernsey</option>
                      <option value="GN">Guinea</option>
                      <option value="GW">Guinea-Bissau</option>
                      <option value="GY">Guyana</option>
                      <option value="HT">Haiti</option>
                      <option value="HM">
                        Heard Island and McDonald Islands
                      </option>
                      <option value="VA">Holy See (Vatican City State)</option>
                      <option value="HN">Honduras</option>
                      <option value="HK">Hong Kong</option>
                      <option value="HU">Hungary</option>
                      <option value="IS">Iceland</option>
                      <option value="IN">India</option>
                      <option value="ID">Indonesia</option>
                      <option value="IR">Iran, Islamic Republic of</option>
                      <option value="IQ">Iraq</option>
                      <option value="IE">Ireland</option>
                      <option value="IM">Isle of Man</option>
                      <option value="IL">Israel</option>
                      <option value="IT">Italy</option>
                      <option value="JM">Jamaica</option>
                      <option value="JP">Japan</option>
                      <option value="JE">Jersey</option>
                      <option value="JO">Jordan</option>
                      <option value="KZ">Kazakhstan</option>
                      <option value="KE">Kenya</option>
                      <option value="KI">Kiribati</option>
                      <option value="KP">
                        Korea, Democratic People's Republic of
                      </option>
                      <option value="KR">Korea, Republic of</option>
                      <option value="KW">Kuwait</option>
                      <option value="KG">Kyrgyzstan</option>
                      <option value="LA">
                        Lao People's Democratic Republic
                      </option>
                      <option value="LV">Latvia</option>
                      <option value="LB">Lebanon</option>
                      <option value="LS">Lesotho</option>
                      <option value="LR">Liberia</option>
                      <option value="LY">Libyan Arab Jamahiriya</option>
                      <option value="LI">Liechtenstein</option>
                      <option value="LT">Lithuania</option>
                      <option value="LU">Luxembourg</option>
                      <option value="MO">Macao</option>
                      <option value="MK">
                        Macedonia, the former Yugoslav Republic of
                      </option>
                      <option value="MG">Madagascar</option>
                      <option value="MW">Malawi</option>
                      <option value="MY">Malaysia</option>
                      <option value="MV">Maldives</option>
                      <option value="ML">Mali</option>
                      <option value="MT">Malta</option>
                      <option value="MH">Marshall Islands</option>
                      <option value="MQ">Martinique</option>
                      <option value="MR">Mauritania</option>
                      <option value="MU">Mauritius</option>
                      <option value="YT">Mayotte</option>
                      <option value="MX">Mexico</option>
                      <option value="FM">
                        Micronesia, Federated States of
                      </option>
                      <option value="MD">Moldova, Republic of</option>
                      <option value="MC">Monaco</option>
                      <option value="MN">Mongolia</option>
                      <option value="ME">Montenegro</option>
                      <option value="MS">Montserrat</option>
                      <option value="MA">Morocco</option>
                      <option value="MZ">Mozambique</option>
                      <option value="MM">Myanmar</option>
                      <option value="NA">Namibia</option>
                      <option value="NR">Nauru</option>
                      <option value="NP">Nepal</option>
                      <option value="NL">Netherlands</option>
                      <option value="AN">Netherlands Antilles</option>
                      <option value="NC">New Caledonia</option>
                      <option value="NZ">New Zealand</option>
                      <option value="NI">Nicaragua</option>
                      <option value="NE">Niger</option>
                      <option value="NG">Nigeria</option>
                      <option value="NU">Niue</option>
                      <option value="NF">Norfolk Island</option>
                      <option value="MP">Northern Mariana Islands</option>
                      <option value="NO">Norway</option>
                      <option value="OM">Oman</option>
                      <option value="PK">Pakistan</option>
                      <option value="PW">Palau</option>
                      <option value="PS">
                        Palestinian Territory, Occupied
                      </option>
                      <option value="PA">Panama</option>
                      <option value="PG">Papua New Guinea</option>
                      <option value="PY">Paraguay</option>
                      <option value="PE">Peru</option>
                      <option value="PH">Philippines</option>
                      <option value="PN">Pitcairn</option>
                      <option value="PL">Poland</option>
                      <option value="PT">Portugal</option>
                      <option value="PR">Puerto Rico</option>
                      <option value="QA">Qatar</option>
                      <option value="RE">Réunion</option>
                      <option value="RO">Romania</option>
                      <option value="RU">Russian Federation</option>
                      <option value="RW">Rwanda</option>
                      <option value="BL">Saint Barthélemy</option>
                      <option value="SH">
                        Saint Helena, Ascension and Tristan da Cunha
                      </option>
                      <option value="KN">Saint Kitts and Nevis</option>
                      <option value="LC">Saint Lucia</option>
                      <option value="MF">Saint Martin (French part)</option>
                      <option value="PM">Saint Pierre and Miquelon</option>
                      <option value="VC">
                        Saint Vincent and the Grenadines
                      </option>
                      <option value="WS">Samoa</option>
                      <option value="SM">San Marino</option>
                      <option value="ST">Sao Tome and Principe</option>
                      <option value="SA">Saudi Arabia</option>
                      <option value="SN">Senegal</option>
                      <option value="RS">Serbia</option>
                      <option value="SC">Seychelles</option>
                      <option value="SL">Sierra Leone</option>
                      <option value="SG">Singapore</option>
                      <option value="SK">Slovakia</option>
                      <option value="SI">Slovenia</option>
                      <option value="SB">Solomon Islands</option>
                      <option value="SO">Somalia</option>
                      <option value="ZA">South Africa</option>
                      <option value="GS">
                        South Georgia and the South Sandwich Islands
                      </option>
                      <option value="ES">Spain</option>
                      <option value="LK">Sri Lanka</option>
                      <option value="SD">Sudan</option>
                      <option value="SR">Suriname</option>
                      <option value="SJ">Svalbard and Jan Mayen</option>
                      <option value="SZ">Swaziland</option>
                      <option value="SE">Sweden</option>
                      <option value="CH">Switzerland</option>
                      <option value="SY">Syrian Arab Republic</option>
                      <option value="TW">Taiwan, Province of China</option>
                      <option value="TJ">Tajikistan</option>
                      <option value="TZ">Tanzania, United Republic of</option>
                      <option value="TH">Thailand</option>
                      <option value="TL">Timor-Leste</option>
                      <option value="TG">Togo</option>
                      <option value="TK">Tokelau</option>
                      <option value="TO">Tonga</option>
                      <option value="TT">Trinidad and Tobago</option>
                      <option value="TN">Tunisia</option>
                      <option value="TR">Turkey</option>
                      <option value="TM">Turkmenistan</option>
                      <option value="TC">Turks and Caicos Islands</option>
                      <option value="TV">Tuvalu</option>
                      <option value="UG">Uganda</option>
                      <option value="UA">Ukraine</option>
                      <option value="AE">United Arab Emirates</option>
                      <option value="GB">United Kingdom</option>
                      <option value="US">United States</option>
                      <option value="UM">
                        United States Minor Outlying Islands
                      </option>
                      <option value="UY">Uruguay</option>
                      <option value="UZ">Uzbekistan</option>
                      <option value="VU">Vanuatu</option>
                      <option value="VE">
                        Venezuela, Bolivarian Republic of
                      </option>
                      <option value="VN">Viet Nam</option>
                      <option value="VG">Virgin Islands, British</option>
                      <option value="VI">Virgin Islands, U.S.</option>
                      <option value="WF">Wallis and Futuna</option>
                      <option value="EH">Western Sahara</option>
                      <option value="YE">Yemen</option>
                      <option value="ZM">Zambia</option>
                      <option value="ZW">Zimbabwe</option>
                    </select>
                  </div>
                  <span id="sco" className="invisible">
                    First name field is required
                  </span>
                </div>
                <div className="col-md-12 col-ms-12 col-xs-12">
                  <div>
                    <select
                      disabled
                      id="ho"
                      className="form-select"
                      placeholder="How did you find us?*"
                      name="googlemap"
                      onChange={(e) =>
                        props.setCustomerWhereToFind(e.target.value)
                      }
                      value={
                        "YToxOntpOjM7czoyODoiSGVhcmQgZnJvbSBFeGlzdGluZyBDdXN0b21lciI7fQ=="
                      }
                    >
                      <option value="YToxOntpOjM7czo2OiJHb29nbGUiO30=">
                        Google
                      </option>
                      <option value="YToxOntpOjM7czoyODoiSGVhcmQgZnJvbSBFeGlzdGluZyBDdXN0b21lciI7fQ==">
                        Heard from Existing Customer
                      </option>
                      <option value="YToxOntpOjM7czo4OiJGYWNlYm9vayI7fQ==">
                        Facebook
                      </option>
                      <option value="YToxOntpOjM7czoxMzoiU01TIE1hcmtldGluZyI7fQ==">
                        SMS Marketing
                      </option>
                      <option value="YToxOntpOjM7czoxNjoiRW1haWwgTmV3c2xldHRlciI7fQ==">
                        Email Newsletter
                      </option>
                    </select>
                  </div>
                  <span id="sho" className="invisible">
                    First name field is required
                  </span>
                </div>
              </>

              {/* {props.hideContinueButton ? (
              <div className="col-md-12 col-sm-12 col-xs-12">
                <button
                  onClick={(e) => props.handelRemaningFormFeild(e)}
                  className="button-sigup"
                >
                  Continue
                </button>
              </div>
            ) : null} */}
            </div>
          ) : (
            <div className="row pt-3">
              <span
                id="salready"
                style={{ color: "red" }}
                className="d-none pb-2"
              >
                Sorry the provided email address is already exist in our
                database please try with different one.
              </span>

              <div className="col-md-6 col-ms-12 col-xs-12">
                <div>
                  <span id="sfn" className="invisible">
                    First name field is required
                  </span>
                  <input
                    id="fn"
                    className="form-control"
                    name="first_name"
                    placeholder="Enter your first name"
                    type="text"
                    value={props.customerFirstName}
                    onChange={(e) => props.setCustomerFirstName(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-md-6 col-ms-12 col-xs-12">
                <div>
                  <span id="sln" className="invisible">
                    Last name field is required
                  </span>
                  <input
                    id="ln"
                    className="form-control"
                    name="last_name"
                    placeholder="Enter your last name"
                    type="text"
                    value={props.customerLastName}
                    onChange={(e) => props.setCustomerLastName(e.target.value)}
                  />
                </div>
              </div>
              <div className="col-md-6 col-ms-12 col-xs-12">
                <div>
                  <span id="sem" className="invisible">
                    Email field is required
                  </span>
                  <span id="seme" className="invisible">
                    (Invalid email format)
                  </span>
                  <input
                    id="em"
                    className="form-control"
                    name="email"
                    placeholder="Enter your email"
                    readOnly={props.remaningFormToggle}
                    type="email"
                    value={props.customerEmailAddress}
                    onChange={(e) =>
                      props.setCustomerEmailAddress(e.target.value)
                    }
                  />
                </div>
              </div>
              <div className="col-md-6 col-ms-12 col-xs-12">
                <div>
                  <span id="spw" className="invisible">
                    Password field is required
                  </span>
                  <input
                    className="form-control"
                    required=""
                    id="pw"
                    placeholder="Enter your password"
                    name="password2"
                    type="password"
                    value={props.customerPassword}
                    onChange={(e) => props.setCustomerPassword(e.target.value)}
                  />
                </div>
              </div>
              {props.remaningFormToggle ? (
                <>
                  <div className="col-md-6 col-ms-12 col-xs-12">
                    <div>
                      <span className="invisible">a</span>
                      <input
                        className="form-control"
                        name="company_name"
                        placeholder="Enter your organization name"
                        type="text"
                        value={props.customerCompanyName}
                        onChange={(e) =>
                          props.setCustomerCompanyName(e.target.value)
                        }
                      />
                      <span id="scddddt" className="invisible">
                        City field is required
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6 col-ms-12 col-xs-12">
                    <div>
                      <span className="invisible">
                        Phone number field is required
                      </span>
                      <input
                        className="form-control"
                        name="phonenumber"
                        id="ph"
                        placeholder="Enter your phone number"
                        type="number"
                        value={props.customerPhoneNumber}
                        onChange={(e) =>
                          props.setCustomerPhoneNumber(e.target.value)
                        }
                      />
                      <span
                        style={{ fontSize: "12px" }}
                        id="sph"
                        className="invisible"
                      >
                        This field is required
                      </span>
                    </div>
                  </div>
                  <div className="col-md-12 col-ms-12 col-xs-12">
                    <div>
                      <input
                        className="form-control"
                        id="add"
                        type="text"
                        placeholder="Enter your address"
                        value={props.customerAddress}
                        onChange={(e) =>
                          props.setCustomerAddress(e.target.value)
                        }
                      />
                      <span
                        style={{ fontSize: "12px" }}
                        id="sadd"
                        className="invisible"
                      >
                        This field is required
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6 col-ms-12 col-xs-12">
                    <div>
                      <input
                        required
                        className="form-control"
                        id="ct"
                        name="city"
                        placeholder="Enter your city"
                        type="text"
                        value={props.customerCity}
                        onChange={(e) => props.setCustomerCity(e.target.value)}
                      />
                      <span
                        style={{ fontSize: "12px" }}
                        id="sct"
                        className="invisible"
                      >
                        This field is required
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6 col-ms-12 col-xs-12">
                    <div>
                      <input
                        className="form-control"
                        name="state"
                        id="st"
                        placeholder="Enter your state"
                        type="text"
                        value={props.customerState}
                        onChange={(e) => props.setCustomerState(e.target.value)}
                      />
                      <span
                        style={{ fontSize: "12px" }}
                        id="sst"
                        className="invisible"
                      >
                        This field is required
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6 col-ms-12 col-xs-12">
                    <div>
                      <input
                        className="form-control"
                        name="postal_code"
                        type="number"
                        id="po"
                        placeholder="Enter the Postal code"
                        value={props.customerPostalCode}
                        onChange={(e) =>
                          props.setCustomerPostalCode(e.target.value)
                        }
                      />
                      <span
                        style={{ fontSize: "12px" }}
                        id="spo"
                        className="invisible"
                      >
                        This field is required
                      </span>
                    </div>
                  </div>
                  <div className="col-md-6 col-ms-12 col-xs-12">
                    <div>
                      <select
                        id="co"
                        className="form-select"
                        placeholder="Enter your country"
                        value={props.customerCountry}
                        onChange={(e) =>
                          props.setCustomerCountry(e.target.value)
                        }
                      >
                        <option value="">Enter your country</option>
                        <option value="AF">Afghanistan</option>
                        <option value="AX">Åland Islands</option>
                        <option value="AL">Albania</option>
                        <option value="DZ">Algeria</option>
                        <option value="AS">American Samoa</option>
                        <option value="AD">Andorra</option>
                        <option value="AO">Angola</option>
                        <option value="AI">Anguilla</option>
                        <option value="AQ">Antarctica</option>
                        <option value="AG">Antigua and Barbuda</option>
                        <option value="AR">Argentina</option>
                        <option value="AM">Armenia</option>
                        <option value="AW">Aruba</option>
                        <option value="AU">Australia</option>
                        <option value="AT">Austria</option>
                        <option value="AZ">Azerbaijan</option>
                        <option value="BS">Bahamas</option>
                        <option value="BH">Bahrain</option>
                        <option value="BD">Bangladesh</option>
                        <option value="BB">Barbados</option>
                        <option value="BY">Belarus</option>
                        <option value="BE">Belgium</option>
                        <option value="BZ">Belize</option>
                        <option value="BJ">Benin</option>
                        <option value="BM">Bermuda</option>
                        <option value="BT">Bhutan</option>
                        <option value="BO">
                          Bolivia, Plurinational State of
                        </option>
                        <option value="BA">Bosnia and Herzegovina</option>
                        <option value="BW">Botswana</option>
                        <option value="BV">Bouvet Island</option>
                        <option value="BR">Brazil</option>
                        <option value="IO">
                          British Indian Ocean Territory
                        </option>
                        <option value="BN">Brunei Darussalam</option>
                        <option value="BG">Bulgaria</option>
                        <option value="BF">Burkina Faso</option>
                        <option value="BI">Burundi</option>
                        <option value="KH">Cambodia</option>
                        <option value="CM">Cameroon</option>
                        <option value="CA">Canada</option>
                        <option value="CV">Cape Verde</option>
                        <option value="KY">Cayman Islands</option>
                        <option value="CF">Central African Republic</option>
                        <option value="TD">Chad</option>
                        <option value="CL">Chile</option>
                        <option value="CN">China</option>
                        <option value="CX">Christmas Island</option>
                        <option value="CC">Cocos (Keeling) Islands</option>
                        <option value="CO">Colombia</option>
                        <option value="KM">Comoros</option>
                        <option value="CG">Congo</option>
                        <option value="CD">
                          Congo, the Democratic Republic of the
                        </option>
                        <option value="CK">Cook Islands</option>
                        <option value="CR">Costa Rica</option>
                        <option value="CI">Côte d'Ivoire</option>
                        <option value="HR">Croatia</option>
                        <option value="CU">Cuba</option>
                        <option value="CY">Cyprus</option>
                        <option value="CZ">Czech Republic</option>
                        <option value="DK">Denmark</option>
                        <option value="DJ">Djibouti</option>
                        <option value="DM">Dominica</option>
                        <option value="DO">Dominican Republic</option>
                        <option value="EC">Ecuador</option>
                        <option value="EG">Egypt</option>
                        <option value="SV">El Salvador</option>
                        <option value="GQ">Equatorial Guinea</option>
                        <option value="ER">Eritrea</option>
                        <option value="EE">Estonia</option>
                        <option value="ET">Ethiopia</option>
                        <option value="FK">Falkland Islands (Malvinas)</option>
                        <option value="FO">Faroe Islands</option>
                        <option value="FJ">Fiji</option>
                        <option value="FI">Finland</option>
                        <option value="FR">France</option>
                        <option value="GF">French Guiana</option>
                        <option value="PF">French Polynesia</option>
                        <option value="TF">French Southern Territories</option>
                        <option value="GA">Gabon</option>
                        <option value="GM">Gambia</option>
                        <option value="GE">Georgia</option>
                        <option value="DE">Germany</option>
                        <option value="GH">Ghana</option>
                        <option value="GI">Gibraltar</option>
                        <option value="GR">Greece</option>
                        <option value="GL">Greenland</option>
                        <option value="GD">Grenada</option>
                        <option value="GP">Guadeloupe</option>
                        <option value="GU">Guam</option>
                        <option value="GT">Guatemala</option>
                        <option value="GG">Guernsey</option>
                        <option value="GN">Guinea</option>
                        <option value="GW">Guinea-Bissau</option>
                        <option value="GY">Guyana</option>
                        <option value="HT">Haiti</option>
                        <option value="HM">
                          Heard Island and McDonald Islands
                        </option>
                        <option value="VA">
                          Holy See (Vatican City State)
                        </option>
                        <option value="HN">Honduras</option>
                        <option value="HK">Hong Kong</option>
                        <option value="HU">Hungary</option>
                        <option value="IS">Iceland</option>
                        <option value="IN">India</option>
                        <option value="ID">Indonesia</option>
                        <option value="IR">Iran, Islamic Republic of</option>
                        <option value="IQ">Iraq</option>
                        <option value="IE">Ireland</option>
                        <option value="IM">Isle of Man</option>
                        <option value="IL">Israel</option>
                        <option value="IT">Italy</option>
                        <option value="JM">Jamaica</option>
                        <option value="JP">Japan</option>
                        <option value="JE">Jersey</option>
                        <option value="JO">Jordan</option>
                        <option value="KZ">Kazakhstan</option>
                        <option value="KE">Kenya</option>
                        <option value="KI">Kiribati</option>
                        <option value="KP">
                          Korea, Democratic People's Republic of
                        </option>
                        <option value="KR">Korea, Republic of</option>
                        <option value="KW">Kuwait</option>
                        <option value="KG">Kyrgyzstan</option>
                        <option value="LA">
                          Lao People's Democratic Republic
                        </option>
                        <option value="LV">Latvia</option>
                        <option value="LB">Lebanon</option>
                        <option value="LS">Lesotho</option>
                        <option value="LR">Liberia</option>
                        <option value="LY">Libyan Arab Jamahiriya</option>
                        <option value="LI">Liechtenstein</option>
                        <option value="LT">Lithuania</option>
                        <option value="LU">Luxembourg</option>
                        <option value="MO">Macao</option>
                        <option value="MK">
                          Macedonia, the former Yugoslav Republic of
                        </option>
                        <option value="MG">Madagascar</option>
                        <option value="MW">Malawi</option>
                        <option value="MY">Malaysia</option>
                        <option value="MV">Maldives</option>
                        <option value="ML">Mali</option>
                        <option value="MT">Malta</option>
                        <option value="MH">Marshall Islands</option>
                        <option value="MQ">Martinique</option>
                        <option value="MR">Mauritania</option>
                        <option value="MU">Mauritius</option>
                        <option value="YT">Mayotte</option>
                        <option value="MX">Mexico</option>
                        <option value="FM">
                          Micronesia, Federated States of
                        </option>
                        <option value="MD">Moldova, Republic of</option>
                        <option value="MC">Monaco</option>
                        <option value="MN">Mongolia</option>
                        <option value="ME">Montenegro</option>
                        <option value="MS">Montserrat</option>
                        <option value="MA">Morocco</option>
                        <option value="MZ">Mozambique</option>
                        <option value="MM">Myanmar</option>
                        <option value="NA">Namibia</option>
                        <option value="NR">Nauru</option>
                        <option value="NP">Nepal</option>
                        <option value="NL">Netherlands</option>
                        <option value="AN">Netherlands Antilles</option>
                        <option value="NC">New Caledonia</option>
                        <option value="NZ">New Zealand</option>
                        <option value="NI">Nicaragua</option>
                        <option value="NE">Niger</option>
                        <option value="NG">Nigeria</option>
                        <option value="NU">Niue</option>
                        <option value="NF">Norfolk Island</option>
                        <option value="MP">Northern Mariana Islands</option>
                        <option value="NO">Norway</option>
                        <option value="OM">Oman</option>
                        <option value="PK">Pakistan</option>
                        <option value="PW">Palau</option>
                        <option value="PS">
                          Palestinian Territory, Occupied
                        </option>
                        <option value="PA">Panama</option>
                        <option value="PG">Papua New Guinea</option>
                        <option value="PY">Paraguay</option>
                        <option value="PE">Peru</option>
                        <option value="PH">Philippines</option>
                        <option value="PN">Pitcairn</option>
                        <option value="PL">Poland</option>
                        <option value="PT">Portugal</option>
                        <option value="PR">Puerto Rico</option>
                        <option value="QA">Qatar</option>
                        <option value="RE">Réunion</option>
                        <option value="RO">Romania</option>
                        <option value="RU">Russian Federation</option>
                        <option value="RW">Rwanda</option>
                        <option value="BL">Saint Barthélemy</option>
                        <option value="SH">
                          Saint Helena, Ascension and Tristan da Cunha
                        </option>
                        <option value="KN">Saint Kitts and Nevis</option>
                        <option value="LC">Saint Lucia</option>
                        <option value="MF">Saint Martin (French part)</option>
                        <option value="PM">Saint Pierre and Miquelon</option>
                        <option value="VC">
                          Saint Vincent and the Grenadines
                        </option>
                        <option value="WS">Samoa</option>
                        <option value="SM">San Marino</option>
                        <option value="ST">Sao Tome and Principe</option>
                        <option value="SA">Saudi Arabia</option>
                        <option value="SN">Senegal</option>
                        <option value="RS">Serbia</option>
                        <option value="SC">Seychelles</option>
                        <option value="SL">Sierra Leone</option>
                        <option value="SG">Singapore</option>
                        <option value="SK">Slovakia</option>
                        <option value="SI">Slovenia</option>
                        <option value="SB">Solomon Islands</option>
                        <option value="SO">Somalia</option>
                        <option value="ZA">South Africa</option>
                        <option value="GS">
                          South Georgia and the South Sandwich Islands
                        </option>
                        <option value="ES">Spain</option>
                        <option value="LK">Sri Lanka</option>
                        <option value="SD">Sudan</option>
                        <option value="SR">Suriname</option>
                        <option value="SJ">Svalbard and Jan Mayen</option>
                        <option value="SZ">Swaziland</option>
                        <option value="SE">Sweden</option>
                        <option value="CH">Switzerland</option>
                        <option value="SY">Syrian Arab Republic</option>
                        <option value="TW">Taiwan, Province of China</option>
                        <option value="TJ">Tajikistan</option>
                        <option value="TZ">Tanzania, United Republic of</option>
                        <option value="TH">Thailand</option>
                        <option value="TL">Timor-Leste</option>
                        <option value="TG">Togo</option>
                        <option value="TK">Tokelau</option>
                        <option value="TO">Tonga</option>
                        <option value="TT">Trinidad and Tobago</option>
                        <option value="TN">Tunisia</option>
                        <option value="TR">Turkey</option>
                        <option value="TM">Turkmenistan</option>
                        <option value="TC">Turks and Caicos Islands</option>
                        <option value="TV">Tuvalu</option>
                        <option value="UG">Uganda</option>
                        <option value="UA">Ukraine</option>
                        <option value="AE">United Arab Emirates</option>
                        <option value="GB">United Kingdom</option>
                        <option value="US">United States</option>
                        <option value="UM">
                          United States Minor Outlying Islands
                        </option>
                        <option value="UY">Uruguay</option>
                        <option value="UZ">Uzbekistan</option>
                        <option value="VU">Vanuatu</option>
                        <option value="VE">
                          Venezuela, Bolivarian Republic of
                        </option>
                        <option value="VN">Viet Nam</option>
                        <option value="VG">Virgin Islands, British</option>
                        <option value="VI">Virgin Islands, U.S.</option>
                        <option value="WF">Wallis and Futuna</option>
                        <option value="EH">Western Sahara</option>
                        <option value="YE">Yemen</option>
                        <option value="ZM">Zambia</option>
                        <option value="ZW">Zimbabwe</option>
                      </select>
                      <span
                        style={{ fontSize: "12px" }}
                        id="sco"
                        className="invisible"
                      >
                        This field is required
                      </span>
                    </div>
                  </div>
                  <div className="col-md-12 col-ms-12 col-xs-12">
                    <div>
                      <select
                        id="ho"
                        className="form-select"
                        placeholder="How did you find us?*"
                        name="googlemap"
                        onChange={(e) =>
                          props.setCustomerWhereToFind(e.target.value)
                        }
                        value={props.customerWhereToFind}
                      >
                        <option value="">How did you find us?*</option>
                        <option value="YToxOntpOjM7czo2OiJHb29nbGUiO30=">
                        Google
                      </option>
                      <option value="YToxOntpOjM7czoyODoiSGVhcmQgZnJvbSBFeGlzdGluZyBDdXN0b21lciI7fQ==">
                        Heard from Existing Customer
                      </option>
                      <option value="YToxOntpOjM7czo4OiJGYWNlYm9vayI7fQ==">
                        Facebook
                      </option>
                      <option value="YToxOntpOjM7czoxMzoiU01TIE1hcmtldGluZyI7fQ==">
                        SMS Marketing
                      </option>
                      <option value="YToxOntpOjM7czoxNjoiRW1haWwgTmV3c2xldHRlciI7fQ==">
                        Email Newsletter
                      </option>
                      </select>
                      <span
                        style={{ fontSize: "12px" }}
                        id="sho"
                        className="invisible"
                      >
                        This field is required
                      </span>
                    </div>
                  </div>
                </>
              ) : null}

              {props.hideContinueButton ? (
                <div className="col-md-12 col-sm-12 col-xs-12">
                  {/* <button
                    onClick={(e) => props.handelRemaningFormFeild(e)}
                    className="button-sigup"
                  >
                    Continue
                  </button> */}
                  <button
                    onClick={(e) => handelRemaningFormFeildon(e, props)}
                    className="button-sigup mt-3"
                  >
                    Continue
                  </button>
                </div>
              ) : null}
            </div>
          )}
        </>
      </form>
    </>
  );
};

const handelRemaningFormFeildon = (e, props) => {
  e.preventDefault();
  var bl = "t";
  var fn = document.getElementById("fn").value;
  var ln = document.getElementById("ln").value;
  var em = document.getElementById("em").value;
  var pw = document.getElementById("pw").value;

  const regex = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

  if (fn == "") {
    document.getElementById("sfn").classList.remove("invisible");
  } else {
    document.getElementById("sfn").classList.add("invisible");
  }
  if (ln == "") {
    document.getElementById("sln").classList.remove("invisible");
  } else {
    document.getElementById("sln").classList.add("invisible");
  }
  if (em == "") {
    document.getElementById("sem").classList.remove("invisible");
  } else {
    document.getElementById("sem").classList.add("invisible");
  }
  if (!regex.test(em)) {
    document.getElementById("seme").classList.remove("invisible");
    bl = "";
  } else {
    document.getElementById("seme").classList.add("invisible");
  }

  if (pw == "") {
    document.getElementById("spw").classList.remove("invisible");
  } else {
    document.getElementById("spw").classList.add("invisible");
  }
  if (fn != "" && ln != "" && em != "" && pw != "" && bl != "") {
    props.handelRemaningFormFeild(e);
  }
};

export default Signup;
