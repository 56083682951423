import React from "react";

const WillingForHelp = ({ helpData }) => {
  return (
    <section className="sec-need_help pt-5 mb-50" style={{ margin: "0px" }}>
      <div className="container">
        <div className="row">
          <div className="col-md-4 help-oper-img d-none d-md-block">
            <img
              className="mt-neg-78 img-fluid"
              src={helpData && helpData.image}
              alt=""
              srcSet=""
            />
          </div>
          <div className="col-md-8 col-lg-6 text-oper m-auto">
            <h4>{helpData && helpData.heading}</h4>
            <p>{helpData && helpData.subHeading}</p>
            <div className="row">
              <div className="col-sm-12 col-md-7 col-lg-8 col-xl-7 col-xxl-5 text-sm-center pb-sm-3">
                <button className="button-first">
                  {helpData && helpData.buttonOne}
                </button>
              </div>
              <div className="col-sm-12 col-md-5 col-lg-4 col-xl-4 col-xxl-5 text-sm-center">
                <a href='mailto:sales@websouls.com"' className="button-sec">
                  {helpData && helpData.buttonTwo}
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default React.memo(WillingForHelp);
