import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const DomainSearchHome = () => {
  //navigation hook
  const navigate = useNavigate();

  //states

  const [HomeDomainSearchName, setHomeDomainSearch] = useState("");

  //handel the search

  const searchDomain = (e) => {
    e.preventDefault();
    navigate("/domain", { state: { name: HomeDomainSearchName } });
  };



  return (
    <section className="front_search">
      <div className="w-100">
        <div className="container">
          <div className="row d-flex align-items-center mt-3 mt-xs-3 mt-sm-3 md-md-4 mt-lg-5 justify-content-center">
            <div className="col-sm-12 col-lg-3">
              <h3 className="get-started text-center mb-0">
                Let's get started!
              </h3>
            </div>

            <div className="col-sm-12 col-lg-7">
              <form onSubmit={(e) => searchDomain(e)}>
                <div className="input-group">
                  <input
                    type="search"
                    name="search"
                    id="check_search"
                    className="form-control homesearchbar"
                    placeholder="Find the perfect domain name ..."
                    aria-labelledby="search-domain"
                    aria-label="Find the perfect domain name ..."
                    value={HomeDomainSearchName}
                    onChange={(e) => setHomeDomainSearch(e.target.value)}
                    style={{ height: 65 }}
                  />
                  <div className="input-group-append">
                    <button
                      className="checkdomian input-group-text"
                      type="submit"
                      disabled={HomeDomainSearchName === ""}
                      onClick={(e) => searchDomain(e)}
                      style={{ height: "100%" }}
                    >
                      <i className="fa fa-search" />
                    </button>
                  </div>
                </div>
              </form>
            </div>
          </div>
          <br />
          <br />
        </div>
      </div>
    </section>
  );
};

export default DomainSearchHome;
