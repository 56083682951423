import React from 'react'

const WhyChooseUsSecond = () => {
  return (
    <section className="why_choose_us " >
    <div className="container">
      <h2 className='first-heading'>Why Choose Us?</h2>
      <h5 className="second-heading">Hosting Plans Fueled With the Latest Technology</h5>
      <hr className="divider scnd_divider mx-auto" />
      <ul className="row row-centered">
        <li className="col-md-4 col-centered">
          <div className="serving_services">
            <div className="serving_center">
              <div className="icon ws-serve-b" />
              <p>Serving Since 2002</p>
            </div>
          </div>
        </li>
        <li className="col-md-4 col-centered">
          <div className="serving_services">
            <div className="serving_center">
              <div className="icon ws-cpanel" />
              <p>cPanel Official Partner</p>
            </div>
          </div>
        </li>
        <li className="col-md-4 col-centered">
          <div className="serving_services">
            <div className="serving_center">
              <div className="icon ws-clients" />
              <p>1000+ Corporate Clients</p>
            </div>
          </div>
        </li>
        <li className="col-md-4 col-centered">
          <div className="serving_services">
            <div className="serving_center">
              <div className="icon ws-scripts" />
              <p>Bash Scripts</p>
            </div>
          </div>
        </li>
        <li className="col-md-4 col-centered">
          <div className="serving_services">
            <div className="serving_center">
              <div className="icon ws-email-c" />
              <p>Smooth Email Service</p>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </section>
  )
}

export default React.memo(WhyChooseUsSecond)