import React from "react";

const InPakistan = (props) => {
  const { Data } = props;
  return (
    <section className="pakistan_hosting pt-100" >
      <div className="container">
        <h1 className="first-heading">{Data.Heading}</h1>
        <h5 className="second-heading">{Data.subHeading}</h5>
        <hr className="divider scnd_divider mx-auto" />
        <div className="websouls_experience" >
          {Data.peragraph} 
        </div>
      </div>
    </section>
  );
};

export default React.memo(InPakistan);
