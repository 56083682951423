import React from "react";
import Carousel from "react-elastic-carousel";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import {
  getPrice,
  packagesFeatures,
  packagesFirstFeature,
  getSSlPrice,
} from "../../services/Helper";

const Crousels = ({ packages, getActivePromotions, loading }) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const buttonAddToCart = (e, item) => {
    e.preventDefault();
    dispatch({
      type: "addingCycle",
      payload: {
        packageId: item.pid,
        packageCycle: "annually",
      },
    });
    dispatch({
      type: "packageCart",
      payload: item,
    });

    //redirecting to the new page
    navigate("/dedicated-ssl-hostname");
  };

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2 },
    { width: 768, itemsToShow: 3 },
    { width: 1200, itemsToShow: 3 },
  ];

  const { currencyName } = useSelector((state) => state.currencyName);
  const { billingCycleHosting } = useSelector(
    (state) => state.billingCycleHosting
  );

  return (
    <>
      {loading ? (
        <>
          <Carousel
            itemPadding={[8, 10, 8, 10]}
            breakPoints={breakPoints}
            pagination={true}
            showArrows={false}
            className=""
          >
            <div
              className="col-lg-4 pacakge-one text-start "
              style={{
                backgroundColor: "transparent",
                border: "1px solid rgb(210, 210, 210)",
                borderRadius: 5,
              }}
            >
              <div className="package-name ">
                <h3>
                  <Skeleton width={200} />
                </h3>
                <p>
                  <Skeleton width={100} />
                </p>
              </div>
              <div
                className="package-one-price checkss"
                style={{ marginBottom: 0 }}
              >
                <div className="discount">
                  <h4 className="">
                    <b>
                      <Skeleton width={300} />
                    </b>
                  </h4>
                </div>
                <div className="package-one-price">
                  <span className="amount">
                    {" "}
                    <Skeleton width={190} />
                  </span>
                </div>
              </div>
              <ul>
                <li>
                  <Skeleton style={{ width: "100%" }} />
                </li>
                <li>
                  <Skeleton style={{ width: "100%" }} />
                </li>
                <li>
                  <Skeleton style={{ width: "100%" }} />
                </li>
                <li>
                  <Skeleton style={{ width: "100%" }} />
                </li>
                <li>
                  <Skeleton style={{ width: "100%" }} />
                </li>
                <li>
                  <Skeleton style={{ width: "100%" }} />
                </li>
                <li>
                  <Skeleton style={{ width: "100%" }} />
                </li>
                <li>
                  <Skeleton style={{ width: "100%" }} />
                </li>
              </ul>
              <Skeleton width={200} style={{ paddingTop: 20, marginTop: 10 }} />
            </div>
            <div
              className="col-lg-4 pacakge-one text-start "
              style={{
                backgroundColor: "transparent",
                border: "1px solid rgb(210, 210, 210)",
                borderRadius: 5,
              }}
            >
              <div className="package-name ">
                <h3>
                  <Skeleton width={200} />
                </h3>
                <p>
                  <Skeleton width={100} />
                </p>
              </div>
              <div
                className="package-one-price checkss"
                style={{ marginBottom: 0 }}
              >
                <div className="discount">
                  <h4 className="">
                    <b>
                      <Skeleton width={300} />
                    </b>
                  </h4>
                </div>
                <div className="package-one-price">
                  <span className="amount">
                    {" "}
                    <Skeleton width={190} />
                  </span>
                </div>
              </div>
              <ul>
                <li>
                  <Skeleton style={{ width: "100%" }} />
                </li>
                <li>
                  <Skeleton style={{ width: "100%" }} />
                </li>
                <li>
                  <Skeleton style={{ width: "100%" }} />
                </li>
                <li>
                  <Skeleton style={{ width: "100%" }} />
                </li>
                <li>
                  <Skeleton style={{ width: "100%" }} />
                </li>
                <li>
                  <Skeleton style={{ width: "100%" }} />
                </li>
                <li>
                  <Skeleton style={{ width: "100%" }} />
                </li>
                <li>
                  <Skeleton style={{ width: "100%" }} />
                </li>
              </ul>
              <Skeleton width={200} style={{ paddingTop: 20, marginTop: 10 }} />
            </div>
            <div
              className="col-lg-4 pacakge-one text-start "
              style={{
                backgroundColor: "transparent",
                border: "1px solid rgb(210, 210, 210)",
                borderRadius: 5,
              }}
            >
              <div className="package-name ">
                <h3>
                  <Skeleton width={200} />
                </h3>
                <p>
                  <Skeleton width={100} />
                </p>
              </div>
              <div
                className="package-one-price checkss"
                style={{ marginBottom: 0 }}
              >
                <div className="discount">
                  <h4 className="">
                    <b>
                      <Skeleton width={300} />
                    </b>
                  </h4>
                </div>
                <div className="package-one-price">
                  <span className="amount">
                    {" "}
                    <Skeleton width={190} />
                  </span>
                </div>
              </div>
              <ul>
                <li>
                  <Skeleton style={{ width: "100%" }} />
                </li>
                <li>
                  <Skeleton style={{ width: "100%" }} />
                </li>
                <li>
                  <Skeleton style={{ width: "100%" }} />
                </li>
                <li>
                  <Skeleton style={{ width: "100%" }} />
                </li>
                <li>
                  <Skeleton style={{ width: "100%" }} />
                </li>
                <li>
                  <Skeleton style={{ width: "100%" }} />
                </li>
                <li>
                  <Skeleton style={{ width: "100%" }} />
                </li>
                <li>
                  <Skeleton style={{ width: "100%" }} />
                </li>
              </ul>
              <Skeleton width={200} style={{ paddingTop: 20, marginTop: 10 }} />
            </div>
          </Carousel>
        </>
      ) : (
        <>
          {" "}
          <Carousel
            className="animate__animated animate__fadeIn"
            itemPadding={[8, 10, 8, 10]}
            breakPoints={breakPoints}
            pagination={true}
          >
            {packages &&
              packages.map((item) => (
                <React.Fragment key={item.pid}>
                  <div
                    style={{
                      backgroundColor: "transparent",
                      border: "1px solid #D2D2D2",
                      borderRadius: 5,
                    }}
                    className="col-lg-4 pacakge-one text-start"
                  >
                    <div className="package-name ">
                      <h3>{item.title}</h3>
                      <p className="package-namepaaaa"> {item.fname}</p>
                    </div>
                    <div
                      style={{ marginBottom: 0 }}
                      className="package-one-price checkss"
                    >
                      {getSSlPrice(
                        item?.currency
                          .filter(
                            (fil) => fil?.currency === currencyName?.api_id
                          )
                          .map((pr) => pr[billingCycleHosting]),
                        item?.pid,
                        billingCycleHosting,
                        getActivePromotions,
                        currencyName.prefix
                      )}
                    </div>
                    {/* <ul>
                          <li> <i className="fa fa-check popupcheck" />8GB Web Space </li>
                          <li> <i className="fa fa-check popupcheck" />Unlimited Bandwidth </li>
                          <li> <i className="fa fa-check popupcheck" />Unlimited Email Accounts </li>
                          <li> <i className="fa fa-check popupcheck" /> Virus &amp; Spam Protection </li>
                        </ul> */}

                    <ul>
                      {item.packageFeatures &&
                        item.packageFeatures[0]?.features.map((elss) => (
                          <li>
                            <font>
                              <strong
                                style={{
                                  color: "rgb(0, 88, 128)",
                                  fontSize: 20,
                                  fontWeight: 400,
                                  paddingRight: 8,
                                }}
                              >
                                ✓{" "}
                              </strong>
                            </font>

                            {elss.includes("_") ? (
                              <>
                                {elss.split("_")[0]}
                                {
                                  <i className="fa fa-info-circle">
                                    <span
                                      className="litooltip"
                                      dangerouslySetInnerHTML={{
                                        __html: elss.split("_")[1],
                                      }}
                                    />
                                  </i>
                                }
                              </>
                            ) : (
                              elss
                            )}
                          </li>
                        ))}
                    </ul>

                    <button
                      onClick={(e) => buttonAddToCart(e, item)}
                      className="text mb-3"
                    >
                      Add To Cart
                    </button>
                  </div>
                </React.Fragment>
              ))}
          </Carousel>
        </>
      )}
    </>
  );
};

export default Crousels;
