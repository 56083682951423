import React from "react";
import { useSelector } from "react-redux";
import { Link } from "react-router-dom";

const Footer = () => {

  const { isError } = useSelector((state) => state.isError);

  return (
   <>
    {
      isError ? null :   <footer className="footer">
      <div className="container">
        <div className="upper_section">
          <div className="row">
            <div className="col-md-6">
              <div className="contact_datail">
                <Link to="/">
                  <img
                    style={{ maxWidth: "45%", width: "35%" }}
                    src="/assests/img/logo.png"
                    className=""
                    alt="Websouls"
                  />
                </Link>
                <p>
                  We are Pakistan’s leading Web Hosting Provider – trusting your
                  site to us means a powerful online presence with an uptime
                  that meets all your expectations, guaranteed.
                </p>
                <h4>Contact Us</h4>
                <ul
                  style={{
                    display: "flex",
                    flexWrap: "wrap",
                    flexDirection: "column",
                  }}
                >
                  <li>
                    <Link to="">
                      <i className="fa fa-tty" />
                      +92 423 5238871-73
                    </Link>
                  </li>
                  <li>
                    <Link to="">
                      <i className="fa fa-phone" />
                      +92 321 4776995, +92 322 5252352, +92 321 4776996, +92 300 8420448
                    </Link>
                  </li>
                  <li>
                    <Link to="">
                      <i className="fa fa-envelope" />
                      sales@websouls.com
                    </Link>
                  </li>
                </ul>
                <div className="input-group">
                  <h4>Payment Methods</h4>
                  <div id="results"> </div>
                  <div className="payment_method_ftr">
                    <ul>
                      <li>
                        <Link style={{ cursor: "default" }} to="" href="#">
                          <img
                            src="/assests/img/credit_Card.png"
                            alt="credit_Card"
                          />
                        </Link>
                      </li>
                      <li>
                        <Link style={{ cursor: "default" }} to="" href="#">
                          <img src="/assests/img/paypal.png" alt="paypal" />
                        </Link>
                      </li>
                      <li>
                        <Link style={{ cursor: "default" }} to="" href="#">
                          <img
                            src="/assests/img/online_banking.png"
                            alt="online_banking"
                          />
                        </Link>
                      </li>
                    </ul>
                  </div>
                </div>
                <div className="ftr_social_icon">
                  <h4>Follow Us</h4>
                  <ul>
                    <li>
                      <a
                        rel="noreferer, ,noopener"
                        href="https://www.facebook.com/WebSouls"
                        target="_blank"
                      >
                        <i className="fa fa-facebook" />
                      </a>
                    </li>
                    <li>
                      <a
                        rel="noreferer, ,noopener"
                        href="https://twitter.com/futuresouls"
                        target="_blank"
                      >
                        <i className="fa fa-twitter" />
                      </a>
                    </li>
                    <li>
                      <a
                        rel="noreferer, ,noopener"
                        href="https://www.instagram.com/websouls.pk"
                        target="_blank"
                      >
                        <i className="fa fa-instagram" />
                      </a>
                    </li>
                    <li>
                      <a
                        rel="noreferer, ,noopener"
                        href="https://www.linkedin.com/company/websouls"
                        target="_blank"
                      >
                        <i className="fa fa-linkedin" />
                      </a>
                    </li>
                    <li>
                      <a
                        rel="noreferer, ,noopener"
                        href="https://www.youtube.com/channel/UCaZpD0gU9hEOBvfqjMKutyw"
                        target="_blank"
                      >
                        <i className="fa fa-youtube" />
                      </a>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
            
            <div className="col-md-2 d-none d-md-block d-lg-block">
              <div className="ftr_services">
                <h3>Our Services</h3>
                <ul>
                  <li>
                    <Link to="/shared-hosting" target="_self">
                      Shared Hosting
                    </Link>
                  </li>
                  <li>
                    <Link to="/business-hosting" target="_self">
                      Business Hosting
                    </Link>
                  </li>
                  <li>
                    <Link to="/reseller-hosting" target="_self">
                      Reseller Hosting
                    </Link>
                  </li>
                  <li>
                    <Link to="/wordpress-hosting-in-pakistan" target="_self">
                      WordPress Hosting
                    </Link>
                  </li>
                  <li>
                    <Link to="/dedicated-server" target="_self">
                      Dedicated Server
                    </Link>
                  </li>
                  <li>
                    <Link to="/vps-hosting" target="_self">
                      VPS Hosting
                    </Link>
                  </li>
                  <li>
                    <Link to="/pk-vps" target="_self">
                      Pak Based VPS
                    </Link>
                  </li>
                  <li>
                    <Link to="/ssd-vps" target="_self">
                      SSD VPS
                    </Link>
                  </li>
                  <li>
                    <Link to="/ssl-certificates" target="_self">
                      SSL Certificates
                    </Link>
                  </li>
                  {/* <li>
                    <Link to="/server-management" target="_self">
                      Server Management
                    </Link>
                  </li> */}
                  <li>
                    <Link to="/domain-registration" target="_self">
                      Domain Pricing
                    </Link>
                  </li>
                  <li>
                    <Link to="/domain-transfer" target="_self">
                      Domain Transfer
                    </Link>
                  </li>
                  <li>
                    <Link to="/buy-pk-domain" target="_self">
                      PK Domains
                    </Link>
                  </li>
                  <li>
                    <Link to="/buy-ae-domains" target="_self">
                      AE Domains
                    </Link>
                  </li>
                </ul>
                <h3>Our Company</h3>
                <ul>
                  <li>
                    <Link to="/about" target="_self">
                      About Us
                    </Link>
                  </li>
                  <li>
                    <Link to="/team" target="_self">
                      Our Team
                    </Link>
                  </li>
                  <li>
                    <Link to="/whyus" target="_self">
                      Why Us
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-2 d-none d-md-block d-lg-block">
              <div className="ftr_services">
                <h3>Client Area</h3>
                <ul>
                  <li>
                    <a
                      href="https://billing.websouls.com/clientarea.php"
                      target="_blank"
                    >
                      Billing Area
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://billing.websouls.com/announcements.php"
                      target="_blank"
                    >
                      Announcement
                    </a>
                  </li>
                  <li>
                    <a
                      href="https://billing.websouls.com/submitticket.php?step=2&amp;deptid=2"
                      target="_blank"
                    >
                      Generate a Lead
                    </a>
                  </li>
                  <li>
                    <Link to="/policy" target="_self">
                      Acceptable Use Policy
                    </Link>
                  </li>
                  <li>
                    <Link to="/privacy" target="_self">
                      Privacy Policy
                    </Link>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://billing.websouls.com/submitticket.php?step=2&amp;deptid=5"
                    >
                      Feedback
                    </a>
                  </li>
                  <li>
                    <Link to="" target="_self" href="/sitemap">
                      Sitemap
                    </Link>
                  </li>
                </ul>
                <h3>Support Center</h3>
                <ul>
                  <li>
                    <a
                      target="_blank"
                      href="https://billing.websouls.com//submitticket.php"
                    >
                      Open A Ticket
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://billing.websouls.com/knowledgebase.php"
                    >
                      Knowledgebase Articles
                    </a>
                  </li>
                  <li>
                    <a
                      target="_blank"
                      href="https://billing.websouls.com/serverstatus.php"
                    >
                      Network Status
                    </a>
                  </li>
                  <li>
                    <Link to="/faq" target="_self" href="/faq">
                      FAQ's
                    </Link>
                  </li>
                  <li>
                    <Link to="/payment-methods" target="_self">
                      Payment Method
                    </Link>
                  </li>
                  <li>
                    <Link to="/contactus" target="_self">
                      Contact Us
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-md-2 d-none d-md-block d-lg-block">
              <div className="ftr_services">
                <h3>Website Development</h3>
                <ul>
                  <li>
                    <Link to="/web-development" target="_self">
                      Business Website
                    </Link>
                  </li>
                  <li>
                    <Link to="/ecommerce-solution" target="_self">
                      Ecommerce Solution
                    </Link>
                  </li>
                  <li>
                    <Link to="/seo-services" target="_self">
                      SEO
                    </Link>
                  </li>
                  <li>
                    <Link to="/clients">
                      Clients / Portfolio
                    </Link>
                  </li>
                </ul>
                <span className="cell red">
                  <Link to="/team#hiring" className="we_are_hiring">
                    <span className="celltext button_shape">We're Hiring</span>
                  </Link>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div className="privacy">
          <div className="row">
            <div className="col-md-12">
              <div className="row">
                <div className="col-md-6">
                  <p>Copyright © 2002-2023 Websouls. All Rights Reserved.</p>
                </div>
                <div className="col-md-6">
                  <ul>
                    <li>
                      <Link to="/team#hiring" target="_self">
                        Career
                      </Link>
                    </li>
                    <li>
                      <Link to="/blog" target="_self" href="/blog">
                        Blog
                      </Link>
                    </li>
                    <li>
                      <Link to="/privacy" target="_self">
                        Privacy Policy
                      </Link>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </footer>
    }
   </>
  
  );
};

export default Footer;
