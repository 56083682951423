import React, { useState } from "react";
import { Helmet } from "react-helmet";
import BannerTwo from "../reuseable/Banner2";

const Clients = () => {
  const [clients, setClients] = useState([
    "logo-(65)1.png",
    "logo1-(4).png",
    "logo1-(7).png",
    "logo1-(9).png",
    "logo1-(12).png",
    "logo1-(33).png",
    "logo-1-(48)1.png",
    "logo-1-(49).png",
    "logo-1-(50).png",
    "logo-1-(53).png",
    "logo-1-(59).png",
    "logo-1-(61).png",
    "logo-1-(65).png",
    "logo-1-(66).png",
    "logo-1-(67).png",
    "logo-1-(71).png",
    "logo-1-(75).png",
    "logo-1-(85).png",
    "logo-1-(86).png",
    "logo-1-(89).png",
    "logo-1-(90).png",
    "logo-1-(94).png",
    "logo-1-(97).png",
    "logo-1-(100).png",
    "logo-1-(107).png",
    "logo-1-(109).png",
    "logo-1-(112).png",
    "logo-1-(114).png",
    "logo-1-(116).png",
    "logo-1-(118).png",
    "logo-1-(119).png",
    "logo-1-(122).png",
    "logo-1-(123).png",
    "logo-1-(135).png",
    "logo-1-(136).png",
    "logo-1-(137).png",
    "logo-1-(141).png",
    "logo-1-(144).png",
  ]);
  const bannerData = {
    heading: "Our Clients",
  };
  return (
    <>
      <Helmet>
        <title>Clients | WebSouls</title>
        <meta name="title" content="Clients | WebSouls" />
      </Helmet>
      {/* <BannerTwo Data={bannerData} /> */}
      <section className="payment_method pt-100">
        <div className="container">
          <p style={{ marginBottom: "50px" }}>
            At WebSouls, every day starts with the mission to help our clients
            succeed in the digital realm. We partner with small businesses,
            giant companies, and government organizations on their journey to
            digital transformation, assisting all our clients in becoming the
            best version of themselves. Our valued customers are the reason why
            we do what we do and we’re proud to have earned the trust of
            numerous industry leaders!
          </p>
          <div className="row">
            <div className="col-md-12">
              <ul className="row">
                {clients?.map((esl) => (
                  <li
                    className="col-md-3 col-sm-3 col-xs-3 pl-0 pr-0"
                    style={{ backgroundColor: "" }}
                  >
                    <div className="client_logo">
                      <img
                        src={`/assests/img/clients/${esl}`}
                        className="lazy"
                        title={`${esl}`}
                        alt={`${esl}`}
                      />
                    </div>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </div>
      </section>
    </>
  );
};

export default Clients;
