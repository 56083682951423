import React from "react";
import { Helmet } from "react-helmet";
import BundelBooster from "./includes/EcommerSolition/BundelBooster";
import DomainMigration from "./includes/EcommerSolition/DomainMigration";
import GoingOnline from "./includes/EcommerSolition/GoingOnline";
import PopupSlider from "../../reuseable/includes/PopupSlider";
import InPakistan from "./includes/EcommerSolition/InPakistan";
import AllPlanIncludes from "./includes/EcommerSolition/AllPlanIncludes";
import WillingForHelp from "../../reuseable/WillingForHelp";
import DomainTips from "./includes/EcommerSolition/DomainTips";
import Faq from "../../reuseable/Faq";
import { Link } from "react-router-dom";

const EcommerSolution = () => {
  const data = {
    Heading: "Got A Tired Website?",
    SubHeading: "We can freshen it up!",
    ButtonName: "Migrate Now!",
  };
  const allplans = {
    Heading: "All Plans Include",
    SubHeading: "Everything you Need for an Online Store that Actually Sells",
    Box: [
      {
        id: 0,
        heading: "Mobile Responsive",
        subHeading: "",
        icon: "icon ws-responsive",
      },
      {
        id: 1,
        heading: "SM Integration",
        subHeading: "",
        icon: "icon ws-sm-icons",
      },
      {
        id: 2,
        heading: "SEO Friendly",
        subHeading: "",
        icon: "icon ws-location",
      },
      {
        id: 3,
        heading: "Google Analytics",
        subHeading: "",
        icon: "icon ws-analytic",
      },
      {
        id: 4,
        heading: "Easy To Use CMS",
        subHeading: "",
        icon: "icon ws-easy",
      },
      {
        id: 5,
        heading: "Cart Integration",
        subHeading: "",
        icon: "icon ws-cart",
      },
    ],
  };
  const BundleData = {
    Heading: "Bundle Boosters",
    SubHeading: "Complete Your Package with a Little Something Extra",
    Bundles: [
      {
        id: 0,
        bundleHeading: "SEO",
        bundleParagh:
          "We can optimize your site for giant search engines like Google and help you rank higher on Search Engine Result Pages for increased web traffic, higher conversions, boosted sales, and maximized revenue generation.",
        bundleImage: "assests/img/SEO-services-in-Pakistan.png",
        bundleId: "seo-tab",
        bundleDataTarget: "#seo",
        bundleAriaControl: "seo",
        bundleParaghId: "seo",
        bundleParaghAriaLable: "seo-tab",
        class: "tab-pane fade show active",
        buttonClass: "nav-link active",
      },
      {
        id: 1,
        bundleHeading: "Content Writing",
        bundleParagh:
          "Our team of professional content writers is adept in creating just the right kind of content that can help improve your search engine rankings, attract more customers, and resonate across your audience’s minds.",
        bundleImage: "assests/img/Professional-Content-Writting-Services.png",
        bundleId: "content-Writing-tab",
        bundleDataTarget: "#content",
        bundleAriaControl: "content",
        bundleParaghId: "content",
        bundleParaghAriaLable: "content-Writing-tab",
        class: "tab-pane fade show",
        buttonClass: "nav-link",
      },
      {
        id: 2,
        bundleHeading: "Social Media",
        bundleParagh:
          "We know how important it is to stay active on social media nowadays. Our certified social media marketers can target your customers across various social media platforms and persuade them to buy from you.",
        bundleImage: "assests/img/Social-Media-Marketing-Services.png",
        bundleId: "social-media-tab",
        bundleDataTarget: "#social",
        bundleAriaControl: "social",
        bundleParaghId: "social",
        bundleParaghAriaLable: "social-media-tab",
        class: "tab-pane fade show",
        buttonClass: "nav-link",
      },
      {
        id: 3,
        bundleHeading: "SSL Certificate",
        bundleParagh:
          "We provide our clients with SSL certificates to help them keep their site, and the data of their customers safe from hackers. An SSL is just what you need to build your customers’ trust and get those sales going!",
        bundleImage: "assests/img/SSL-certificate-in-Pakistan.png",
        bundleId: "ssl-certificates-tab",
        bundleDataTarget: "#ssl",
        bundleAriaControl: "ssl",
        bundleParaghId: "ssl",
        bundleParaghAriaLable: "ssl-certificates-tab",
        class: "tab-pane fade show",
        buttonClass: "nav-link",
      },
    ],
  };
  const WillingToHelpData = {
    heading: "Ready, Willing and Able to Help!",
    subHeading:
      "Whether it’s a question, query, or you want to discuss a project, the WebSouls team is at your service anytime, at all times!",
    buttonOne: "Call: +92 423 523 8871 - 73",
    buttonTwo: " Email Us",
    image: "assests/img/Muhammad-Shehryar-Team-Websouls.png",
  };
  const GoingOnlineData = {
    Heading: "Going Online? You’d Need More!",
    subHeading: "We Don’t Just Build Websites, We Create an Impact",
    box: [
      {
        id: 0,
        topTitle: "Need Email Hosting?",
        Title: "Try Business Hosting",
        buttonLink: "/business-hosting",
      },
      {
        id: 1,
        topTitle: "Need Something Simpler?",
        Title: "Try WordPress Hosting",
        buttonLink: "/wordpress-hosting-in-pakistan",
      },
    ],
  };
  const InPakistanData = {
    Heading: "Ecommerce Solution in Pakistan",
    subHeading: "Helping Businesses Step-Up their Selling Game",
    peragraph: [
      <>
        <p>
          <Link to="/">WebSouls</Link> has successfully helped numerous small
          and large-scale businesses in putting up a digital store online. And
          not just that, but we also know how to build an ecommerce website that
          can actually persuade your visitors to make a purchase online.
        </p>
        <p>
          As a leading company in <Link to="/">Pakistani web hosting</Link>
           industry, we are trusted by 1000’s of corporate, as well as
          government organizations in Pakistan. Our ecommerce web development
          services are focused on helping our clients build their businesses
          online with our quick and fully managed ecommerce solutions in
          Pakistan.
        </p>
        <p>
          The best part is, our ecommerce web development packages are quite
          affordable. Which means, we can actually help you in making more sales
          online and generating higher revenue without you having to pay a
          fortune.
        </p>
        <p>
          Not only do we provide ecommerce solutions at the best prices, but the
          e-stores built by us also incorporate latest ecommerce features,
          including mobile responsiveness, cart integration, and SM integration.
          Plus, since we’re an official partner of cPanel, all our plans come
          with an easy-to use CMS that can make online growth even simpler for
          you. So, <Link to="/contactus">contact us</Link> now, or visit one of
          our offices in Lahore and Karachi.
        </p>
      </>,
    ],
  };
  const technicalSpecificationsData = {
    Heading: "Why Ecommerce",
    subHeading:
      "Harnessing the Power of Technology to Deliver a Dependable Experience",
    box: [
      {
        id: 0,
        icon: "icon ws-cart-b",
        title: "Sell All Day, All Year",
        peragraph:
          "With our powerful servers that guarantee 99.9% uptime, we enable our customers to be available to their customers all day 24/7 throughout the year.",
      },
      {
        id: 1,
        icon: "icon ws-cart-c",
        title: "Sell Products Globally",
        peragraph:
          "Unlike a physical store, an ecommerce site isn’t restricted to just a single location, and with our help, you can put up a digital shop that sells to customers globally.",
      },
      {
        id: 2,
        icon: "icon ws-graph",
        title: "Increased Revenue",
        peragraph:
          "We know how to build an ecommerce store that actually persuades the visitors to make a purchase. Our services can help spark up your revenue generation!",
      },
      {
        id: 3,
        icon: "icon ws-speaker",
        title: "Better Brand Awareness",
        peragraph:
          "An ecommerce store can be your sales representative, and we make sure it works hard. With us, your customers would know you, and your products, better.",
      },
      {
        id: 4,
        icon: "icon ws-payless",
        title: "Pay Less Shop Rent",
        peragraph:
          "Our ecommerce solutions come at remarkably affordable rates. With us, you’d be investing less, and earning way more than you do with a physical store.",
      },

      {
        id: 5,
        icon: "icon ws-config",
        title: "Online Store Automation",
        peragraph:
          "We provide our clients with the best store automation tools so that you can save both your time and labour, while your business grows exponentially as well as globally.",
      },
    ],
  };
  const faqData = {
    heading: "Frequently Asked Questions",
    subHeading: "Dedicated to Helping You Succeed!",
    faqs: [
      {
        id: 0,
        title: "How can you make my ecommerce website effective?",
        desc: "We build ecommerce websites with an increased focus on functionality and design. Plus, we also incorporate all the latest features that your online store needs to give the desired results.",
        faqId: "FAQ1",
        dataBsTarget: "#faqOne",
        ariaControls: "faqOne",
        ariaLabelledby: "FAQ1",
        faqParaghId: "faqOne",
      },
      {
        id: 1,
        title: "How will my customers find me online?",
        desc: "After your ecommerce site has been developed and launched successfully, we can help you out with effective Search Engine Optimization as well. With improved search engine rankings and increased online visibility, your prospects would be able to find you at the right time, and at the right place, always.",
        faqId: "FAQ2",
        dataBsTarget: "#faqTwo",
        ariaControls: "faqTwo",
        ariaLabelledby: "FAQ2",
        faqParaghId: "faqTwo",
      },
      {
        id: 2,
        title:
          "Can I see how your ecommerce CMS works before purchasing a plan?",
        desc: "Off course, you can! We provide free trials that can help you get an understanding of how our CMS works and also the overall quality of our services. In fact, you can get a free trial now!",
        faqId: "FAQ3",
        dataBsTarget: "#faqThree",
        ariaControls: "faqThree",
        ariaLabelledby: "FAQ3",
        faqParaghId: "faqThree",
      },
      {
        id: 3,
        title: "Can I use my own domain name?",
        desc: "Why not, surely you can use your own domain name. Also, you won’t be required to use a temporary domain name placeholder.",
        faqId: "FAQ4",
        dataBsTarget: "#faqFour",
        ariaControls: "faqFour",
        ariaLabelledby: "FAQ4",
        faqParaghId: "faqFour",
      },
    ],
  };

  const dataPlans = {
    heading1: "Building Sales-Driven Online Stores",
    heading2: "We Know How to Make Your Customers Return!",
    OnCall: "Get Quote on Call",
    pakageArray: [
      {
        pid: 310,
        package_name: "STARTUP",
        package_plans: "BASIC",
        planList: [
          "Upto 50 Product",
          "Upto 30 Categories",
          "Upto 20 Content Pages",
          "Integrated Social Pages",
          "SEO Friendly Online Store",
          "Fully Mobile Responsive",
          " 15 Days Support ",
          " On Demand Training Session ",
          " Cash On Delivery Mechanism ",
        ],
      },
      {
        pid: 311,
        package_name: "CUSTOM",
        package_plans: "IDEAL ",
        planList: [
          "Creative Logo Design ",
          "Custom Niche Based Design ",
          "Custom Designed Sliders ",
          "Payment Gateways Integrations ",
          " Custom Modules Development ",
          " Unlimited Categories ",
          " Unlimited Products ",
          " Unlimited Content Pages ",
          " Custom Courier Integrations ",
        ],
      },
      {
        pid: 182,
        package_name: "GROWTH",
        package_plans: "IMPROVED ",
        planList: [
          "Unlimited Categories ",
          "Unlimited Products ",
          "Unlimited Content Pages ",
          "Integrated Social Pages ",
          " SEO Friendly Online Store ",
          " Fully Mobile Responsive ",
          " 30 Days Support ",
          " On Demand Training Session ",
          " Cash On Delivery Mechanism ",
        ],
      },
    ],
  };

  return (
    <>
      <Helmet>
        <title>
          Ecommerce Website Development Solution in Pakistan | WebSouls
        </title>
        <meta
          name="title"
          content="Ecommerce Website Development Solution in Pakistan | WebSouls"
        />
        <meta
          name="description"
          content="WebSouls providing ecommerce website development solution in Pakistan. Build your online store now and start selling globally."
        />
        <meta name="keywords" content="" />
        <script type="application/ld+json">
          {`{
  "@context": "https://schema.org",
  "@type": "Organization",
  "name": "WebSouls",
  "alternateName": "Web Hosting Pakistan",
  "url": "https://www.websouls.com/",
  "logo": "https://www.websouls.com/public/uploads/images/logo_1589438878.png",
  "contactPoint": [{
    "@type": "ContactPoint",
    "telephone": "+92 321 407 7991",
    "contactType": "technical support",
    "areaServed": "PK",
    "availableLanguage": ["en","Urdu"]
  },{
    "@type": "ContactPoint",
    "telephone": "+92 322 525 2352",
    "contactType": "sales",
    "areaServed": "PK",
    "availableLanguage": ["en","Urdu"]
  }],
  "sameAs": [
    "https://www.facebook.com/WebSouls/",
    "https://www.twitter.com/futuresouls",
    "https://www.instagram.com/websouls.pk/",
    "https://www.linkedin.com/company/websouls/",
    "https://github.com/websouls",
    "https://www.websouls.com/"
  ]
}`}
        </script>
        <script type="application/ld+json">
          {`{
  "@context": "https://schema.org/", 
  "@type": "Product", 
  "name": "E-commerce Solution Pakistan - WebSouls",
  "image": "https://www.websouls.com/public/uploads/images/logo_1589438878.png",
  "description": "WebSouls has successfully helped numerous small and large-scale businesses in putting up a digital store online. And not just that, but we also know how to build an ecommerce website that can actually persuade your visitors to make a purchase online.

As a leading company in Pakistani web hosting industry, we are trusted by 1000’s of corporate, as well as government organizations in Pakistan. Our ecommerce web development services are focused on helping our clients build their businesses online with our quick and fully managed ecommerce solutions in Pakistan.",
  "brand": "WebSouls",
  "sku": "E-commerce Solution Pakistan",
  "mpn": "E-commerce Solution Pakistan",
  "offers": {
    "@type": "AggregateOffer",
    "url": "https://www.websouls.com/ecommerce-solution",
    "priceCurrency": "PKR",
    "lowPrice": "49999",
    "highPrice": "78999",
    "offerCount": "3"
  },
  "aggregateRating": {
    "@type": "AggregateRating",
    "ratingValue": "4.9",
    "bestRating": "4.9",
    "worstRating": "4.2",
    "ratingCount": "1322"
  }
}`}
        </script>
        <script type="application/ld+json">
          {`{
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "How can you make my ecommerce website effective?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "We build ecommerce websites with an increased focus on functionality and design. Plus, we also incorporate all the latest features that your online store needs to give the desired results."
    }
  },{
    "@type": "Question",
    "name": "How will my customers find me online?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "After your ecommerce site has been developed and launched successfully, we can help you out with effective Search Engine Optimization as well. With improved search engine rankings and increased online visibility, your prospects would be able to find you at the right time, and at the right place, always."
    }
  },{
    "@type": "Question",
    "name": "Can I see how your ecommerce CMS works before purchasing a plan?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Off course, you can! We provide free trials that can help you get an understanding of how our CMS works and also the overall quality of our services. In fact, you can get a free trial now!"
    }
  },{
    "@type": "Question",
    "name": "Can I use my own domain name?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Why not, surely you can use your own domain name. Also, you won’t be required to use a temporary domain name placeholder."
    }
  }]
}`}
        </script>
        <link rel="canonical" href="https://websouls.com/ecommerce-solution" />
      </Helmet>
      <PopupSlider data={dataPlans} />
      <AllPlanIncludes plansData={allplans} />
      <BundelBooster bundleData={BundleData} />
      <GoingOnline Data={GoingOnlineData} />
      {/* <WhyChooseUsSecond /> */}
      <DomainTips Data={technicalSpecificationsData} />
      <DomainMigration Data={data} />
      <InPakistan Data={InPakistanData} />
      <Faq Data={faqData} />
      <WillingForHelp helpData={WillingToHelpData} />
    </>
  );
};

export default EcommerSolution;
