import React, {useEffect} from "react";
import { useLocation, useNavigate } from "react-router-dom";

const ThankyouSample = () => {

  const location = useLocation();
  const navigate = useNavigate();

  useEffect(()=>{
    if (location.key === "default") {
      navigate("/");
    }
  },[])

  return (
    <>
      <div className="container">
        <div className="row text-center justify-content-center">
          <div className="col-xs-12 col-sm-12 col-md-6">
            <h2 className="first-heading mt-5 mb-0">Thank you saleem</h2>
            <p className="ordersub">Your Order has been submitted.</p>
            <button className="submitammiunt mt-3">
              Total due ammount is Rs4569/-
            </button>
            <p className="mt-3 mb-5">
              Please wait, redirecting you to make payment...
            </p>
          </div>
        </div>
        <div className="row text-center justify-content-center">
          <div className="col-xs-12 col-sm-12 col-md-6" style={{ textAlign: "-webkit-center" }}>
            <h2 className="first-heading mt-5 mb-0">Thank you saleem</h2>
            <p className="ordersub">Your Order has been submitted.</p>
            <p className="  pt-4">Your order number is 177743434</p>
            <p className="pb-1">Your invoice number is 177743434</p>
            <button className="submitammiunt mt-3">
              Total due ammount is Rs4569/-
            </button>
            <h4 style={{color: '#005880', fontWeight: '700'}} className="pt-5 pb-3">
                Pay with Online Banking
            </h4>
            <p style={{width:'54%'}} className="pt-3">
              HBL: Websouls, Habib Bank Ltd, A/C no. 01957900259103,
              cavalry Ground Brankc, Lahore (Code 0195)
            </p>
            <p style={{width:'70%'}} className="pt-3">
                MCB: Websouls, Muslim Commerical Bank Ltd, A/C No. 01410101010 Defence Branh, Lahore
            </p>
            <p className="pt-3 mb-5">
                Note: Please send us transaction snapshot at sales@websouls.com
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default ThankyouSample;
