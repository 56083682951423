import React from 'react';
import { Link } from "react-router-dom";

const GoingOnline = (props) => {
    const { Data } = props;
  return (
    <section className="explore_more explore_scnd pt-100 margin-t-0">
      <div className="container">
        <h2 className="first-heading">{Data?.Heading}</h2>
        <h5 className="second-heading">{Data?.subHeading} </h5>
        <hr className="divider scnd_divider scnd_divider scnd_divider mx-auto mb-4" />
        {Data?.box?.map((el) => (
          <React.Fragment key={el.id}>
            <div className="business_hosting">
              <div className="center_hosting scnd_hosting">
                <p>{el?.topTitle}</p>
                <h5>{el?.Title}</h5>
                <Link to={el?.buttonLink}>
                  <button>See Plans</button>
                </Link>
              </div>
            </div>
            <div className="dashed">
              <hr className="divider scnd_divider_two" />
            </div>
          </React.Fragment>
        ))}
      </div>
    </section>
  )
}

export default React.memo(GoingOnline)