import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import axios from "axios";
import { toast } from "react-toastify";
import { useDispatch, useSelector } from "react-redux";
import CartOrder from "./CartOrder";
import Carousel from "react-elastic-carousel";
import "./owl.carouselnew.css";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import { test2 } from "../../services/Helper";
import {
  GetPackageAddons,
  GetPackageAddonsCustomName,
} from "../../services/Network.js";

const GetAddons = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const navigate = useNavigate();

  const [addonState, setAddonState] = useState([]);
  const [getPid, setgetPid] = useState([]);
  const [addonCount, setaddonCount] = useState();
  const [loading, setLoading] = useState(false);
  //reducx states
  const { currencyName } = useSelector((state) => state.currencyName);
  const { packageCart } = useSelector((state) => state.packageCart);

  let packageCartt;
  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 2, itemsToScroll: 2 },
    { width: 768, itemsToShow: 3 },
    { width: 1200, itemsToShow: 3 },
  ];

  useEffect(() => {
    if (location.key === "default") {
      navigate("/");
    }
  }, []);

  useEffect(() => {
    // if (packageCart.length === 0) {
    //   navigate("/cart");
    // }

    var GetLastIndex;

    if (localStorage?.getItem("packageCart")) {
      packageCartt = JSON?.parse(localStorage?.getItem("packageCart"));
    }

    if (packageCartt?.length > 1) {
      GetLastIndex = packageCartt[packageCartt?.length - 1];
      //console.log("GetLastIndex cjheck: ", GetLastIndex)
    } else {
      GetLastIndex = packageCartt[0];
      //console.log("GetLastIndex cjheck1: ", GetLastIndex)
    }

    dispatch({
      type: "urlCurrent",
      payload: location.pathname,
    });
    setgetPid(GetLastIndex);

    /////////  addon count

    // GetPackageAddons(GetLastIndex?.pid).then((res) => {
    //   dispatch({
    //     type: "addonsExrra",
    //     payload: res[0],
    //   });
    //   let newObj = [];
    //   for (let a = 0; a < res?.length; a++) {
    //     let newss = res[a];
    //     GetPackageAddonsCustomName(GetLastIndex?.pid).then((resp) => {
    //       let newsb = {};
    //       newsb = resp[0];
    //       newObj.push({ ...newss, ...{ custome: newsb } });

    //       setAddonState([...newObj]);
    //     });
    //     setLoading(true);
    //   }
    // });

    axios
      .get(
        `${process.env.REACT_APP_BILLING_URL}custom/package_addon_node_new.php?package_id=${GetLastIndex?.addonids}`
      )
      .then((res) => {
        setaddonCount(res.data.length);

        if (
          (addonState && addonState?.length === 0) ||
          (addonState && addonState?.length < res?.data?.length)
        ) {
          axios
            .get(
              `${process.env.REACT_APP_BILLING_URL}custom/package_addon_node_new.php?package_id=${GetLastIndex?.addonids}`
            )
            .then((response) => {
              if (response?.data?.length === res?.data?.length) {
                //comenting to stop adding the addons starts from here
                dispatch({
                  type: "addonsExrra",
                  payload: response.data[0],
                });
                //comenting to stop adding the addons starts ends
                setAddonState(response.data);
                //here api for custom api addon names

                // let newObj = [];
                // for (let a = 0; a < response?.data?.length; a++) {
                //   let newss = response?.data[a];
                //   axios
                //     .get(
                //       `${process.env.REACT_APP_BILLING_URL}custom/addoncustomfield.php?id=${response?.data[a]?.id}`
                //     )
                //     .then((res) => {
                //       let newsb = {};
                //       newsb = res?.data[0];
                //       newObj.push({ ...newss, ...{ custome: newsb } });

                //       setAddonState([...newObj]);
                //     })
                //     .then(function () {
                //       setTimeout(() => {
                //         // dispatch({
                //         //   type: "addonsExrra",
                //         //   payload: addonState[0],
                //         // });
                //       }, 2000);
                //     })
                //     .catch((err) => console.warn(err));
                // }
                setLoading(true);
              }
            })
            .catch((err) => console.warn(err));
        }
      })
      .catch((err) => console.warn(err));

    ////////
  }, [currencyName, packageCart]);

  const addonsAdding = (e, adon) => {
    e.preventDefault();

    dispatch({
      type: "addonsExrra",
      payload: adon,
    });
    toast.info("Successfully Added To Cart", {
      position: "top-right",
      autoClose: 1000,
      hideProgressBar: false,
      closeOnClick: true,
      pauseOnHover: true,
      draggable: true,
      progress: undefined,
    });
  };

  //checking billing cycle

  const addonValues = [];
  const btt = addonState;

  for (var p = 0; p < btt?.length; p++) {
    var inn = btt[p]?.id;
    const bt = btt[p]?.pricing[currencyName?.api_id];
    const billing = [bt];

    for (const ob of billing) {
      var indx;
      var price;
      if (btt[p]?.billingcycle === "onetime") {
        if (currencyName?.api_id === 1) {
          addonValues[inn] = test2(ob?.monthly) + "/onetime";
        } else {
          addonValues[inn] = test2(ob?.monthly)?.split(".")[0] + "/onetime";
        }
        break;
      }
      if (ob?.monthly > 0) {
        if (currencyName?.api_id === 1) {
          addonValues[inn] = test2(ob?.monthly) + "/mon";
        } else {
          addonValues[inn] = test2(ob?.monthly).split(".")[0] + "/mon";
        }
        break;
      }

      if (ob?.quarterly > 0) {
        if (currencyName?.api_id === 1) {
          addonValues[inn] = test2(ob?.quarterly) + "/3mons";
        } else {
          addonValues[inn] = test2(ob?.quarterly).split(".")[0] + "/3mons";
        }
        break;
      }
      if (ob?.semiannually > 0) {
        if (currencyName?.api_id === 1) {
          addonValues[inn] = test2(ob?.semiannually) + "/6mons";
        } else {
          addonValues[inn] = test2(ob?.semiannually)?.split(".")[0] + "/6mons";
        }
        break;
      }
      if (ob.annually > 0) {
        if (currencyName?.api_id === 1) {
          addonValues[inn] = test2(ob?.annually) + "/yr";
        } else {
          addonValues[inn] = test2(ob?.annually)?.split(".")[0] + "/yr";
        }
        break;
      }
      if (ob.biennially > 0) {
        if (currencyName?.api_id === 1) {
          addonValues[inn] = test2(ob?.biennially) + "/2yrs";
        } else {
          addonValues[inn] = test2(ob?.biennially)?.split(".")[0] + "/2yrs";
        }
        break;
      }
      if (ob.triennially > 0) {
        if (currencyName?.api_id === 1) {
          addonValues[inn] = test2(ob?.triennially) + "/3yrs";
        } else {
          addonValues[inn] = test2(ob?.triennially)?.split(".")[0] + "/3yrs";
        }
        break;
      }
    }
  }

  //crousel settings

  const carouselOptions = {
    items: 3,
    nav: false,
    // autoplay: true,
    rewind: false,
    loop: false,
    margin: 40,
    responsive: {
      0: {
        items: 1,
      },
      600: {
        items: 3,
      },
      1000: {
        items: 3,
      },
    },
  };
  // const handleUpdate = () => {
  //   //by calling this method react re-renders the component
  //   this.setState({});
  // };
  const addedtoCartCheckaddon = (pid, id) => {
    if (pid && pid?.addons) {
      //let dexist = false;
      const dexist = pid?.addons?.filter((dm) => dm?.id === id);

      if (dexist && dexist?.length > 0) {
        return 1;
      } else {
        return 0;
      }
    }
  };

  return (
    <section style={{ margin: "0" }} className="psty">
      <div className="container">
        <div className="row">
          <Link
            to="#"
            style={{ textDecoration: "none", color: "#005880" }}
            className="go-back"
            onClick={() => navigate(-1)}
          >
            <i className="fa fa-arrow-left"></i> Go Back
          </Link>
        </div>
        <div className="row sm-g-0 mb-5 g-5">
          <CartOrder />
          <div className="col-lg-7 col-xl-8 col-xxl-8 ">
            <h6 className="result-message p-2 p-sm-1 p-md-4 p-lg-4">
              Add MUST-HAVE ADD-ONS to make your hosting functional.
            </h6>
            <div className="searched-result  mb-2">
              {loading ? (
                <>
                  <Carousel
                    className="animate__animated animate__fadeIn"
                    itemPadding={[8, 10, 8, 10]}
                    breakPoints={breakPoints}
                    pagination={true}
                    showArrows={false}
                  >
                    {addonState &&
                      addonState.map((item, index) => (
                        <React.Fragment key={index}>
                          <div
                            style={{
                              backgroundColor: "transparent",
                              border: "1px solid #D2D2D2",
                              borderRadius: 5,
                            }}
                            className="col-lg-4 pacakge-one text-start"
                          >
                            <div className="package-name  ">
                              <h3
                                dangerouslySetInnerHTML={{ __html: item.name }}
                              />
                              <p
                                dangerouslySetInnerHTML={{
                                  __html: item.customAddonField,
                                }}
                              />
                            </div>
                            <div className="package-one-price">
                              <span className="amount">
                                {currencyName.api_id === 3 ? (
                                  <>
                                    {currencyName.prefix}
                                    {
                                      // item.pricing[currencyName.api_id][
                                      //   billingCycleHosting
                                      // ]
                                    
                                        addonValues[item.id] === undefined   ? '0 /yr' : addonValues[item.id]
                                      
                                      
                                    }{" "}
                                  </>
                                ) : (
                                  <>
                                    {" "}
                                    {currencyName.prefix}
                                    {
                                      // item.pricing[currencyName.api_id][
                                      //   billingCycleHosting
                                      // ]
                                      addonValues[item.id] === undefined   ? '0 /yr' : addonValues[item.id]
                                    }{" "}
                                  </>
                                )}
                                {currencyName.api_id === 3 ? (
                                  <>
                                    {" "}
                                    {item.pricing[currencyName.api_id]
                                      .asetupfee <= 0 ? null : (
                                      <p>
                                        {" "}
                                        {currencyName.prefix}
                                        {
                                          test2(
                                            item.pricing[currencyName.api_id]
                                              .asetupfee
                                          ).split(".")[0]
                                        }{" "}
                                        Setup Fee
                                      </p>
                                    )}{" "}
                                  </>
                                ) : (
                                  <>
                                    {" "}
                                    {item?.pricing[currencyName?.api_id]
                                      ?.asetupfee <= 0 ? null : (
                                      <p>
                                        {" "}
                                        {currencyName.prefix}
                                        {test2(
                                          item?.pricing[currencyName?.api_id]
                                            ?.asetupfee
                                        )}{" "}
                                        Setup Fee
                                      </p>
                                    )}{" "}
                                  </>
                                )}
                              </span>
                            </div>
                            <ul className="addon-mobile">
                              <p>{item?.description}</p>
                            </ul>
                            {addedtoCartCheckaddon(getPid, item.id) === 1 ? (
                              <>
                                <button
                                  onClick={(e, adon) => addonsAdding(e, item)}
                                  className="text mb-3 pacakgenotAllowed"
                                >
                                  Added To Cart
                                </button>
                              </>
                            ) : (
                              <>
                                <button
                                  onClick={(e, adon) => addonsAdding(e, item)}
                                  className="text mb-3 "
                                >
                                  Add To Cart
                                </button>
                              </>
                            )}
                          </div>
                        </React.Fragment>
                      ))}
                  </Carousel>
                </>
              ) : (
                <>
                  <Carousel
                    itemPadding={[8, 10, 8, 10]}
                    breakPoints={breakPoints}
                    pagination={true}
                    showArrows={false}
                    className="animate__animated animate__fadeOut"
                  >
                    <div
                      className="col-lg-4 pacakge-one text-start "
                      style={{
                        backgroundColor: "transparent",
                        border: "1px solid rgb(210, 210, 210)",
                        borderRadius: 5,
                      }}
                    >
                      <div className="package-name ">
                        <h3>
                          <Skeleton width={200} />
                        </h3>
                        <p>
                          <Skeleton width={100} />
                        </p>
                      </div>
                      <div
                        className="package-one-price checkss"
                        style={{ marginBottom: 0 }}
                      >
                        <div className="discount">
                          <h4 className="">
                            <b>
                              <Skeleton width={300} />
                            </b>
                          </h4>
                        </div>
                        <div className="package-one-price">
                          <span className="amount">
                            {" "}
                            <Skeleton width={190} />
                          </span>
                        </div>
                      </div>
                      <ul>
                        <li>
                          <Skeleton style={{ width: "100%" }} />
                        </li>
                        <li>
                          <Skeleton style={{ width: "100%" }} />
                        </li>
                        <li>
                          <Skeleton style={{ width: "100%" }} />
                        </li>
                        <li>
                          <Skeleton style={{ width: "100%" }} />
                        </li>
                        <li>
                          <Skeleton style={{ width: "100%" }} />
                        </li>
                        <li>
                          <Skeleton style={{ width: "100%" }} />
                        </li>
                        <li>
                          <Skeleton style={{ width: "100%" }} />
                        </li>
                        <li>
                          <Skeleton style={{ width: "100%" }} />
                        </li>
                      </ul>
                      <Skeleton
                        width={200}
                        style={{ paddingTop: 20, marginTop: 10 }}
                      />
                    </div>
                    <div
                      className="col-lg-4 pacakge-one text-start "
                      style={{
                        backgroundColor: "transparent",
                        border: "1px solid rgb(210, 210, 210)",
                        borderRadius: 5,
                      }}
                    >
                      <div className="package-name ">
                        <h3>
                          <Skeleton width={200} />
                        </h3>
                        <p>
                          <Skeleton width={100} />
                        </p>
                      </div>
                      <div
                        className="package-one-price checkss"
                        style={{ marginBottom: 0 }}
                      >
                        <div className="discount">
                          <h4 className="">
                            <b>
                              <Skeleton width={300} />
                            </b>
                          </h4>
                        </div>
                        <div className="package-one-price">
                          <span className="amount">
                            {" "}
                            <Skeleton width={190} />
                          </span>
                        </div>
                      </div>
                      <ul>
                        <li>
                          <Skeleton style={{ width: "100%" }} />
                        </li>
                        <li>
                          <Skeleton style={{ width: "100%" }} />
                        </li>
                        <li>
                          <Skeleton style={{ width: "100%" }} />
                        </li>
                        <li>
                          <Skeleton style={{ width: "100%" }} />
                        </li>
                        <li>
                          <Skeleton style={{ width: "100%" }} />
                        </li>
                        <li>
                          <Skeleton style={{ width: "100%" }} />
                        </li>
                        <li>
                          <Skeleton style={{ width: "100%" }} />
                        </li>
                        <li>
                          <Skeleton style={{ width: "100%" }} />
                        </li>
                      </ul>
                      <Skeleton
                        width={200}
                        style={{ paddingTop: 20, marginTop: 10 }}
                      />
                    </div>
                    <div
                      className="col-lg-4 pacakge-one text-start "
                      style={{
                        backgroundColor: "transparent",
                        border: "1px solid rgb(210, 210, 210)",
                        borderRadius: 5,
                      }}
                    >
                      <div className="package-name ">
                        <h3>
                          <Skeleton width={200} />
                        </h3>
                        <p>
                          <Skeleton width={100} />
                        </p>
                      </div>
                      <div
                        className="package-one-price checkss"
                        style={{ marginBottom: 0 }}
                      >
                        <div className="discount">
                          <h4 className="">
                            <b>
                              <Skeleton width={300} />
                            </b>
                          </h4>
                        </div>
                        <div className="package-one-price">
                          <span className="amount">
                            {" "}
                            <Skeleton width={190} />
                          </span>
                        </div>
                      </div>
                      <ul>
                        <li>
                          <Skeleton style={{ width: "100%" }} />
                        </li>
                        <li>
                          <Skeleton style={{ width: "100%" }} />
                        </li>
                        <li>
                          <Skeleton style={{ width: "100%" }} />
                        </li>
                        <li>
                          <Skeleton style={{ width: "100%" }} />
                        </li>
                        <li>
                          <Skeleton style={{ width: "100%" }} />
                        </li>
                        <li>
                          <Skeleton style={{ width: "100%" }} />
                        </li>
                        <li>
                          <Skeleton style={{ width: "100%" }} />
                        </li>
                        <li>
                          <Skeleton style={{ width: "100%" }} />
                        </li>
                      </ul>
                      <Skeleton
                        width={200}
                        style={{ paddingTop: 20, marginTop: 10 }}
                      />
                    </div>
                  </Carousel>
                </>
              )}
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GetAddons;
