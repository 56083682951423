import React, { useState } from "react";
import { useNavigate } from "react-router-dom";

const DomainSearchGlobal = () => {
  const navigate = useNavigate();
  const [registerDomainName, setRegisterDomainName] = useState("");

  const handelRegisterDomain = (e) => {
    const nameConcatenate = `${registerDomainName}`;
    setRegisterDomainName(nameConcatenate);
    e.preventDefault();
    navigate("/domain", { state: { name: nameConcatenate } });
  };

  return (
    <section className="about_bnr mt-0 md-mb-70">
      <div style={{background: 'none'}} className="align_about_text">
        <h2>A Perfect Beginning for a Perfect Website</h2>
        <p>Find and claim the perfect domain name before anyone else does!</p>
        
        <form id="search-form">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="input-group pl-5 pr-5 mt-3">
                  <input
                    type="search"
                    id="name"
                    name="domain"
                    required=""
                    pattern="^([a-zA-Z0-9]([a-zA-Z0-9\-]{0,61}[a-zA-Z0-9])?\.)+[a-zA-Z]{2,50}$"
                    className="form-control homesearchbar"
                    placeholder="Find your perfect domain name..."
                    title="Please provide valid domain"
                    value={registerDomainName}
                    style={{ height: 65 }}
                    onChange={(e) => setRegisterDomainName(e.target.value)}
                  />

                  <div className="input-group-append">
                    <button
                    disabled={registerDomainName === ""}
                      onClick={(e) => handelRegisterDomain(e)}
                      className="checkdomian newdomainCheck input-group-text"
                      type="submit"
                      data-badge="bottomleft"
                      style={{ height: "100%" }}
                    >
                      <i className="fa fa-search" />
                    </button>
                  </div>
               
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </section>
  );
};

export default DomainSearchGlobal;
