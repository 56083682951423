import React from "react";
import AllPlanIncludes from "../../reuseable/AllPlanIncludes";
import Faq from "../../reuseable/Faq";
import NewBanner from "../../reuseable/NewBanner";
import Testimonails from "../../reuseable/Testimonails";
import TrustedIndus from "../../reuseable/TrustedIndus";
import WillingForHelp from "../../reuseable/WillingForHelp";

const BestWebHostingKarachi = () => {
  const allplans = {
    Heading: "Secure, Reliable And Blazing-Fast",
    SubHeading: "Hosting Solutions Focused on Cost-Effective Reliability",
    Box: [
      {
        heading: "99.9% Uptime Guarantee",
        subHeading: "(24/7 Performance Monitoring)",
        icon: "icon ws-uptime",
      },
      {
        heading: "Swift Upgrading",
        subHeading: "( CPU, RAM, I/O etc.)",
        icon: "icon ws-chain",
      },
      {
        heading: "Easy To Use",
        subHeading: "(Hosting Panel)",
        icon: "icon ws-easy",
      },
      {
        heading: "Proactive Support",
        subHeading: "(Phone, Chat, Tickets)",
        icon: "icon ws-proactive",
      },
      {
        heading: "Personalized Email",
        subHeading: "(Example: info@yourdomain.com)",
        icon: "icon ws-email-b",
      },
      {
        heading: "Easy Management",
        subHeading: "(Database management from cPanel)",
        icon: "icon ws-disk-b",
      },
    ],
  };
  const faqData = {
    heading: "Frequently Asked Questions",
    subHeading: "Dedicated to Helping You Succeed!",
    faqs: [
      {
        id: 0,
        title: "What is web hosting and why do I need it?",
        desc: "Web hosting is a service that is provided by web hosting companies. It is necessary to make your website available on the internet. You will be provided with server space where your website would be stored in order to make it accessible to your visitors.",
        faqId: "FAQ1",
        dataBsTarget: "#faqOne",
        ariaControls: "faqOne",
        ariaLabelledby: "FAQ1",
        faqParaghId: "faqOne",
      },
      {
        id: 1,
        title: "Can I transfer my own domain name?",
        desc: "If you have a registered domain name, or have a website that is currently hosted with another web host, you can transfer it to WebSouls web hosting without much hassle or any extra charges.",
        faqId: "FAQ2",
        dataBsTarget: "#faqTwo",
        ariaControls: "faqTwo",
        ariaLabelledby: "FAQ2",
        faqParaghId: "faqTwo",
      },
      {
        id: 2,
        title: "What is cPanel in web hosting?",
        desc: "cPanel is a web hosting control panel that allows a website owner to easily manage their website. It allows the users to create websites using any content management system of their choice (WordPress, Drupal, Joomla and Magento).",
        faqId: "FAQ3",
        dataBsTarget: "#faqThree",
        ariaControls: "faqThree",
        ariaLabelledby: "FAQ3",
        faqParaghId: "faqThree",
      },
      {
        id: 3,
        title: "Would I be able to upgrade my package later?",
        desc: "Yes, you can always upgrade your package through a quick process and there’s no downtime. If you register more domains or need to use your account for hosting more websites, it’s quite easy to upgrade your package and shift to a hosting plan that suits your requirements.",
        faqId: "FAQ4",
        dataBsTarget: "#faqFour",
        ariaControls: "faqFour",
        ariaLabelledby: "FAQ4",
        faqParaghId: "faqFour",
      },
    ],
  };
  const WillingToHelpData = {
    heading: "Proactive Customer Support!",
    subHeading:
      "Our in-house team of professional technical support experts is always ready, willing and able to help your business grow online!",
    buttonOne: "Call: +92 423 523 8871 - 73",
    buttonTwo: "Email Us",
    image: "assests/img/Net-Ops-Engineer-Haris-Mukhtar.png",
  };
  const NewBannerData = {
    Tprice: "Rs 870",
    Dprice: "Rs 555",
  };
  return (
    <>
      <NewBanner Data={NewBannerData} />
      <TrustedIndus />
      <AllPlanIncludes plansData={allplans} />
      <Testimonails />
      <Faq Data={faqData} />
      <WillingForHelp helpData={WillingToHelpData} />
    </>
  );
};

export default BestWebHostingKarachi;
