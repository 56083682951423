import React from 'react'

const AllPlanIncludes = ({plansData}) => {
  return (
    <section style={{paddingBottom: 100}} className="all_plans pt-100 sm-p-0">
    <div className="container">
      <h2 className="first-heading">{plansData.Heading}</h2>
      <h5 className="second-heading"> {plansData.SubHeading}</h5>
      <hr className="divider scnd_divider mx-auto  mb-5" />
      <div className="row">
        {
          plansData.Box.map((items)=> (
            <React.Fragment key={items.id}>
            <div className="col-sm-12 col-md-6 col-lg-4">
            <div className="plans_types">
              <div className="icon_box">
                <div className={items.icon} />
              </div>
              <div className="align_data">
                <h4>{items.heading}</h4>
                <p>{items.subHeading}</p>
              </div>
            </div>
          </div>
          </React.Fragment>
          ))
        }

      </div>
    </div>
  </section>
  )
}

export default React.memo(AllPlanIncludes)