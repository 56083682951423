import React, {useEffect} from "react";

const Faq = () => {

  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);
  const faqData = {
    heading: "Frequently Asked Questions",
    subHeading: "Dedicated to Helping You Succeed!",
    faqs: [
      {
        id: 0,
        title: "What is Shared Hosting?",
        desc: "Shared web hosting is an affordable hosting plan that’s perfect for small businesses and start-ups. Multiple website owners share resources of a single server to make their sites available on the internet. WebSouls offers various shared hosting packages that are light on the wallet and can fulfil all your website needs.",
        faqId: "FAQ1",
        dataBsTarget: "#faqOne",
        ariaControls: "faqOne",
        ariaLabelledby: "FAQ1",
        faqParaghId: "faqOne",
      },
      {
        id: 1,
        title:
          "Do I need to have prior technical knowledge for shared hosting?",
        desc: "No prior technical knowledge is required for shared hosting. Just after you’ve ordered your service, we’d send you your login details as well as all the necessary information required to start your hosting journey.  In addition to this, we also offer quality support through live chat, email, and phone, so you can always hit us up in case of any questions.",
        faqId: "FAQ2",
        dataBsTarget: "#faqTwo",
        ariaControls: "faqTwo",
        ariaLabelledby: "FAQ2",
        faqParaghId: "faqTwo",
      },
      {
        id: 2,
        title: "How does shared hosting differ from business hosting?",
        desc: "The key difference between shared and businesses hosting is the number of clients per server. Apart from this, there are also certain limitations, such as the hourly emails. However, your website won’t be affected in any other way if you decide to go for shared server space.",
        faqId: "FAQ3",
        dataBsTarget: "#faqThree",
        ariaControls: "faqThree",
        ariaLabelledby: "FAQ3",
        faqParaghId: "faqThree",
      },
      {
        id: 3,
        title: "Would I be able to upgrade my package later?",
        desc: "Yes, you can always upgrade your package through a quick process and there’s no downtime. If you register more domains or need to use your account for hosting more websites, it’s quite easy to upgrade your package from Premium to Standard and to Professional hosting plan.",
        faqId: "FAQ4",
        dataBsTarget: "#faqFour",
        ariaControls: "faqFour",
        ariaLabelledby: "FAQ4",
        faqParaghId: "faqFour",
      },
    ],
  };

  const bussFaqData = {
    heading: "Frequently Asked Questions",
    subHeading: "Dedicated to Helping You Succeed!",
    faqs: [
      {
        id: 0,
        title: "What is Unlimited hosting?",
        desc: "Unlimited hosting means that there won’t be any restrictions on amount of bandwidth, databases, FTP, and email IDs. This plan is perfect for you if you want to use your hosting account without worrying about overloading the server or affecting other users.",
        faqId: "FAQ1",
        dataBsTarget: "#faqOne",
        ariaControls: "faqOne",
        ariaLabelledby: "FAQ1",
        faqParaghId: "faqOne",
        class: "tab-pane fade show active",
        buttonClass: "nav-link active",
      },
      {
        id: 1,
        title: "How can I manage my website with unlimited web hosting?",
        desc: "Our unlimited web hosting service comes with cPanel, so tasks such as managing your databases or adding/removing email accounts would be completely effortless.",
        faqId: "FAQ2",
        dataBsTarget: "#faqTwo",
        ariaControls: "faqTwo",
        ariaLabelledby: "FAQ2",
        faqParaghId: "faqTwo",
        class: "tab-pane fade show",
        buttonClass: "nav-link",
      },
      {
        id: 2,
        title: "How many websites can I host on unlimited hosting plan?",
        desc: "On an Ultimate unlimited web hosting plan, you’d be able to host up to 5 domains. However, if you upgrade to Ultimate Supreme or Corporate, you can host multiple domain names and unlimited websites.",
        faqId: "FAQ3",
        dataBsTarget: "#faqThree",
        ariaControls: "faqThree",
        ariaLabelledby: "FAQ3",
        faqParaghId: "faqThree",
        class: "tab-pane fade show",
        buttonClass: "nav-link",
      },
      {
        id: 3,
        title: "Can I subdivide and resell my unlimited hosting package?",
        desc: "No. While you can host multiple websites or domain names on an unlimited hosting plan, you cannot divide it and resell. If you want to resell to your customers then we’d recommend you to opt for our Reseller Hosting plan.",
        faqId: "FAQ4",
        dataBsTarget: "#faqFour",
        ariaControls: "faqFour",
        ariaLabelledby: "FAQ4",
        faqParaghId: "faqFour",
        class: "tab-pane fade show",
        buttonClass: "nav-link",
      },
    ],
  };

  const WordpFaqData = {
    heading: "Frequently Asked Questions",
    subHeading: "Dedicated to Helping You Succeed!",
    faqs: [
      {
        id: 0,
        title: "What is WordPress hosting?",
        desc: "A website creation software that powers up to 25% of the internet, WordPress provides easy to use templates for websites that are both beautiful and powerful. WordPress hosting is often the best bid for those who need a hosting solution that’s completely hassle-free and doesn’t require one to have much technical expertise.",
        faqId: "FAQ1",
        dataBsTarget: "#faqOne",
        ariaControls: "faqOne",
        ariaLabelledby: "FAQ1",
        faqParaghId: "faqOne",
        class: "tab-pane fade show active",
        buttonClass: "nav-link active",
      },
      {
        id: 1,
        title: "Can I transfer my WordPress hosting to WebSouls?",
        desc: "Yes. Our team of experts can migrate your site free of charge and get you ready to go live instantly.",
        faqId: "FAQ2",
        dataBsTarget: "#faqTwo",
        ariaControls: "faqTwo",
        ariaLabelledby: "FAQ2",
        faqParaghId: "faqTwo",
        class: "tab-pane fade show",
        buttonClass: "nav-link",
      },
      {
        id: 2,
        title:
          "How do I get started after purchasing a WordPress hosting package?",
        desc: "We provide hosting plans that are connected to the latest version of WordPress. So, once you’ve purchased a WordPress hosting package from us, you can just log in and start creating a site from your control panel. In case of any questions or queries, don’t hesitate to contact our team at any hour during the day.",
        faqId: "FAQ3",
        dataBsTarget: "#faqThree",
        ariaControls: "faqThree",
        ariaLabelledby: "FAQ3",
        faqParaghId: "faqThree",
        class: "tab-pane fade show",
        buttonClass: "nav-link",
      },
      {
        id: 3,
        title: "Would I be required to set up my own database?",
        desc: "No, it’s a part of process. After purchasing a WordPress hosting package from us you can start creating your site right away while we manage the technical stuff.",
        faqId: "FAQ4",
        dataBsTarget: "#faqFour",
        ariaControls: "faqFour",
        ariaLabelledby: "FAQ4",
        faqParaghId: "faqFour",
        class: "tab-pane fade show",
        buttonClass: "nav-link",
      },
    ],
  };

  const resslerFaqData = {
    heading: "Frequently Asked Questions",
    subHeading: "Dedicated to Helping You Succeed!",
    faqs: [
      {
        id: 0,
        title: "What is Reseller hosting?",
        desc: "With reseller web hosting we enable our customers to own a web hosting business without having to deal with any kind of server management procedures. You can also host multiple websites if you buy our reseller hosting package.",
        faqId: "FAQ1",
        dataBsTarget: "#faqOne",
        ariaControls: "faqOne",
        ariaLabelledby: "FAQ1",
        faqParaghId: "faqOne",
        class: "tab-pane fade show active",
        buttonClass: "nav-link active",
      },
      {
        id: 1,
        title:
          "Who will provide customer service to my customers with reseller hosting?",
        desc: "As a reseller who has purchased a reseller hosting plan, you’d provide your customers with your own outclass support services.",
        faqId: "FAQ2",
        dataBsTarget: "#faqTwo",
        ariaControls: "faqTwo",
        ariaLabelledby: "FAQ2",
        faqParaghId: "faqTwo",
        class: "tab-pane fade show",
        buttonClass: "nav-link",
      },
      {
        id: 2,
        title: "Can my customer trace you as my host?",
        desc: "Most probably not, because we try and stay as anonymous as possible. So, if your customer tries to track your services, he’d see only your name.",
        faqId: "FAQ3",
        dataBsTarget: "#faqThree",
        ariaControls: "faqThree",
        ariaLabelledby: "FAQ3",
        faqParaghId: "faqThree",
        class: "tab-pane fade show",
        buttonClass: "nav-link",
      },
      {
        id: 3,
        title: "Can I upgrade my accounts after purchasing?",
        desc: "Of course, you can! The process of upgrading an account from one reseller package to other is quite an easy one and you can start enjoying the benefits as soon as the payment is cleared.",
        faqId: "FAQ4",
        dataBsTarget: "#faqFour",
        ariaControls: "faqFour",
        ariaLabelledby: "FAQ4",
        faqParaghId: "faqFour",
        class: "tab-pane fade show",
        buttonClass: "nav-link",
      },
    ],
  };

  const domainPricingFaq = {
    heading: "Frequently Asked Questions",
    subHeading: "Dedicated to Helping You Succeed!",
    faqs: [
      {
        id: 0,
        title: "Why do I need to register a domain?",
        desc: "Choosing a domain name and registering is often the very first step when it comes to creating your own website. Your domain name is the address that will help your visitors find you, make sure it’s perfect and resonates with your business.",
        faqId: "FAQ1",
        dataBsTarget: "#faqOne",
        ariaControls: "faqOne",
        ariaLabelledby: "FAQ1",
        faqParaghId: "faqOne",
      },
      {
        id: 1,
        title: "What is the best way to choose a domain name?",
        desc: "Go for domain names that are easy to remember and preferably the ones that are short. It’s also a great idea to choose a domain name that is linked with your business and company goals. You can also use keywords in your domain name to increase the probability of your site appearing in Search Engine Result Pages. A domain name can include letters, hyphens, and numbers, but not special characters.",
        faqId: "FAQ2",
        dataBsTarget: "#faqTwo",
        ariaControls: "faqTwo",
        ariaLabelledby: "FAQ2",
        faqParaghId: "faqTwo",
      },
      {
        id: 2,
        title: "Is domain name privacy and protection worth it?",
        desc: "Yes, it is, if you don’t want your personal contact information to be found in the public WHOIS directory. Domain privacy and protection can also help protect you from identity theft and spam.",
        faqId: "FAQ3",
        dataBsTarget: "#faqThree",
        ariaControls: "faqThree",
        ariaLabelledby: "FAQ3",
        faqParaghId: "faqThree",
      },
      {
        id: 3,
        title: "Can I buy any domain extension?",
        desc: "Some domain extensions have restrictions on them and you cannot purchase them until you meet a certain criterion or have the required authorization, such as in case of .gov and .edu. Other than that, most domain extensions are available to anyone for a purchase.",
        faqId: "FAQ4",
        dataBsTarget: "#faqFour",
        ariaControls: "faqFour",
        ariaLabelledby: "FAQ4",
        faqParaghId: "faqFour",
      },
    ],
  };

  const PkdoaminFaqData = {
    heading: "Frequently Asked Questions",
    subHeading: "Dedicated to Helping You Succeed!",
    faqs: [
      {
        id: 0,
        title: "How do I know if my domain name is available?",
        desc: "It’s really simple to find out. Just enter your ideal domain name in the Search Box and click Search. If the domain name is available you can purchase it instantly, or we can help you search for another unique domain name that is equally perfect. Moreover, you also have the option of choosing from a variety of extensions for your domain name.",
        faqId: "FAQ1",
        dataBsTarget: "#faqOne",
        ariaControls: "faqOne",
        ariaLabelledby: "FAQ1",
        faqParaghId: "faqOne",
      },
      {
        id: 1,
        title:
          "How many minimum characters are required to register a .pk domain?",
        desc: "While other domain extensions could be registered with even a single-character domain name, in order to register a .pk domain, you’d need at least 4 minimum characters.",
        faqId: "FAQ2",
        dataBsTarget: "#faqTwo",
        ariaControls: "faqTwo",
        ariaLabelledby: "FAQ2",
        faqParaghId: "faqTwo",
      },
      {
        id: 2,
        title: "Is the registration of an edu.pk domain similar to other TLDs?",
        desc: "No, it isn’t. For this, you’d need to fulfil a certain criterion, e.g. your institute must be registered by the government. For further details on an edu.pk domain, you can contact our pre-sales team.",
        faqId: "FAQ3",
        dataBsTarget: "#faqThree",
        ariaControls: "faqThree",
        ariaLabelledby: "FAQ3",
        faqParaghId: "faqThree",
      },
      {
        id: 3,
        title: "What is domain extension?",
        desc: "These are the letters that appear after your domain name, such as .pk, .com, .edu, .gov, etc. These extensions usually represent a word, for instance, .pk is short for Pakistan.",
        faqId: "FAQ4",
        dataBsTarget: "#faqFour",
        ariaControls: "faqFour",
        ariaLabelledby: "FAQ4",
        faqParaghId: "faqFour",
      },
    ],
  };

  const AEFaqData = {
    heading: "Frequently Asked Questions",
    subHeading: "Dedicated to Helping You Succeed!",
    faqs: [
      {
        id: 0,
        title: "What is a domain name?",
        desc: "A domain name is an address that allows your visitors to find you on web. While choosing a domain name, make sure it’s easy to remember, catchy, and reflects your business and company goals.",
        faqId: "FAQ1",
        dataBsTarget: "#faqOne",
        ariaControls: "faqOne",
        ariaLabelledby: "FAQ1",
        faqParaghId: "faqOne",
      },
      {
        id: 1,
        title: "Which domain extensions are available?",
        desc: "With us you can find the perfect domain name with a variety of extensions to choose from, including .com, .pk, .edu, .gov, .net, .us, .biz, .info, .ae, .co.uk, and many more.",
        faqId: "FAQ2",
        dataBsTarget: "#faqTwo",
        ariaControls: "faqTwo",
        ariaLabelledby: "FAQ2",
        faqParaghId: "faqTwo",
      },
      {
        id: 2,
        title: "How is auto-renewal helpful?",
        desc: "Our auto-renewal service protects your domain from getting accidentally lost or expiring. We make sure that your domain names are always protected.",
        faqId: "FAQ3",
        dataBsTarget: "#faqThree",
        ariaControls: "faqThree",
        ariaLabelledby: "FAQ3",
        faqParaghId: "faqThree",
      },
      {
        id: 3,
        title: "Can I transfer my domain name to WebSouls?",
        desc: "Of course, you can! We offer free domain transfer facility for all our customers. Even if you are new to WebSouls, just contact us via phone, email, or live chat and allow our dedicated customer support executives to help you out. You can also visit us at one of our offices in Lahore or Karachi.",
        faqId: "FAQ4",
        dataBsTarget: "#faqFour",
        ariaControls: "faqFour",
        ariaLabelledby: "FAQ4",
        faqParaghId: "faqFour",
      },
    ],
  };

  const dedicatedFaqData = {
    heading: "Frequently Asked Questions",
    subHeading: "Dedicated to Helping You Succeed!",
    faqs: [
      {
        id: 0,
        title: "What is dedicated server hosting?",
        desc: "With a dedicated server hosting package, you can have exclusive access to the server and all its resources. In a sense, you’d have full control of your server and can tailor it to fit your business requirements. In addition to this, dedicated server hosting provides you with an increasingly high level of performance, reliability, security, and speed.",
        faqId: "FAQ1",
        dataBsTarget: "#faqOne",
        ariaControls: "faqOne",
        ariaLabelledby: "FAQ1",
        faqParaghId: "faqOne",
      },
      {
        id: 1,
        title:
          "Can I manage my dedicated server through a web-based control panel?",
        desc: "Yes, you can. We are the official partner of cPanel in Pakistan and offer FREE installation and a complete FREE initial server setup, so that you can enjoy complete GUI access of their control panel.",
        faqId: "FAQ2",
        dataBsTarget: "#faqTwo",
        ariaControls: "faqTwo",
        ariaLabelledby: "FAQ2",
        faqParaghId: "faqTwo",
      },
      {
        id: 2,
        title: "Can I upgrade my VPS hosting plan to dedicated server?",
        desc: "Yes. If you have a VPS server account at WebSouls, it’s quite easy to upgrade it to a dedicated server. Our team will help you through the process and we will migrate all your data to your new dedicated server.",
        faqId: "FAQ3",
        dataBsTarget: "#faqThree",
        ariaControls: "faqThree",
        ariaLabelledby: "FAQ3",
        faqParaghId: "faqThree",
      },
      {
        id: 3,
        title: "Would I be able to upgrade my package later?",
        desc: "Yes, you can always upgrade your package through a quick process and there’s no downtime. If you register more domains or need to use your account for hosting more websites, it’s quite easy to upgrade your package from Premium to Standard and to Professional hosting plan.",
        faqId: "FAQ4",
        dataBsTarget: "#faqFour",
        ariaControls: "faqFour",
        ariaLabelledby: "FAQ4",
        faqParaghId: "faqFour",
      },
    ],
  };

  const VPSfaqData = {
    heading: "Frequently Asked Questions",
    subHeading: "Dedicated to Helping You Succeed!",
    faqs: [
      {
        id: 0,
        title:
          "How is managed VPS different from unmanaged VPS? Which one should I get?",
        desc: "For unmanaged VPS, you’d need to have an advanced level of technical knowledge, as well as a basic understanding of Linux, since you’d be configuring and updating your VPS hosting yourself. On the other hand, managed hosting is a piece of cake for you even if you aren’t familiar with the tech stuff, because the WebSouls team would be doing all the heavy lifting for you.",
        faqId: "FAQ1",
        dataBsTarget: "#faqOne",
        ariaControls: "faqOne",
        ariaLabelledby: "FAQ1",
        faqParaghId: "faqOne",
      },
      {
        id: 1,
        title: "Do I need to have prior technical knowledge for VPS hosting?",
        desc: "Yes, technical knowledge is required to manage a VPS server. Since this hosting plan offers more power and flexibility than the standard shared hosting, its server management is equally complex. It must be noted here that all our VPS hosting packages are unmanaged by default, however, you can go for a managed VPS hosting plan for more ease and simplicity.",
        faqId: "FAQ2",
        dataBsTarget: "#faqTwo",
        ariaControls: "faqTwo",
        ariaLabelledby: "FAQ2",
        faqParaghId: "faqTwo",
      },
      {
        id: 2,
        title: "Do you provide Windows VPS hosting?",
        desc: "Yes! we do offer Windows VPS hosting with 4 GB to 32 GB RAM. Also, when it comes to WordPress website management, you can also add Plesk Obsidian Webhost Edition to create and manage your websites with ease.",
        faqId: "FAQ3",
        dataBsTarget: "#faqThree",
        ariaControls: "faqThree",
        ariaLabelledby: "FAQ3",
        faqParaghId: "faqThree",
      },
      {
        id: 3,
        title: "Does a VPS hosting plan include backups?",
        desc: "Yes, we offer automated weekly backup as one of our Bundle Boosters for VPS hosting plans. Plus, you can also create a backup on demand whenever needed.",
        faqId: "FAQ4",
        dataBsTarget: "#faqFour",
        ariaControls: "faqFour",
        ariaLabelledby: "FAQ4",
        faqParaghId: "faqFour",
      },
    ],
  };

  const PkBasefaqData = {
    heading: "Frequently Asked Questions",
    subHeading: "Dedicated to Helping You Succeed!",
    faqs: [
      {
        id: 0,
        title:
          "How is managed VPS different from unmanaged VPS? Which one should I get?",
        desc: "For unmanaged VPS, you’d need to have an advanced level of technical knowledge, as well as a basic understanding of Linux, since you’d be configuring and updating your VPS hosting yourself. On the other hand, managed hosting is a piece of cake for you even if you aren’t familiar with the tech stuff, because the WebSouls team would be doing all the heavy lifting for you.",
        faqId: "FAQ1",
        dataBsTarget: "#faqOne",
        ariaControls: "faqOne",
        ariaLabelledby: "FAQ1",
        faqParaghId: "faqOne",
      },
      {
        id: 1,
        title: "Do I need to have prior technical knowledge for VPS hosting?",
        desc: "No prior technical knowledge is required for shared hosting. Just after you’ve ordered your service, we’d send you your login details as well as all the necessary information required to start your hosting journey.  In addition to this, we also offer quality support through live chat, email, and phone, so you can always hit us up in case of any questions.",
        faqId: "FAQ2",
        dataBsTarget: "#faqTwo",
        ariaControls: "faqTwo",
        ariaLabelledby: "FAQ2",
        faqParaghId: "faqTwo",
      },
      {
        id: 2,
        title: "Do you provide Windows VPS hosting?",
        desc: "Yes! we do offer Windows VPS hosting with 4 GB to 32 GB RAM. Also, when it comes to WordPress website management, you can also add Plesk Obsidian Webhost Edition to create and manage your websites with ease.",
        faqId: "FAQ3",
        dataBsTarget: "#faqThree",
        ariaControls: "faqThree",
        ariaLabelledby: "FAQ3",
        faqParaghId: "faqThree",
      },
      {
        id: 3,
        title: "Does a VPS hosting plan include backups?",
        desc: "Yes, we offer automated weekly backup as one of our Bundle Boosters for VPS hosting plans. Plus, you can also create a backup on demand whenever needed.",
        faqId: "FAQ4",
        dataBsTarget: "#faqFour",
        ariaControls: "faqFour",
        ariaLabelledby: "FAQ4",
        faqParaghId: "faqFour",
      },
    ],
  };

  const SSDVpsfaqData = {
    heading: "Frequently Asked Questions",
    subHeading: "Dedicated to Helping You Succeed!",
    faqs: [
      {
        id: 0,
        title:
          "How is managed VPS different from unmanaged VPS? Which one should I get?",
        desc: "For unmanaged VPS, you’d need to have an advanced level of technical knowledge, as well as a basic understanding of Linux, since you’d be configuring and updating your VPS hosting yourself. On the other hand, managed hosting is a piece of cake for you even if you aren’t familiar with the tech stuff, because the WebSouls team would be doing all the heavy lifting for you.",
        faqId: "FAQ1",
        dataBsTarget: "#faqOne",
        ariaControls: "faqOne",
        ariaLabelledby: "FAQ1",
        faqParaghId: "faqOne",
      },
      {
        id: 1,
        title: "Do I need to have prior technical knowledge for VPS hosting?",
        desc: "Yes, technical knowledge is required to manage a VPS server. Since this hosting plan offers more power and flexibility than the standard shared hosting, its server management is equally complex. It must be noted here that all our VPS hosting packages are unmanaged by default, however, you can go for a managed VPS hosting plan for more ease and simplicity.",
        faqId: "FAQ2",
        dataBsTarget: "#faqTwo",
        ariaControls: "faqTwo",
        ariaLabelledby: "FAQ2",
        faqParaghId: "faqTwo",
      },
      {
        id: 2,
        title: "How is SSD VPS different from simple VPS hosting?",
        desc: "As compared to a simple VPS hosting plan, SSD VPS offers increasingly remarkable speed so that your website gets a quick load time, better user experience, and ends up ranking high on Search Engine Result Pages.",
        faqId: "FAQ3",
        dataBsTarget: "#faqThree",
        ariaControls: "faqThree",
        ariaLabelledby: "FAQ3",
        faqParaghId: "faqThree",
      },
      {
        id: 3,
        title: "Does a VPS hosting plan include backups?",
        desc: "Yes, we offer automated weekly backup as one of our Bundle Boosters for VPS hosting plans. Plus, you can also create a backup on demand whenever needed.",
        faqId: "FAQ4",
        dataBsTarget: "#faqFour",
        ariaControls: "faqFour",
        ariaLabelledby: "FAQ4",
        faqParaghId: "faqFour",
      },
    ],
  };

  return (
    <section className="faq_tabs">
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12">
            <ul
              className="nav-tabs faq_tabs nav pt-5 pb-5"
              id="pills-tab"
              role="tablist"
            >
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link active"
                  id="pills-sharedhosting-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-sharedhosting"
                  type="button"
                  role="tab"
                  aria-controls="pills-sharedhosting"
                  aria-selected="true"
                >
                  Shared Hosting
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="pills-businesshosting-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-businesshosting"
                  type="button"
                  role="tab"
                  aria-controls="pills-businesshosting"
                  aria-selected="false"
                >
                  Business Hosting
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="pills-wordpresshosting-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-wordpresshosting"
                  type="button"
                  role="tab"
                  aria-controls="pills-wordpresshosting"
                  aria-selected="false"
                >
                  Wordpress Hosting
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="pills-resellerhosting-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-resellerhosting"
                  type="button"
                  role="tab"
                  aria-controls="pills-resellerhosting"
                  aria-selected="false"
                >
                  Reseller Hosting
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="pills-domainpricing-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-domainpricing"
                  type="button"
                  role="tab"
                  aria-controls="pills-domainpricing"
                  aria-selected="false"
                >
                  domainpricing
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="pills-pkdomain-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-pkdomain"
                  type="button"
                  role="tab"
                  aria-controls="pills-pkdomain"
                  aria-selected="false"
                >
                  PK Domain
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="pills-aedomain-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-aedomain"
                  type="button"
                  role="tab"
                  aria-controls="pills-aedomain"
                  aria-selected="false"
                >
                  AE Domain
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="pills-dedicatedserver-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-dedicatedserver"
                  type="button"
                  role="tab"
                  aria-controls="pills-dedicatedserver"
                  aria-selected="false"
                >
                  Dedicated Server
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="pills-vps-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-vps"
                  type="button"
                  role="tab"
                  aria-controls="pills-vps"
                  aria-selected="false"
                >
                  VPS
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="pills-pkvps-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-pkvps"
                  type="button"
                  role="tab"
                  aria-controls="pills-pkvps"
                  aria-selected="false"
                >
                  Pak Based VPS
                </button>
              </li>
              <li className="nav-item" role="presentation">
                <button
                  className="nav-link"
                  id="pills-ssdvps-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#pills-ssdvps"
                  type="button"
                  role="tab"
                  aria-controls="pills-ssdvps"
                  aria-selected="false"
                >
                  SSD VPS
                </button>
              </li>
            </ul>
          </div>
        </div>
      </div>

      <div className="tab-content pt-0" id="pills-tabContent">
        <div
          className="tab-pane fade show active"
          id="pills-sharedhosting"
          role="tabpanel"
          aria-labelledby="pills-sharedhosting-tab"
        >
          <section className="frequently pt-0">
            <div className="container">
              <div className="satisfied_customers_faq pt-5 pb-5 text-left">
                <p>
                  Whatever your question, query, or concern may be, we’ve got
                  all the answers!
                </p>
              </div>
              <div className="question_section border-top">
                <div className="row">
                  <div className="sec-faq-accor">
                    <div className="accordion" id="accordionExample">
                      {faqData.faqs.map((el) => (
                        <div className="accordion-item">
                          <h2 className="accordion-header" id={el.faqId}>
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={el.dataBsTarget}
                              aria-expanded="false"
                              aria-controls={el.ariaControls}
                            >
                              {el.title}
                            </button>
                          </h2>
                          <div
                            id={el.faqParaghId}
                            className="accordion-collapse collapse"
                            aria-labelledby={el.ariaLabelledby}
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              <p>{el.desc}</p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div
          className="tab-pane fade"
          id="pills-businesshosting"
          role="tabpanel"
          aria-labelledby="pills-businesshosting-tab"
        >
          <section className="frequently pt-0">
            <div className="container">
              <div className="satisfied_customers_faq pt-5 pb-5 text-left">
                <p>
                  Whatever your question, query, or concern may be, we’ve got
                  all the answers!
                </p>
              </div>
              <div className="question_section border-top">
                <div className="row">
                  <div className="sec-faq-accor">
                    <div className="accordion" id="accordionExample">
                      {bussFaqData.faqs.map((el) => (
                        <div className="accordion-item">
                          <h2 className="accordion-header" id={el.faqId}>
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={el.dataBsTarget}
                              aria-expanded="false"
                              aria-controls={el.ariaControls}
                            >
                              {el.title}
                            </button>
                          </h2>
                          <div
                            id={el.faqParaghId}
                            className="accordion-collapse collapse"
                            aria-labelledby={el.ariaLabelledby}
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              <p>{el.desc}</p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div
          className="tab-pane fade"
          id="pills-wordpresshosting"
          role="tabpanel"
          aria-labelledby="pills-wordpresshosting-tab"
        >
          <section className="frequently pt-0">
            <div className="container">
              <div className="satisfied_customers_faq pt-5 pb-5 text-left">
                <p>
                  Whatever your question, query, or concern may be, we’ve got
                  all the answers!
                </p>
              </div>
              <div className="question_section border-top">
                <div className="row">
                  <div className="sec-faq-accor">
                    <div className="accordion" id="accordionExample">
                      {WordpFaqData.faqs.map((el) => (
                        <div className="accordion-item">
                          <h2 className="accordion-header" id={el.faqId}>
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={el.dataBsTarget}
                              aria-expanded="false"
                              aria-controls={el.ariaControls}
                            >
                              {el.title}
                            </button>
                          </h2>
                          <div
                            id={el.faqParaghId}
                            className="accordion-collapse collapse"
                            aria-labelledby={el.ariaLabelledby}
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              <p>{el.desc}</p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div
          className="tab-pane fade"
          id="pills-resellerhosting"
          role="tabpanel"
          aria-labelledby="pills-resellerhosting-tab"
        >
          <section className="frequently pt-0">
            <div className="container">
              <div className="satisfied_customers_faq pt-5 pb-5 text-left">
                <p>
                  Whatever your question, query, or concern may be, we’ve got
                  all the answers!
                </p>
              </div>
              <div className="question_section border-top">
                <div className="row">
                  <div className="sec-faq-accor">
                    <div className="accordion" id="accordionExample">
                      {resslerFaqData.faqs.map((el) => (
                        <div className="accordion-item">
                          <h2 className="accordion-header" id={el.faqId}>
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={el.dataBsTarget}
                              aria-expanded="false"
                              aria-controls={el.ariaControls}
                            >
                              {el.title}
                            </button>
                          </h2>
                          <div
                            id={el.faqParaghId}
                            className="accordion-collapse collapse"
                            aria-labelledby={el.ariaLabelledby}
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              <p>{el.desc}</p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div
          className="tab-pane fade"
          id="pills-domainpricing"
          role="tabpanel"
          aria-labelledby="pills-domainpricing-tab"
        >
          <section className="frequently pt-0">
            <div className="container">
              <div className="satisfied_customers_faq pt-5 pb-5 text-left">
                <p>
                  Whatever your question, query, or concern may be, we’ve got
                  all the answers!
                </p>
              </div>
              <div className="question_section border-top">
                <div className="row">
                  <div className="sec-faq-accor">
                    <div className="accordion" id="accordionExample">
                      {domainPricingFaq.faqs.map((el) => (
                        <div className="accordion-item">
                          <h2 className="accordion-header" id={el.faqId}>
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={el.dataBsTarget}
                              aria-expanded="false"
                              aria-controls={el.ariaControls}
                            >
                              {el.title}
                            </button>
                          </h2>
                          <div
                            id={el.faqParaghId}
                            className="accordion-collapse collapse"
                            aria-labelledby={el.ariaLabelledby}
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              <p>{el.desc}</p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div
          className="tab-pane fade"
          id="pills-pkdomain"
          role="tabpanel"
          aria-labelledby="pills-pkdomain-tab"
        >
          <section className="frequently pt-0">
            <div className="container">
              <div className="satisfied_customers_faq pt-5 pb-5 text-left">
                <p>
                  Whatever your question, query, or concern may be, we’ve got
                  all the answers!
                </p>
              </div>
              <div className="question_section border-top">
                <div className="row">
                  <div className="sec-faq-accor">
                    <div className="accordion" id="accordionExample">
                      {PkdoaminFaqData.faqs.map((el) => (
                        <div className="accordion-item">
                          <h2 className="accordion-header" id={el.faqId}>
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={el.dataBsTarget}
                              aria-expanded="false"
                              aria-controls={el.ariaControls}
                            >
                              {el.title}
                            </button>
                          </h2>
                          <div
                            id={el.faqParaghId}
                            className="accordion-collapse collapse"
                            aria-labelledby={el.ariaLabelledby}
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              <p>{el.desc}</p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div
          className="tab-pane fade"
          id="pills-aedomain"
          role="tabpanel"
          aria-labelledby="pills-aedomain-tab"
        >
          <section className="frequently pt-0">
            <div className="container">
              <div className="satisfied_customers_faq pt-5 pb-5 text-left">
                <p>
                  Whatever your question, query, or concern may be, we’ve got
                  all the answers!
                </p>
              </div>
              <div className="question_section border-top">
                <div className="row">
                  <div className="sec-faq-accor">
                    <div className="accordion" id="accordionExample">
                      {AEFaqData.faqs.map((el) => (
                        <div className="accordion-item">
                          <h2 className="accordion-header" id={el.faqId}>
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={el.dataBsTarget}
                              aria-expanded="false"
                              aria-controls={el.ariaControls}
                            >
                              {el.title}
                            </button>
                          </h2>
                          <div
                            id={el.faqParaghId}
                            className="accordion-collapse collapse"
                            aria-labelledby={el.ariaLabelledby}
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              <p>{el.desc}</p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div
          className="tab-pane fade"
          id="pills-dedicatedserver"
          role="tabpanel"
          aria-labelledby="pills-dedicatedserver-tab"
        >
          <section className="frequently pt-0">
            <div className="container">
              <div className="satisfied_customers_faq pt-5 pb-5 text-left">
                <p>
                  Whatever your question, query, or concern may be, we’ve got
                  all the answers!
                </p>
              </div>
              <div className="question_section border-top">
                <div className="row">
                  <div className="sec-faq-accor">
                    <div className="accordion" id="accordionExample">
                      {dedicatedFaqData.faqs.map((el) => (
                        <div className="accordion-item">
                          <h2 className="accordion-header" id={el.faqId}>
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={el.dataBsTarget}
                              aria-expanded="false"
                              aria-controls={el.ariaControls}
                            >
                              {el.title}
                            </button>
                          </h2>
                          <div
                            id={el.faqParaghId}
                            className="accordion-collapse collapse"
                            aria-labelledby={el.ariaLabelledby}
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              <p>{el.desc}</p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div
          className="tab-pane fade"
          id="pills-vps"
          role="tabpanel"
          aria-labelledby="pills-vps-tab"
        >
          <section className="frequently pt-0">
            <div className="container">
              <div className="satisfied_customers_faq pt-5 pb-5 text-left">
                <p>
                  Whatever your question, query, or concern may be, we’ve got
                  all the answers!
                </p>
              </div>
              <div className="question_section border-top">
                <div className="row">
                  <div className="sec-faq-accor">
                    <div className="accordion" id="accordionExample">
                      {VPSfaqData.faqs.map((el) => (
                        <div className="accordion-item">
                          <h2 className="accordion-header" id={el.faqId}>
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={el.dataBsTarget}
                              aria-expanded="false"
                              aria-controls={el.ariaControls}
                            >
                              {el.title}
                            </button>
                          </h2>
                          <div
                            id={el.faqParaghId}
                            className="accordion-collapse collapse"
                            aria-labelledby={el.ariaLabelledby}
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              <p>{el.desc}</p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div
          className="tab-pane fade"
          id="pills-pkvps"
          role="tabpanel"
          aria-labelledby="pills-pkvps-tab"
        >
          <section className="frequently pt-0">
            <div className="container">
              <div className="satisfied_customers_faq pt-5 pb-5 text-left">
                <p>
                  Whatever your question, query, or concern may be, we’ve got
                  all the answers!
                </p>
              </div>
              <div className="question_section border-top">
                <div className="row">
                  <div className="sec-faq-accor">
                    <div className="accordion" id="accordionExample">
                      {PkBasefaqData.faqs.map((el) => (
                        <div className="accordion-item">
                          <h2 className="accordion-header" id={el.faqId}>
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={el.dataBsTarget}
                              aria-expanded="false"
                              aria-controls={el.ariaControls}
                            >
                              {el.title}
                            </button>
                          </h2>
                          <div
                            id={el.faqParaghId}
                            className="accordion-collapse collapse"
                            aria-labelledby={el.ariaLabelledby}
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              <p>{el.desc}</p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <div
          className="tab-pane fade"
          id="pills-ssdvps"
          role="tabpanel"
          aria-labelledby="pills-ssdvps-tab"
        >
          <section className="frequently pt-0">
            <div className="container">
              <div className="satisfied_customers_faq pt-5 pb-5 text-left">
                <p>
                  Whatever your question, query, or concern may be, we’ve got
                  all the answers!
                </p>
              </div>
              <div className="question_section border-top">
                <div className="row">
                  <div className="sec-faq-accor">
                    <div className="accordion" id="accordionExample">
                      {SSDVpsfaqData.faqs.map((el) => (
                        <div className="accordion-item">
                          <h2 className="accordion-header" id={el.faqId}>
                            <button
                              className="accordion-button collapsed"
                              type="button"
                              data-bs-toggle="collapse"
                              data-bs-target={el.dataBsTarget}
                              aria-expanded="false"
                              aria-controls={el.ariaControls}
                            >
                              {el.title}
                            </button>
                          </h2>
                          <div
                            id={el.faqParaghId}
                            className="accordion-collapse collapse"
                            aria-labelledby={el.ariaLabelledby}
                            data-bs-parent="#accordionExample"
                          >
                            <div className="accordion-body">
                              <p>{el.desc}</p>
                            </div>
                          </div>
                        </div>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
      </div>
    </section>
  );
};

export default Faq;
