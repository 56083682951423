import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { getPriceBundleBoster } from "../../../../../services/Helper.js";
import { useDispatch, useSelector } from "react-redux";

const ComparePlans = (props) => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { currentUrl } = useSelector((state) => state.currentUrl);
  const { comparePlansState } = props;
  const [getActivePromotions, setActivePromotions] = useState([]);
  //reducx states
  const { currencyName } = useSelector((state) => state.currencyName);
  const { billingCycleHosting } = useSelector(
    (state) => state.billingCycleHosting
  );

  const addingComparePlans = (e, item) => {
    e.preventDefault();

    dispatch({
      type: "addingCycle",
      payload: {
        packageId: item.pid,
        packageCycle: "annually",
      },
    });
    dispatch({
      type: "packageCart",
      payload: item,
    });

    //redirecting to the new page
    navigate("/free-domain");
  };
  return (
    <section className="sec-compare-plans d-none d-xs-none d-sm-none d-md-block d-lg-block ">
      <div className="container-fluid">
        <h2 className="text-center first-heading">Compare Our Plans </h2>
        <h5 className="text-center second-heading">
          Make Your Website a Safer Place Affordably
        </h5>
        <hr className="divider scnd_divider mx-auto" />
        {/* Responsive compare price plan starts*/}
        <div className="table-responsive">
          <table className="table text-center">
            <thead>
              <tr>
                <td />
                {comparePlansState.slice(0, 3)?.map((item) => (
                  <td key={item.id}>
                    <h3>{item?.name}</h3>
                    {currentUrl === "/free-pk-domain-with-hosting" ? (
                      <>
                        {getPriceBundleBoster(
                          item?.currency
                            ?.filter(
                              (fil) => fil?.currency === currencyName?.api_id
                            )
                            ?.map((pr) => pr["biennially"]),
                          item?.pid,
                          "biennially",
                          getActivePromotions,
                          currencyName?.prefix
                        )}
                      </>
                    ) : (
                      <>
                        {getPriceBundleBoster(
                          item?.currency
                            ?.filter(
                              (fil) => fil?.currency === currencyName?.api_id
                            )
                            ?.map((pr) => pr[billingCycleHosting]),
                          item?.pid,
                          billingCycleHosting,
                          getActivePromotions,
                          currencyName?.prefix
                        )}
                      </>
                    )}

                    <span className="packages_price">
                      <button
                        onClick={(e) => addingComparePlans(e, item)}
                        className="orderButton"
                        type="submit"
                      >
                        Order Now
                      </button>
                    </span>
                  </td>
                ))}
              </tr>
            </thead>

            <tbody>
              <tr>
                <td>No. OF ADDRESSES</td>
                <td>Unlimited</td>
                <td>Unlimited</td>
                <td>Unlimited</td>
              </tr>
              <tr>
                <td>WEB SPACE</td>
                <td>3GB</td>
                <td>8GB</td>
                <td>12GB</td>
              </tr>
              <tr>
                <td>WEB MAIL</td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
              </tr>
              <tr>
                <td>FORWARDING</td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
              </tr>
              <tr>
                <td>AUTO-RESPONDER</td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
              </tr>
              <tr>
                <td>VIRUS &amp; SPAM PROTECTION</td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
              </tr>
              <tr>
                <td>EMAIL NOTIFICATIONS</td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
              </tr>
              <tr>
                <td>MYSQL</td>
                <td>1</td>
                <td>3</td>
                <td>5</td>
              </tr>
              <tr>
                <td>DIRECT DATABASE ACCESS</td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
              </tr>
              <tr>
                <td>DATABASE BACKUP/RESTORE</td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
              </tr>
              <tr>
                <td>BILLING MANAGEMENT</td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
              </tr>
              <tr>
                <td>EXPIRY DATES OF ACCOUNTS</td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
              </tr>
              <tr>
                <td>INTERNAL TICKET SYSTEM</td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
              </tr>
              <tr>
                <td>INVOICE HISTORY</td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
              </tr>
              <tr>
                <td>AFFILIATE UPDATES</td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
              </tr>
              <tr>
                <td>NS MANAGEMENT</td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
              </tr>
              <tr>
                <td>ACCESS WITHOUT WWW</td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
              </tr>
              <tr>
                <td>SUB DOMAINS</td>
                <td>
                  <font>--</font>
                </td>
                <td>2</td>
                <td>3</td>
              </tr>
              <tr>
                <td>ADDONS</td>
                <td>
                  <font>--</font>
                </td>
                <td>2</td>
                <td>3</td>
              </tr>
              <tr>
                <td>PARK DOMAIN</td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
              </tr>
              <tr>
                <td>WORDPRESS</td>
                <td>
                  <font>
                    <strong>✗ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✗ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✗ </strong>
                  </font>
                </td>
              </tr>
              <tr>
                <td>JOOMLA</td>
                <td>
                  <font>
                    <strong>✗ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✗ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✗ </strong>
                  </font>
                </td>
              </tr>
              <tr>
                <td>DRUPAL</td>
                <td>
                  <font>
                    <strong>✗ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✗ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✗ </strong>
                  </font>
                </td>
              </tr>
              <tr>
                <td>24/7 EMAIL SUPPORT</td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
              </tr>
              <tr>
                <td>FILE MANAGER</td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
              </tr>
              <tr>
                <td>FORUMS</td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
              </tr>
              <tr>
                <td>BLOGS</td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
              </tr>
              <tr>
                <td>OSCOMMERCE</td>
                <td>
                  <font>
                    <strong>✗ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✗ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✗ </strong>
                  </font>
                </td>
              </tr>
              <tr>
                <td>ZENCART</td>
                <td>
                  <font>
                    <strong>✗ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✗ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✗ </strong>
                  </font>
                </td>
              </tr>
              <tr>
                <td>SITE STATISTICS</td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
              </tr>
              <tr>
                <td>CRON JOBS</td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
              </tr>
              <tr>
                <td>PHP</td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
              </tr>
              <tr>
                <td>JSP</td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
              </tr>
              <tr>
                <td>HTML</td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
              </tr>
              <tr>
                <td>XHTML</td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
              </tr>
              <tr>
                <td>FLASH</td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
              </tr>
              <tr>
                <td>PERL</td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
              </tr>
              <tr>
                <td>CGI</td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
              </tr>
              <tr>
                <td>JAVA</td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
                <td>
                  <font>
                    <strong>✓ </strong>
                  </font>
                </td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>
    </section>
  );
};

export default ComparePlans;
