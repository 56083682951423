import React from 'react'

const DomainTips = (props) => {
    const { Data } = props;
  return (
    <section  className="technical_specification pt-100 margin-b-0">
    <div className="container">
      <h2 className="first-heading">{Data?.Heading}</h2>
      <h5 className="second-heading">{Data?.subHeading}</h5>
      <hr className="divider scnd_divider mx-auto margin-b-0" />
      <div className="row">
        <ul>
          {Data?.box?.map((el) => (
            <li key={el.id} className="col-md-4">
              <div className="secure_server">
                <div className={el?.icon} />
                <h5>{el?.title}</h5>
                <p>{el?.peragraph}</p>
              </div>
            </li>
          ))}
        </ul>
      </div>
    </div>
  </section>
  )
}

export default React.memo(DomainTips)