import React from 'react'

const WhyItWoth = (props) => {
    const { Data } = props;
  return (
    <section className="worth-it-sec pt-100">
    <div className="container">
      <h2 className="text-center first-heading">{Data?.Heading}</h2>
      <h5 className="text-center second-heading">{Data?.subHeading}</h5>
      <hr className="divider scnd_divider mx-auto" />
      <div className="row">
        {Data?.box?.map((el) => (
          <React.Fragment key={el.id}>
          <div className="col-md-4 text-center padding">
            <div className="block-power">
              <div className={el?.icon} />
              <h3>{el?.heading}</h3>
              <p>{el?.subHeading}</p>
            </div>
          </div>
          </React.Fragment>
        ))}
      </div>
    </div>
  </section>
  )
}

export default React.memo(WhyItWoth);