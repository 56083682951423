import React from "react";

const WhyUsIcons = ({ Data }) => {
  return (
    <section className="worth-it-sec pt-100">
      <div className="container">
        <div className="row">
          {Data.box.map((el) => (
            <div className="col-md-4 text-center padding">
              <div
                className="block-power"
                style={{ backgroundColor: "#fff", border: "none" }}
              >
                <div className={el.icon} />
                <h3>{el.heading}</h3>
              </div>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default WhyUsIcons;
