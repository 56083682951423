import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Helmet } from "react-helmet";
import {
  GetSpotlightTldswithPrice,
  GetTldPriceNew,
  GetAllTlds,
} from "../../../services/Network.js";
import DomainExtraction from "../../reuseable/DomainExtraction";
import DomainMigrationPriceDomain from "./includes/DomainPricing/DomainMigrationPriceDomain";
import DomainPricingRegistration from "./includes/DomainPricing/DomainPricingRegistration";
import DomainSearchGlobal from "../../reuseable/DomainSearchGlobal";
import Faq from "../../reuseable/Faq";
import GoingOnlineDomainPricing from "./includes/DomainPricing/GoingOnlineDomainPricing";
import WeGotMore from "../../reuseable/WeGotMore";
import WillingForHelp from "../../reuseable/WillingForHelp";
import TechinalDomainPricing from "./includes/DomainPricing/TechinalDomainPricing";
const DomainPricing = () => {
  const { currencyName } = useSelector((state) => state.currencyName);
  const [spotlight, setspotlight] = useState([]);
  const [popList, setpopList] = useState([]);
  const [ccList, setccList] = useState([]);
  const [allList, setallList] = useState([]);

  const [poptlds] = useState([
    "com",
    "net",
    "org",
    "info",
    "us",
    "biz",
    "pk",
    "com.pk",
    "edu.pk",
    "uk",
    "co.uk",
    "com.au",
    "ca",
    "online",
    "ae",
    "co",
  ]);
  const [cctlds] = useState([
    "pk",
    "com.pk",
    "edu.pk",
    "net.pk",
    "org.pk",
    "uk",
    "co.uk",
    "com.au",
    "ca",
    "ae",
    // "com.ae",
    "de",
    "in",
    // "es",
    "org.ae",
    "co.ae",
  ]);

  useEffect(() => {
    GetSpotlightTldswithPrice().then((res) => {
      setspotlight(res);
      GetTldPriceNew(poptlds).then((respo) => {
        setpopList(respo);
        GetTldPriceNew(cctlds).then((ress) => {
          setccList(ress);
          GetAllTlds().then((resss) => {
            setallList(resss);
          });
        });
      });
    });
  }, [currencyName]);

  const data = {
    Heading: "Quit Worrying About Migration Hassles!",
    SubHeading:
      "Our experts can transfer your website and get you onboard for FREE. With us, migration is like ripping off a band aid – quick and painless!",
    ButtonName: "Migrate Now!",
  };
  const faqData = {
    heading: "Frequently Asked Questions",
    subHeading: "Dedicated to Helping You Succeed!",
    faqs: [
      {
        id: 0,
        title: "Why do I need to register a domain?",
        desc: "Choosing a domain name and registering is often the very first step when it comes to creating your own website. Your domain name is the address that will help your visitors find you, make sure it’s perfect and resonates with your business.",
        faqId: "FAQ1",
        dataBsTarget: "#faqOne",
        ariaControls: "faqOne",
        ariaLabelledby: "FAQ1",
        faqParaghId: "faqOne",
      },
      {
        id: 1,
        title: "What is the best way to choose a domain name?",
        desc: "Go for domain names that are easy to remember and preferably the ones that are short. It’s also a great idea to choose a domain name that is linked with your business and company goals. You can also use keywords in your domain name to increase the probability of your site appearing in Search Engine Result Pages. A domain name can include letters, hyphens, and numbers, but not special characters.",
        faqId: "FAQ2",
        dataBsTarget: "#faqTwo",
        ariaControls: "faqTwo",
        ariaLabelledby: "FAQ2",
        faqParaghId: "faqTwo",
      },
      {
        id: 2,
        title: "Is domain name privacy and protection worth it?",
        desc: "Yes, it is, if you don’t want your personal contact information to be found in the public WHOIS directory. Domain privacy and protection can also help protect you from identity theft and spam.",
        faqId: "FAQ3",
        dataBsTarget: "#faqThree",
        ariaControls: "faqThree",
        ariaLabelledby: "FAQ3",
        faqParaghId: "faqThree",
      },
      {
        id: 3,
        title: "Can I buy any domain extension?",
        desc: "Some domain extensions have restrictions on them and you cannot purchase them until you meet a certain criterion or have the required authorization, such as in case of .gov and .edu. Other than that, most domain extensions are available to anyone for a purchase.",
        faqId: "FAQ4",
        dataBsTarget: "#faqFour",
        ariaControls: "faqFour",
        ariaLabelledby: "FAQ4",
        faqParaghId: "faqFour",
      },
    ],
  };
  const WillingToHelpData = {
    heading: "Ready, Willing and Able to Help!",
    subHeading:
      "Whether it’s a question, query, or you want to discuss a project, the WebSouls team is at your service anytime, at all times!",
    buttonOne: "Call: +92 423 523 8871 - 73",
    buttonTwo: " Email Us",
    image: "assests/img/Team-Websouls-Gulraiz-Khan.png",
  };
  const GoingOnlineData = {
    Heading: "Going Online? You’d Need More!",
    subHeading: "We Don’t Just Sell Domains, We Launch Businesses!",
    box: [
      {
        id: 1,
        topTitle: "Need Email Hosting?",
        Title: "Try Business Hosting",
        buttonLink: "/business-hosting",
      },
      {
        id: 2,
        topTitle: "Need Something Simpler?",
        Title: "Try WordPress Hosting",
        buttonLink: "/wordpress-hosting-in-pakistan",
      },
    ],
  };
  const technicalSpecificationsData = {
    Heading: "Don’t Be Stumped. We’ve Got You Covered!",
    subHeading: "Domain Name Tips from Experts",
    box: [
      {
        id: 1,
        icon: "icon ws-quick",
        title: "Easy-to-Remember",
        peragraph:
          "It’s great if your domain name includes a keyword to help with SEO, but make sure to keep it simple, catchy, and memorable. Avoid going for a name that’s way too long or complicated.",
      },
      {
        id: 2,
        icon: "icon ws-brand",
        title: "Resonates with Your Brand",
        peragraph:
          "Nothing is better than a domain name that’s brandable, highly unique, and resonates with your business and company goals. Try and keep it relevant to the purpose of your website",
      },
      {
        id: 3,
        icon: "icon ws-time-a",
        title: "Don’t Waste Time",
        peragraph:
          "When it comes to domain names, they get registered pretty fast. So, if you’ve found your ideal one, don’t waste any more time, just hurry up and register before anyone else does!",
      },
    ],
  };

  const domainSearch = {
    Heading: "A Perfect Beginning For A Perfect Website",
    SubHeading:
      "Find and claim the perfect domain name before anyone else does!",
  };
  return (
    <>
      <Helmet>
        <title>
          Domain Registration in Pakistan | Buy Domain in Pakistan | WebSouls
        </title>
        <meta
          name="title"
          content="Domain Registration in Pakistan | Buy Domain in Pakistan | WebSouls"
        />
        <meta
          name="description"
          content="Looking for Domain Registration in Pakistan? WebSouls providing industry’s cheapest domains in Pakistan. You can buy domain in Pakistan of your choice in no time."
        />
        <meta name="keywords" content="" />
        <script type="application/ld+json">
          {`{
  "@context": "https://schema.org",
  "@type": "Organization",
  "name": "WebSouls",
  "alternateName": "Web Hosting Pakistan",
  "url": "https://www.websouls.com/",
  "logo": "https://www.websouls.com/public/uploads/images/logo_1589438878.png",
  "contactPoint": [{
    "@type": "ContactPoint",
    "telephone": "+92 321 407 7991",
    "contactType": "technical support",
    "areaServed": "PK",
    "availableLanguage": ["en","Urdu"]
  },{
    "@type": "ContactPoint",
    "telephone": "+92 322 525 2352",
    "contactType": "sales",
    "areaServed": "PK",
    "availableLanguage": ["en","Urdu"]
  }],
  "sameAs": [
    "https://www.facebook.com/WebSouls/",
    "https://www.twitter.com/futuresouls",
    "https://www.instagram.com/websouls.pk/",
    "https://www.linkedin.com/company/websouls/",
    "https://github.com/websouls",
    "https://www.websouls.com/"
  ]
}`}
        </script>
        <script type="application/ld+json">
          {`{
  "@context": "https://schema.org/", 
  "@type": "Product", 
  "name": "Domain Registration in Pakistan - WebSouls",
  "image": "https://www.websouls.com/public/uploads/images/logo_1589438878.png",
  "description": "Buying the perfect domain for your business website has never been so easy before in Pakistan. With our online domain checker at WebSouls you can look up for the perfect domain name for your site, find a suitable web address, and purchase your ideal domain at cheap rates in Pakistan.

If you want to buy a domain in Lahore or Karachi, all you have to do is visit one of our offices and let our experts guide you through the process. Apart from this, you can get all the information about our domain registration services from our website or by contacting us through live chat, phone call, or email. We respond to all our customer tickets within no time.",
  "brand": "WebSouls",
  "sku": "Domain Registration in Pakistan",
  "mpn": "Domain Registration in Pakistan",
  "offers": {
    "@type": "AggregateOffer",
    "url": "https://www.websouls.com/domain-registration",
    "priceCurrency": "PKR",
    "lowPrice": "3500",
    "highPrice": "8500",
    "offerCount": "3"
  },
  "aggregateRating": {
    "@type": "AggregateRating",
    "ratingValue": "4.8",
    "bestRating": "5",
    "worstRating": "4.1",
    "ratingCount": "5329"
  }
}`}
        </script>
        <script type="application/ld+json">
          {`{
  "@context": "https://schema.org",
  "@type": "FAQPage",
  "mainEntity": [{
    "@type": "Question",
    "name": "Why do I need to register a domain?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Choosing a domain name and registering is often the very first step when it comes to creating your own website. Your domain name is the address that will help your visitors find you, make sure it’s perfect and resonates with your business."
    }
  },{
    "@type": "Question",
    "name": "What is the best way to choose domain name?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Go for domain names that are easy to remember and preferably the ones that are short. It’s also a great idea to choose a domain name that is linked with your business and company goals. You can also use keywords in your domain name to increase the probability of your site appearing in Search Engine Result Pages. A domain name can include letters, hyphens, and numbers, but not special characters."
    }
  },{
    "@type": "Question",
    "name": "Is domain privacy & protection worth it?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Yes, it is, if you don’t want your personal contact information to be found in the public WHOIS directory. Domain privacy and protection can also help protect you from identity theft and spam."
    }
  },{
    "@type": "Question",
    "name": "Can I buy any domain extension?",
    "acceptedAnswer": {
      "@type": "Answer",
      "text": "Some domain extensions have restrictions on them and you cannot purchase them until you meet a certain criterion or have the required authorization, such as in case of .gov and .edu. Other than that, most domain extensions are available to anyone for a purchase."
    }
  }]
}`}
        </script>
        <link rel="canonical" href="https://websouls.com/domain-registration" />
      </Helmet>
      <DomainSearchGlobal domainSearch={domainSearch}/>
      <DomainExtraction spotlight={spotlight} />
      <WeGotMore popList={popList} ccList={ccList} allList={allList} />
      <GoingOnlineDomainPricing Data={GoingOnlineData} />
      <TechinalDomainPricing Data={technicalSpecificationsData} />
      <DomainMigrationPriceDomain Data={data} />
      <DomainPricingRegistration />
      <Faq Data={faqData} />
      <WillingForHelp helpData={WillingToHelpData} />
    </>
  );
};

export default DomainPricing;
