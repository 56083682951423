import React from "react";

const GetInTouch = () => {
  return (
    <section
      className="banner-size d-flex align-items-center w-100 h-100  mb-0"
      style={{  height: "400px" }}
    >
      <div className="container">
        <div className="row">
          <div className="col-md-12 col-sm-12 col-xs-12 pb-sm-25">
            <div className="w-100 text-center">
              <h2 className="first-heading color-b">Get In Touch!</h2>
              <p className="second-heading">
                We’re Approachable and Willing to Help
              </p>
              <hr className="divider scnd_divider mx-auto mb-5" />
              <div className="row">
                <div className="col-md-6 border-right border-1 ">
                  <div className="reach_sction">
                    <h2 className="first-heading color-b">PK OFFICE</h2>
                    <p className="fs-5" style={{ color: "#414042" }}>
                      <strong>
                        Lahore Office:
                      </strong>
                      &nbsp;Office # 22, 2nd Office Floor, IT Tower, Behind
                      Hafeez Center, 73-E-1 Hali Road, Gulberg III, Lahore,
                      Pakistan.
                    </p>
                    <p className="fs-5" style={{ color: "#414042" }}>
                      <strong>Sales:&nbsp;</strong>
                      +92 321 4776996,&nbsp;+92 321 4776995, +92 322
                      5252352,&nbsp;+92 42 35238871-73
                    </p>
                    <p className="fs-5" style={{ color: "#414042" }}>
                      <strong>Timing:</strong> (9AM
                      to 7PM)
                    </p>
                    <p className="fs-5" style={{ color: "#414042" }}>&nbsp;</p>
                    <p className="fs-5" style={{ color: "#414042" }}>
                      <strong>
                        Karachi Office:
                      </strong>
                      &nbsp;Bilal Arcade&nbsp;Building No. 19-D
                      Office&nbsp;No.&nbsp;1 Khy-E-Shamsheer St No. 10 Khada
                      Market DHA Phase 5 Karachi.
                    </p>
                    <p className="fs-5" style={{ color: "#414042" }}>
                      <strong>Sales :</strong>
                      &nbsp;+92 300 2002122,&nbsp;+92 321 477 0052&nbsp;
                    </p>
                    <p className="fs-5" style={{ color: "#414042" }}>
                      <strong>Timing:</strong> (11AM
                      to 8PM)
                    </p>
                    <p className="fs-5" style={{ color: "#414042" }}>
                      <br />
                      <strong>
                        Support (Lahore &amp; Karachi):
                      </strong>
                    </p>
                    <p className="fs-5" style={{ color: "#414042" }}>
                      &nbsp;+92 321 407 7991, +92 321 477 6910, +92 42
                      35238871-73&nbsp;
                    </p>
                    <p className="fs-5" style={{ color: "#414042" }}>
                      <strong>Timing:</strong> (8AM
                      to 12AM)
                    </p>
                    <p className="fs-5" style={{ color: "#414042" }}>&nbsp;</p>
                  </div>
                </div>
                <div className="col-md-6">
                  <div style={{marginLeft: 20}} className="reach_sction sm-ml-0">
                    <h2 className="first-heading color-b">UK OFFICE</h2>
                    <p className="fs-5" style={{ color: "#414042" }}>
                      915 Yeovil Road, SL1 4JG Slough Trading Estate, United
                      Kingdom
                    </p>
                    <p className="fs-5" style={{ color: "#414042" }}>M. +44 1753 339 432</p>
                    <p className="fs-5" style={{ color: "#414042" }}>Registered in UK:&nbsp;10514127</p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default GetInTouch;
