import React from "react";

const Faq = (props) => {
  const { Data } = props;
  return (
    <section 
      className="sec-faq background-alter"
      style={{ marginBottom: "0px", marginTop: "0" }}
    >
      <div className="faq-padding-set bg-white">
        <div className="container">
          <h2 className="text-center first-heading">{Data && Data.heading}</h2>
          <h5 className="text-center second-heading">
            {Data && Data.subHeading}
          </h5>
          <hr className="divider scnd_divider mx-auto mb-4" />
          <div className="row">
            <div className="sec-faq-accor">
              <div className="accordion" id="accordionExample">
                {Data &&
                  Data.faqs &&
                  Data.faqs.map((el) => (
                    <React.Fragment key={el.id}>
                      <div id="hiring"   className="accordion-item">
                        <h2  className="accordion-header" id={el.faqId}>
                          <button
                            className="accordion-button collapsed"
                            type="button"
                            data-bs-toggle="collapse"
                            data-bs-target={el.dataBsTarget}
                            aria-expanded="false"
                            aria-controls={el.ariaControls}
                          >
                            {el.title}
                          </button>
                        </h2>
                        <div
                          id={el.faqParaghId}
                          className="accordion-collapse collapse"
                          aria-labelledby={el.ariaLabelledby}
                          data-bs-parent="#accordionExample"
                        >
                          <div className="accordion-body">
                            <p>{el.desc}</p>
                            {
                              el.desc1 ? <> <br/>
                              <p>{el.desc1}</p></> : null
                            }
                           
                          </div>
                        </div>
                      </div>
                    </React.Fragment>
                  ))}
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default React.memo(Faq);
