import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import { saleApi } from "../../services/Network";
import { useSelector } from "react-redux";

function useOutsideAlerter(ref) {
  useEffect(() => {
    
    /**
     * Alert if clicked on outside of element
     */
    function handleClickOutside(event) {
      if (ref.current && !ref.current.contains(event.target)) {
        if (window.innerWidth < 960) {
          let element = document.getElementById("offcanvasExample");
          document.getElementById("cross").click();
        }
      }
    }
    // Bind the event listener
    document.addEventListener("mousedown", handleClickOutside);
    return () => {
      // Unbind the event listener on clean up
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, [ref]);
}

const Navbar = () => {
  const [promotions, setPromotions] = useState([]);
  const wrapperRef = useRef(null);

  const { isError } = useSelector((state) => state.isError);

  useOutsideAlerter(wrapperRef);

  const MobileMenuHandelClick = () => {
    if (window.innerWidth < 960) {
      let element = document.getElementById("offcanvasExample");
      document.getElementById("cross").click();
    }
  };

  const handelPreventDrop = () => {
    // document
    //   .getElementsByClassName("dropdown-menu")[0]
    //   .classList.remove("show");
    // document
    //   .getElementsByClassName("dropdown-menu")[1]
    //   .classList.remove("show");
    // document
    //   .getElementsByClassName("dropdown-menu")[2]
    //   .classList.remove("show");
    // document
    //   .getElementsByClassName("dropdown-menu")[3]
    //   .classList.remove("show");
  };

  useEffect(() => {
    saleApi().then((res) => {
      setPromotions(res);
    });
  }, []);

  return (
    <div className="navigation_bar">
      <nav className="navbar navbar-expand-lg navbar-light">
        <div className="container">
          <div className="logo ">
            <Link to="/">
              <img src="/assests/img/logo.png" className="" alt="Websouls" />
            </Link>
          </div>
          <button
            className="btn  navbar-toggler border-3 px-2 btn-mobile-nav"
            type="button"
            data-bs-toggle="offcanvas"
            data-bs-target="#offcanvasExample"
            aria-controls="offcanvasExample"
          >
            <span className="navbar-toggler-icon"></span>
          </button>
          <div
            ref={wrapperRef}
            className="offcanvas offcanvas-start-lg"
            tabIndex={-1}
            id="offcanvasExample"
            aria-labelledby="offcanvasExampleLabel"
          >
            <div className="offcanvas-header d-flex d-lg-none">
              <Link to="/">
                <img
                  src="assests/img/logo.png"
                  className=""
                  alt="Websouls"
                  style={{ width: "50%" }}
                />
              </Link>
              <a
                id="cross"
                className="text-reset p-0"
                data-bs-dismiss="offcanvas"
                aria-label="close"
              >
                <svg
                  style={{ fill: "#414042" }}
                  xmlns="http://www.w3.org/2000/svg"
                  width={24}
                  height={24}
                  fill="#FFFFFF"
                  className="bi bi-x-circle"
                  viewBox="0 0 16 16"
                >
                  <path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" />
                  <path d="M4.646 4.646a.5.5 0 0 1 .708 0L8 7.293l2.646-2.647a.5.5 0 0 1 .708.708L8.707 8l2.647 2.646a.5.5 0 0 1-.708.708L8 8.707l-2.646 2.647a.5.5 0 0 1-.708-.708L7.293 8 4.646 5.354a.5.5 0 0 1 0-.708z" />
                </svg>
              </a>
            </div>

            <div className="offcanvas-body text-right justify-content-end">
              {
                isError ? null :  <ul className="navbar-nav">
                <li
                  onClick={() => handelPreventDrop()}
                  className="nav-item dropdown"
                >
                  <a
                    className="nav-link nav-link-new  dropdown-toggle"
                    id="domainDropDown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Domain
                  </a>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="domainDropDown"
                  >
                    <li>
                      <span>
                        <Link
                          onClick={() => MobileMenuHandelClick()}
                          className="dropdown-item"
                          to="/domain-registration"
                        >
                          Domain Pricing
                        </Link>
                      </span>
                    </li>
                    <li>
                      <span>
                        <Link
                          onClick={() => MobileMenuHandelClick()}
                          className="dropdown-item"
                          to="buy-pk-domain"
                        >
                          Pk Domain
                        </Link>
                      </span>
                    </li>
                    <li>
                      <span>
                        <Link
                          onClick={() => MobileMenuHandelClick()}
                          className="dropdown-item"
                          to="/domain-transfer"
                        >
                          Domain Transfer
                        </Link>
                      </span>
                    </li>
                    <li>
                      <span>
                        <Link
                          onClick={() => MobileMenuHandelClick()}
                          className="dropdown-item"
                          to="/buy-ae-domains"
                        >
                          Ae Domain
                        </Link>
                      </span>
                    </li>
                  </ul>
                </li>

                <li
                  onClick={() => handelPreventDrop()}
                  className="nav-item dropdown"
                >
                  {promotions.some((type) => type.includes("_hosting")) ? (
                    <span
                      className=""
                      style={{
                        position: "absolute",
                        fontSize: "75%",
                        lineHeight: 0,
                        verticalAlign: "baseline",
                        paddingLeft: ".5rem",
                        
                      }}
                    >
                      Sale
                    </span>
                  ) : null}

                  <a
                    className="nav-link nav-link-new dropdown-toggle"
                    id="hostingDropDown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Hosting
                  </a>

                  <ul
                    className="dropdown-menu"
                    aria-labelledby="hostingDropDown"
                  >
                    <li>
                      <Link
                        onClick={() => MobileMenuHandelClick()}
                        className="dropdown-item"
                        to="/shared-hosting"
                      >
                        Shared Hosting{" "}
                        {promotions.includes("shared_hosting") ? (
                          <span
                            className="badge badge-important"
                            style={{
                              background: "red",
                              border: 10,
                              borderRadius: 10,
                              fontSize: 10,
                              display: "inline-block",
                              lexDirection: "row-reverse",
                              flexWrap: "wrap",
                            }}
                          >
                            Sale
                          </span>
                        ) : null}
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => MobileMenuHandelClick()}
                        className="dropdown-item"
                        to="/business-hosting"
                      >
                        Business Hosting{" "}
                        {promotions.includes("bussiness_hosting") ? (
                          <span
                            className="badge badge-important"
                            style={{
                              background: "red",
                              border: 10,
                              borderRadius: 10,
                              fontSize: 10,
                              display: "inline-block",
                              lexDirection: "row-reverse",
                              flexWrap: "wrap",
                            }}
                          >
                           Sale
                          </span>
                        ) : null}
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => MobileMenuHandelClick()}
                        className="dropdown-item"
                        to="/reseller-hosting"
                      >
                        Reseller Hosting{" "}
                        {promotions.includes("reseller_hosting") ? (
                          <span
                            className="badge badge-important"
                            style={{
                              background: "red",
                              border: 10,
                              borderRadius: 10,
                              fontSize: 10,
                              display: "inline-block",
                              lexDirection: "row-reverse",
                              flexWrap: "wrap",
                            }}
                          >
                             Sale
                          </span>
                        ) : null}
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => MobileMenuHandelClick()}
                        className="dropdown-item"
                        to="/wordpress-hosting-in-pakistan"
                      >
                        Wordpress Hosting{" "}
                        {promotions.includes("wordpress_hosting") ? (
                          <span
                            className="badge badge-important"
                            style={{
                              background: "red",
                              border: 10,
                              borderRadius: 10,
                              fontSize: 10,
                              display: "inline-block",
                              lexDirection: "row-reverse",
                              flexWrap: "wrap",
                            }}
                          >
                            Sale
                          </span>
                        ) : null}
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => MobileMenuHandelClick()}
                        className="dropdown-item"
                        to="/free-pk-domain-with-hosting"
                      >
                        PK Hosting{" "}
                        {promotions.includes("pk_hosting") ? (
                          <span
                            className="badge badge-important"
                            style={{
                              background: "red",
                              border: 10,
                              borderRadius: 10,
                              fontSize: 10,
                              display: "inline-block",
                              lexDirection: "row-reverse",
                              flexWrap: "wrap",
                            }}
                          >
                            Sale
                          </span>
                        ) : null}
                      </Link>
                    </li>
                  </ul>
                </li>

                <li
                  onClick={() => handelPreventDrop()}
                  className="nav-item dropdown"
                >
                  {promotions.some((type) => type.includes("_server")) ? (
                    <span
                      className=""
                      style={{
                        position: "absolute",
                        fontSize: "75%",
                        lineHeight: 0,
                        verticalAlign: "baseline",
                        paddingLeft: ".5rem",
                       
                      }}
                    >
                      Sale
                    </span>
                  ) : null}
                  <a
                    className="nav-link nav-link-new dropdown-toggle"
                    id="serversDropdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Servers
                  </a>
                  <ul
                    className="dropdown-menu"
                    aria-labelledby="serversDropdown"
                  >
                    <li>
                      <Link
                        onClick={() => MobileMenuHandelClick()}
                        className="dropdown-item"
                        to="/dedicated-server"
                      >
                        Dedicated Servers{" "}
                        {promotions.includes("dedicated_server") ? (
                          <span
                            className="badge badge-important"
                            style={{
                              background: "red",
                              border: 10,
                              borderRadius: 10,
                              fontSize: 10,
                              display: "inline-block",
                              lexDirection: "row-reverse",
                              flexWrap: "wrap",
                            }}
                          >
                            Sale
                          </span>
                        ) : null}
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => MobileMenuHandelClick()}
                        className="dropdown-item"
                        to="/vps-hosting"
                      >
                        VPS{" "}
                        {promotions.includes("vps_server") ? (
                          <span
                            className="badge badge-important"
                            style={{
                              background: "red",
                              border: 10,
                              borderRadius: 10,
                              fontSize: 10,
                              display: "inline-block",
                              lexDirection: "row-reverse",
                              flexWrap: "wrap",
                            }}
                          >
                             Sale
                          </span>
                        ) : null}
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => MobileMenuHandelClick()}
                        className="dropdown-item"
                        to="/pk-vps"
                      >
                        PK-Based VPS{" "}
                        {promotions.includes("pk_server") ? (
                          <span
                            className="badge badge-important"
                            style={{
                              background: "red",
                              border: 10,
                              borderRadius: 10,
                              fontSize: 10,
                              display: "inline-block",
                              lexDirection: "row-reverse",
                              flexWrap: "wrap",
                            }}
                          >
                             Sale
                          </span>
                        ) : null}
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => MobileMenuHandelClick()}
                        className="dropdown-item"
                        to="/ssd-vps"
                      >
                        SSD VPS{" "}
                        {promotions.includes("ssd_vps") ? (
                          <span
                            className="badge badge-important"
                            style={{
                              background: "red",
                              border: 10,
                              borderRadius: 10,
                              fontSize: 10,
                              display: "inline-block",
                              lexDirection: "row-reverse",
                              flexWrap: "wrap",
                            }}
                          >
                             Sale
                          </span>
                        ) : null}
                      </Link>
                    </li>
                  </ul>
                </li>

                <li
                  onClick={() => handelPreventDrop()}
                  className="nav-item dropdown"
                >
                  <a
                    className="nav-link nav-link-new dropdown-toggle"
                    id="webDropwdown"
                    role="button"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                  >
                    Web Development
                  </a>
                  <ul className="dropdown-menu" aria-labelledby="webDropwdown">
                    <li>
                      <Link
                        onClick={() => MobileMenuHandelClick()}
                        className="dropdown-item"
                        to="/ecommerce-solution"
                      >
                        Ecommerce Solution
                      </Link>
                    </li>
                    <li>
                      <Link
                        onClick={() => MobileMenuHandelClick()}
                        className="dropdown-item"
                        to="/web-development"
                      >
                        Business Website
                      </Link>
                    </li>
                  </ul>
                </li>

                <li className="nav-item">
                  <Link
                    onClick={() => MobileMenuHandelClick()}
                    className="nav-link nav-link-new "
                    aria-current="page"
                    to="/ssl-certificates"
                  >
                    SSL Certificates{" "}
                    {promotions.includes("ssl") ? (
                      <span
                        className="badge badge-important"
                        style={{
                          background: "red",
                          border: 10,
                          borderRadius: 10,
                          fontSize: 10,
                          display: "inline-block",
                          lexDirection: "row-reverse",
                          flexWrap: "wrap",
                        }}
                      >
                         Sale
                      </span>
                    ) : null}
                  </Link>
                </li>
                <li className="nav-item">
                  <Link
                    onClick={() => MobileMenuHandelClick()}
                    className="nav-link nav-link-new "
                    aria-current="page"
                    to="/contactus"
                  >
                    Contact Us
                  </Link>
                </li>
              </ul>
              }
             
            </div>
          </div>
        </div>
      </nav>
    </div>
  );
};

export default Navbar;
