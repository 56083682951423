import React, { useState, useEffect } from "react";
import { GetPaymentMethods } from "../../../../services/Network.js";

const PaymentMethod = (props) => {
  const [paymentMethodsList, setPaymentMethodList] = useState([]);

  useEffect(() => {
    GetPaymentMethods().then((response) => {
      setPaymentMethodList(response.paymentmethods.paymentmethod);
    });
  }, []);
  const handleChange = (event) => {
    props.setPaymentMethodSelected(event.target.value);
  };
  return (
    <div
      className={
        localStorage.getItem("loggedClicked")
          ? "d-none"
          : "chose_payment_method"
      }
    >
      <h6 className="result-message p-4">Choose Your Payment Method*</h6>
      <div className="row">
        <div className="col-md-12">
          <div className="row px-3 py-3">
            <span id="spay" className="d-none">
              Select your Payment Method
            </span>
            {paymentMethodsList &&
              paymentMethodsList.map((pay) => (
                <React.Fragment key={pay.module}>
                  <div className=" col-xs-12 col-sm-12 col-md-4">
                    <div className="form-group">
                      <label>
                        <input
                          onChange={handleChange}
                          name="payment_method"
                          className="checkbox-round"
                          checked={props.paymentMethodSelected === pay.module}
                          value={pay.module}
                          type="checkbox"
                        />
                        <span> {pay.displayname}</span>
                      </label>
                    </div>
                  </div>
                </React.Fragment>
              ))}
          </div>
        </div>
      </div>
    </div>
  );
};

export default PaymentMethod;
