import { Link } from "react-router-dom";

const Banner = () => {
  return (
    <section
      className="banner-size  d-flex align-items-center"
      style={{
        backgroundImage: `url("assests/img/newmainbanner.webp")`,
        marginBottom: "0px",
      }}
    >
      <div className="container">
        <div className="row">
          <div className="col-md-7 col-sm-12 col-xs-12 pb-sm-25">
            <div className="w-100 float-left banner-left-text">
              <h3 className=" pb-sm-0">
                Get a<span>Hosting Plan</span> That's Worth it
              </h3>
              <Link className="banner-left-button" to="/business-hosting">
                Get Started
              </Link>
            </div>
          </div>
          <div className="col-md-5 col-sm-12 col-xs-12">
            <div className="w-100 float-left banner-right-text">
              <h3 className=" pb-sm-0">
                Ideal<span>Domain Name</span>Means Ideal Traffic
              </h3>
              <Link className="banner-right-button" to="/domain-registration">
                Search Domain
              </Link>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Banner;
