import React, { useState, useEffect } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import CartOrder from "./CartOrder";

const DedicatedSSLHostname = () => {
  //router hooks
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const { currencyName } = useSelector((state) => state.currencyName);
  const { packageCart } = useSelector((state) => state.packageCart);
  const { domainNameClient } = useSelector((state) => state.domainNameClient);

  const [isActive, setActive] = useState(false);
  const [isActive1, setActive1] = useState(true);
  const [isActive2, setActive2] = useState(false);
  const [isActive3, setActive3] = useState(false);
  const [isChecked, setChecked] = useState(false);

  //form states
  const [registerDomainName, setRegisterDomainName] = useState("");
  const [registerDomainTld, setRegisterDomainTld] = useState("com");
  const [transferDomainTld, setTransferDomainTld] = useState("com");
  const [transferDomainName, setTransferDomainName] = useState("");
  const [existingDomainTld, setExistingDomainTld] = useState("com");
  const [existingDomainName, setExistingDomainName] = useState("");
  const [DomainSearchResults, setDomainSearchResults] = useState([]);
  const [DomainTransferResults, setDomainTransferResults] = useState([]);
  const [radioName, setRadioName] = useState({});
  const [radioType, setRadioType] = useState("domainregister");
  const [priceDataSearchDomain, setPriceDataSearchDomain] = useState([]);
  const [reducerDomainName, setReducerDomainName] = useState("");

  useEffect(() => {
    if (location.key === "default") {
      navigate("/");
    } else {
      dispatch({
        type: "urlCurrent",
        payload: location.pathname,
      });
    }
  }, []);

  //handeling Register Domain

  const handelRegisterDomain = (e) => {
    const nameConcatenate = `${registerDomainName}`;
    setReducerDomainName(nameConcatenate);

    e.preventDefault();
    navigate("/domain", { state: { name: nameConcatenate, var: "bypass" } });
  };

  //handeling transfer domain

  const handelExitingDomain = (e) => {
    e.preventDefault();

    // if(existingDomainName.includes("."))
    const nameConcatenate = `${existingDomainName}`;
    dispatch({
      type: "clientDomainName",
      payload: existingDomainName,
    });
    // navigate("/get-hosting");
  };

  //continye to cart

  const handelCartDomain = (e) => {
    e.preventDefault();

    let newPrice = DomainSearchResults.price.filter(
      (el) => el.currency === currencyName.api_id
    );

    //navigating to cart

    navigate("/get-addons");
  };

  //handel radio button chnage
  const handleRadioChange = (e) => {
    const { name, value } = e.target;
    setRadioName({ [name]: value });
  };

  // Style
  const searchBox = {
    backgroundColor: "#f4f4f4",
    borderRadius: 5,
    border: "1px solid #d7d7d7",
    boxShadow: "#f6f6f6 0px 0px 11px 4px",
    padding: "0px 0px 30px 0px",
  };

  const toggleChecked1 = (e) => {
    setActive1(true);
    setActive2(false);
    setActive3(false);
  };
  const toggleChecked2 = (e) => {
    setActive1(false);
    setActive2(true);
    setActive3(false);
  };
  const toggleChecked3 = (e) => {
    setActive1(false);
    setActive2(false);
    setActive3(true);
  };

  return (
    <section style={{ margin: "0" }} className="psty">
      <div className="container">
        <div className="row g-5 mb-5">
          <CartOrder />
          <div className="col-lg-7 col-xl-8 col-xxl-8 margin-top11">
            <div className="selection_tab">
              {/* for regtister domain */}

              <div
                className="first_select_tab mb-3"
                id="first_tab"
                style={isActive1 ? searchBox : null}
              >
                <div className="row">
                  <div className="col-12">
                    <div className="form-group">
                      <label className="section-tab-heading checkbox_container active py-3">
                        <input
                          className=""
                          type="radio"
                          name="Domain"
                          value="Domain Register"
                          defaultChecked={true}
                          onClick={(e) => toggleChecked1(e.target)}
                          onChange={(e) => handleRadioChange(e)}
                        />
                        I would like to have SSL with this domain
                        <span className="checkedmark m-1" />
                      </label>
                    </div>
                  </div>

                  <div
                    className="first_tab search_form"
                    style={{ display: "block" }}
                  >
                    <div className="row">
                      <div className="col-md-12 col-sm-12 col-xs-12">
                        <div className="input-group p-3">
                          <div className="input-group box-border">
                            <input
                              autoComplete="off"
                              type="search"
                              name="search"
                              id="dedicated_check_search"
                              className="form-control"
                              placeholder="I would like to use this domain as hostname..."
                              aria-labelledby="search-domain"
                              aria-label="Search your domain here"
                              value={existingDomainName}
                              onChange={(e) =>
                                setExistingDomainName(e.target.value)
                              }
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div
                className="congratulation_section"
                id="empty-error"
                style={{ display: "none" }}
              >
                <div className="row">
                  <div className="col-md-12">
                    <div className="search_congratulation_msg search_domain_msg">
                      <div className="row">
                        <div className="col-md-1 col-sm-2 col-xs-2 pr-0">
                          <i className="fa fa-times-circle" />
                        </div>
                        <div className="col-md-8 col-sm-8 col-xs-8 pl-0 pr-0">
                          <div className="pl-4">
                            <h5 style={{ paddingTop: 10 }}>
                              Please enter the domain name first.
                            </h5>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              {/* for transfer domain */}

              {/* For existing domain Second */}

              <br />
              <br />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default DedicatedSSLHostname;
