import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import BannerTwo from "../reuseable/Banner2";
import Faq from "../reuseable/Faq";

const Privacy = () => {
  useEffect(() => {
    window.scrollTo({
      top: 0,
      left: 0,
      behavior: "smooth",
    });
  }, []);

  const bannerData = {
    heading: "Your Privacy Matters",
    peragraph:
      "Committed to Protecting the Privacy of You and Your Customers' Data",
  };
  const faqData = {
    heading: "",
    subHeading: [
      <>
        <p>
          Use of the WebSouls websites and services indicates your consent to
          the collection and use of information as described below.
        </p>
        <p>
          Any questions which you may have regarding this privacy policy should
          be directed to&nbsp;
          <a href="mailto:info@websouls.com">info@websouls.com</a>.
        </p>
      </>,
    ],
    faqs: [
      {
        id: 1,
        title: "What information is collected and how is it used?",
        desc: `We ask you to supply certain information when you use our site, especially when you sign up with us as a customer. This information will be used in the management of our own database and to enable us to contact you about the service you have requested (for example, to confirm the registration of a domain name or to inform you of the need to renew your registration when it expires). We may also use your email address to send you our regular WebSouls newsletters. If you wish you may opt out from receiving these newsletters (see "WebSouls Newsletter" below). In addition, when you use our website we may store your IP address and may use this information to administer our website and help to diagnose any network or server problems. We may use cookies to keep track of your use of our website and e-commerce system. Information passed to us from your web browser may be stored and used in our internal statistical analysis. We may also automatically collect non-personal information about you such as the type of internet browsers you use or the site from which you linked to our website. You cannot be identified from this information and it is only used to assist us in providing an effective service on our website.`,
        faqId: "FAQ1",
        dataBsTarget: "#faqOne",
        ariaControls: "faqOne",
        ariaLabelledby: "FAQ1",
        faqParaghId: "faqOne",
      },
      {
        id: 2,
        title: "Do we share the information with anyone else?",
        desc: `WebSouls will not, without your consent, pass your personal information to a third party except in the following circumstances:
        2.1 in order to register a domain name we are required to send certain information like your name, business name (if applicable), postal address and email address etc to organisations that formally record the registration; this information may be available to others via a "Whois" search;
        2.2 if required to do so by law or by any organisation with appropriate authority;
        2.3 if you have signed up for optional services which require us to pass your personal information to another company (see "Optional Services" below)
        2.4 to any financial institution with whom we hold an account for the purpose of verifying credit status and / or validating financial transactions;
        2.5 we may provide aggregate statistics about our sales, customers, traffic patterns and other site information to third parties, but these statistics will not include any information that could personally identify you;
        2.6 in order to assist us in understanding our customer base some anonymous information (i.e not name or specific address) may from time to time be passed on to a third party for data analysis.
        2.7 as part of a sale or transfer of the shares and/or assets of WebSouls Limited. WebSouls Limited is not responsible for the privacy practices of other companies or websites. 3. Credit Cards
        Credit card payment for our services is processed by our secure payment service provider. We do not record your credit card details on our database and the payment service provider does not record any other information about you.`,
        faqId: "FAQ2",
        dataBsTarget: "#faqTwo",
        ariaControls: "faqTwo",
        ariaLabelledby: "FAQ2",
        faqParaghId: "faqTwo",
      },
      {
        id: 3,
        title: "WebSouls Newsletter",
        desc: `From time to time WebSouls will send newsletters to those customers who have opted to be included on our various customer mailing lists. New customers have the opportunity to decide to opt in or out of these mailing lists when they first sign up with us to create an account. Existing customers or other people who are interested in the newsletters may opt in or out by sending an email request to our support team.`,
        faqId: "FAQ3",
        dataBsTarget: "#faqThree",
        ariaControls: "faqThree",
        ariaLabelledby: "FAQ3",
        faqParaghId: "faqThree",
      },
      {
        id: 4,
        title: "Optional Services",
        desc: "From time to time WebSouls may offer additional services to our customers. In some cases these services may require us to pass personal data held by us to a third party. Any such service will be optional and the offer will be to those who have opted-in at the time of sign-up or otherwise.",
        faqId: "FAQ4",
        dataBsTarget: "#faqFour",
        ariaControls: "faqFour",
        ariaLabelledby: "FAQ4",
        faqParaghId: "faqFour",
      },
      {
        id: 5,
        title: "Spam And Phishing Policy",
        desc: `Manipulating identifiers, such as email headers and images,to disguise the origin of any content transmitted using the Service. Using or causing to be used Service computer systems to facilitate the transmission of unsolicited or unauthorized material. This includes any promotional materials, URLs, "junk mail," "chain letters," "pyramid schemes," or any other form of unauthorized solicitation that you may upload, post, email, transmit, or otherwise make available.Using "robots" or otherwise harvesting other's email addresses from the Service. Sending a message that directs a recipient to a web site that uses a third party's branding, trademarks and or other intellectual property to mislead the recipient into believing that he is using a site authorized by that third party. Test email accounts and Test keywords are blocked at shared hosting environment in order to prevent spamming and other malicious activities. eg. test@yourdomain.com, any.test@yourdomain.com, test.any@yourdomain.com`,
        faqId: "FAQ5",
        dataBsTarget: "#faqFive",
        ariaControls: "faqFive",
        ariaLabelledby: "FAQ5",
        faqParaghId: "faqFive",
      },
      {
        id: 7,
        title: "Fraudulent activity",
        desc: `Use of WebSouls service to make fraudulent offers to sell or buy products, items, or services, or to advance any type of financial scam such as "pyramid schemes," and "chain letters" is strictly prohibited. `,
        faqId: "FAQ7",
        dataBsTarget: "#faqSeven",
        ariaControls: "faqSeven",
        ariaLabelledby: "FAQ7",
        faqParaghId: "faqSeven",
      },
      {
        id: 8,
        title: "Impersonation",
        desc: `Adding, removing or modifying identifying network header information in an effort to deceive or mislead is prohibited. Misuse the name of any Government entity is strictly prohibited and WebSouls reserves the rights to shutdown services at request of any Government entity. Company domains registered by employees or third parties shall be considered under the ownership of organizatoin and can be claimed back after providing all necessary information. Attempting to impersonate any person by using forged headers or other identifying information is prohibited. The use of anonymous remailers or nicknames does not constitute impersonation.`,
        faqId: "FAQ8",
        dataBsTarget: "#faqEight",
        ariaControls: "faqEight",
        ariaLabelledby: "FAQ8",
        faqParaghId: "faqEight",
      },
      {
        id: 9,
        title: "Unsolicited commercial e-mail / Unsolicited bulk e-mail (SPAM)",
        desc: `Use of the WebSouls service to transmit any unsolicited commercial or unsolicited bulk e-mail is expressly prohibited. Violations of this type will result in the immediate termination of the offending WebSouls account. `,
        faqId: "FAQ9",
        dataBsTarget: "#faqNine",
        ariaControls: "faqNine",
        ariaLabelledby: "FAQ9",
        faqParaghId: "faqNine",
      },
      {
        id: 10,
        title: "E-mail / News Bombing",
        desc: `Malicious intent to impede another person's use of electronic mail services or news will result in the immediate termination of the offending WebSouls account. `,
        faqId: "FAQ10",
        dataBsTarget: "#faqTen",
        ariaControls: "faqTen",
        ariaLabelledby: "FAQ10",
        faqParaghId: "faqTen",
      },
      {
        id: 11,
        title: "E-mail / Message Forging",
        desc: `Forging any message header, in part or whole, of any electronic transmission, originating or passing through the WebSouls service is in violation of this POLICY.`,
        faqId: "FAQ11",
        dataBsTarget: "#faqEleven",
        ariaControls: "faqEleven",
        ariaLabelledby: "FAQ11",
        faqParaghId: "faqEleven",
      },
      {
        id: 12,
        title: "Hourly E-mail Limit",
        desc: `At shared hosting accounts, clients are limited to send not more than 60 emails per hour. Similarly VIP accounts hosted at shared hosting are restricted to send up to 200 email per hour max. Clients can contact to our Sales Team and can take appropriate email solution if they may want to send more emails within an hour.`,
        faqId: "FAQ12",
        dataBsTarget: "#faqTwelve",
        ariaControls: "faqTwelve",
        ariaLabelledby: "FAQ12",
        faqParaghId: "faqTwelve",
      },
      {
        id: 13,
        title: "Usenet Spaming",
        desc: `WebSouls has a zero tolerance policy for the use of its network for the posting of messages or commercial advertisements, which violate the rules, regulations, FAQ or charter of any newsgroups or mailing list. Commercial messages that are appropriate under the rules of a newsgroup or mailing list or that are solicited by the recipients are permitted.`,
        faqId: "FAQ13",
        dataBsTarget: "#faqThirteen",
        ariaControls: "faqThirteen",
        ariaLabelledby: "FAQ13",
        faqParaghId: "faqThirteen",
      },
      {
        id: 14,
        title: "Unauthorized access",
        desc: `Use of the WebSouls service to access, or to attempt to access, the accounts of others, or to penetrate, or attempt to penetrate, security measures of HostRock.Net's or another entity's computer software or hardware, electronic communications system, or telecommunications system, whether or not the intrusion results in the corruption or loss of data, is expressly prohibited and the offending WebSouls account is subject to immediate termination.`,
        faqId: "FAQ14",
        dataBsTarget: "#faqForteen",
        ariaControls: "faqForteen",
        ariaLabelledby: "FAQ14",
        faqParaghId: "faqForteen",
      },
    ],
  };
  return (
    <>
      <Helmet>
        <title>Privacy Policy</title>
        <meta name="title" content="" />
        <meta name="description" content="" />
        <meta name="keywords" content="" />
        <link rel="canonical" href="https://websouls.com/privacy" />
      </Helmet>
      <BannerTwo Data={bannerData} />
      <Faq Data={faqData} />
    </>
  );
};

export default Privacy;
